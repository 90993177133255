import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "App";
import { useApi } from "ApiHooks";
import BlockUi from "react-block-ui";
import { GrillaSync, TipoCampo } from "Grilla";
import { Button, Form, Modal } from "react-bootstrap";
import { DialogoConfirmar, DialogoConfirmarRef } from "DialogoConfirmar";
import { MyModal } from "MyModal";
import { TiposCertificacion } from "Paginas/Catalogos/Certificaciones/TiposCertificacion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";
export function Certificaciones() {
    let history = useHistory();
    let { mostrarError } = useContext(AppContext);
    let api = useApi();
    let [cargando, updateCargando] = useState(true);
    let [nroArticulo, updateNroArticulo] = useState('');
    let [certificaciones, updateCertificaciones] = useState<any[]>([]);
    let [mostrarModalTipos, updateMostrarModalTipos] = useState(false);
    let dialogoConfirmarRef = useRef<DialogoConfirmarRef>(null);
    async function cargarCertificaciones() {
        try {
            updateCargando(true);
            let lista = await api.busquedaCertificaciones(nroArticulo);
            updateCertificaciones(lista);
            updateCargando(false);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar certificaciones', error);
                mostrarError('Error al cargar certificaciones');
                updateCargando(false);
            }
        }
    }
    useEffect(() => {
        cargarCertificaciones();
        //eslint-disable-next-line
    }, []);
    function eliminarCertificacion(id: string) {
        dialogoConfirmarRef.current!.mostrar().then(async () => {
            try {
                updateCargando(true);
                await api.deleteCertificacion(id);
                cargarCertificaciones();
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al eliminar certificación', error);
                    mostrarError('Error al eliminar certificación');
                    updateCargando(false);
                }
            }
        }).catch(() => { });
    }
    let campos = [{ propiedad: 'Id', clave: true, visible: false }, { titulo: 'Tipo', propiedad: 'NombreTipo' },
    { titulo: 'Número', propiedad: 'Numero' },
    { titulo: 'Fecha Vencimiento', propiedad: 'FechaVencimiento', tipo: TipoCampo.Date },
    { titulo: 'Descripción', propiedad: 'Descripcion' }, { titulo: 'Usuario Modificación', propiedad: 'NombreModificadoPor' },
    { titulo: 'Fecha Modificación', propiedad: 'ModificadoEl', tipo: TipoCampo.DateTime }];
    return <>
        <h2>Certificaciones</h2>
        <BlockUi blocking={cargando}>
            <Form inline onSubmit={cargarCertificaciones} className="mb-2">
                <Form.Group controlId="txtNroArticulo">
                    <Form.Label className="mr-2">Nro. Articulo</Form.Label>
                    <Form.Control type="text" value={nroArticulo} onChange={(e: any) => updateNroArticulo(e.target.value)}></Form.Control>
                </Form.Group>
                <Button type="submit" variant="primary" className="ml-2">Buscar</Button>
            </Form>
            <Button className="mr-2 mb-2" onClick={() => history.push('/certificaciones/add')}>
                <FontAwesomeIcon icon={faPlus} />
                <span>Agregar</span>
            </Button>
            <Button className="mr-2 mb-2" onClick={() => updateMostrarModalTipos(true)}>Tipos Certificación</Button>
            <GrillaSync datos={certificaciones} campos={campos}
                eventoDetalle={(item: any) => history.push(`/certificaciones/edit/${encodeURIComponent(item.Id)}`)}
                eventoEliminar={(item: any) => eliminarCertificacion(item.Id)}></GrillaSync>
            <DialogoConfirmar ref={dialogoConfirmarRef} mensaje="¿Está seguro que desea eliminar esta certificación?" textoBotonConfirmar="Sí" textoBotonCancelar="No"></DialogoConfirmar>
        </BlockUi>
        <MyModal show={mostrarModalTipos} onHide={() => updateMostrarModalTipos(false)}>
            <Modal.Dialog size="xl">
                <Modal.Header closeButton>
                    <h2>Tipos certificación</h2>
                </Modal.Header>
                <Modal.Body>
                    <TiposCertificacion></TiposCertificacion>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateMostrarModalTipos(false)}>Cerrar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
    </>;
}