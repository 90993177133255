import React from "react";
import { MyModal } from "./MyModal";
import { Button, Modal } from "react-bootstrap";

export type DialogoConfirmarRef = {
    mostrar: () => Promise<boolean>
    cerrar: () => Promise<boolean>
}

export const DialogoConfirmar = React.forwardRef((props: {
    mensaje: string, textoBotonConfirmar?: string, textoBotonCancelar?: string,
    resolverPromesaAlCancelar?: boolean
}, ref: any) => {
    let [mostrar, updateMostrar] = React.useState(false);
    let funcionesPromesa = React.useRef<any>({ resolve: null, reject: null });
    const cerrar = () => {
        updateMostrar(false);
        funcionesPromesa.current.reject();
    }
    React.useImperativeHandle(ref, () => ({
        mostrar: () => {
            return new Promise((resolve, reject) => {
                funcionesPromesa.current = { resolve: resolve, reject: reject };
                updateMostrar(true);
            });
        }
    }));
    return (<MyModal show={mostrar} onHide={cerrar}>
        <Modal.Dialog>
            <Modal.Body>
                <p   style={{whiteSpace: 'pre-line'}}   className="lead">{props.mensaje}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => {
                    if (props.resolverPromesaAlCancelar) {
                        updateMostrar(false);
                        funcionesPromesa.current?.resolve(false);
                    } else {
                        cerrar();
                    }
                }}>
                    {props.textoBotonCancelar ?? 'Cancelar'}
                </Button>
                <Button type="button" variant="primary" onClick={() => {
                    updateMostrar(false);
                    funcionesPromesa.current.resolve(true);
                }}>
                    {props.textoBotonConfirmar ?? 'Confirmar'}
                </Button>
            </Modal.Footer>
        </Modal.Dialog>
    </MyModal>)
});

export const DialogoSiNoCancelar = React.forwardRef((props: {
    mensaje: string, textoBotonSi?: string, textoBotonNo?: string, textoBotonCancelar?: string,
}, ref: any) => {
    let [mostrar, updateMostrar] = React.useState(false);
    let funcionesPromesa = React.useRef<any>({ resolve: null, reject: null });
    const cerrar = () => {
        updateMostrar(false);
        funcionesPromesa.current.reject();
    }
    React.useImperativeHandle(ref, () => ({
        mostrar: () => {
            return new Promise((resolve, reject) => {
                funcionesPromesa.current = { resolve: resolve, reject: reject };
                updateMostrar(true);
            });
        }
    }));
    return (<MyModal show={mostrar} onHide={cerrar}>
        <Modal.Dialog>
            <Modal.Body>
                <p className="lead">{props.mensaje}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={cerrar}>
                    {props.textoBotonCancelar ?? 'Cancelar'}
                </Button>
                <Button type="button" variant="primary" onClick={() => {
                    updateMostrar(false);
                    funcionesPromesa.current.resolve(true);
                }}>
                    {props.textoBotonSi ?? 'Sí'}
                </Button>
                <Button type="button" variant="primary" onClick={() => {
                    updateMostrar(false);
                    funcionesPromesa.current.resolve(false);
                }}>
                    {props.textoBotonNo ?? 'No'}
                </Button>
            </Modal.Footer>
        </Modal.Dialog>
    </MyModal>)
});