//import { Formik, FormikProps } from "formik";
import React, { useState } from "react";
import { Button, Container, Form, Modal } from "react-bootstrap";
import { MyModal } from "MyModal";
//import { validarCUIT } from "Utilidades";
import Grilla, {GrillaRef} from "../../Grilla";

export type GetModalManifiestosRef = {
    mostrar: (manifiestos: any[] | any) => Promise<{nroManifiesto: string}>
};

export const GetModalManifiestos = React.forwardRef((props: any, ref: any) => {


    const [listaManifiestos, setListaManifiestos] = useState([{}])
    //const [showModal, setshowModal] = React.useState(true);
  //  let formikRef = React.useRef<FormikProps<any>>(null);
    let refGrilla = React.useRef<GrillaRef>(null);
    let funcionesPromesa = React.useRef<any>({ resolve: null, reject: null });


    let [estadoModal, updateEstadoModal] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrar') {
            return { abierto: true, opciones: accion.opciones, error: '', resolve: accion.resolve };
        } else if (accion.tipo === 'cerrar') {
            return { abierto: false, opciones: null, error: '' };
        } else if (accion.tipo === 'setError') {
            return { ...estado, error: accion.error };
        }
    }, { abierto: false, opciones: null, error: '' });
    

    React.useImperativeHandle(ref, () => ({
        mostrar: (manifiestos : any) => new Promise<void>((resolve, reject) => {
            funcionesPromesa.current = { resolve: resolve, reject: reject };
            setListaManifiestos(manifiestos);
            updateEstadoModal({ tipo: 'mostrar', resolve: resolve });
        })
    }));

    const cerrar = () => {
        if (estadoModal.resolve) {
            estadoModal.resolve();
        }
        updateEstadoModal({ tipo: 'cerrar' });
    }

    /*CONFIGURACION GRILLA*/
/*
    "Manifiesto": "96073MANI066369C",
    "EstadoManifiesto": "PRE",
    "Fraccionado": null,
    "Consolidado": null,
*/

    let campos = [
        { propiedad: 'Id', visible: false, clave: true },
        { titulo: 'Manifiesto', propiedad: 'Manifiesto' },
        { titulo: "Estado", propiedad: 'EstadoManifiesto' },
        { titulo: "Fraccionado", propiedad: 'Fraccionado' },
        { titulo: 'Consolidado', propiedad: 'Consolidado' }
    ]

    /*
    const modalStyles = {
        "display":"flex !important",
        "position": "absolute",
        "top": "50%"
    };*/
    async function cargarDatos(desde: number, hasta: number, cancelToken: any) {
        return { cantidadItems: listaManifiestos.length, items: listaManifiestos };
    }


    return (<>
        {/*onHide={cerrar}*/}
        <MyModal show={estadoModal.abierto} onHide={cerrar} >
            <Modal.Dialog size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Container >
                        <Form onSubmit={e => {
                            e.preventDefault();
                            }} >
                                <Grilla responsiva={true} campos={campos} cargarDatos={cargarDatos}
                                mostrarFormCambiarPagina={false}
                                eventoDetalle={false ? undefined : item => {
                                    updateEstadoModal({ tipo: 'cerrar' });
                                    funcionesPromesa.current?.resolve({ nroManifiesto: item.Manifiesto });
                                }} ref={refGrilla} seleccionMultiple={false} />

                            <div className="d-flex flex-wrap justify-content-end">
                                <Button className="btn-md mr-1" variant="danger" onClick={cerrar}>Cancelar</Button>
                            </div>

                            <br/>
                            <br/>
                        </Form>
                    </Container>
            </Modal.Dialog>
        </MyModal>
        </>)

})
