import React from "react";
import { MyModal } from "MyModal";
import { Modal, Button, Form, Col } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, FormikProps, Field } from "formik";
import { MyForm, MyFormControl, MySelect } from "FormikHooks";
import { AppContext } from "App";
import Decimal from "decimal.js";
import { isNullOrWhiteSpace } from "Utilidades";
import { BaseConceptoPlantillaCalculoImportador, MonedaConceptoPlantillaCalculoImportador, MonedaConceptoPresupuesto } from "Enums";
import { useApi } from "ApiHooks";
enum Estado {
    Creando,
    Modificando,
    Cerrado
}

const opcionesBase = [{ value: BaseConceptoPlantillaCalculoImportador.FOB.toString(), label: 'FOB' },
{ value: BaseConceptoPlantillaCalculoImportador.CIF.toString(), label: 'CIF' },
{ value: BaseConceptoPlantillaCalculoImportador.BaseImponible.toString(), label: 'Base Imponible' }];

const opcionesMoneda = [{ value: MonedaConceptoPlantillaCalculoImportador.DolarA.toString(), label: 'Dolar A/SIM' },
{ value: MonedaConceptoPlantillaCalculoImportador.DolarB.toString(), label: 'Dolar B/Libre' }];

export type CrearEditarRangoPlantillaImportadorRef = {
    mostrarCrear: () => Promise<any>, mostrarModificar: (rango: any, concepto: any) => Promise<any>
};
export const CrearEditarRangoPlantillaImportador = React.forwardRef((props: {
    conceptosExistentes: any[]
}, ref: any) => {
    let { conceptosExistentes } = props;
    let { mostrarError } = React.useContext(AppContext);
    let api = useApi();
    let formikRef = React.useRef<FormikProps<any>>(null);
    let [estado, updateEstado] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'cerrar') {
            return {
                estadoActual: Estado.Cerrado, rangoModificando: null, conceptoModificando: null, resolve: null, reject: null,
                cargando: false, tiposConcepto: [], optionsTiposConcepto: [], conceptoEnPesos: false
            };
        } else if (accion.tipo === 'mostrarCrear') {
            return {
                estadoActual: Estado.Creando, valorModificando: null,
                resolve: accion.resolve, reject: accion.reject,
                cargando: true, tiposConcepto: [], optionsTiposConcepto: [], conceptoEnPesos: false
            };
        } else if (accion.tipo === 'mostrarModificar') {
            return {
                estadoActual: Estado.Modificando, rangoModificando: accion.rango,
                conceptoModificando: accion.concepto,
                resolve: accion.resolve, reject: accion.reject, cargando: false,
                tiposConcepto: [], optionsTiposConcepto: [], conceptoEnPesos: accion.concepto.Moneda === MonedaConceptoPlantillaCalculoImportador.Peso
            };
        } else if (accion.tipo === 'inicializarTiposConcepto') {
            let nuevoEstado = { ...estado };
            nuevoEstado.tiposConcepto = accion.valor ?? [];
            nuevoEstado.optionsTiposConcepto = accion.valor.map((item: any) => {
                let label = item.Nombre;
                switch (item.Moneda) {
                    case MonedaConceptoPresupuesto.Peso:
                        label += ' (Peso)';
                        break;
                    case MonedaConceptoPresupuesto.DolarA:
                        label += ' (Dolar A/SIM)';
                        break;
                    case MonedaConceptoPresupuesto.DolarB:
                        label += ' (Dolar B/Libre)';
                        break;
                }
                return { value: item.Nombre, label: label };
            });
            nuevoEstado.cargando = false;
            return nuevoEstado;
        } else if (accion.tipo === 'setConceptoEnPesos') {
            return { ...estado, conceptoEnPesos: accion.valor };
        }
        return estado;
    }, {
        estadoActual: Estado.Cerrado, rangoModificando: null, conceptoModificando: null, cargando: false,
        resolve: null, reject: null, tiposConcepto: [], optionsTiposConcepto: [], conceptoEnPesos: false
    });
    const cerrar = () => {
        estado?.reject();
        updateEstado({ tipo: 'cerrar' });
    }
    React.useImperativeHandle(ref, () => ({
        mostrarCrear: () => {
            return new Promise<any>((resolve, reject) => {
                updateEstado({ tipo: 'mostrarCrear', resolve: resolve, reject: reject });
            });
        }, mostrarModificar: (rango: any, concepto: any) => {
            return new Promise<any>((resolve, reject) => {
                updateEstado({ tipo: 'mostrarModificar', rango: rango, concepto: concepto, resolve: resolve, reject: reject });
            })
        }
    }));
    React.useEffect(() => {
        if (estado.estadoActual === Estado.Creando) {
            async function cargarTiposConcepto() {
                try {
                    let respuesta = await api.getTiposConceptoPresupuesto();
                    updateEstado({ tipo: 'inicializarTiposConcepto', valor: respuesta });
                } catch (error) {
                    if (!api.isCancel(error)) {
                        console.error('Error al cargar tipos concepto', error);
                        mostrarError('Error al cargar tipos concepto');
                        cerrar();
                    }
                }
            }
            cargarTiposConcepto();
            return () => {
                api.cancelCurrentTokens();
            }
        }
        //eslint-disable-next-line
    }, [estado.estadoActual]);
    return (<MyModal show={estado.estadoActual !== Estado.Cerrado} onHide={cerrar}>
        <Modal.Dialog size="lg">
            <Modal.Header>
                <h2>{estado.estadoActual === Estado.Creando ? 'Nuevo rango' : 'Modificar rango'}</h2>
            </Modal.Header>
            <Modal.Body>
                <Formik innerRef={formikRef} validationSchema={Yup.object({
                    'Nombre': Yup.string().nullable().required('Debe seleccionar el concepto'),
                    'Base': Yup.number().nullable().required('Debe seleccionar la base'),
                    'Moneda': Yup.number().nullable().required('Debe seleccionar el tipo de cambio'),
                    'PorcentajeGravado': Yup.number(),
                    'Minimo': Yup.number().nullable().typeError('Debe ingresar un número')
                        .required('Debe ingresar el monto Desde')
                        .min(0, 'El valor minimo debe ser mayor o igual a cero'),
                    'Maximo': Yup.number().nullable().typeError('Debe ingresar un número')
                        .required('Debe ingresar el monto Hasta')
                        .moreThan(Yup.ref('Minimo'), 'El valor maximo debe ser mayor al valor minimo'),
                    'Porcentaje': Yup.number().nullable().typeError('Debe ingresar un número')
                        .required('Debe ingresar el porcentaje')
                        .min(0, 'El porcentaje debe ser mayor o igual a cero'),
                    'MontoMinimo': Yup.number().nullable().typeError('Debe ingresar un número')
                        .required('Debe ingresar el monto minimo')
                        .min(0, 'El monto debe ser mayor o igual a cero'),
                })} initialValues={{
                    'Nombre': estado.conceptoModificando?.Nombre,
                    'Base': estado.conceptoModificando?.Base,
                    'Moneda': estado.conceptoModificando?.Moneda,
                    'PorcentajeGravado': estado.conceptoModificando?.PorcentajeGravado ?? 0,
                    'Minimo': estado.rangoModificando?.Minimo,
                    'Maximo': estado.rangoModificando?.Maximo,
                    'Porcentaje': estado.rangoModificando?.Porcentaje,
                    'MontoMinimo': estado.rangoModificando?.MontoMinimo
                }} onSubmit={(values: any, actions) => {
                    let rangosFiltrados = conceptosExistentes.find((c: any) => c.Nombre === values.Nombre)?.Rangos ?? [];
                    if (estado.estadoActual === Estado.Modificando && rangosFiltrados.includes(estado.rangoModificando)) {
                        rangosFiltrados = rangosFiltrados.filter((r: any) => r !== estado.rangoModificando);
                    }
                    if (estado.conceptoEnPesos && rangosFiltrados.length > 0) {
                        mostrarError('Ya fue ingresado este concepto en pesos');
                        actions.setSubmitting(false);
                        return;
                    }
                    let exito = true;
                    let desde = new Decimal(values.Minimo);
                    let hasta = new Decimal(values.Maximo);
                    for (let rango of rangosFiltrados) {
                        //desde es inclusive, hasta es exclusive, por eso hasta no tiene >=
                        if ((desde.comparedTo(rango.Minimo) > -1 && desde.comparedTo(rango.Maximo) === -1)
                            || (hasta.comparedTo(rango.Minimo) === 1 && hasta.comparedTo(rango.Maximo) === -1)) {
                            exito = false;
                            mostrarError('El rango se superpone con otros rangos');
                            break;
                        }
                    }
                    if (exito) {
                        estado.resolve(values);
                        actions.setSubmitting(false);
                        updateEstado({ tipo: 'cerrar' });
                    } else {
                        actions.setSubmitting(false);
                    }
                }}>
                    <MyForm blocking={estado.cargando} blockWhenSubmitting={false}>
                        <Form.Row>
                            <Form.Group as={Col} xs={6}>
                                {estado.estadoActual === Estado.Creando
                                    ? <MySelect name="Nombre" label="Concepto" options={estado.optionsTiposConcepto} onValueChange={valor => {
                                        let concepto = valor as string | null | undefined;
                                        if (!isNullOrWhiteSpace(concepto)) {
                                            let valores = { ...formikRef.current!.values, Nombre: concepto };
                                            let tipo = estado.tiposConcepto.find((item: any) => item.Nombre === concepto);
                                            valores.PorcentajeGravado = tipo.Porcentaje;
                                            if (tipo.Moneda === MonedaConceptoPresupuesto.Peso) {
                                                valores.Minimo = 0;
                                                valores.Maximo = '999999999999';
                                                valores.Porcentaje = 0;
                                                valores.Base = BaseConceptoPlantillaCalculoImportador.FOB;
                                                valores.Moneda = MonedaConceptoPlantillaCalculoImportador.Peso;
                                                updateEstado({ tipo: 'setConceptoEnPesos', valor: true });
                                            } else {
                                                let conceptoExistente = conceptosExistentes.find((item: any) => item.Nombre === concepto);
                                                let monedaSeteada = false;
                                                if (conceptoExistente) {
                                                    valores.Base = conceptoExistente.Base;
                                                    valores.Moneda = conceptoExistente.Moneda;
                                                    monedaSeteada = true;
                                                } else {
                                                    if (tipo.Moneda === MonedaConceptoPresupuesto.DolarA) {
                                                        valores.Moneda = MonedaConceptoPlantillaCalculoImportador.DolarA;
                                                        monedaSeteada = true;
                                                    } else if (tipo.Moneda === MonedaConceptoPresupuesto.DolarB) {
                                                        valores.Moneda = MonedaConceptoPlantillaCalculoImportador.DolarB;
                                                        monedaSeteada = true;
                                                    }
                                                }
                                                if (!monedaSeteada && formikRef.current?.values?.Moneda === MonedaConceptoPlantillaCalculoImportador.Peso) {
                                                    valores.Moneda = true;
                                                }
                                                updateEstado({ tipo: 'setConceptoEnPesos', valor: false });
                                            }
                                            formikRef.current?.setValues(valores);
                                        }
                                    }}></MySelect>
                                    : <MyFormControl type="text" name="Nombre" label="Concepto" readOnly plaintext></MyFormControl>}
                            </Form.Group>
                            {estado.conceptoEnPesos ? <>
                                <Form.Group as={Col}>
                                    <p>Concepto en pesos</p>
                                    <Field type="hidden" name="Base"></Field>
                                    <Field type="hidden" name="Moneda"></Field>
                                </Form.Group>
                            </> : <>
                                <Form.Group as={Col}>
                                    <MySelect name="Base" label="Base" options={opcionesBase}></MySelect>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <MySelect name="Moneda" label="Tipo de Cambio" options={opcionesMoneda}></MySelect>
                                </Form.Group>
                            </>}
                        </Form.Row>
                        <Form.Row>
                            <Field type="hidden" name="PorcentajeGravado"></Field>
                            {estado.conceptoEnPesos ? <>
                                <Field type="hidden" name="Minimo"></Field>
                                <Field type="hidden" name="Maximo"></Field>
                                <Field type="hidden" name="Porcentaje"></Field>
                            </> : <>
                                <Form.Group as={Col}>
                                    <MyFormControl name="Minimo" type="number" label="Desde"></MyFormControl>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <MyFormControl name="Maximo" type="number" label="Hasta"></MyFormControl>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <MyFormControl name="Porcentaje" type="number" label="Porcentaje"></MyFormControl>
                                </Form.Group>
                            </>}
                            <Form.Group as={Col}>
                                <MyFormControl name="MontoMinimo" type="number" label={estado.conceptoEnPesos ? 'Monto' : 'Monto Minimo'}></MyFormControl>
                            </Form.Group>
                        </Form.Row>
                    </MyForm>
                </Formik>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={cerrar}>
                    Cancelar
                </Button>
                <Button type="button" variant="primary" onClick={() => formikRef.current?.submitForm()}>
                    Ingresar
                </Button>
            </Modal.Footer>
        </Modal.Dialog>
    </MyModal>)
});
