import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Route, Router, Switch } from 'react-router';

import './App.css';
import { Login } from './Login';
import Axios from 'axios';
import ProtectedRoute, { OnlyAnonymousRoute } from './ProtectedRoute'
import jwt_decode from 'jwt-decode'
import { Home } from './Paginas/Home'
import { Catalogos } from './Paginas/Catalogos/Catalogos'
import { SelectEmpresa } from './Paginas/SelectEmpresa'
import { CrearEditarCatalogo } from './Paginas/Catalogos/CrearEditarCatalogo'
import { ArticulosCatalogo } from './Paginas/Catalogos/ArticulosCatalogo';
import { CrearEditarArticuloCatalogo } from './Paginas/Catalogos/CrearEditarArticuloCatalogo';
import { CargaMasivaCatalogo } from './Paginas/Catalogos/CargaMasiva/Principal';
import { ReporteLNA } from './Paginas/Catalogos/ReporteLNA';
import { ReporteDetallado } from './Paginas/Catalogos/ReporteDetallado';
import { ReporteDetalladoCatalogo } from './Paginas/Catalogos/ReporteDetalladoCatalogo';
import { ReporteNCMNoVigente } from './Paginas/Catalogos/ReporteNCMNoVigente';
import { ReporteNCMPorCatalogo } from './Paginas/Catalogos/ReporteNCMPorCatalogo';
import { ReporteNCMPorInterno } from './Paginas/Catalogos/ReporteNCMPorInterno';
import { ReporteNCMPorNCM } from './Paginas/Catalogos/ReporteNCMPorNCM';
import { Avisos } from './Paginas/Catalogos/Avisos/Avisos';
import { TextosAviso } from './Paginas/Catalogos/Avisos/TextosAviso';
import { TiposAviso } from './Paginas/Catalogos/Avisos/TiposAviso';
import { CrearEditarAviso } from './Paginas/Catalogos/Avisos/CrearEditarAviso';
import { Intervenciones } from './Paginas/Catalogos/Intervenciones';
import { ReglasValidacion } from './Paginas/Catalogos/ReglasValidacion';
import { CrearEditarReglaValidacion } from './Paginas/Catalogos/CrearEditarReglaValidacion';
import { BitacorasArticulosCatalogo } from './Paginas/Catalogos/BitacorasArticulosCatalogo';
import { DetalleBitacoraArticuloCatalogo } from './Paginas/Catalogos/DetalleBitacoraArticuloCatalogo';
import { CrearEditarCertificacion } from 'Paginas/Catalogos/Certificaciones/CrearEditarCertificacion';
import { Certificaciones } from 'Paginas/Catalogos/Certificaciones/Certificaciones';
import { RutasDespachante } from './Paginas/Despachantes/Despachantes';
import { RutasImportador } from './Paginas/Importadores/Importadores';
import { Transportistas } from './Paginas/Transportistas/Transportistas';
import { RutasVendedor } from './Paginas/Vendedores/Vendedores';
import { Caratulas } from './Paginas/Caratulas/Caratulas';
import { PantallaPrincipalCaratula } from 'Paginas/Caratulas/PantallaPrincipalCaratula';
import { EditarCaratula } from 'Paginas/Caratulas/EditarCaratula';
import { EditarBultos } from 'Paginas/Caratulas/EditarBultos';
import { Subitems } from 'Paginas/Caratulas/Subitems';
import { CrearEditarSubitem } from 'Paginas/Caratulas/CrearEditarSubitem';
import { CrearEditarPresupuesto } from 'Paginas/Caratulas/CrearEditarPresupuesto';
import { RecuperarCaratulas } from 'Paginas/Caratulas/RecuperarCaratulas';
import { ImportarCaratulas } from 'Paginas/Caratulas/ImportarCaratulas';
import { ReporteSubitemsDetallado } from 'Paginas/Caratulas/ReportesSubitems/ReporteSubitemsDetallado';
import { ReporteSubitemsArticuloYGravamenes } from 'Paginas/Caratulas/ReportesSubitems/ReporteSubitemsArticuloYGravamenes';
import { ReporteSubitemsIntervenciones } from 'Paginas/Caratulas/ReportesSubitems/ReporteSubitemsIntervenciones';
import { ReporteSubitemsMineria } from 'Paginas/Caratulas/ReportesSubitems/ReporteSubitemsMineria';
import { ReporteSubitemsValorCriterio } from 'Paginas/Caratulas/ReportesSubitems/ReporteSubitemsValorCriterio';
import { ArmadoItems } from 'Paginas/Caratulas/ArmadoItems/ArmadoItems';
import { MensajesArmadoItems } from 'Paginas/Caratulas/ArmadoItems/MensajesArmadoItems';
import { CargarExcel } from './Paginas/ProcesoExcel/CargarExcel';
import { CaratulasProcesoExcel } from './Paginas/ProcesoExcel/CaratulasProcesoExcel';
import { PantallaCaratulaProcesoExcel } from './Paginas/ProcesoExcel/PantallaCaratulaProcesoExcel';
import { ArticulosProcesoExcel } from './Paginas/ProcesoExcel/ArticulosProcesoExcel';
import { EditarArticuloProcesoExcel } from './Paginas/ProcesoExcel/EditarArticuloProcesoExcel';
import { ReporteContableCuentaCorrienteCliente } from './Paginas/ReportesContable/ReporteContableCuentaCorrienteCliente';
import { ReporteContableCuentaCorrienteProveedor } from './Paginas/ReportesContable/ReporteContableCuentaCorrienteProveedor';
import { ReporteContableCuentaCorrienteProveedorTerceros } from './Paginas/ReportesContable/ReporteContableCuentaCorrienteProveedorTerceros';
import { ReporteContableIvaCompras } from './Paginas/ReportesContable/ReporteContableIvaCompras';
import { ReporteContableIvaVentas } from './Paginas/ReportesContable/ReporteContableIvaVentas';
import { ReporteContableOrdenPago } from './Paginas/ReportesContable/ReporteContableOrdenPago';
import { InformeSIMI } from 'Paginas/Informes/SIMI';
import { InformeDefinitivas } from 'Paginas/Informes/Definitivas';
import { InformeIndicadores } from 'Paginas/Informes/Indicadores';
import { InformeEstadisticas } from 'Paginas/Informes/Estadisticas';

//** advanced
import { Avisodecarga } from 'Paginas/advanced/avisodecarga';
import { BuscarCaratula } from 'Paginas/advanced/getDeclaraciones';
import { SubCuentaMaria } from 'Paginas/advanced/getSubCuentaMaria';
import { Advanced } from 'Paginas/advanced/advanced';
import { ConsultaBultos } from 'Paginas/advanced/getConsultaBultos';
import { RatificacionHistorico } from 'Paginas/advanced/getRatificaciones';
import { GetSession } from 'Paginas/advanced/getSesionManager';
import { VerDestincaciones } from 'Paginas/advanced/verDestinaciones';
// advanced **



import { Configuracion } from './Paginas/Configuracion';
import { LoginLNA } from "./Paginas/LoginLNA";
import { tokenStorage } from './TokenStorage';
import { createBrowserHistory } from 'history';
import { Button, Container, Form, Modal, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { History } from 'history';
import { MyModal } from './MyModal'
import BlockUi from 'react-block-ui';
import { isInRole, toDate } from 'Utilidades';
import RateLimit from 'RateLimit';
import MyErrorBoundary from 'MyErrorBoundary';
import preval from 'preval.macro';
import { RedirectEventCallbackContext, useRedirectEventCallbackContextState } from "SintiaHooks";
import 'react-block-ui/style.css';
import 'loaders.css';
import ProvisorioTable from 'Paginas/advanced/ProvisorioTable/ProvisorioTable';
//import { LoginAFIP } from 'Paginas/advanced/sesionafip';
export class SesionPerdidaError extends Error {
  innerError: Error | undefined;
  constructor(message?: string, innerError?: Error) {
    // Pasa los argumentos restantes (incluidos los específicos del proveedor) al constructor padre
    super(message);
    // Mantiene un seguimiento adecuado de la pila para el lugar donde se lanzó nuestro error (solo disponible en V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, SesionPerdidaError)
    }

    this.name = 'SesionPerdidaError';
    this.innerError = innerError;
  }
}

function getUrlMongo() {
  if (process.env.NODE_ENV === 'development') {
    // return 'https://localhost:44365/api';
    return 'https://localhost:44365/api';
    // return 'https://localhost:30001/api';
    // return 'https://api.sintia.com.ar/mongo/api'; 
    
    //return 'https://test.api.sintia.com.ar/api';
  } else if (process.env.REACT_APP_BUILD === 'dev') {
    //return 'http://192.168.2.90/apimongosintia/api';
    //return 'https://api.sintia.com.ar/mongo/api';
    return 'https://test.api.sintia.com.ar/api';
  } else {
    return 'https://api.sintia.com.ar/mongo/api';
  }
}

export const urlMongo = getUrlMongo();
export const urlMantenimiento = 'https://api.sintia.com.ar/mongoMant/api';
export const urlAdvanced = 'https://apiadv.sintia.com.ar/';



const buildDate = preval`module.exports = new Date()`;
//const basename = process.env.NODE_ENV === 'development' ? undefined : '/websintiareboot';
const basename = undefined;

export const mantenimientoClient = Axios.create({
  baseURL: urlMantenimiento
});

export const loginClient = Axios.create({
  baseURL: urlMongo
});

export const advClient = Axios.create({
  baseURL: urlAdvanced
});



const client = Axios.create({
  baseURL: urlMongo
});
const rateLimit = new RateLimit(6);
client.interceptors.request.use(config => {
  config.headers = {
    ...config.headers,
    'Authorization': 'Bearer ' + tokenStorage.getAccessToken()
  };
  return rateLimit.delayConfig(config) as Promise<any>;
}, error => error);



let mostrarErrorSesionPerdida: any = null;
export const userClient = client;

export const AppContext = React.createContext<AppContextState>({
  userInfo: { sesionIniciada: false },
  iniciarSesion: accessToken => { },
  cerrarSesion: () => { },
  mostrarError: error => { },
  setEmpresaActual: empresa => { },
});

export interface UserInfo {
  sesionIniciada: boolean,
  nroClienteAlpha?: number,
  empresaActual?: string,
  nombreUsuario?: string,
  claims?: any
}

interface AppContextState {
  userInfo: UserInfo,
  iniciarSesion: (token: string) => void,
  cerrarSesion: () => void,
  setEmpresaActual: (empresa: string) => void,
  mostrarError: (error: string) => void,
}

function AppReducer(userInfo: UserInfo, action: any) {
  if (action.type === 'cerrarSesion') {
    return { sesionIniciada: false };
  } else if (action.type === 'iniciarSesion') {
    let empresas = action.claims.EmpresaId;
    
    if (Array.isArray(empresas) && empresas.length === 1) {
      return {
        sesionIniciada: true, claims: action.claims, empresaActual: empresas[0],
        nroClienteAlpha: action.claims.NroClienteAlpha, nombreUsuario: action.claims.unique_name
      };
    } else if (typeof empresas === 'string') {
      return {
        sesionIniciada: true, claims: action.claims, empresaActual: empresas,
        nroClienteAlpha: action.claims.NroClienteAlpha, nombreUsuario: action.claims.unique_name
      };
    } else {
      return {
        sesionIniciada: true, claims: action.claims,
        nroClienteAlpha: action.claims.NroClienteAlpha, nombreUsuario: action.claims.unique_name
      };
    }
  } else if (action.type === 'setEmpresaActual') {
    return { ...userInfo, empresaActual: action.empresa };
  }
  else {
    throw new Error('Accion desconocida');
  }
}
let appContextState: AppContextState
let browserHistory: History<unknown> | undefined = undefined;
export const getUserInfo = () => appContextState.userInfo;
export const mostrarError = (error: string) => appContextState.mostrarError(error);
function App() {
  let [userInfo, updateUserInfo] = useReducer(AppReducer, { sesionIniciada: false });
  let [bloquearApp, updateBloquearApp] = useState(false);
  let [nombreEmpresa, updateNombreEmpresa] = useState('');
  let [fechaNcm, updateFechaNcm] = useState('');
  let [fechaLna, updateFechaLna] = useState('');
  let [estadoErrorModal, updateEstadoErrorModal] = useReducer((estado: any, accion: any) => {
    if (accion.tipo === 'setMostrarErrorSesionPerdida') {
      return { mensajeError: '', mostrarErrorSesionPerdida: accion.valor };
    } else if (accion.tipo === 'setMensajeError') {
      if (estado.mostrarErrorSesionPerdida) {
        return estado;
      } else {
        return { ...estado, mensajeError: accion.valor };
      }
    }
    return estado;
  },
    { mensajeError: '', mostrarErrorSesionPerdida: false });
  mostrarErrorSesionPerdida = () => {
    updateEstadoErrorModal({ tipo: 'setMostrarErrorSesionPerdida', valor: true });
  }
  const mostrarError = useCallback((error: string) => {
    updateEstadoErrorModal({ tipo: 'setMensajeError', valor: error });
  }, []);
  const redirectCallbackContextState = useRedirectEventCallbackContextState();
  browserHistory = useMemo(() => createBrowserHistory({
    basename: basename,
    getUserConfirmation: (_, routerCallback) => redirectCallbackContextState.runAfterUserConfirmation(routerCallback)
  }), []); //eslint-disable-line
  appContextState = {
    userInfo: userInfo,
    iniciarSesion: accessToken => updateUserInfo({ type: 'iniciarSesion', claims: jwt_decode(accessToken) }),
    cerrarSesion: () => {
      tokenStorage.setAccessToken('');
      tokenStorage.setRefreshToken('');
      updateUserInfo({ type: 'cerrarSesion' });
      //browserHistory.replace('/login');
    },
    mostrarError: mostrarError,
    setEmpresaActual: empresa => updateUserInfo({ type: 'setEmpresaActual', empresa: empresa }),
  };

  

  async function cargarNombreEmpresa(cuitEmpresa: string) {
    try {
      let respuesta = await client.get('/Account/GetEmpresasUsuario');
      let empresa = respuesta.data.filter((item: any) => item.CUIT === cuitEmpresa);
      if (empresa.length > 0) {
        updateNombreEmpresa(empresa[0].Nombre);
      }
    } catch (error) {
      console.error('Error al cargar empresas', error);
    }
  }
  useEffect(() => {
    async function cargarFechasNcmLna() {
      try {
        let respuesta = await userClient.get('/DatosGenericos/FechasActualizacion');
        updateFechaNcm(toDate(respuesta.data.FechaModificacionNCM));
        updateFechaLna(toDate(respuesta.data.FechaModificacionLNA));
      } catch (error) {
        console.error('Error al obtener fechas ncm y lna', error);
      }
    }
    cargarFechasNcmLna();
    let accessToken = tokenStorage.getAccessToken();
    if (accessToken) {
      appContextState.iniciarSesion(accessToken);
    }
  }, []);
  useEffect(() => {
    let logo = document.getElementById('logo');
    if (logo) {
      const observer = new ResizeObserver(entries => {
        // let navbarNav = document.getElementById('navbar-nav');
        document.documentElement.style.setProperty('--logoHeight', entries[0].contentRect.height + 'px');
      });
      observer.observe(logo);
    }
    //console.log(userInfo);
  }, []);
  useEffect(() => {
    if (userInfo.empresaActual) {
      cargarNombreEmpresa(userInfo.empresaActual);
    }

  }, [userInfo.empresaActual]);


  async function cerrarSesion() {
    try {
      const redirectCallback = redirectCallbackContextState.getCurrentRedirectCallback();
      if (redirectCallback) {
        const continuar = await redirectCallback();
        if (!continuar) {
          return;
        }
        redirectCallbackContextState.setCurrentRedirectCallback(null);
      }
      updateBloquearApp(true);
      await client.post('/Account/LogOut');
      appContextState.cerrarSesion();
    } catch (error) {
      console.error('Error al cerrar sesión', error);
      appContextState.cerrarSesion();
    } finally {
      updateBloquearApp(false);
    }



  }


  async function refrescarToken() {
    try {
        let respuesta = await loginClient.post('/Account/Refresh', {
          AccessToken: tokenStorage.getAccessToken(),
          RefreshToken: tokenStorage.getRefreshToken()
        });
        console.log(respuesta);
        tokenStorage.setAccessToken(respuesta.data.AccessToken);
        tokenStorage.setRefreshToken(respuesta.data.RefreshToken);
    } catch (error2) {
  //      console.log('Error en funcion refrescarToken()', JSON.stringify(error2));
      console.log('Error en funcion refrescarToken() - throw',error2);
      throw(error2);
      //cerrarSesion();
  //      browserHistory?.replace('/login');
    }
  }
  
  


  
  let promesaRefrescarToken: Promise<void> | null = null;
  client.interceptors.response.use(success => success, async error => {
    try {
  
      if (error.response?.status === 429) {
        debugger;
      }
      if (!error.response || error.response.status !== 401) {
        return Promise.reject(error);
      }
  
      if (!promesaRefrescarToken) {
        promesaRefrescarToken = refrescarToken();
      }
  
      await promesaRefrescarToken;
      promesaRefrescarToken = null;
  
      return client.request(error.config);
  
    } catch (error2) {
  
      //todo: ver como avisar al usuario de un error
      console.log('Error al refrescar la sesion en client.interceptors.response.use', JSON.stringify(error2));
      appContextState.cerrarSesion();
      browserHistory?.replace('/login');
      mostrarErrorSesionPerdida();
  
      return Promise.reject(new SesionPerdidaError('Error al refrescar la sesion', error2));
  
    }
  });

  return (
    <MyErrorBoundary>
      <div className="d-flex flex-column min-vh-100">
        <AppContext.Provider value={appContextState}>
          <RedirectEventCallbackContext.Provider value={redirectCallbackContextState}>
            <MyModal show={estadoErrorModal.mostrarErrorSesionPerdida} onHide={() => updateEstadoErrorModal({ tipo: 'setMostrarErrorSesionPerdida', valor: false })}>
              <Modal.Dialog>
                <Modal.Body>
                  <p className="text-danger lead">Se ha perdido la sesión. Vuelva a iniciar sesión</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={() => updateEstadoErrorModal({ tipo: 'setMostrarErrorSesionPerdida', valor: false })}>Cerrar</Button>
                </Modal.Footer>
              </Modal.Dialog>
            </MyModal>
            <MyModal show={!!estadoErrorModal.mensajeError} onHide={() => updateEstadoErrorModal({ tipo: 'setMensajeError', valor: '' })}>
              <Modal.Dialog>
                <Modal.Body>
                  <p className="text-danger lead">{estadoErrorModal.mensajeError}</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={() => updateEstadoErrorModal({ tipo: 'setMensajeError', valor: '' })}>Cerrar</Button>
                </Modal.Footer>
              </Modal.Dialog>
            </MyModal>
            <BlockUi blocking={bloquearApp ? true : undefined}>
              <Router history={browserHistory}>
                <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark" id="navbar">
                  <Navbar.Brand>
                      <h1>Sintia.Net</h1>
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="navbar-nav" data-bs-target="#navbar-nav"/>
                  <Navbar.Collapse id="navbar-nav">
                    <Nav>
                      {userInfo.sesionIniciada && userInfo.empresaActual && !isInRole(userInfo, 'ContableWeb')  && !isInRole(userInfo, 'Advanced') &&
                        (<>
                          <Nav.Link as={Link} to="/catalogos">Catalogos</Nav.Link>
                          <Nav.Link as={Link} to="/catalogos/cargaMasiva">Carga Masiva</Nav.Link>
                          {!isInRole(userInfo, 'Catalogo') ? (<>
                            <Nav.Link as={Link} to="/caratulas">Operaciones y carpetas</Nav.Link>
                            <Nav.Link as={Link} to="/procesoExcel">Proceso Excel</Nav.Link>

{/*
                            <NavDropdown title="Informes (Beta)" id="informes-dropdown" disabled>
                              <NavDropdown.Item as={Link} to="/informes/simi">SIMI</NavDropdown.Item>
                              <NavDropdown.Item as={Link} to="/informes/indicadores">Indicadores</NavDropdown.Item>
                              <NavDropdown.Item as={Link} to="/informes/definitivas">Definitivas</NavDropdown.Item>
                              <NavDropdown.Item as={Link} to="/informes/estadisticas">Estadisticas</NavDropdown.Item>
                            </NavDropdown>
                              <Nav.Link as={Link} to="/loginLNA">LNA (Beta)</Nav.Link>--> */}


                          </>) : (<></>)}
                        </>)}


                        {
                        // Se habilita la opcion Sintia Advanced cuando en el claims existe el item "Advanced"
                        userInfo.sesionIniciada && userInfo.empresaActual &&   
                        (<>
                            <NavDropdown title="Sintia Advanced" id="advanced-dropdown">
                              <NavDropdown.Item disabled={!(userInfo.claims?.Advanced || isInRole(userInfo, 'Advanced'))} as={Link} to="/advanced/"><b>Inicio - Advanced</b></NavDropdown.Item>
                              <NavDropdown.Item disabled={!(userInfo.claims?.Advanced || isInRole(userInfo, 'Advanced'))} as={Link} to="/advanced/getSesions">Administrador de Sesiones</NavDropdown.Item>
                              <NavDropdown.Item disabled={!(userInfo.claims?.Advanced || isInRole(userInfo, 'Advanced'))} as={Link} to="/advanced/VerDestincaciones">Ver Destinaciones</NavDropdown.Item>
                              <NavDropdown.Item disabled={!(userInfo.claims?.Advanced || isInRole(userInfo, 'Advanced'))} as={Link} to="/advanced/declaracionesoficializadas">Consulta de Declaraciones Oficializadas</NavDropdown.Item>
                              <NavDropdown.Item disabled={!(userInfo.claims?.Advanced || isInRole(userInfo, 'Advanced'))} as={Link} to="/advanced/avisodecarga">Tirar Aviso de Carga</NavDropdown.Item>
                              <NavDropdown.Item disabled={!(userInfo.claims?.Advanced || isInRole(userInfo, 'Advanced'))} as={Link} to="/advanced/consultasubcuenta">Consulta de la SubCuenta</NavDropdown.Item>
                              <NavDropdown.Item disabled={!(userInfo.claims?.Advanced || isInRole(userInfo, 'Advanced'))} as={Link} to="/advanced/ratificacionautoria">Consulta de Ratificaciones</NavDropdown.Item>
                              <NavDropdown.Item disabled={!(userInfo.claims?.Advanced || isInRole(userInfo, 'Advanced'))} as={Link} to="/advanced/consultabultos">Consulta de Bultos</NavDropdown.Item>
                            </NavDropdown>
                            </>)}



                      {userInfo.sesionIniciada && isInRole(userInfo, 'ContableWeb') &&
                        <NavDropdown title="Reportes Contable" id="reportes-contable-dropdown">
                          <NavDropdown.Item as={Link} to="/reportesContable/cuentaCorrienteCliente">Cuenta Corriente Clientes</NavDropdown.Item>
                          <NavDropdown.Item as={Link} to="/reportesContable/cuentaCorrienteProveedor">Cuenta Corriente Proveedores</NavDropdown.Item>
                          <NavDropdown.Item as={Link} to="/reportesContable/cuentaCorrienteProveedorTerceros">Cuenta Corriente Proveedores Terceros</NavDropdown.Item>
                          <NavDropdown.Item as={Link} to="/reportesContable/ivaCompras">IVA Compras</NavDropdown.Item>
                          <NavDropdown.Item as={Link} to="/reportesContable/ivaVentas">IVA Ventas</NavDropdown.Item>
                          <NavDropdown.Item as={Link} to="/reportesContable/ordenPago">Ordenes Pago</NavDropdown.Item>
                        </NavDropdown>}
                    </Nav>
                  </Navbar.Collapse>
                    {userInfo.empresaActual && <Nav>
                    <Nav.Link as={Link} to="/selectEmpresa">Cliente Alpha: {userInfo.nroClienteAlpha}<br></br> Empresa: {nombreEmpresa}<br></br> Usuario: {userInfo.nombreUsuario}</Nav.Link>
                  </Nav>}
                  {
                    userInfo.sesionIniciada &&
                    (<Form inline onSubmit={e => {
                      e.preventDefault();
                      cerrarSesion();
                    }}>
                      <Button className="ml-2" type="submit" variant="warning">Cerrar sesión</Button>
                    </Form>)
                  }
                </Navbar>

                
                <Container fluid className="mt-2 justify-content-md-center">
                  <div>
                    <MyErrorBoundary>
                      <Switch>
                        <ProtectedRoute allowRoles={['ContableWeb']} path="/reportesContable/cuentaCorrienteCliente" component={ReporteContableCuentaCorrienteCliente}></ProtectedRoute>
                        <ProtectedRoute allowRoles={['ContableWeb']} path="/reportesContable/cuentaCorrienteProveedor" component={ReporteContableCuentaCorrienteProveedor}></ProtectedRoute>
                        <ProtectedRoute allowRoles={['ContableWeb']} path="/reportesContable/cuentaCorrienteProveedorTerceros" component={ReporteContableCuentaCorrienteProveedorTerceros}></ProtectedRoute>
                        <ProtectedRoute allowRoles={['ContableWeb']} path="/reportesContable/ivaCompras" component={ReporteContableIvaCompras}></ProtectedRoute>
                        <ProtectedRoute allowRoles={['ContableWeb']} path="/reportesContable/ivaVentas" component={ReporteContableIvaVentas}></ProtectedRoute>
                        <ProtectedRoute allowRoles={['ContableWeb']} path="/reportesContable/ordenPago" component={ReporteContableOrdenPago}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb', 'Operador']} path="/catalogos/articulos/:codigoCatalogo/add" component={CrearEditarArticuloCatalogo}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/catalogos/articulos/:codigoCatalogo/edit/:codigoArticulo" component={CrearEditarArticuloCatalogo}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/catalogos/articulos/:codigoCatalogo/reporteLNA" component={ReporteLNA}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/catalogos/articulos/:codigoCatalogo/reporteDetallado" component={ReporteDetalladoCatalogo}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/catalogos/articulos/:codigoCatalogo/reporteNCMNoVigente" component={ReporteNCMNoVigente}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} exact path="/catalogos/articulos/:codigoCatalogo" component={ArticulosCatalogo}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb', 'Operador']} path="/catalogos/add" component={CrearEditarCatalogo}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/catalogos/edit/:codigo" component={CrearEditarCatalogo}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/catalogos/intervenciones" component={Intervenciones}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/catalogos/cargaMasiva" component={CargaMasivaCatalogo}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/catalogos/reporteNCMCatalogo" component={ReporteNCMPorCatalogo}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/catalogos/reporteNCMInterno" component={ReporteNCMPorInterno}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/catalogos/reporteNCMNCM" component={ReporteNCMPorNCM}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/catalogos/reporteDetallado" component={ReporteDetallado}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} exact path="/catalogos" component={Catalogos}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} exact path="/certificaciones/edit/:id" component={CrearEditarCertificacion}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} exact path="/certificaciones/add" component={CrearEditarCertificacion}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} exact path="/certificaciones" component={Certificaciones}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/avisos/edit/:id" component={CrearEditarAviso}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/avisos/textos" component={TextosAviso}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/avisos/tipos" component={TiposAviso}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb', 'Operador']} path="/avisos/add" component={CrearEditarAviso}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} exact path="/avisos" component={Avisos}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb', 'Operador']} path="/reglasValidacion/add" component={CrearEditarReglaValidacion}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/reglasValidacion/edit/:id" component={CrearEditarReglaValidacion}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} exact path="/reglasValidacion" component={ReglasValidacion}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/bitacoras/articulos/:id" component={DetalleBitacoraArticuloCatalogo}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} exact path="/bitacoras/articulos" component={BitacorasArticulosCatalogo}></ProtectedRoute>
                        <Route path="/despachantes" component={RutasDespachante}></Route>
                        <Route path="/importadores" component={RutasImportador}></Route>
                        <Route path="/provisorio/:id" component={ProvisorioTable}/>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} exact path="/transportistas" component={Transportistas}></ProtectedRoute>
                        <Route path="/vendedores" component={RutasVendedor}></Route>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/caratulas/:interno/armadoItems/edit" component={ArmadoItems}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/caratulas/:interno/armadoItems/mensajes" component={MensajesArmadoItems}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/caratulas/:interno/subitems/edit/:id" component={CrearEditarSubitem}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/caratulas/:interno/subitems/add" component={CrearEditarSubitem}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/caratulas/:interno/subitems/reporteDetallado" component={ReporteSubitemsDetallado}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/caratulas/:interno/subitems/reporteGravamenes" component={ReporteSubitemsArticuloYGravamenes}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/caratulas/:interno/subitems/reporteIntervenciones" component={ReporteSubitemsIntervenciones}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/caratulas/:interno/subitems/reporteMineria" component={ReporteSubitemsMineria}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/caratulas/:interno/subitems/reporteValorCriterio" component={ReporteSubitemsValorCriterio}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/caratulas/:interno/subitems" component={Subitems}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/caratulas/:interno/caratula" component={EditarCaratula}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/caratulas/:interno/bultos" component={EditarBultos}></ProtectedRoute>
                        {/* <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/caratulas/:interno/generarTxt" component={GenerarTxtMaria}></ProtectedRoute> */}
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/caratulas/:interno/presupuestos/edit/:id" component={CrearEditarPresupuesto}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/caratulas/:interno/presupuestos/add" component={CrearEditarPresupuesto}></ProtectedRoute>
                        {/* <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/caratulas/:interno/presupuestos" component={Presupuestos}></ProtectedRoute> */}
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/caratulas/:interno" component={PantallaPrincipalCaratula}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/recuperarCaratulas" component={RecuperarCaratulas}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/importarCaratulas" component={ImportarCaratulas}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} exact path="/caratulas" component={Caratulas}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/procesoExcel/caratulas/:interno/articulos/:nroLinea" component={EditarArticuloProcesoExcel}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/procesoExcel/caratulas/:interno/articulos" component={ArticulosProcesoExcel}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/procesoExcel/caratulas/:interno" component={PantallaCaratulaProcesoExcel}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/procesoExcel/cargar/:interno" component={CargarExcel}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} exact path="/procesoExcel/cargar" component={CargarExcel}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} exact path="/procesoExcel" component={CaratulasProcesoExcel}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} exact path="/configuracion" component={Configuracion}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/informes/simi" component={InformeSIMI}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/informes/definitivas" component={InformeDefinitivas}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/informes/indicadores" component={InformeIndicadores}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/informes/estadisticas" component={InformeEstadisticas}></ProtectedRoute>
                        <ProtectedRoute path="/loginLNA" component={LoginLNA}></ProtectedRoute>
                        {/*<ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/advanced/ratificacionautoria" component={}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/advanced/consultabultos" component={}></ProtectedRoute> 
                        <ProtectedRoute debeTenerEmpresa blockRoles={['Catalogo', 'ContableWeb']} path="/advanced/avisodecarga" component={Avisodecarga}></ProtectedRoute>*/}                        
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/advanced/consultabultos/" component={ConsultaBultos}></ProtectedRoute> 
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/advanced/avisodecarga/" component={Avisodecarga}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/advanced/consultasubcuenta/" component={SubCuentaMaria}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/advanced/avisodecargapost/:nroDespacho" component={Avisodecarga}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/advanced/declaracionesoficializadas" component={BuscarCaratula}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/advanced/ratificacionautoria" component={RatificacionHistorico}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/advanced/getSesions" component={GetSession}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/advanced/VerDestincaciones" component={VerDestincaciones}></ProtectedRoute>
                        <ProtectedRoute debeTenerEmpresa blockRoles={['ContableWeb']} path="/advanced" component={Advanced}></ProtectedRoute>




                        <ProtectedRoute path="/selectEmpresa" component={SelectEmpresa}></ProtectedRoute>
                        <OnlyAnonymousRoute path="/login" component={Login}></OnlyAnonymousRoute>
                        <ProtectedRoute exact path="/" component={Home}></ProtectedRoute>
                        <Route path="*" component={NotFoundComponent}></Route>
                      </Switch>
                    </MyErrorBoundary>
                  </div>
                </Container>
              </Router>
            </BlockUi>
          </RedirectEventCallbackContext.Provider>
        </AppContext.Provider>
        <footer className="mt-auto text-light bg-dark text-center p-2">
          <p>Fecha NCM: {fechaNcm} - Fecha LNA: {fechaLna}</p>
          <p>&copy; Alpha 2000 - Versión {process.env.REACT_APP_VERSION} - Fecha {toDate(buildDate)}</p>
        </footer>
      </div>
    </MyErrorBoundary>
  );
}

export default App;

export function NotFoundComponent() {
  return (<div>
    <p className="lead">No se encuentra la página</p>
  </div>);
}