import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { BaseAsyncCreatableSelect as AsyncSelect } from "BaseSelect";
import { AppContext } from "App";
import Grilla, { GrillaRef, TipoCampo } from "../../Grilla";
import { MyModal } from "../../MyModal";
import { isNullOrWhiteSpace, toFixedDecimal } from "../../Utilidades";
import Loader from "react-loaders";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { arabToRoman } from "roman-numbers";
import FileSaver from "file-saver";
import { useApi } from "ApiHooks";
import styled from "styled-components";

const AsyncSelectCustom = styled(AsyncSelect)`
    width:300px;
`;
export function ReporteNCMPorNCM() {
    let { mostrarError } = React.useContext(AppContext);
    let api = useApi();
    let reporte = React.useRef([]);
    let listadoInternos = React.useRef<any[]>([]);
    let refGrilla = React.useRef<GrillaRef>(null);
    let refGrilla2 = React.useRef<GrillaRef>(null);
    let [generandoExcel, updateGenerandoExcel] = React.useState(false);
    function busquedaReducer(estado: any, accion: any) {
        if (accion.tipo === 'ncm') {
            return { ...estado, ncm: accion.valor };
        }
        return estado;
    }
    let busquedaInicial = { ncm: '' };
    let [busquedaActual, updateBusquedaActual] = React.useState(busquedaInicial);
    let [busqueda, updateBusqueda] = React.useReducer(busquedaReducer, { ...busquedaInicial });
    const cargarReporte = React.useCallback(async () => {
        try {
            if (isNullOrWhiteSpace(busquedaActual.ncm)) {
                return;
            }
            refGrilla.current?.setBloqueado(true);
            let respuesta = await api.getReporteNCMPorNCM(busquedaActual.ncm);
            let unidades = await api.getUnidades();
            reporte.current = respuesta.map((item: any, indice: number) => ({
                ...item, Indice: indice,
                NombreUnidadDeclarada: unidades.find((unidad: any) => unidad.Codigo === item.CodigoUnidadDeclarada)?.Descripcion ?? item.CodigoUnidadDeclarada
            }));
            let internos = [...new Set(respuesta.map((item: any) => item.Interno))];
            listadoInternos.current = internos.map((interno: any) => ({ Interno: interno }));
            refGrilla.current?.setBloqueado(false);
            refGrilla.current?.cambiarPagina(1);
            refGrilla2.current?.setBloqueado(false);
            refGrilla2.current?.cambiarPagina(1);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al cargar reporte NCM');
            }
        }
    }, [mostrarError, busquedaActual.ncm, api]);
    React.useEffect(() => {
        cargarReporte();
        //eslint-disable-next-line
    }, [busquedaActual]);
    async function exportarAExcel() {
        try {
            if (isNullOrWhiteSpace(busquedaActual.ncm)) {
                mostrarError('Debe ingresar el ncm');
                return;
            }
            updateGenerandoExcel(true);
            let { excel, fileName } = await api.excelReporteNCMPorNCM(busquedaActual.ncm);
            updateGenerandoExcel(false);
            FileSaver.saveAs(excel, fileName);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al exportar reporte NCM a Excel');
            }
            if (!api.isUnmounted()) {
                updateGenerandoExcel(false);
            }
        }
    }
    let funcionFormatoBool = (valor: any) => {
        if (valor === null || valor === undefined) {
            return '';
        } else {
            return valor ? 'Sí' : 'No';
        }
    }
    let funcionFormatoIVA = (valor: any, item: any) => item.IvaVariable ? 'Varía de acuerdo al destino' : toFixedDecimal(valor, 2);
    let camposGrilla = [{ propiedad: 'Indice', titulo: '', visible: false, clave: true },
    { propiedad: 'Interno', titulo: 'Interno' },
    { propiedad: 'CodigoCatalogo', titulo: 'Catalogo' },
    { propiedad: 'CodigoArticulo', titulo: 'Articulo' },
    { propiedad: 'Ncm', titulo: 'Nomenclador' },
    { propiedad: 'Sufijos', titulo: 'Sufijos' },
    { propiedad: 'NombreUnidadDeclarada', titulo: 'Unidad Declarada' },
    { propiedad: 'CantidadDeclarada', titulo: 'Cantidad Declarada', tipo: TipoCampo.Number },
    { propiedad: 'PrecioUnitario', titulo: 'Precio Unitario', tipo: TipoCampo.Number, decimales: 5 },
    { propiedad: 'Fob', titulo: 'FOB' }, { propiedad: 'KgNeto', titulo: 'Kg Neto', tipo: TipoCampo.Number },
    { propiedad: 'PorcentajeDerImpoIntrazona', titulo: '% Der. Impo Intrazona', tipo: TipoCampo.Number },
    { propiedad: 'PorcentajeDerImpoExtrazona', titulo: '% Der. Impo Extrazona', tipo: TipoCampo.Number },
    { propiedad: 'PorcentajeDerExpo', titulo: '% Der. Expo', tipo: TipoCampo.Number },
    { propiedad: 'PorcentajeEstadistica', titulo: '% Estadistica', tipo: TipoCampo.Number },
    { propiedad: 'PorcentajeIva', titulo: '% Iva', plantillaFormato: funcionFormatoIVA },
    { propiedad: 'PorcentajeIvaAdicional', titulo: '%Iva Adic. (Estimado)', plantillaFormato: funcionFormatoIVA, alinearDerecha: true },
    { propiedad: 'PorcentajeReintegroExtrazona', titulo: '% Reintegro Extrazona', tipo: TipoCampo.Number },
    { propiedad: 'PorcentajeReintegroBrasil', titulo: '% Reintegro Intrazona', tipo: TipoCampo.Number },
    { propiedad: 'TieneLNA', titulo: 'Tiene LNA', plantillaFormato: funcionFormatoBool },
    { propiedad: 'AnexoLNA', titulo: 'Anexo LNA', plantillaFormato: arabToRoman },
    { propiedad: 'Otros', titulo: 'Otros' },
    { propiedad: 'Observaciones', titulo: 'Observaciones', plantillaFormato: (valor: any) => valor?.join(' ') },
    { propiedad: 'TieneDJCP', titulo: 'Tiene DJCP', plantillaFormato: funcionFormatoBool },
    { propiedad: 'TieneSeguridadElectrica', titulo: 'Seg. Electrica', plantillaFormato: funcionFormatoBool }];
    return (<>
        <h2>Reporte por NCM</h2>
        <Form inline onSubmit={e => {
            updateBusquedaActual(busqueda);
            e.preventDefault();
        }}>
            <Form.Group controlId="txtNcm">
                <Form.Label className="mx-2">NCM</Form.Label>
                <AsyncSelectCustom isClearable loadOptions={api.cargarNcm()} value={busqueda.ncm ? { value: busqueda.ncm, label: busqueda.ncm } : null}
                    onChange={(option: any) => updateBusqueda({ tipo: 'ncm', valor: option?.value ?? '' })}
                    placeholder="Ingrese un NCM..." createOptionPosition="first"
                    formatCreateLabel={(valor: any) => valor} ></AsyncSelectCustom>
            </Form.Group>
            <Button type="submit" className="ml-2 mb-2">Buscar</Button>
        </Form>
        <Button variant="success" className="mb-2" onClick={exportarAExcel}>
            <FontAwesomeIcon icon={faFileExcel} />
            <span>Exportar a Excel</span>
        </Button>
        <div className="row">
            <div className="col-9">
                <h4>Reporte</h4>
                <Grilla responsiva cargarDatos={(desde: number, hasta: number) => {
                    return Promise.resolve({ cantidadItems: reporte.current.length, items: reporte.current.slice(desde - 1, hasta) });
                }} campos={camposGrilla} ref={refGrilla}></Grilla>
            </div>
            <div className="col">
                <h4>Internos donde fue usado</h4>
                <Grilla responsiva cargarDatos={(desde: number, hasta: number) => {
                    return Promise.resolve({ cantidadItems: listadoInternos.current.length, items: listadoInternos.current.slice(desde - 1, hasta) });
                }} campos={[{ propiedad: 'Interno', titulo: 'Interno', clave: true }]} ref={refGrilla2}></Grilla>
            </div>
        </div>
        <MyModal show={generandoExcel}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead">Generando Excel...</p>
                    <div className="loader-container">
                        <Loader type="ball-spin-fade-loader" active></Loader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        api.cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
    </>);
}