import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { AppContext, NotFoundComponent } from "../../App";
import * as Yup from "yup";
import { Field, Formik, FormikProps } from "formik";
import { MyForm, MyFormCheck, MyFormControl, MyFormRadio, MyTextarea, MySelect } from "../../FormikHooks";
import { Button, Col, Form, Alert, Modal } from "react-bootstrap";
import { isNullOrWhiteSpace, toFixedDecimal, groupBy, strCmp, abrirPdf, useDecodedParams } from "../../Utilidades";
import { DateTime } from "luxon";
import { Decimal } from "decimal.js";
import { MyModal } from "../../MyModal";
import { GrillaSync, TipoCampo } from "../../Grilla";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import { MonedaConceptoPresupuesto, BaseConceptoPlantillaCalculoImportador, MonedaConceptoPlantillaCalculoImportador, TabCaratula } from "Enums";
import { ReferenciasPresupuesto } from "./ReferenciasPresupuesto";
import { ConceptosPresupuesto } from "./ConceptosPresupuesto";
import Loader from "react-loaders";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useApi, TipoLock } from "ApiHooks";
enum TipoAccion {
    Guardar,
    GuardarEImprimir
}
function calcularTotalConcepto(c: any) {
    return new Decimal(c.Monto).plus(new Decimal(c.Monto).mul(c.Porcentaje).div(100));
}

function calcularConceptosPresupuesto(fob: Decimal, flete: Decimal, seguro: Decimal, baseImponible: Decimal,
    plantilla: any) {
    const cif = fob.plus(flete).plus(seguro);
    let lista = [];
    for (let conceptoPlantilla of plantilla.Conceptos) {
        let montoBase;
        switch (conceptoPlantilla.Base) {
            case BaseConceptoPlantillaCalculoImportador.FOB:
                montoBase = fob;
                break;
            case BaseConceptoPlantillaCalculoImportador.CIF:
                montoBase = cif;
                break;
            case BaseConceptoPlantillaCalculoImportador.BaseImponible:
                montoBase = baseImponible;
                break;
            default:
                throw new Error('Plantilla calculo importador no tiene una base valida');
        }
        let montoConcepto = new Decimal(0);
        for (let rango of conceptoPlantilla.Rangos) {
            if (montoBase.comparedTo(rango.Minimo) > -1 && montoBase.comparedTo(rango.Maximo) === -1) {
                let total = montoBase.mul(rango.Porcentaje).div(100);
                let montoMinimo = new Decimal(rango.MontoMinimo);
                if (total.comparedTo(montoMinimo) === 1) {
                    montoConcepto = total;
                } else {
                    montoConcepto = montoMinimo;
                }
                break;
            }
        }
        if (montoConcepto.comparedTo(0) === 1) {
            let moneda;
            switch (conceptoPlantilla.Moneda) {
                case MonedaConceptoPlantillaCalculoImportador.DolarA:
                    moneda = MonedaConceptoPresupuesto.DolarA;
                    break;
                case MonedaConceptoPlantillaCalculoImportador.DolarB:
                    moneda = MonedaConceptoPresupuesto.DolarB;
                    break;
                case MonedaConceptoPlantillaCalculoImportador.Peso:
                    moneda = MonedaConceptoPresupuesto.Peso;
                    break;
                default:
                    throw new Error('Plantilla calculo importador no tiene una moneda valida');
            }
            lista.push({
                Nombre: conceptoPlantilla.Nombre,
                Monto: +montoConcepto, //castear a numero javascript
                Calculado: true,
                Porcentaje: conceptoPlantilla.PorcentajeGravado,
                Moneda: moneda
            });
        }
    }
    return lista;
}

export function CrearEditarPresupuesto() {
    let formikRef = React.useRef<FormikProps<any>>(null);
    let formikConceptosRef = React.useRef<FormikProps<any>>(null);
    let formikReferenciasRef = React.useRef<FormikProps<any>>(null);
    let formikPlantillasRef = React.useRef<FormikProps<any>>(null);
    let dialogoRef = React.useRef<DialogoConfirmarRef>(null);
    let [mensajeDialogoConfirmar, updateMensajeDialogoConfirmar] = useState('');
    let history = useHistory();
    let { mostrarError, userInfo } = React.useContext(AppContext);
    let api = useApi();
    let [cargandoDatosIniciales, updateCargandoDatosIniciales] = React.useState(true);
    let [cargandoPlantilla, updateCargandoPlantilla] = React.useState(false);
    let [notFound, updateNotFound] = React.useState(false);
    let { interno, id } = useDecodedParams() as { interno: string, id?: string };
    let location = useLocation<{ usarNombreInterno?: boolean }>();
    let idNuevo = React.useRef<{ valor: string | null }>({ valor: null });
    let [generandoPdf, updateGenerandoPdf] = useState(false);
    const [submitRef, setSubmitRef] = useState(false)
    const [valoresNuevaReferencia, setValoresNuevaReferencia] = useState()
    let [estadoPresupuesto, updateEstadoPresupuesto] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'cargar') {
            return {
                ...estado, configuracion: accion.configuracion ?? {},
                caratula: accion.caratula ?? {},
                items: accion.items ?? [],
                liquidacion: accion.liquidacion ?? [], cotizaciones: accion.cotizaciones ?? [],
                tipoCambioDolarA: accion.tipoCambioDolarA, referencias: accion.referencias ?? [],
                conceptos: accion.conceptos ?? [], cliente: accion.cliente ?? '',
                domicilio: accion.domicilio ?? '', presupuesto: accion.presupuesto,
                versionLiquidacion: accion.presupuesto?.VersionLiquidacion ?? (accion.caratula?.VersionLiquidacion ?? 0),
                plantillaCalculoId: accion.presupuesto?.PlantillaCalculoId
            };
        } else if (accion.tipo === 'setLiquidacionGrilla') {
            return { ...estado, liquidacionGrilla: accion.valor ?? [] };
        } else if (accion.tipo === 'setTotales') {
            return { ...estado, totales: accion.valor ?? [] };
        } else if (accion.tipo === 'setConceptosGrilla') {
            return { ...estado, conceptosGrilla: accion.valor ?? [] };
        } else if (accion.tipo === 'insertConcepto') {
            let nuevosConceptos = Array.from(estado.conceptos);
            let indice = nuevosConceptos.findIndex((c: any) => c.Nombre === accion.valor.Nombre);
            if (indice > -1) {
                nuevosConceptos.splice(indice, 1);
            }
            nuevosConceptos.push(accion.valor);
            return { ...estado, conceptos: nuevosConceptos };
        } else if (accion.tipo === 'deleteConcepto') {
            let nuevosConceptos = Array.from(estado.conceptos);
            let indice = nuevosConceptos.findIndex((c: any) => c.Nombre === accion.valor);
            if (indice > -1) {
                nuevosConceptos.splice(indice, 1);
            }
            return { ...estado, conceptos: nuevosConceptos };
        } else if (accion.tipo === 'insertConceptosCalculados') {
            let nombresConceptosCalculados = accion.conceptos.map((c: any) => c.Nombre);
            let nuevosConceptos = estado.conceptos.filter((c: any) => !c.Calculado && !nombresConceptosCalculados.includes(c.Nombre));
            nuevosConceptos = nuevosConceptos.concat(accion.conceptos);
            return {
                ...estado, conceptos: nuevosConceptos,
                versionLiquidacion: estado.caratula.VersionLiquidacion,
                plantillaCalculoId: accion.plantillaCalculoId
            };
        } else if (accion.tipo === 'insertReferencia') {
            let nuevasReferencias = Array.from(estado.referencias);
            let indice = nuevasReferencias.findIndex((r: any) => r.Nombre === accion.valor.Nombre);
            if (indice > -1) {
                nuevasReferencias.splice(indice, 1);
            }
            nuevasReferencias.push(accion.valor);
            return { ...estado, referencias: nuevasReferencias };
        } else if (accion.tipo === 'deleteReferencia') {
            let nuevasReferencias = Array.from(estado.referencias);
            let indice = nuevasReferencias.findIndex((r: any) => r.Nombre === accion.valor);
            if (indice > -1) {
                nuevasReferencias.splice(indice, 1);
            }
            return { ...estado, referencias: nuevasReferencias };
        } else if (accion.tipo === 'setTipoCambioDolarA') {
            return { ...estado, tipoCambioDolarA: accion.valor };
        } else if (accion.tipo === 'setTipoCambioDolarB') {
            return { ...estado, tipoCambioDolarB: accion.valor };
        }
        return { ...estado };
    }, {
        configuracion: {},
        caratula: {},
        items: [],
        liquidacion: [],
        cotizaciones: [],
        tipoCambioDolarA: 0,
        tipoCambioDolarB: 0,
        liquidacionGrilla: [],
        referencias: [],
        conceptos: [],
        conceptosGrilla: [],
        totales: [],
        cliente: '',
        domicilio: '',
        presupuesto: null,
        versionLiquidacion: 0,
        plantillaCalculoId: null
    });
    let [mensajeErrorAlert, updateMensajeErrorAlert] = React.useState('');
    let [valores, updateValores] = React.useState<any>({});
    let [mostrarSeleccionConceptos, updateMostrarSeleccionConceptos] = React.useState(false);
    let [mostrarSeleccionReferencias, updateMostrarSeleccionReferencias] = React.useState(false);
    let [ultimoBlobUrl, updateUltimoBlobUrl] = React.useState('');
    let [estadoModalConceptos, updateEstadoModalConceptos] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrarCrear') {
            return { creando: true, modificando: false, valorModificando: null, conceptoSeleccionado: accion.concepto };
        } else if (accion.tipo === 'mostrarModificar') {
            return { creando: false, modificando: true, valorModificando: accion.valor, conceptoSeleccionado: null };
        } else if (accion.tipo === 'esconderVentana') {
            return { creando: false, modificando: false, valorModificando: null, conceptoSeleccionado: null };
        }
        return { ...estado };
    }, { creando: false, modificando: false, valorModificando: null, conceptoSeleccionado: null });
    let [estadoModalReferencias, updateEstadoModalReferencias] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrarCrear') {
            return { creando: true, modificando: false, valorModificando: null, referenciaSeleccionada: accion.referencia };
        } else if (accion.tipo === 'mostrarModificar') {
            return { creando: false, modificando: true, valorModificando: accion.valor, referenciaSeleccionada: null };
        } else if (accion.tipo === 'esconderVentana') {
            return { creando: false, modificando: false, valorModificando: null, referenciaSeleccionada: null };
        }
        return { ...estado };
    }, { creando: false, modificando: false, valorModificando: null, referenciaSeleccionada: null });
    let [estadoModalSeleccionarPlantilla, updateEstadoModalSeleccionarPlantilla] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrar') {
            return { mostrar: true, cargando: true, plantillas: [], optionsPlantillas: [] };
        } else if (accion.tipo === 'cargarPlantillas') {
            return { mostrar: true, cargando: false, plantillas: accion.plantillas, optionsPlantillas: accion.plantillas.map((item: any) => ({ value: item.Id, label: item.Nombre })) };
        } else if (accion.tipo === 'esconder') {
            return { mostrar: false, cargando: false, plantillas: [], optionsPlantillas: [] };
        }
        return estado;
    }, { mostrar: false, cargando: false, optionsPlantillas: [] });
    React.useEffect(() => {
        async function cargar() {
            try {
                let respuesta = await api.getCaratulaPresupuesto(interno);
                if (!respuesta) {
                    updateNotFound(true);
                    return;
                }
                let caratula = respuesta.Caratula;
                let items = respuesta.Items ?? [];
                let liquidacion = respuesta.Liquidacion ?? [];
                let cotizaciones: any[] = [];
                try {
                    cotizaciones = await api.getCotizacionesDivisas() ?? [];
                } catch (error) {
                    if (api.isCancel(error)) {
                        return;
                    } else {
                        console.error('Error al cargar cotizaciones', error);
                    }
                }
                let tipoCambioDolarA, tipoCambioDolarB;
                let presupuesto = null;
                let cliente = '';
                let email = '';
                let domicilio = '';
                let importador = null;
                if (!isNullOrWhiteSpace(caratula.CuitImportador)) {
                    try {
                        importador = await api.getImportador(caratula.CuitImportador);
                    } catch (error) {
                        if (api.isCancel(error)) {
                            return;
                        }
                        console.error('Error al cargar importador', error);
                    }
                }
                cliente = importador?.Nombre ?? '';
                domicilio = (importador?.Domicilio ?? '') + ' ' + (importador?.Localidad ?? '');
                email = importador?.Email ?? '';
                if (id) {
                    presupuesto = await api.getPresupuesto(id);
                    if (!presupuesto) {
                        updateNotFound(true);
                        return;
                    }
                    tipoCambioDolarA = presupuesto.TipoCambioDolarA;
                    tipoCambioDolarB = presupuesto.TipoCambioDolarB;
                    formikRef.current?.resetForm({
                        values: {
                            'Nombre': presupuesto.Nombre,
                            'ACuenta': presupuesto.ACuenta,
                            'NombreDespachante': presupuesto.NombreDespachante,
                            'TipoCambioDolarA': presupuesto.TipoCambioDolarA,
                            'TipoCambioDolarB': presupuesto.TipoCambioDolarB,
                            'Comentarios': presupuesto.Comentarios,
                            'Email': presupuesto.Email,
                            'Accion': TipoAccion.Guardar
                        }
                    });
                } else {
                    tipoCambioDolarA = cotizaciones.find((c: any) => c.CodigoSIM === 'DOL')?.Venta ?? caratula.TipoCambioDolar;
                    tipoCambioDolarB = tipoCambioDolarA;
                    let nombreDespachante = '';
                    if (!isNullOrWhiteSpace(caratula.CuitDespachante)) {
                        try {
                            let despachante = await api.getDespachante(caratula.CuitDespachante);
                            nombreDespachante = despachante?.Nombre ?? '';
                        } catch (error) {
                            if (api.isCancel(error)) {
                                return;
                            }
                            console.error('Error al obtener despachante', error);
                        }
                    }
                    formikRef.current?.resetForm({
                        values: {
                            'Nombre': location.state.usarNombreInterno ? interno : '',
                            'ACuenta': 0,
                            'NombreDespachante': nombreDespachante,
                            'TipoCambioDolarA': toFixedDecimal(tipoCambioDolarA, 2),
                            'TipoCambioDolarB': toFixedDecimal(tipoCambioDolarB, 2),
                            'Comentarios': '',
                            'Email': email,
                            'Accion': TipoAccion.Guardar
                        }
                    });
                }
                updateEstadoPresupuesto({
                    tipo: 'cargar', caratula: caratula, items: items, liquidacion: liquidacion,
                    cotizaciones: cotizaciones, tipoCambioDolarA: tipoCambioDolarA,
                    tipoCambioDolarB: tipoCambioDolarB, referencias: presupuesto?.Referencias ?? [],
                    conceptos: presupuesto?.Conceptos ?? [], presupuesto: presupuesto,
                    cliente: cliente, domicilio: domicilio
                });
                updateCargandoDatosIniciales(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar presupuesto', error);
                    mostrarError('Error al cargar presupuesto');
                }
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);
    function hacerCalculoAutomatico(plantilla: any) {
        try {
            let conceptosCalculados = calcularConceptosPresupuesto(new Decimal(valores.FobCaratula),
                new Decimal(valores.FleteCaratula), new Decimal(valores.SeguroCaratula),
                new Decimal(valores.BaseImponible), plantilla);
            updateEstadoPresupuesto({
                tipo: 'insertConceptosCalculados',
                conceptos: conceptosCalculados,
                plantillaCalculoId: plantilla.Id
            });
        } catch (error) {
            console.error('Error en calculo automatico de conceptos', error);
            mostrarError('Hubo un error al calcular los conceptos');
        }
    }
    React.useEffect(() => {
        if (!cargandoDatosIniciales) {
            if (estadoPresupuesto.plantillaCalculoId && estadoPresupuesto.versionLiquidacion < estadoPresupuesto.caratula.VersionLiquidacion) {
                updateMensajeDialogoConfirmar('Hay conceptos que fueron calculados con una liquidación anterior. ¿Desea volver a calcularlos?');
                dialogoRef.current!.mostrar().then(async () => {
                    try {
                        updateCargandoPlantilla(true);
                        let respuesta = await api.getPlantillaImportador(estadoPresupuesto.plantillaCalculoId);
                        if (respuesta) {
                            hacerCalculoAutomatico(respuesta);
                        } else {
                            mostrarError('La plantilla que se usó para calcular los conceptos de esta liquidación ya no existe');
                        }
                        updateCargandoPlantilla(false);
                    } catch (error) {
                        if (!api.isCancel(error)) {
                            console.error('Error al cargar plantilla de calculo', error);
                            updateCargandoPlantilla(false);
                        }
                    }
                }).catch(() => { });
            }
        }
        //eslint-disable-next-line
    }, [cargandoDatosIniciales]);
    React.useEffect(() => {
        let fobCaratula = toFixedDecimal(new Decimal(estadoPresupuesto.caratula.TotalFob ?? 0).plus(estadoPresupuesto.caratula.AjusteFob ?? 0), 2);
        let fleteCaratula = toFixedDecimal(estadoPresupuesto.items.filter((i: any) => i.NroItem !== 0)
            .reduce((total: Decimal, i: any) => total.plus(i.Flete ?? 0), new Decimal(0)), 2);
        let seguroCaratula = toFixedDecimal(estadoPresupuesto.items.filter((i: any) => i.NroItem !== 0)
            .reduce((total: Decimal, i: any) => total.plus(i.Seguro ?? 0), new Decimal(0)), 2);
        let ajusteIncluir = toFixedDecimal(estadoPresupuesto.caratula.AjusteIncluir ?? 0, 2);
        let ajusteDeducir = toFixedDecimal(estadoPresupuesto.caratula.AjusteDeducir ?? 0, 2);
        let cotizacionFob = estadoPresupuesto.cotizaciones.find((c: any) => c.CodigoSIM === estadoPresupuesto.caratula.CodigoDivisaFob);
        let cotizacionFlete = estadoPresupuesto.cotizaciones.find((c: any) => c.CodigoSIM === estadoPresupuesto.caratula.CodigoDivisaSeguro);
        let cotizacionSeguro = estadoPresupuesto.cotizaciones.find((c: any) => c.CodigoSIM === estadoPresupuesto.caratula.CodigoDivisaSeguro);
        let paseFob = toFixedDecimal(cotizacionFob?.TipoPaseVenta ?? estadoPresupuesto.caratula.TipoCambioFob, 5);
        let paseFlete = toFixedDecimal(cotizacionFlete?.TipoPaseVenta ?? estadoPresupuesto.caratula.TipoCambioFlete, 5);
        let paseSeguro = toFixedDecimal((cotizacionSeguro?.TipoPaseVenta ?? estadoPresupuesto.caratula.TipoCambioSeguro) ?? 0, 5);
        let baseImponible = toFixedDecimal(estadoPresupuesto.items.filter((i: any) => i.NroItem !== 0)
            .reduce((total: Decimal, i: any) => total.plus(i.BaseImponible), new Decimal(0)), 2);
        let baseIva = toFixedDecimal(estadoPresupuesto.items.filter((i: any) => i.NroItem !== 0)
            .reduce((total: Decimal, i: any) => total.plus(i.BaseIva), new Decimal(0)), 2);
        updateValores({
            FobCaratula: fobCaratula, FleteCaratula: fleteCaratula, SeguroCaratula: seguroCaratula,
            AjusteIncluir: ajusteIncluir, AjusteDeducir: ajusteDeducir, PaseFob: paseFob, PaseFlete: paseFlete,
            PaseSeguro: paseSeguro, BaseImponible: baseImponible, BaseIva: baseIva
        });
    }, [estadoPresupuesto.caratula, estadoPresupuesto.items, estadoPresupuesto.cotizaciones]);
    React.useEffect(() => {
        let liquidacionGrilla = [];
        for (let [clave, grupo] of groupBy(estadoPresupuesto.liquidacion.filter((l: any) => l.NroItem === 0),
            (l: any) => l.Codigo)) {
            let total = grupo.reduce((total: Decimal, l: any) => total.plus(l.Total), new Decimal(0));
            liquidacionGrilla.push({
                Codigo: clave,
                Descripcion: grupo[0].Descripcion,
                PGC: grupo[0].PGC,
                Total: total,
                Pesos: total.mul(estadoPresupuesto.tipoCambioDolarA)
            });
        }
        updateEstadoPresupuesto({ tipo: 'setLiquidacionGrilla', valor: liquidacionGrilla.sort((l1: any, l2: any) => strCmp(l1.Codigo, l2.Codigo)) });
    }, [estadoPresupuesto.liquidacion, estadoPresupuesto.tipoCambioDolarA]);
    React.useEffect(() => {
        let totalAPagar = estadoPresupuesto.liquidacion.filter((l: any) => l.PGC === 'P' && l.NroItem !== 0)
            .reduce((total: Decimal, l: any) => total.plus(l.Total), new Decimal(0));
        let totalACobrar = estadoPresupuesto.liquidacion.filter((l: any) => l.PGC === 'C' && l.NroItem !== 0)
            .reduce((total: Decimal, l: any) => total.plus(l.Total), new Decimal(0));
        let totalAGarantizar = estadoPresupuesto.liquidacion.filter((l: any) => l.PGC === 'G' && l.NroItem !== 0)
            .reduce((total: Decimal, l: any) => total.plus(l.Total), new Decimal(0));
        let gastosPesos = estadoPresupuesto.conceptos.filter((c: any) => c.Moneda === MonedaConceptoPresupuesto.Peso)
            .reduce((total: Decimal, c: any) => total.plus(calcularTotalConcepto(c)), new Decimal(0));
        let gastosDolarA = estadoPresupuesto.conceptos.filter((c: any) => c.Moneda === MonedaConceptoPresupuesto.DolarA)
            .reduce((total: Decimal, c: any) => total.plus(calcularTotalConcepto(c)), new Decimal(0));
        let gastosDolarB = estadoPresupuesto.conceptos.filter((c: any) => c.Moneda === MonedaConceptoPresupuesto.DolarB)
            .reduce((total: Decimal, c: any) => total.plus(calcularTotalConcepto(c)), new Decimal(0));
        let gastosOperativos = gastosPesos.plus(gastosDolarA.mul(estadoPresupuesto.tipoCambioDolarA))
            .plus(gastosDolarB.mul(estadoPresupuesto.tipoCambioDolarB));
        updateEstadoPresupuesto({
            tipo: 'setTotales', valor: [{
                Nombre: 'A Pagar',
                Pesos: totalAPagar.mul(estadoPresupuesto.tipoCambioDolarA),
                Dolares: totalAPagar
            }, {
                Nombre: 'A Cobrar',
                Pesos: totalACobrar.mul(estadoPresupuesto.tipoCambioDolarA),
                Dolares: totalACobrar
            }, {
                Nombre: 'A Garantizar',
                Pesos: totalAGarantizar.mul(estadoPresupuesto.tipoCambioDolarA),
                Dolares: totalAGarantizar
            }, {
                Nombre: 'Gastos Operativos',
                Pesos: gastosOperativos,
                Dolares: gastosDolarA.plus(gastosDolarB)
            }]
        });
    }, [estadoPresupuesto.liquidacion, estadoPresupuesto.tipoCambioDolarA, estadoPresupuesto.tipoCambioDolarB, estadoPresupuesto.conceptos]);
    React.useEffect(() => {
        let conceptosGrilla = estadoPresupuesto.conceptos.map((c: any) => {
            let peso = null;
            let dolarA = null;
            let dolarB = null;
            if (c.Moneda === MonedaConceptoPresupuesto.Peso) {
                peso = c.Monto;
            } else if (c.Moneda === MonedaConceptoPresupuesto.DolarA) {
                dolarA = c.Monto;
            } else if (c.Moneda === MonedaConceptoPresupuesto.DolarB) {
                dolarB = c.Monto;
            }
            return { Nombre: c.Nombre, Pesos: peso, DolaresA: dolarA, DolaresB: dolarB };
        });
        updateEstadoPresupuesto({ tipo: 'setConceptosGrilla', valor: conceptosGrilla });
    }, [estadoPresupuesto.conceptos]);
    React.useEffect(() => {
        if (estadoModalSeleccionarPlantilla.mostrar) {
            async function cargarPlantillas() {
                try {
                    let respuesta = await api.getPlantillasImportador(estadoPresupuesto.caratula.CuitImportador);
                    if (!respuesta || respuesta.length === 0) {
                        mostrarError(`El importador con cuit ${estadoPresupuesto.caratula.CuitImportador} no tiene ninguna plantilla de calculo cargada`);
                        updateEstadoModalSeleccionarPlantilla({ tipo: 'esconder' });
                    } else {
                        updateEstadoModalSeleccionarPlantilla({ tipo: 'cargarPlantillas', plantillas: respuesta });
                    }
                } catch (error) {
                    if (!api.isCancel(error)) {
                        console.error('Error al cargar plantillas de calculo', error);
                        mostrarError('Error al cargar plantillas de calculo');
                        updateEstadoModalSeleccionarPlantilla({ tipo: 'esconder' });
                    }
                }
            }
            cargarPlantillas();
            return () => {
                api.cancelCurrentTokens();
            }
        }
        //eslint-disable-next-line
    }, [estadoModalSeleccionarPlantilla.mostrar]);
    React.useEffect(() => {
        if (!isNullOrWhiteSpace(ultimoBlobUrl)) {
            return () => {
                URL.revokeObjectURL(ultimoBlobUrl);
            }
        }
    }, [ultimoBlobUrl]);
    React.useEffect(() => {
        if (submitRef) {
            updateEstadoPresupuesto({ tipo: 'insertReferencia', valor: valoresNuevaReferencia });
            setSubmitRef(false)
        }
        //eslint-disable-next-line
    }, [submitRef, valoresNuevaReferencia])
    async function submit(values: any) {
        let { Accion, ...values2 } = values;
        let presupuesto: any;
        let idActual = id || idNuevo.current.valor;
        if (idActual) {
            presupuesto = {
                ...values2,
                Id: idActual,
                Carpeta: interno,
                NroClienteAlpha: userInfo.nroClienteAlpha,
                EmpresaId: userInfo.empresaActual,
                Nombre: estadoPresupuesto.presupuesto.Nombre,
                Referencias: estadoPresupuesto.referencias,
                Conceptos: estadoPresupuesto.conceptos,
                CreadoPor: estadoPresupuesto.presupuesto.CreadoPor,
                CreadoEl: estadoPresupuesto.presupuesto.CreadoEl,
                ModificadoPor: userInfo.claims.nameid,
                ModificadoEl: DateTime.local().toISO(),
                PlantillaCalculoId: estadoPresupuesto.plantillaCalculoId,
                VersionLiquidacion: estadoPresupuesto.versionLiquidacion
            };
        } else {
            presupuesto = {
                ...values2,
                Carpeta: interno,
                NroClienteAlpha: userInfo.nroClienteAlpha,
                EmpresaId: userInfo.empresaActual,
                Referencias: estadoPresupuesto.referencias,
                Conceptos: estadoPresupuesto.conceptos,
                CreadoPor: userInfo.claims.nameid,
                CreadoEl: DateTime.local().toISO(),
                ModificadoPor: userInfo.claims.nameid,
                ModificadoEl: DateTime.local().toISO(),
                PlantillaCalculoId: estadoPresupuesto.plantillaCalculoId,
                VersionLiquidacion: estadoPresupuesto.versionLiquidacion
            };
        }
        try {
            let puedeModificar = await api.obtenerLock(TipoLock.Caratula, interno);
            if (puedeModificar) {
                let { exito, error, nuevoId } = await api.insertPresupuesto(presupuesto);
                if (exito) {
                    idNuevo.current.valor = nuevoId;
                    await api.eliminarLock(TipoLock.Caratula, interno);
                    if (Accion === TipoAccion.GuardarEImprimir) {
                        await imprimir();
                    } else {
                        history.replace('/caratulas/' + encodeURIComponent(interno),
                            { tabAMostrar: TabCaratula.Presupuestos });
                    }
                } else {
                    updateMensajeErrorAlert(error);
                }
            } else {
                mostrarError('No se puede guardar el presupuesto porque otro usuario está modificando la caratula');
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al guardar presupuesto', error);
                mostrarError('Error al guardar presupuesto');
            }
        }
    }

    async function imprimir() {
        try {
            updateGenerandoPdf(true);
            let respuesta = await api.getPdfPresupuesto(idNuevo.current.valor as string);
            updateGenerandoPdf(false);
            let blobUrl = URL.createObjectURL(respuesta);
            updateUltimoBlobUrl(blobUrl);
            abrirPdf(blobUrl).catch(() => {
                mostrarError('Tiene habilitado el bloqueador de pop-ups. Por favor deshabilitelo y vuelva a intentarlo');
            });
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al imprimir presupuesto', error);
                mostrarError('Error al imprimir presupuesto');
            }
            if (!api.isUnmounted()) {
                updateGenerandoPdf(false);
            }
        }
    }
    let schema = Yup.object({
        "Nombre": Yup.string().nullable().required('Debe ingresar el nombre'),
        "ACuenta": Yup.number().nullable().required('Debe ingresar el monto a cuenta')
            .typeError('Debe ingresar un número')
            .min(0, 'El monto a cuenta debe ser mayor o igual a cero'),
        "TipoCambioDolarA": Yup.number().nullable().required('Debe ingresar el tipo de cambio Dolar A')
            .typeError('Debe ingresar un número')
            .moreThan(0, 'El tipo de cambio Dolar A debe ser mayor a cero'),
        "TipoCambioDolarB": Yup.number().nullable().required('Debe ingresar el tipo de cambio Dolar B')
            .typeError('Debe ingresar un número')
            .moreThan(0, 'El tipo de cambio Dolar B debe ser mayor a cero'),
        "Comentarios": Yup.string().nullable(),
        "Email": Yup.string().nullable(),
        "NombreDespachante": Yup.string().nullable(),
        "Accion": Yup.number().nullable()
    });
    return notFound ? <NotFoundComponent></NotFoundComponent> : (<>
        <h2>{id ? 'Modificar presupuesto' : 'Crear presupuesto'} de <Link to={'/caratulas/' + encodeURIComponent(interno)}>{interno}</Link></h2>
        <Formik innerRef={formikRef} validationSchema={schema} initialValues={{}} onSubmit={submit}>
            <MyForm blocking={cargandoDatosIniciales || cargandoPlantilla}>
                {mensajeErrorAlert && (<Alert variant="danger">{mensajeErrorAlert}</Alert>)}
                <Field type="hidden" name="Accion" value={TipoAccion.Guardar}></Field>
                <Form.Row>
                    <Form.Group as={Col}>
                        <MyFormControl type="text" name="Nombre" label="Nombre" readOnly={!!id}></MyFormControl>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <MyFormControl type="text" name="NombreDespachante" label="Despachante"></MyFormControl>
                        </Form.Group>
                        <Form.Group controlId="txtCliente">
                            <Form.Label>Cliente</Form.Label>
                            <Form.Control type="text" disabled plaintext value={estadoPresupuesto.cliente}></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="txtDomicilio">
                            <Form.Label>Domicilio</Form.Label>
                            <Form.Control type="text" disabled plaintext value={estadoPresupuesto.domicilio}></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <MyFormControl type="text" name="Email" label="Email"></MyFormControl>
                        </Form.Group>
                        <Form.Group controlId="txtInterno">
                            <Form.Label>Carpeta</Form.Label>
                            <Form.Control type="text" disabled plaintext value={interno}></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <MyTextarea name="Comentarios" label="Comentarios"></MyTextarea>
                        </Form.Group>
                    </Col>
                    <Col>
                        <h2>Valores del despacho</h2>
                        <div className="valoresPresupuesto">
                            <p className="text-right" style={{ gridColumn: '1/span 1', gridRow: '1/span 1' }}>FOB</p>
                            <p className="text-right" style={{ gridColumn: '2/span 1', gridRow: '1/span 1' }}>{valores.FobCaratula ?? ''}</p>
                            <p className="text-right" style={{ gridColumn: '1/span 1', gridRow: '2/span 1' }}>Flete</p>
                            <p className="text-right" style={{ gridColumn: '2/span 1', gridRow: '2/span 1' }}>{valores.FleteCaratula ?? ''}</p>
                            <p className="text-right" style={{ gridColumn: '1/span 1', gridRow: '3/span 1' }}>Seguro</p>
                            <p className="text-right" style={{ gridColumn: '2/span 1', gridRow: '3/span 1' }}>{valores.SeguroCaratula ?? ''}</p>
                            <p className="text-right" style={{ gridColumn: '1/span 1', gridRow: '4/span 1' }}>Ajuste a Incluir</p>
                            <p className="text-right" style={{ gridColumn: '2/span 1', gridRow: '4/span 1' }}>{valores.AjusteIncluir ?? ''}</p>
                            <p className="text-right" style={{ gridColumn: '1/span 1', gridRow: '5/span 1' }}>Ajuste a Deducir</p>
                            <p className="text-right" style={{ gridColumn: '2/span 1', gridRow: '5/span 1' }}>{valores.AjusteDeducir ?? ''}</p>
                            <p className="text-right" style={{ gridColumn: '3/span 1', gridRow: '1/span 1' }}>Divisa</p>
                            <p className="text-center" style={{ gridColumn: '4/span 1', gridRow: '1/span 1' }}>{estadoPresupuesto.caratula.CodigoDivisaFob ?? ''}</p>
                            <p className="text-right" style={{ gridColumn: '3/span 1', gridRow: '2/span 1' }}>Divisa</p>
                            <p className="text-center" style={{ gridColumn: '4/span 1', gridRow: '2/span 1' }}>{estadoPresupuesto.caratula.CodigoDivisaFlete ?? ''}</p>
                            <p className="text-right" style={{ gridColumn: '3/span 1', gridRow: '3/span 1' }}>Divisa</p>
                            <p className="text-center" style={{ gridColumn: '4/span 1', gridRow: '3/span 1' }}>{estadoPresupuesto.caratula.CodigoDivisaFlete ?? ''}</p>
                            <p className="text-right" style={{ gridColumn: '5/span 1', gridRow: '1/span 1' }}>Pase</p>
                            <p className="text-right" style={{ gridColumn: '6/span 1', gridRow: '1/span 1' }}>{valores.PaseFob ?? ''}</p>
                            <p className="text-right" style={{ gridColumn: '5/span 1', gridRow: '2/span 1' }}>Pase</p>
                            <p className="text-right" style={{ gridColumn: '6/span 1', gridRow: '2/span 1' }}>{valores.PaseFlete ?? ''}</p>
                            <p className="text-right" style={{ gridColumn: '5/span 1', gridRow: '3/span 1' }}>Pase</p>
                            <p className="text-right" style={{ gridColumn: '6/span 1', gridRow: '3/span 1' }}>{valores.PaseSeguro ?? ''}</p>
                            <p className="text-right" style={{ gridColumn: '5/span 1', gridRow: '4/span 1' }}>Base Imponible</p>
                            <p className="text-right" style={{ gridColumn: '6/span 1', gridRow: '4/span 1' }}>{valores.BaseImponible ?? ''}</p>
                            <p className="text-right" style={{ gridColumn: '5/span 1', gridRow: '5/span 1' }}>Base Iva</p>
                            <p className="text-right" style={{ gridColumn: '6/span 1', gridRow: '5/span 1' }}>{valores.BaseIva ?? ''}</p>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <h4>Referencias</h4>
                        <GrillaSync datos={estadoPresupuesto.referencias} campos={[{ propiedad: 'Nombre', titulo: 'Nombre', clave: true }, { propiedad: 'Valor', titulo: 'Valor' }]}
                            eventoAgregar={() => updateMostrarSeleccionReferencias(true)} eventoDetalle={(referencia: any) => {
                                updateEstadoModalReferencias({ tipo: 'mostrarModificar', valor: referencia });
                            }} eventoEliminar={(referencia: any) => {
                                updateMensajeDialogoConfirmar(`¿Está seguro que desea eliminar la referencia ${referencia.Nombre}?`);
                                dialogoRef.current!.mostrar().then(() => updateEstadoPresupuesto({ tipo: 'deleteReferencia', valor: referencia.Nombre })).catch(() => { });
                            }} mostrarFormCambiarPagina={false}></GrillaSync>
                    </Col>
                    <Col>
                        <h4>Liquidación</h4>
                        <GrillaSync mostrarFormCambiarPagina={false} datos={estadoPresupuesto.liquidacionGrilla} campos={[{ propiedad: 'Codigo', titulo: 'Codigo', clave: true }, { propiedad: 'Descripcion', titulo: 'Nombre' },
                        { propiedad: 'PGC', titulo: 'PGC' }, { propiedad: 'Pesos', titulo: 'Pesos', tipo: TipoCampo.Number }, { propiedad: 'Total', titulo: 'Dolares', tipo: TipoCampo.Number }]}></GrillaSync>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <h4>Conceptos</h4>
                        <Button className="mb-2 mr-2" onClick={() => updateMostrarSeleccionConceptos(true)}>
                            <FontAwesomeIcon icon={faPlus} />
                            <span>Agregar</span>
                        </Button>
                        <Button className="mb-2" disabled={isNullOrWhiteSpace(estadoPresupuesto.caratula.CuitImportador)}
                            onClick={() => updateEstadoModalSeleccionarPlantilla({ tipo: 'mostrar' })}>Calcular conceptos</Button>
                        <GrillaSync datos={estadoPresupuesto.conceptosGrilla} campos={[{ propiedad: 'Nombre', titulo: 'Nombre', clave: true }, { propiedad: 'Pesos', titulo: 'Pesos', tipo: TipoCampo.Number },
                        { propiedad: 'DolaresA', titulo: 'Dolares A', tipo: TipoCampo.Number }, { propiedad: 'DolaresB', titulo: 'Dolares B', tipo: TipoCampo.Number }]}
                            eventoDetalle={(conceptoGrilla: any) => {
                                let concepto = estadoPresupuesto.conceptos.find((c: any) => c.Nombre === conceptoGrilla.Nombre);
                                updateEstadoModalConceptos({ tipo: 'mostrarModificar', valor: concepto });
                            }} eventoEliminar={(conceptoGrilla: any) => {
                                updateMensajeDialogoConfirmar(`¿Está seguro que desea eliminar el concepto ${conceptoGrilla.Nombre}?`);
                                dialogoRef.current!.mostrar().then(() => updateEstadoPresupuesto({ tipo: 'deleteConcepto', valor: conceptoGrilla.Nombre })).catch(() => { });
                            }} mostrarFormCambiarPagina={false}></GrillaSync>
                    </Col>
                    <Col>
                        <h4>Totales</h4>
                        <GrillaSync datos={estadoPresupuesto.totales} campos={[{ propiedad: 'Nombre', titulo: 'Nombre', clave: true }, { propiedad: 'Pesos', titulo: 'Pesos', tipo: TipoCampo.Number },
                        { propiedad: 'Dolares', titulo: 'Dolares', tipo: TipoCampo.Number }]} mostrarFormCambiarPagina={false}></GrillaSync>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <MyFormControl type="number" name="ACuenta" label="A Cuenta"></MyFormControl>

                    </Form.Group>
                    <Col>
                        <Form.Group>
                            <MyFormControl type="number" name="TipoCambioDolarA" label="Tipo Cambio Dolar A" onValueChange={(valor: any) => {
                                if (!isNullOrWhiteSpace(valor)) {
                                    try {
                                        let tipoCambio = new Decimal(valor);
                                        updateEstadoPresupuesto({ tipo: 'setTipoCambioDolarA', valor: tipoCambio });
                                    } catch (error) { }
                                }
                            }}></MyFormControl>
                        </Form.Group>
                        <Form.Group>
                            <MyFormControl type="number" name="TipoCambioDolarB" label="Tipo Cambio Dolar B" onValueChange={(valor: any) => {
                                if (!isNullOrWhiteSpace(valor)) {
                                    try {
                                        let tipoCambio = new Decimal(valor);
                                        updateEstadoPresupuesto({ tipo: 'setTipoCambioDolarB', valor: tipoCambio });
                                    } catch (error) { }
                                }
                            }}></MyFormControl>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Button variant="danger" className="mt-2 mb-2" onClick={() => history.goBack()}>Cancelar</Button>
                <Button className="mt-2 ml-2 mb-2" onClick={() => {
                    formikRef.current?.setFieldValue('Accion', TipoAccion.Guardar);
                    formikRef.current?.submitForm();
                }}>Guardar</Button>
                <Button className="mt-2 ml-2 mb-2" onClick={() => {
                    formikRef.current?.setFieldValue('Accion', TipoAccion.GuardarEImprimir);
                    formikRef.current?.submitForm();
                }}>Guardar E Imprimir</Button>
            </MyForm>
        </Formik>
        <MyModal show={mostrarSeleccionReferencias} onHide={() => updateMostrarSeleccionReferencias(false)}>
            <Modal.Dialog size="xl">
                <Modal.Header closeButton>
                    Seleccionar Referencia
                </Modal.Header>
                <Modal.Body>
                    <ReferenciasPresupuesto setValoresNuevaReferencia={setValoresNuevaReferencia} setSubmitRef={setSubmitRef} referenciaSeleccionada={referencia => {
                        updateMostrarSeleccionReferencias(false);
                        // updateEstadoModalReferencias({ tipo: 'mostrarCrear', referencia: referencia });
                    }} referenciasExistentes={estadoPresupuesto.referencias.map((r: any) => r.Nombre)}></ReferenciasPresupuesto>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateMostrarSeleccionReferencias(false)}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={mostrarSeleccionConceptos} onHide={() => updateMostrarSeleccionConceptos(false)}>
            <Modal.Dialog size="xl">
                <Modal.Header closeButton>
                    Seleccionar Concepto
                </Modal.Header>
                <Modal.Body>
                    <ConceptosPresupuesto conceptoSeleccionado={(concepto: any) => {
                        updateMostrarSeleccionConceptos(false);
                        updateEstadoModalConceptos({ tipo: 'mostrarCrear', concepto: concepto });
                    }} conceptosExistentes={estadoPresupuesto.conceptos.map((c: any) => c.Nombre)}></ConceptosPresupuesto>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateMostrarSeleccionConceptos(false)}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estadoModalConceptos.creando || estadoModalConceptos.modificando}
            onHide={() => updateEstadoModalConceptos({ tipo: 'esconderVentana' })}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>{estadoModalConceptos.creando ? 'Ingresar Valor de Concepto' : 'Modificar Valor de Concepto'}</h2>
                </Modal.Header>
                <Modal.Body>
                    <Formik innerRef={formikConceptosRef} validationSchema={Yup.object({
                        'Nombre': Yup.string().nullable().required('Debe ingresar el nombre'),
                        'Gravado': Yup.boolean().nullable().required(),
                        'Porcentaje': Yup.number().when('Gravado', {
                            is: true,
                            then: Yup.number().nullable().required('Debe ingresar el porcentaje')
                                .typeError('Debe ingresar un número')
                                .moreThan(0, 'El porcentaje debe ser mayor a cero')
                                .lessThan(100, 'El porcentaje debe ser menor a 100'),
                            otherwise: Yup.number().nullable().typeError('Debe ingresar un número')
                        }),
                        'Moneda': Yup.number().nullable().required('Debe seleccionar la moneda')
                            .oneOf([MonedaConceptoPresupuesto.Peso, MonedaConceptoPresupuesto.DolarA, MonedaConceptoPresupuesto.DolarB], 'Debe seleccionar la moneda'),
                        'Monto': Yup.number().nullable().required('Debe ingresar el monto')
                            .typeError('Debe ingresar un número')
                            .moreThan(0, 'El monto debe ser mayor a cero'),
                    })} initialValues={{
                        'Nombre': estadoModalConceptos.valorModificando?.Nombre ?? estadoModalConceptos.conceptoSeleccionado?.Nombre,
                        'Gravado': estadoModalConceptos.valorModificando === null || estadoModalConceptos.valorModificando === undefined ? estadoModalConceptos.conceptoSeleccionado?.Porcentaje > 0 : estadoModalConceptos.valorModificando.Porcentaje > 0,
                        'Porcentaje': estadoModalConceptos.valorModificando?.Porcentaje ?? estadoModalConceptos.conceptoSeleccionado?.Porcentaje,
                        'Moneda': `${estadoModalConceptos.valorModificando?.Moneda ?? estadoModalConceptos.conceptoSeleccionado?.Moneda}`,
                        'Monto': estadoModalConceptos.valorModificando?.Monto ?? ''
                    }} onSubmit={async (nuevoConcepto: any, actions) => {
                        nuevoConcepto.Moneda = parseInt(nuevoConcepto.Moneda);
                        let conceptosExistentes = estadoPresupuesto.conceptos.map((c: any) => c.Nombre);
                        if (estadoModalConceptos.modificando) {
                            let indice = conceptosExistentes.indexOf(estadoModalConceptos.valorModificando.Nombre);
                            if (indice > -1) {
                                conceptosExistentes.splice(indice, 1);
                            }
                        }
                        if (conceptosExistentes.includes(nuevoConcepto.Nombre)) {
                            actions.setFieldError('Nombre', `El concepto con nombre ${nuevoConcepto.Nombre} ya fue ingresado`);
                        } else {
                            updateEstadoPresupuesto({ tipo: 'insertConcepto', valor: nuevoConcepto });
                            updateEstadoModalConceptos({ tipo: 'esconderVentana' });
                        }
                        actions.setSubmitting(false);
                    }}>
                        <MyForm blockWhenSubmitting={false}>
                            <Form.Group>
                                <MyFormControl type="text" name="Nombre" label="Nombre" readOnly></MyFormControl>
                            </Form.Group>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <MyFormCheck readOnly name="Gravado" label="Gravado" labelOnLeft className="col-form-label"></MyFormCheck>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <MyFormControl inline readOnly type="number" name="Porcentaje" label="Porcentaje"></MyFormControl>
                                </Form.Group>
                            </Form.Row>
                            <Form.Group>
                                <MyFormRadio readOnly inline name="Moneda" id="radioPesos" label="Peso" value={MonedaConceptoPresupuesto.Peso}></MyFormRadio>
                                <MyFormRadio readOnly inline name="Moneda" id="radioDolaresA" label="Dolar A" value={MonedaConceptoPresupuesto.DolarA}></MyFormRadio>
                                <MyFormRadio readOnly inline name="Moneda" id="radioDolaresB" label="Dolar B" value={MonedaConceptoPresupuesto.DolarB}></MyFormRadio>
                            </Form.Group>
                            <Form.Group>
                                <MyFormControl autoFocus type="number" name="Monto" label="Monto"></MyFormControl>
                            </Form.Group>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstadoModalConceptos({ tipo: 'esconderVentana' })}>
                        Cancelar
                    </Button>
                    <Button onClick={() => formikConceptosRef.current?.submitForm()}>
                        Ingresar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estadoModalReferencias.creando || estadoModalReferencias.modificando}
            onHide={() => updateEstadoModalReferencias({ tipo: 'esconderVentana' })}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>{estadoModalReferencias.creando ? 'Ingresar Valor de Referencia' : 'Modificar Valor de Referencia'}</h2>
                </Modal.Header>
                <Modal.Body>
                    <Formik innerRef={formikReferenciasRef} validationSchema={Yup.object({
                        'Nombre': Yup.string().nullable().required('Debe ingresar el nombre'),
                        'Valor': Yup.string().nullable().required('Debe ingresar el valor')
                    })} initialValues={{
                        'Nombre': estadoModalReferencias.valorModificando?.Nombre ?? estadoModalReferencias.referenciaSeleccionada,
                        'Valor': estadoModalReferencias.valorModificando?.Valor ?? ''
                    }} onSubmit={async (nuevaReferencia: any, actions) => {
                        let referenciasExistentes = estadoPresupuesto.referencias.map((c: any) => c.Nombre);
                        if (estadoModalReferencias.valorModificando) {
                            let indice = referenciasExistentes.indexOf(estadoModalReferencias.valorModificando.Nombre);
                            if (indice > -1) {
                                referenciasExistentes.splice(indice, 1);
                            }
                        }
                        if (referenciasExistentes.includes(nuevaReferencia.Nombre)) {
                            actions.setFieldError('Nombre', `La referencia con nombre ${nuevaReferencia.Nombre} ya fue ingresada`);
                        } else {
                            updateEstadoPresupuesto({ tipo: 'insertReferencia', valor: nuevaReferencia });
                            updateEstadoModalReferencias({ tipo: 'esconderVentana' });
                        }
                        actions.setSubmitting(false);
                    }}>
                        <MyForm blockWhenSubmitting={false}>
                            <Form.Group>
                                <MyFormControl type="text" name="Nombre" label="Nombre"></MyFormControl>
                            </Form.Group>
                            <Form.Group>
                                <MyFormControl type="text" name="Valor" label="Valor" autoFocus></MyFormControl>
                            </Form.Group>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstadoModalReferencias({ tipo: 'esconderVentana' })}>
                        Cancelar
                    </Button>
                    <Button onClick={() => formikReferenciasRef.current?.submitForm()}>
                        Ingresar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estadoModalSeleccionarPlantilla.mostrar}
            onHide={() => updateEstadoModalSeleccionarPlantilla({ tipo: 'esconder' })}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>Seleccionar Plantilla de Calculo</h2>
                </Modal.Header>
                <Modal.Body>
                    <Formik innerRef={formikPlantillasRef} validationSchema={Yup.object({
                        'IdPlantilla': Yup.string().nullable().required('Debe seleccionar la plantilla'),
                    })} initialValues={{
                        'IdPlantilla': null
                    }} onSubmit={(values: any, actions) => {
                        let plantilla = estadoModalSeleccionarPlantilla.plantillas.find((p: any) => p.Id === values.IdPlantilla);
                        actions.setSubmitting(false);
                        updateEstadoModalSeleccionarPlantilla({ tipo: 'esconder' });
                        hacerCalculoAutomatico(plantilla);
                    }}>
                        <MyForm blocking={estadoModalSeleccionarPlantilla.cargando} blockWhenSubmitting={false}>
                            <Form.Group>
                                <MySelect name="IdPlantilla" label="Plantilla"
                                    options={estadoModalSeleccionarPlantilla.optionsPlantillas}></MySelect>
                            </Form.Group>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstadoModalSeleccionarPlantilla({ tipo: 'esconder' })}>
                        Cancelar
                    </Button>
                    <Button disabled={estadoModalSeleccionarPlantilla.cargando} onClick={() => formikPlantillasRef.current?.submitForm()}>
                        Ingresar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={generandoPdf}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead">Generando PDF presupuesto...</p>
                    <div className="loader-container">
                        <Loader type="ball-spin-fade-loader" active></Loader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        api.cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <DialogoConfirmar ref={dialogoRef} mensaje={mensajeDialogoConfirmar} textoBotonCancelar="No" textoBotonConfirmar="Sí"></DialogoConfirmar>
    </>);
}