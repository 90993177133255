import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import BlockUi from "react-block-ui";
import { SelectOption, OnValueChangeArgument } from './FormikHooks';
import { isNullOrWhiteSpace } from "./Utilidades";
import WindowedSelect, { WindowedMenuList } from "react-windowed-select";
import AsyncSelect from "react-select/async";
import CreatableSelect from "react-select/creatable";
import AsyncCreatableSelect from "react-select/async-creatable";

//import { passiveSupported } from 'passive-events-support/src/utils';


type AllowedSelectValues = string | number | string[];

function typeGuardSelectOption(option: SelectOption | readonly SelectOption[] | null | undefined): option is readonly SelectOption[] {
    return Array.isArray(option);
}
function typeGuardVal(val: AllowedSelectValues): val is string[] {
    return Array.isArray(val);
}
type MySelectNoFormikProps = {
    id?: string, name?: string, autoFocus?: boolean,
    options: Array<SelectOption> | (() => Promise<Array<SelectOption>>),
    isMulti?: boolean, isDisabled?: boolean,
    getOptionLabel?: (option: SelectOption) => string,
    onOptionChange?: (option: SelectOption | readonly SelectOption[] | null | undefined) => void,
    onValueChange?: (option: OnValueChangeArgument) => void,
    value: AllowedSelectValues, className?: string
};

function getColorsForSelect() {
    let div = document.createElement('div');
    div.className = 'form-control d-none';
    document?.querySelector('body')?.append(div);
    let style = window.getComputedStyle(div);
    let borderColor = style.getPropertyValue('border-color') || style.getPropertyValue('border-top-color');
    let borderWidth = style.getPropertyValue('border-width') || style.getPropertyValue('border-top-width');
    let bgColor = style.getPropertyValue('background-color');
    let color = style.getPropertyValue('color');
    div.className = 'form-control is-invalid d-none';
    style = window.getComputedStyle(div);
    let errorBorderColor = style.getPropertyValue('border-color') || style.getPropertyValue('border-top-color');
    div.parentNode?.removeChild(div);
    return {
        errorBorderColor: errorBorderColor,
        borderColor: borderColor,
        borderWidth: borderWidth,
        bgColor: bgColor,
        color: color
    };
}

export function BaseSelect(props: any) {
    //eslint-disable-next-line
    let [colors, ...ignorar] = useState(() => getColorsForSelect());
    
  //  console.log(passiveSupported());

    return <WindowedSelect {...props}
        styles={{
            control: (provided: any, state: any) => {
                return {
                    ...provided,
                    borderColor: colors.borderColor,
                    color: colors.color,
                    backgroundColor: colors.bgColor,
                    borderWidth: colors.borderWidth,
                }
            }, menu: (provided: any, state: any) => ({ ...provided, zIndex: 4 }),
        }} />
}

//TODO: poner tipo correcto en props
export function BaseAsyncSelect(props: any) {
    //eslint-disable-next-line
    let [colors, ...ignorar] = useState(() => getColorsForSelect());
    return <AsyncSelect {...props}
        styles={{
            control: (provided: any, state: any) => {
                return {
                    ...provided,
                    borderColor: colors.borderColor,
                    color: colors.color,
                    backgroundColor: colors.bgColor,
                    borderWidth: colors.borderWidth,
                }
            }, menu: (provided: any, state: any) => ({ ...provided, zIndex: 4 }),
        }} />
}


//TODO: poner tipo correcto en props
export function BaseCreatableSelect(props: any) {
    //eslint-disable-next-line
    let [colors, ...ignorar] = useState(() => getColorsForSelect());
    return <CreatableSelect {...props}
        styles={{
            control: (provided: any, state: any) => {
                return {
                    ...provided,
                    borderColor: colors.borderColor,
                    color: colors.color,
                    backgroundColor: colors.bgColor,
                    borderWidth: colors.borderWidth,
                }
            }, menu: (provided: any, state: any) => ({ ...provided, zIndex: 4 }),
        }} components={{ MenuList: WindowedMenuList }} />
}


//TODO: poner tipo correcto en props
export function BaseAsyncCreatableSelect(props: any) {
    //eslint-disable-next-line
    let [colors, ...ignorar] = useState(() => getColorsForSelect());
    return <AsyncCreatableSelect {...props}
        styles={{
            control: (provided: any, state: any) => {
                return {
                    ...provided,
                    borderColor: colors.borderColor,
                    color: colors.color,
                    backgroundColor: colors.bgColor,
                    borderWidth: colors.borderWidth,
                }
            }, menu: (provided: any, state: any) => ({ ...provided, zIndex: 4 }),
        }} />
}
export const MySelectNoFormik = React.forwardRef((props: MySelectNoFormikProps, ref: any) => {
    let optionsInProps = useRef(props.options);
    let [options, updateOptions] = useState(typeof props.options === 'function' ? [] : props.options);
    let [cargando, updateCargando] = useState(typeof props.options === 'function' ? true : false);
    let selectedOptions = useRef<SelectOption | readonly SelectOption[] | null>(null);

   
    useEffect(() => {
        if (typeof optionsInProps.current === 'function') {
            optionsInProps.current().then(result => {
                updateOptions(result);
                updateCargando(false);
            }).catch(error => {
                updateOptions([]);
                updateCargando(false);
            });
        }
    }, []);

    useEffect(() => {
        if (Array.isArray(props.options) && typeof optionsInProps.current !== 'function') {
            if (options.toString !== props.options.toString)
            {
                updateOptions(props.options);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.options]);

    useImperativeHandle(ref, () => ({
        getCurrentOptions: () => selectedOptions.current
    }));
    function getValue() {
        if (props.isMulti) {
            return options.filter(option => typeGuardVal(props.value) ? props.value.includes(option.value) :
                option.value === props.value?.toString());
        } else {
            if (props.value === null || props.value === undefined ||
                (typeof (props.value) === 'string' && isNullOrWhiteSpace(props.value))) {
                return null;
            } else {
                return options.find(option => typeGuardVal(props.value) ? props.value.includes(option.value)
                    : option.value === props.value.toString()) ?? null;
            }
        }
    }
    return (
        <>
            <BlockUi blocking={cargando}>
                <BaseSelect autoFocus={props.autoFocus} isDisabled={props.isDisabled} options={options} isMulti={props.isMulti} name={props.name}
                    value={getValue()} getOptionLabel={props.getOptionLabel} id={props.id} placeholder="Seleccionar..."
                    onChange={(option: SelectOption | readonly SelectOption[] | null | undefined) => {
                        selectedOptions.current = option ?? null;
                        if (typeGuardSelectOption(option)) {
                            props.onValueChange?.call(undefined, option.map((item: SelectOption) => item.value));
                        } else {
                            props.onValueChange?.call(undefined, option?.value ?? null);
                        }
                        props.onOptionChange?.call(undefined, option);
                    }} isClearable className={props.className}>
                </BaseSelect>
            </BlockUi>
        </>
    )
});