
import CryptoJS from 'crypto-js';
import { storageFactory } from 'storage-factory';

const storage = storageFactory(() => localStorage);
const clavePassword = 'alpha2000';
export const tokenStorage = {
    getUserName: () => storage.getItem('userName') ?? '',
    setUserName: (userName: string) => storage.setItem('userName', userName),
    getPassword: () => {
        try {
            let valor = storage.getItem('password');
            if (valor) {
                let decryptedPassword = CryptoJS.AES.decrypt(valor, clavePassword);
                return decryptedPassword.toString(CryptoJS.enc.Utf8);
            }
            return '';
        } catch (error) {
            console.error('Error al descifrar contraseña', error);
            return '';
        }
    }, setPassword: (password: string) => {
        let encryptedPassword = CryptoJS.AES.encrypt(password, clavePassword);
        storage.setItem('password', encryptedPassword.toString());
    }, getAccessToken: () => storage.getItem('accessToken') ?? '',
    setAccessToken: (accessToken: string) => storage.setItem('accessToken', accessToken),
    getRefreshToken: () => storage.getItem('refreshToken') ?? '',
    setRefreshToken: (refreshToken: string) => storage.setItem('refreshToken', refreshToken)
}


export const tokenStorageADV = {
    getUserName: () => storage.getItem('userNameADV') ?? '',
    setUserName: (userName: string) => storage.setItem('userNameADV', userName),
    getPassword: () => {
        try {
            let valor = storage.getItem('passwordADV');
            if (valor) {
                let decryptedPassword = CryptoJS.AES.decrypt(valor, clavePassword);
                return decryptedPassword.toString(CryptoJS.enc.Utf8);
            }
            return '';
        } catch (error) {
            console.error('Error al descifrar contraseña ADV', error);
            return '';
        }
    }, setPassword: (password: string) => {
        let encryptedPassword = CryptoJS.AES.encrypt(password, clavePassword);
        storage.setItem('passwordADV', encryptedPassword.toString());
    }, getAccessToken: () => storage.getItem('accessToken') + ':' + storage.getItem('userName') ?? '',
    setAccessToken: (accessToken: string) => storage.setItem('accessTokenADV', accessToken),
    getRefreshToken: () => storage.getItem('refreshTokenADV') ?? '',
    setRefreshToken: (refreshToken: string) => storage.setItem('refreshTokenADV', refreshToken)
}
// eslint-disable-next-line
export default {tokenStorage, tokenStorageADV};