import React from "react";
import { MyModal } from "MyModal";
import { Modal, Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import { MyForm, MyFormControl } from "FormikHooks";
import { AppContext } from "App";
import { GrillaSync, TipoCampo } from "Grilla";
import { DialogoConfirmar, DialogoConfirmarRef } from "DialogoConfirmar";
import { strCmp } from "Utilidades";
import { CrearEditarRangoPlantillaImportador, CrearEditarRangoPlantillaImportadorRef } from "./CrearEditarRangoPlantillaImportador";
import { BaseConceptoPlantillaCalculoImportador, MonedaConceptoPlantillaCalculoImportador } from "Enums";

export type CrearEditarPlantillaCalculoImportadorRef = {
    mostrarCrear: () => Promise<any>, mostrarModificar: (valor: any) => Promise<any>
};

export const CrearEditarPlantillaCalculoImportador = React.forwardRef((props: {
    plantillasExistentes: any[]
}, ref: any) => {
    let { plantillasExistentes } = props;
    let { mostrarError } = React.useContext(AppContext);
    let formikRef = React.useRef<FormikProps<any>>(null);
    let dialogoRef = React.useRef<DialogoConfirmarRef>(null);
    let crearEditarRangoRef = React.useRef<CrearEditarRangoPlantillaImportadorRef>(null);
    let contadorTempId = React.useRef(0);
    let [estado, updateEstado] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'cerrar') {
            return {
                creando: false, modificando: false, valorModificando: null, resolve: null, reject: null,
                conceptos: [], listaGrilla: []
            };
        } else if (accion.tipo === 'mostrarCrear') {
            return {
                creando: true, modificando: false, valorModificando: null, resolve: accion.resolve, reject: accion.reject,
                conceptos: [], listaGrilla: []
            };
        } else if (accion.tipo === 'mostrarModificar') {
            return {
                creando: false, modificando: true, valorModificando: accion.valor, resolve: accion.resolve, reject: accion.reject,
                conceptos: accion.valor.Conceptos ?? [], listaGrilla: []
            };
        } else if (accion.tipo === 'insertConcepto') {
            let nuevosConceptos = Array.from(estado.conceptos);
            let indice = nuevosConceptos.findIndex((item: any) => item.Nombre === accion.valor.Nombre);
            if (indice > -1) {
                nuevosConceptos.splice(indice, 1, accion.valor);
            } else {
                nuevosConceptos.push(accion.valor);
            }
            return { ...estado, conceptos: nuevosConceptos };
        } else if (accion.tipo === 'deleteConcepto') {
            return { ...estado, conceptos: estado.conceptos.filter((item: any) => item.Nombre !== accion.valor) };
        } else if (accion.tipo === 'setListaGrilla') {
            return { ...estado, listaGrilla: accion.valor };
        }
        return estado;
    }, {
        creando: false, modificando: false, valorModificando: null, resolve: null, reject: null, conceptos: [],
        listaGrilla: []
    });
    const cerrar = () => {
        estado?.reject();
        updateEstado({ tipo: 'cerrar' });
    }
    React.useImperativeHandle(ref, () => ({
        mostrarCrear: () => {
            return new Promise<any>((resolve, reject) => {
                updateEstado({ tipo: 'mostrarCrear', resolve: resolve, reject: reject });
            });
        }, mostrarModificar: (valor: any) => {
            return new Promise<any>((resolve, reject) => {
                updateEstado({ tipo: 'mostrarModificar', valor: valor, resolve: resolve, reject: reject });
            })
        }
    }));
    React.useEffect(() => {
        if (estado.creando || estado.modificando) {
            let listaGrilla = [];
            let indice = 0;
            for (let concepto of estado.conceptos) {
                for (let rango of concepto.Rangos) {
                    listaGrilla.push({
                        Indice: ++indice,
                        NombreConcepto: concepto.Nombre,
                        BaseConcepto: concepto.Base,
                        MonedaConcepto: concepto.Moneda,
                        MontoMinimo: rango.MontoMinimo,
                        Minimo: rango.Minimo,
                        Maximo: rango.Maximo,
                        Porcentaje: rango.Porcentaje,
                        Concepto: concepto,
                        Rango: rango
                    });
                }
            }
            listaGrilla.sort((a, b) => strCmp(a.NombreConcepto, b.NombreConcepto) || a.Minimo - b.Minimo);
            updateEstado({ tipo: 'setListaGrilla', valor: listaGrilla });
        }
    }, [estado.creando, estado.modificando, estado.conceptos]);
    const camposGrilla = [{ propiedad: 'Indice', visible: false, clave: true },
    { propiedad: 'NombreConcepto', titulo: 'Concepto' }, { propiedad: 'Minimo', titulo: 'Desde', tipo: TipoCampo.Number },
    { propiedad: 'Maximo', titulo: 'Hasta', tipo: TipoCampo.Number }, { propiedad: 'Porcentaje', titulo: 'Porcentaje', tipo: TipoCampo.Number },
    { propiedad: 'MontoMinimo', titulo: 'Monto Minimo', tipo: TipoCampo.Number },
    {
        propiedad: 'BaseConcepto', titulo: 'Base', plantillaFormato: (valor: any, item: any) => {
            if (typeof valor === 'string') {
                valor = parseInt(valor);
            }
            if (item.MonedaConcepto === MonedaConceptoPlantillaCalculoImportador.Peso) {
                return '';
            } else {
                if (valor === BaseConceptoPlantillaCalculoImportador.FOB) {
                    return 'FOB';
                } else if (valor === BaseConceptoPlantillaCalculoImportador.CIF) {
                    return 'CIF';
                } else if (valor === BaseConceptoPlantillaCalculoImportador.BaseImponible) {
                    return 'Base Imponible';
                }
                return '';
            }
        }
    }, {
        propiedad: 'MonedaConcepto', titulo: 'Tipo de Cambio', plantillaFormato: (valor: any) => {
            if (typeof valor === 'string') {
                valor = parseInt(valor);
            }
            if (valor === MonedaConceptoPlantillaCalculoImportador.DolarA) {
                return 'Dolar A/SIM';
            } else if (valor === MonedaConceptoPlantillaCalculoImportador.DolarB) {
                return 'Dolar B/Libre';
            } else if (valor === MonedaConceptoPlantillaCalculoImportador.Peso) {
                return 'Peso';
            }
            return '';
        }
    }];
    function eventoAgregarRango() {
        crearEditarRangoRef.current!.mostrarCrear().then((valores: any) => {
            let conceptoExistente = estado.conceptos.find((c: any) => c.Nombre === valores.Nombre);
            let nuevoConcepto;
            if (conceptoExistente) {
                nuevoConcepto = {
                    ...conceptoExistente,
                    Base: valores.Base,
                    Moneda: valores.Moneda,
                    PorcentajeGravado: valores.PorcentajeGravado
                };
                nuevoConcepto.Rangos.push({
                    Minimo: valores.Minimo,
                    Maximo: valores.Maximo,
                    Porcentaje: valores.Porcentaje,
                    MontoMinimo: valores.MontoMinimo
                });
            } else {
                nuevoConcepto = {
                    Nombre: valores.Nombre,
                    Base: valores.Base,
                    Moneda: valores.Moneda,
                    PorcentajeGravado: valores.PorcentajeGravado,
                    Rangos: [{
                        Minimo: valores.Minimo,
                        Maximo: valores.Maximo,
                        Porcentaje: valores.Porcentaje,
                        MontoMinimo: valores.MontoMinimo
                    }]
                }
            }
            updateEstado({ tipo: 'insertConcepto', valor: nuevoConcepto });
        }).catch(() => { });
    }
    function eventoModificarRango(item: any) {
        crearEditarRangoRef.current!.mostrarModificar(item.Rango, item.Concepto).then((valores: any) => {
            let nuevoConcepto = {
                ...item.Concepto,
                Base: valores.Base,
                Moneda: valores.Moneda,
                PorcentajeGravado: valores.PorcentajeGravado
            };
            nuevoConcepto.Rangos = nuevoConcepto.Rangos.filter((r: any) => r !== item.Rango);
            nuevoConcepto.Rangos.push({
                Minimo: valores.Minimo,
                Maximo: valores.Maximo,
                Porcentaje: valores.Porcentaje,
                MontoMinimo: valores.MontoMinimo
            });
            updateEstado({ tipo: 'insertConcepto', valor: nuevoConcepto });
        }).catch(() => { });
    }
    function eventoEliminarRango(item: any) {
        dialogoRef.current!.mostrar().then(() => {
            let nuevoConcepto = { ...item.Concepto };
            nuevoConcepto.Rangos = nuevoConcepto.Rangos.filter((r: any) => r !== item.Rango);
            if (nuevoConcepto.Rangos.length === 0) {
                updateEstado({ tipo: 'deleteConcepto', valor: nuevoConcepto.Nombre });
            } else {
                updateEstado({ tipo: 'insertConcepto', valor: nuevoConcepto });
            }
        }).catch(() => { });
    }
    let nombreInicial = '';
    if (estado.valorModificando) {
        nombreInicial = estado.valorModificando.Nombre;
    } else if (plantillasExistentes.length === 0) {
        nombreInicial = 'Plantilla Gastos';
    }
    return (
        <>
            <MyModal show={estado.creando || estado.modificando} onHide={cerrar}>
                <Modal.Dialog size="xl">
                    <Modal.Header closeButton>
                        <h2>{estado.creando ? 'Nueva plantilla' : 'Modificar plantilla'}</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik innerRef={formikRef} validationSchema={Yup.object({
                            'Nombre': Yup.string().nullable().required('Debe ingresar el nombre de la plantilla')
                        })} initialValues={{ 'Nombre': nombreInicial }} onSubmit={(values: any, actions) => {
                            let plantillasFiltradas = plantillasExistentes;
                            if (estado.modificando) {
                                plantillasFiltradas = plantillasExistentes.filter((item: any) => item.Nombre !== estado.valorModificando.Nombre);
                            }
                            if (plantillasFiltradas.map((item: any) => item.Nombre).includes(values.Nombre)) {
                                actions.setFieldError('Nombre', 'Ya existe una plantilla con el nombre ingresado');
                            } else if (estado.conceptos.length === 0) {
                                mostrarError('La plantilla debe tener al menos un concepto');
                            } else {
                                let plantilla = { ...values, Conceptos: estado.conceptos };
                                if (estado.modificando) {
                                    plantilla.Id = estado.valorModificando.Id;
                                    plantilla.TempId = estado.valorModificando.TempId;
                                } else {
                                    plantilla.TempId = ++contadorTempId.current;
                                }
                                estado.resolve(plantilla);
                                updateEstado({ tipo: 'cerrar' });
                            }
                            actions.setSubmitting(false);
                        }}>
                            <MyForm blockWhenSubmitting={false}>
                                <Form.Group>
                                    <MyFormControl type="text" name="Nombre" label="Nombre"></MyFormControl>
                                </Form.Group>
                                <GrillaSync datos={estado.listaGrilla} campos={camposGrilla}
                                    eventoAgregar={eventoAgregarRango}
                                    eventoDetalle={eventoModificarRango}
                                    eventoEliminar={eventoEliminarRango}
                                    mostrarFormCambiarPagina={false}></GrillaSync>
                            </MyForm>
                        </Formik>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={cerrar}>
                            Cancelar
                        </Button>
                        <Button type="button" variant="primary" onClick={() => formikRef.current?.submitForm()}>
                            Ingresar
                        </Button>
                    </Modal.Footer>
                </Modal.Dialog>
            </MyModal>
            <CrearEditarRangoPlantillaImportador ref={crearEditarRangoRef} conceptosExistentes={estado.conceptos}></CrearEditarRangoPlantillaImportador>
            <DialogoConfirmar ref={dialogoRef} mensaje="¿Está seguro que desea eliminar este concepto?" textoBotonConfirmar="Sí" textoBotonCancelar="No"></DialogoConfirmar>
        </>)
});