import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { AppContext } from "../../../App";
import { MyForm, MyFormControl } from "../../../FormikHooks";
import Grilla, { GrillaRef } from "../../../Grilla";
import { MyModal } from "../../../MyModal";
import { cargarDatosGrillaDeArray, isInRole } from "../../../Utilidades";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { DateTime } from "luxon";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../../DialogoConfirmar";
import { Container } from "react-bootstrap";
import { useApi } from "ApiHooks";

export function TextosAviso() {
    let formikRef = useRef<FormikProps<any>>(null);
    let dialogoRef = useRef<DialogoConfirmarRef>(null);
    let { mostrarError, userInfo } = useContext(AppContext);
    let api = useApi();
    let [textos, updateTextos] = useState([]);
    let refGrilla = useRef<GrillaRef>(null);
    function textoReducer(estado: any, accion: any) {
        if (accion.tipo === 'ventanaCrear') {
            return { ...estado, creando: true, modificando: false, textoModificando: null };
        } else if (accion.tipo === 'ventanaModificar') {
            return { ...estado, creando: false, modificando: true, textoModificando: accion.texto };
        } else if (accion.tipo === 'esconderVentana') {
            return { ...estado, creando: false, modificando: false, textoModificando: null };
        }
        return estado;
    }
    let [estadoVentana, updateEstadoVentana] = React.useReducer(textoReducer, { creando: false, modificando: false, textoModificando: null });
    async function cargarTextos() {
        try {
            refGrilla.current?.setBloqueado(true);
            let respuesta = await api.getTextosAviso();
            updateTextos(respuesta);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al cargar textos aviso');
            }
        }
    }
    async function insertTexto(valores: { Texto: string }) {
        let nuevoTexto;
        if (estadoVentana.textoModificando) {
            nuevoTexto = {
                ...estadoVentana.textoModificando,
                Texto: valores.Texto,
                ModificadoPor: userInfo.claims.nameid,
                ModificadoEl: DateTime.local().toISO()
            };
        } else {
            nuevoTexto = {
                Texto: valores.Texto,
                EmpresaId: userInfo.empresaActual,
                NroClienteAlpha: userInfo.nroClienteAlpha,
                CreadoPor: userInfo.claims.nameid,
                CreadoEl: DateTime.local().toISO(),
                ModificadoPor: userInfo.claims.nameid,
                ModificadoEl: DateTime.local().toISO()
            }
        }
        refGrilla.current?.setBloqueado(true);
        try {
            let { exito, error } = await api.insertTextoAviso(nuevoTexto);
            if (exito) {
                cargarTextos();
                updateEstadoVentana({ tipo: 'esconderVentana' });
            } else {
                mostrarError(error);
                refGrilla.current?.setBloqueado(false);
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al insertar texto aviso', error);
                mostrarError('Hubo un error al insertar el texto aviso');
                refGrilla.current?.setBloqueado(false);
            }
        }
    }
    function deleteTexto(id: string) {
        dialogoRef.current!.mostrar().then(async () => {
            refGrilla.current?.setBloqueado(true);
            try {
                let { exito, error } = await api.deleteTextoAviso(id);
                if (exito) {
                    cargarTextos();
                } else {
                    mostrarError(error);
                    refGrilla.current?.setBloqueado(false);
                }
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al eliminar texto aviso', error);
                    mostrarError('Hubo un error al eliminar el texto aviso');
                    refGrilla.current?.setBloqueado(false);
                }
            }
        }).catch(() => { });
    }
    useEffect(() => {
        cargarTextos();
        //eslint-disable-next-line 
    }, []);
    useEffect(() => {
        refGrilla.current?.setBloqueado(false);
        refGrilla.current?.cambiarPagina(1);
    }, [textos]);
    let esOperador = isInRole(userInfo, 'Operador');
    return (<>
        <Container>
            <h2>Textos Aviso</h2>
            <Grilla ref={refGrilla} campos={[{ propiedad: 'Id', titulo: '', visible: false, clave: true },
            { propiedad: 'Texto', titulo: 'Texto' }]} cargarDatos={cargarDatosGrillaDeArray(textos)}
                eventoAgregar={() => updateEstadoVentana({ tipo: 'ventanaCrear' })}
                deshabilitarBotonAgregar={esOperador}
                eventoDetalle={(item: any) => updateEstadoVentana({ tipo: 'ventanaModificar', texto: item })}
                deshabilitarEventoDetalle={esOperador}
                eventoEliminar={(item: any) => deleteTexto(item.Id)}
                deshabilitarBotonEliminar={esOperador} />
        </Container>
        <MyModal show={estadoVentana.creando || estadoVentana.modificando}
            onHide={() => updateEstadoVentana({ tipo: 'esconderVentana' })}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>{estadoVentana.creando ? 'Crear Texto Aviso' : 'Modificar Texto Aviso'}</h2>
                </Modal.Header>
                <Formik innerRef={formikRef} validationSchema={Yup.object({
                    'Texto': Yup.string().nullable().required('Debe ingresar el texto')
                })} initialValues={{
                    'Texto': estadoVentana.textoModificando?.Texto ?? '',
                }} onSubmit={insertTexto}>
                    {({ isSubmitting, submitForm }) => <>
                        <Modal.Body>
                            <MyForm>
                                <Form.Group>
                                    <MyFormControl autoFocus type="text" hideLabel label="Texto" name="Texto"></MyFormControl>
                                </Form.Group>
                            </MyForm>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={() => updateEstadoVentana({ tipo: 'esconderVentana' })}>
                                Cancelar
                            </Button>
                            <Button disabled={isSubmitting} onClick={submitForm}>
                                Ingresar
                            </Button>
                        </Modal.Footer>
                    </>}
                </Formik>
            </Modal.Dialog>
        </MyModal>
        <DialogoConfirmar ref={dialogoRef} mensaje="¿Está seguro que desea eliminar este texto?" textoBotonCancelar="No" textoBotonConfirmar="Sí" />
    </>);
}