import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { MyModal } from "../../MyModal";
import * as Yup from "yup";
import { Field, Formik, FormikProps } from "formik";
import { MyForm, MySelect } from "../../FormikHooks";
import { optionLabelConCodigo } from "../../Utilidades";
import { AppContext } from "../../App";
import { useApi } from "ApiHooks";

export type SeleccionarDespachanteAutorizadoRef = {
    mostrar: () => Promise<{ CUIT: string, Nombre: string }>;
}

export const SeleccionarDespachanteAutorizado = React.forwardRef((props: any, ref: any) => {
    let { mostrarError } = React.useContext(AppContext);
    let api = useApi();
    let [abierto, updateAbierto] = React.useState(false);
    let funcionesPromesa = React.useRef<any>({ resolve: null, reject: null });
    let formikRef = React.useRef<FormikProps<any>>(null);
    let schema = Yup.object({
        'CUIT': Yup.string().nullable().required('Debe seleccionar un despachante'),
        'Nombre': Yup.string()
    });
    React.useEffect(() => {
        if (abierto) {
            formikRef.current?.setFieldValue('CUIT', '');
            formikRef.current?.setFieldValue('Nombre', '');
        } else {
            funcionesPromesa.current.reject?.call(undefined);
        }
    }, [abierto]);
    React.useImperativeHandle(ref, () => ({
        mostrar: () => {
            updateAbierto(true);
            return new Promise<string>((resolve, reject) => {
                funcionesPromesa.current = { resolve: resolve, reject: reject };
            });
        }
    }));
    let cargarDespachantes = React.useCallback(async () => {
        try {
            let respuesta = await api.getDespachantesDisponiblesParaInsertar();
            return respuesta.map((desp: any) => ({ value: desp.CUIT, label: desp.Nombre }));
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar despachantes autorizados', error);
                mostrarError('Error al cargar despachantes autorizados');
            }
            return [];
        }
    }, [mostrarError, api]);
    React.useEffect(() => {
        if (!abierto) {
            api.cancelCurrentTokens();
        }
        //eslint-disable-next-line
    }, [abierto]);
    return (<MyModal show={abierto} onHide={() => updateAbierto(false)}>
        <Modal.Dialog>
            <Modal.Body>
                <Formik validationSchema={schema} initialValues={{ CUIT: '', Nombre: '' }}
                    onSubmit={values => {
                        funcionesPromesa.current.resolve?.call(undefined, values);
                        updateAbierto(false);
                    }} innerRef={formikRef}>
                    <MyForm>
                        <Field name="Nombre" type="hidden"></Field>
                        <Form.Group>
                            <MySelect autoFocus options={cargarDespachantes} name="CUIT" label="Despachante" onOptionChange={(option: any) => {
                                formikRef.current?.setFieldValue('Nombre', option?.label ?? '');
                            }} getOptionLabel={optionLabelConCodigo}></MySelect>
                        </Form.Group>
                    </MyForm>
                </Formik>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => updateAbierto(false)}>
                    Cancelar
                </Button>
                <Button onClick={() => formikRef.current?.submitForm()}>
                    Ingresar
                </Button>
            </Modal.Footer>
        </Modal.Dialog>
    </MyModal>)
});