
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router";
import Grilla, { GrillaRef, TipoCampo } from "../../Grilla";
import { useApi } from "ApiHooks";
import { CancelToken } from "SintiaHooks";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";

export function CaratulasProcesoExcel() {
    let refGrilla = useRef<GrillaRef>(null);
    let history = useHistory();
    let api = useApi();
    let [busquedaActual, updateBusquedaActual] = useState('');
    let [busqueda, updateBusqueda] = useState('');
    let refDialogo = useRef<DialogoConfirmarRef>(null);
    let [mensajeDialogo, updateMensajeDialogo] = useState('');
    
    async function eliminarCaratula(carpeta: string) {
            await api.deleteCaratulaProcesoExcel(carpeta);
            refGrilla.current?.recargar();
    }

    async function cargarDatos(desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await api.busquedaCaratulasProcesoExcelPaginado(busquedaActual, desde, hasta, cancelToken);
        return { cantidadItems: respuesta.CantidadTotal, items: respuesta.Items };
    }
    return (<>
        <h2>Proceso Excel</h2>
        <Form inline onSubmit={e => {
            updateBusquedaActual(busqueda);
            refGrilla.current?.recargar();
            e.preventDefault();
        }}>
            <Form.Label htmlFor="txtInterno" className="mr-2">Interno</Form.Label>
            <Form.Control type="text" id="txtInterno" value={busqueda} className="mr-2 mb-2"
                onChange={e => updateBusqueda(e.target.value)}></Form.Control>
            <Button type="submit" className="mb-2">Buscar</Button>
        </Form>
        <Button onClick={() => history.push('/procesoExcel/cargar')} className="mb-2 mr-2">
            <FontAwesomeIcon icon={faPlus} />
            <span>Cargar Excel</span>
        </Button>
        <Grilla ref={refGrilla} 
                campos={[{ titulo: 'Carpeta', propiedad: 'Carpeta', clave: true }, { titulo: 'Fecha Creación', propiedad: 'FechaCreacion', tipo: TipoCampo.DateTime }]} 
                cargarDatos={cargarDatos}
                eventoEliminar={item => {
                    updateMensajeDialogo(`¿Está seguro que desea eliminar la caratula? ${item.Carpeta}`);
                    refDialogo.current?.mostrar().then(() => eliminarCaratula(item.Carpeta)).catch(() => { });
                }}
                eventoDetalle={item => history.push('/procesoExcel/caratulas/' + encodeURIComponent(item.Carpeta))} />
        <DialogoConfirmar ref={refDialogo} mensaje={mensajeDialogo} textoBotonConfirmar="Sí" textoBotonCancelar="No" />
    </>);
}