import { faFileUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { MutableRefObject } from 'react'
import BlockUi from 'react-block-ui'
import { Button, Col, Modal } from 'react-bootstrap'
import Loader from 'react-loaders'

export interface ModalSubirMultiplesArchivosProps {
    showModalSubirMultiplesArchivos: boolean
    handleCloseModalSubirMultiplesArchivos: () => void
    cargando: boolean
    setCargando: (args: boolean) => void
    archivos: MutableRefObject<File[]>
    TablaGrilla: (props: React.PropsWithChildren<{ className?: string; }>) => React.JSX.Element
    postSubirNuevoArchivo: (base64String: string, multiplesArchivos: boolean, archivo?: File) => Promise<void>
}

const ModalSubirMultiplesArchivos = ({ cargando, handleCloseModalSubirMultiplesArchivos, showModalSubirMultiplesArchivos, archivos, TablaGrilla, postSubirNuevoArchivo, setCargando }: ModalSubirMultiplesArchivosProps) => {

    const subirArchivos = (archivos: File[]) => {
        setCargando(true);
        function fileToBase64(archivo: File): Promise<string> {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = function (event) {
                    const base64String: string | ArrayBuffer | any | undefined | null = event?.target?.result;
                    resolve(base64String?.split(",")?.[1]!);
                };
                reader.onerror = function (error) {
                    reject(error);
                };
                reader.readAsDataURL(archivo);
            });
        }

        let promesas = archivos.map((archivo) =>
            fileToBase64(archivo).then((base64String) => postSubirNuevoArchivo(base64String, true, archivo)
            )
        );

        Promise.all(promesas)
            .then(() => {
                setCargando(false);
                handleCloseModalSubirMultiplesArchivos()
                console.log('EXITO')
            })
            .catch((error) => {
                setCargando(false);
                console.error(error);
            });
    };

    return (
        <Modal animation={false} backdrop="static" show={showModalSubirMultiplesArchivos} onHide={handleCloseModalSubirMultiplesArchivos} dialogClassName="modal-upload"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <BlockUi blocking={cargando} loader={<Loader active={cargando} type='ball-beat' />} message="Subiendo Archivos.." >
                <Modal.Header closeButton={false}>
                    <Modal.Title>Subir Archivos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <Col style={{ display: 'flex', justifyContent: 'center' }}>
                            <h2>¿Desea subir los siguientes archivos?</h2>
                        </Col>
                        <Col style={{
                            maxHeight: "300px",
                            overflowY: "auto"
                        }}>
                            <TablaGrilla >
                                <thead>
                                    <tr>
                                        <th>Nombre Archivo - Extension</th>
                                        <th>Peso</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {archivos?.current?.map((archivo: File) => (
                                        <tr>
                                            <td>{`${archivo?.name}`}</td>
                                            <td>{archivo?.size} bytes </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </TablaGrilla>
                        </Col>

                        <Col style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: 5 }}>
                            <Button variant={'primary'} onClick={() => subirArchivos(archivos?.current)}><FontAwesomeIcon icon={faFileUpload} style={{ fontSize: 20, marginRight: 3 }} />Subir</Button>
                            <Button variant={'danger'} onClick={() => handleCloseModalSubirMultiplesArchivos()}>Cancelar</Button>
                        </Col>
                    </>
                </Modal.Body>
            </BlockUi>
        </Modal>
    )
}

export default ModalSubirMultiplesArchivos