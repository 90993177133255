import React from "react";

export default class MyErrorBoundary extends React.Component {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    state: { hasError: boolean };

    static getDerivedStateFromError(error: any) {    // Actualiza el estado para que el siguiente renderizado muestre la interfaz de repuesto   
        return { hasError: true };
    }
    componentDidCatch(error: any, errorInfo: any) {    // También puedes registrar el error en un servicio de reporte de errores    
        console.error('Error fatal', error, errorInfo);
    }
    render() {
        if (this.state.hasError) {
            return <p className="lead text-danger">Un error critico ha ocurrido. Por favor comuniquese con Alpha 2000</p>
        }
        return this.props.children;
    }
}
