import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../App";
import Grilla, { GrillaRef, TipoCampo } from "../../../Grilla";
import { cargarDatosGrillaDeArray, useDecodedParams } from "../../../Utilidades";
import { MyModal } from "../../../MyModal";
import { Modal, Button } from "react-bootstrap";
import FileSaver from "file-saver";
import Loader from "react-loaders";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { arabToRoman } from "roman-numbers";
import { useApi } from "ApiHooks";

export function ReporteSubitemsArticuloYGravamenes() {
    let { interno } = useDecodedParams() as { interno: string };
    let { mostrarError } = useContext(AppContext);
    let [cargando, updateCargando] = useState(true);
    let api = useApi();
    let refGrilla = useRef<GrillaRef>(null);
    let [reporte, updateReporte] = useState([]);
    let [generandoExcel, updateGenerandoExcel] = useState(false);
    useEffect(() => {
        async function cargar() {
            try {
                refGrilla.current?.setBloqueado(true);
                let reporte = await api.getReporteGravamenesSubitemsCaratula(interno);
                let unidades = await api.getUnidades();
                updateReporte(reporte.sort((a: any, b: any) => a.Orden - b.Orden).map((item: any) => ({
                    ...item,
                    NombreUnidadDeclarada: unidades.find((unidad: any) => unidad.Codigo === item.CodigoUnidadDeclarada)?.Descripcion ?? item.CodigoUnidadDeclarada
                })));
                updateCargando(false);
                refGrilla.current?.setBloqueado(false);
                refGrilla.current?.recargar();
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error(error);
                    mostrarError('Error al cargar reporte subitems detallado');
                }
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);
    async function exportarAExcel() {
        try {
            updateGenerandoExcel(true);
            let { excel, fileName } = await api.exportarReporteGravamenesSubitemsCaratulaAExcel(interno);
            updateGenerandoExcel(false);
            FileSaver.saveAs(excel, fileName);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al exportar reporte gravamenes subitems a Excel');
            }
            if (!api.isUnmounted()) {
                updateGenerandoExcel(false);
            }
        }
    }
    const funcionFormatoBool = (valor: any) => {
        if (valor === true) {
            return 'Sí';
        } else if (valor === false) {
            return 'No';
        } else {
            return '';
        }
    }
    let campos = [{ propiedad: 'Orden', visible: false, clave: true }, { titulo: 'Catalogo', propiedad: 'Catalogo' },
    { titulo: 'Articulo', propiedad: 'Articulo' }, { titulo: 'Descripción Catalogo', propiedad: 'Descripcion' },
    { titulo: 'Nomenclador', propiedad: 'Ncm' }, { titulo: 'Sufijos', propiedad: 'Sufijos' },
    { titulo: 'Unidad Declarada', propiedad: 'NombreUnidadDeclarada' },
    { titulo: 'Cant. Declarada', propiedad: 'CantidadDeclarada', tipo: TipoCampo.Number },
    { titulo: 'Unitario', propiedad: 'PrecioUnitario', tipo: TipoCampo.Number, decimales: 5 },
    { titulo: 'FOB', propiedad: 'TotalFob', tipo: TipoCampo.Number }, { titulo: 'Kg Neto', propiedad: 'KgNeto' },
    { titulo: '% Der. Impo Intrazona', propiedad: 'DerImpoIntrazona', tipo: TipoCampo.Number },
    { titulo: '% Der. Impo Extrazona', propiedad: 'DerImpoExtrazona', tipo: TipoCampo.Number },
    { titulo: '% Der. Expo', propiedad: 'DerechoExportacion', tipo: TipoCampo.Number },
    { titulo: '% Tasa Estadistica', propiedad: 'TasaEstadistica', tipo: TipoCampo.Number },
    { titulo: '% Iva', propiedad: 'Iva', tipo: TipoCampo.Number }, { titulo: '% Iva Adic (Estimado)', propiedad: 'IvaAdicional', tipo: TipoCampo.Number },
    { titulo: '% Reintegro Extrazona', propiedad: 'ReintegroExtrazona', tipo: TipoCampo.Number },
    { titulo: '% Reintegro Intrazona', propiedad: 'ReintegroBrasil', tipo: TipoCampo.Number },
    { titulo: 'Tiene LNA', propiedad: 'TieneLNA', plantillaFormato: funcionFormatoBool },
    { titulo: 'Anexo LNA', propiedad: 'AnexoLNA', plantillaFormato: arabToRoman },
    { titulo: 'Observaciones', propiedad: 'Observaciones' }, { titulo: 'Otros', propiedad: 'Otros' },
    { titulo: 'Tiene DJCP', propiedad: 'TieneDJCP', plantilaFormato: funcionFormatoBool },
    { titulo: 'Tiene Seguridad Electrica', propiedad: 'SeguridadElectrica', plantillaFormato: funcionFormatoBool }];
    return (<>
        <h2>Reporte Articulos Y Gravamenes de <Link to={'/caratulas/' + encodeURIComponent(interno)}>{interno}</Link></h2>
        <Button variant="success" className="mb-2" onClick={exportarAExcel} disabled={cargando}>
            <FontAwesomeIcon icon={faFileExcel} />
            <span>Exportar a Excel</span>
        </Button>
        <Grilla ref={refGrilla} campos={campos} responsiva cargarDatos={cargarDatosGrillaDeArray(reporte)}></Grilla>
        <MyModal show={generandoExcel}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead">Generando Excel...</p>
                    <div className="loader-container">
                        <Loader type="ball-spin-fade-loader" active></Loader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        api.cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
    </>);
}