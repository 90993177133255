import React from 'react';
import * as Yup from 'yup';
import { AppContext } from 'App';
import { useHistory } from 'react-router';
import { Formik, FormikProps } from 'formik';
import { MyForm, MyFormControl } from '../../FormikHooks';
import { Alert, Button, Form } from 'react-bootstrap';
import { DateTime } from 'luxon';
import { isInRole, useDecodedParams } from 'Utilidades';
import { useApi, TipoLock } from 'ApiHooks';

export function CrearEditarCatalogo() {
    let formikRef = React.useRef<FormikProps<any>>(null);
    let { codigo } = useDecodedParams();
    let { userInfo, mostrarError } = React.useContext(AppContext);
    let [cargando, updateCargando] = React.useState(true);
    let [catalogo, updateCatalogo] = React.useState<any>({});
    let [mensajeError, updateMensajeError] = React.useState('');
    let schema = Yup.object({
        'Codigo': Yup.string().nullable().required('Debe ingresar el codigo')
            .test('sin-guiones', 'El codigo de un catalogo no puede tener guiones',
                (valor: string | null | undefined) => !valor?.includes('-')),
        'Descripcion': Yup.string().nullable(),
        'SufijoAA': Yup.string().nullable()
    });
    let history = useHistory();
    let api = useApi();
    React.useEffect(() => {
        async function cargar() {
            try {
                if (codigo) {
                    let respuesta = await api.getCatalogo(codigo);
                    updateCatalogo(respuesta);
                    updateCargando(false);
                    formikRef?.current?.resetForm({
                        values: {
                            'Codigo': respuesta.Codigo,
                            'Descripcion': respuesta.Descripcion,
                            'SufijoAA': respuesta.SufijoAA
                        }
                    });
                } else {
                    formikRef?.current?.resetForm();
                    updateCargando(false);
                }
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar catalogo', error);
                    mostrarError('Error al cargar catalogo');
                }
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);
    async function submit(values: { 'Codigo': string, 'Descripcion': string, 'SufijoAA': string }) {
        let nuevoCatalogo: any;
        if (codigo) {
            nuevoCatalogo = {
                'Codigo': codigo,
                'Descripcion': values.Descripcion,
                'SufijoAA': values.SufijoAA,
                'NroClienteAlpha': userInfo.claims.NroClienteAlpha,
                'CreadoPor': catalogo.CreadoPor,
                'CreadoEl': catalogo.CreadoEl,
                'ModificadoPor': userInfo.claims.nameid,
                'ModificadoEl': DateTime.local().toISO()
            };
        } else {
            nuevoCatalogo = {
                ...values,
                'NroClienteAlpha': userInfo.claims.NroClienteAlpha,
                'CreadoPor': userInfo.claims.nameid,
                'CreadoEl': DateTime.local().toISO(),
                'ModificadoPor': userInfo.claims.nameid,
                'ModificadoEl': DateTime.local().toISO()
            };
        }
        try {
            let tieneLock = await api.obtenerLock(TipoLock.Catalogo, nuevoCatalogo.Codigo);
            if (tieneLock) {
                let { exito, error } = await api.insertCatalogo(nuevoCatalogo);
                if (exito) {
                    await api.eliminarLock(TipoLock.Catalogo, nuevoCatalogo.Codigo);
                    history.replace('/catalogos');
                } else {
                    mostrarError(error);
                }
            } else {
                updateMensajeError('No se pudo modificar el catalogo porque otro usuario lo está usando');
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al insertar catalogo', error);
                updateMensajeError('Hubo un error al insertar el catalogo');
            }
        }
    }
    let esOperador = isInRole(userInfo, 'Operador');
    return (<>
        <h2>
            {codigo ? 'Modificar Catalogo' : 'Crear Catalogo'}
        </h2>
        {mensajeError && (<Alert variant="danger">{mensajeError}</Alert>)}
        <Formik initialValues={{ Codigo: '', Descripcion: '', SufijoAA: '' }}
            innerRef={formikRef} validationSchema={schema} onSubmit={submit}>
            <MyForm blocking={cargando} submitEnUltimoElemento>
                <Form.Group controlId="Codigo">
                    <MyFormControl disabled={esOperador} autoFocus type="text" label="Nombre/Codigo" name="Codigo" readOnly={codigo !== undefined}></MyFormControl>
                </Form.Group>
                <Form.Group>
                    <MyFormControl disabled={esOperador} type="text" label="Descripcion" name="Descripcion"></MyFormControl>
                </Form.Group>
                <Form.Group>
                    <MyFormControl disabled={esOperador} type="text" label="Sufijo AA" name="SufijoAA"></MyFormControl>
                </Form.Group>
                <Button variant="danger" className="mb-2" onClick={() => history.goBack()}>Cancelar</Button>
                <Button type="submit" disabled={esOperador} className="ml-2 mb-2">Guardar</Button>
            </MyForm>
        </Formik>
    </>);
}