import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { MyModal } from "../../MyModal";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import { MyForm, MyFormControl } from "../../FormikHooks";

export type DialogoModificarDespachanteRef = {
    mostrar: (desp: { CUIT: string, Nombre: string }) => Promise<string>;
}

export const DialogoModificarDespachante = React.forwardRef((props: any, ref: any) => {
    let [abierto, updateAbierto] = React.useState(false);
    let [datosInicial, updateDatosInicial] = React.useState({ CUIT: '', Nombre: '' });
    let funcionesPromesa = React.useRef<any>({ resolve: null, reject: null });
    let formikRef = React.useRef<FormikProps<any>>(null);
    let schema = Yup.object({
        'CUIT': Yup.string().nullable(),
        'Nombre': Yup.string().nullable().required('Debe ingresar el nombre del despachante'),
    });
    React.useEffect(() => {
        if (!abierto) {
            funcionesPromesa.current.reject?.call(undefined);
        }
    }, [abierto]);
    React.useEffect(() => {
        if (abierto) {
            formikRef.current?.setFieldValue('CUIT', datosInicial.CUIT);
            formikRef.current?.setFieldValue('Nombre', datosInicial.Nombre);
        }
    }, [abierto, datosInicial]);
    React.useImperativeHandle(ref, () => ({
        mostrar: (desp: { CUIT: string, Nombre: string }) => {
            updateAbierto(true);
            updateDatosInicial({ CUIT: desp.CUIT, Nombre: desp.Nombre });
            return new Promise<string>((resolve, reject) => {
                funcionesPromesa.current = { resolve: resolve, reject: reject };
            });
        }
    }));
    return (<MyModal show={abierto} onHide={() => updateAbierto(false)}>
        <Modal.Dialog>
            <Modal.Body>
                <Formik validationSchema={schema} initialValues={{ CUIT: '', Nombre: '' }}
                    onSubmit={values => {
                        funcionesPromesa.current?.resolve(values.Nombre);
                        updateAbierto(false);
                    }} innerRef={formikRef}>
                    <MyForm blockWhenSubmitting={false}>
                        <Form.Group>
                            <MyFormControl type="text" label="CUIT" readOnly plaintext name="CUIT"></MyFormControl>
                        </Form.Group>
                        <Form.Group>
                            <MyFormControl autoFocus type="text" label="Nombre" name="Nombre"></MyFormControl>
                        </Form.Group>
                    </MyForm>
                </Formik>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => updateAbierto(false)}>
                    Cancelar
                    </Button>
                <Button onClick={() => formikRef.current?.submitForm()}>
                    Ingresar
                    </Button>
            </Modal.Footer>
        </Modal.Dialog>
    </MyModal>)
});