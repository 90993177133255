import React from "react";
import { useHistory } from "react-router";
import { AppContext } from "../../../App";
import Grilla, { GrillaRef } from "../../../Grilla";
import { cargarDatosGrillaDeArray, isInRole } from "../../../Utilidades";
import { Button, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../../DialogoConfirmar";
import { useApi } from "ApiHooks";

export function Avisos() {
    let { mostrarError, userInfo } = React.useContext(AppContext);
    let history = useHistory();
    let api = useApi();
    let refGrilla = React.useRef<GrillaRef>(null);
    let refDialogo = React.useRef<DialogoConfirmarRef>(null);
    let [avisos, updateAvisos] = React.useState([]);
    let campos = [{ propiedad: 'Id', titulo: '', visible: false, clave: true }, { propiedad: 'CatalogoId', titulo: 'Catalogo' },
    { propiedad: 'CodigoArticulo', titulo: 'Articulo' }, { propiedad: 'CodigoSubregimen', titulo: 'Subregimen' },
    { propiedad: 'Ncm', titulo: 'Ncm' }, { propiedad: 'CodigoPaisOrigen', titulo: 'Pais Origen' },
    { propiedad: 'NombreTipo', titulo: 'Tipo' }, { propiedad: 'Texto', titulo: 'Texto' }];
    const cargarAvisos = React.useCallback(async () => {
        try {
            refGrilla.current?.setBloqueado(true);
            let respuesta = await api.getAvisos();
            updateAvisos(respuesta);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al cargar avisos');
            }
        }
    }, [mostrarError, api]);
    React.useEffect(() => {
        cargarAvisos();
        //eslint-disable-next-line
    }, []);
    React.useEffect(() => {
        refGrilla.current?.setBloqueado(false);
        refGrilla.current?.cambiarPagina(1);
    }, [avisos]);
    function deleteAviso(id: string) {
        refDialogo.current!.mostrar().then(async () => {
            refGrilla.current?.setBloqueado(true);
            try {
                let { exito, error } = await api.deleteAviso(id);
                if (exito) {
                    cargarAvisos();
                } else {
                    mostrarError(error);
                    refGrilla.current?.setBloqueado(false);
                }
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al eliminar aviso', error);
                    mostrarError('Hubo un error al eliminar el aviso');
                    refGrilla.current?.setBloqueado(false);
                }
            }
        }).catch(() => { });
    }
    let esOperador = isInRole(userInfo, 'Operador');
    return (<>
        <Container>
            <h2>Avisos</h2>
            <Button className="mb-2 mr-2" onClick={() => history.push('/avisos/add')} disabled={esOperador}>
                <FontAwesomeIcon icon={faPlus} />
                <span>Agregar</span>
            </Button>
            <Button className="mb-2 mr-2" variant="secondary" onClick={() => history.push('/avisos/textos')}>
                Textos Aviso
            </Button>
            <Button className="mb-2 mr-2" variant="secondary" onClick={() => history.push('/avisos/tipos')}>
                Tipos Aviso
            </Button>
            <Grilla campos={campos} cargarDatos={cargarDatosGrillaDeArray(avisos)} ref={refGrilla}
                eventoDetalle={(item: any) => history.push('/avisos/edit/' + encodeURIComponent(item.Id))}
                eventoEliminar={(item: any) => deleteAviso(item.Id)}
                deshabilitarBotonEliminar={esOperador} />
        </Container>
        <DialogoConfirmar ref={refDialogo} textoBotonConfirmar="Sí" textoBotonCancelar="No" mensaje="¿Está seguro que desea eliminar este aviso?" />
    </>);
}