import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal, Form, Button, Container } from "react-bootstrap";
import { AppContext } from "App";
import { MyForm, MyFormControl } from "../../FormikHooks";
import Grilla, { GrillaRef } from "../../Grilla";
import { MyModal } from "../../MyModal";
import { cargarDatosGrillaDeArray, isInRole } from "../../Utilidades";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import { useApi } from "ApiHooks";


export function Intervenciones() {
    let formikRef = React.useRef<FormikProps<any>>(null);
    let { mostrarError, userInfo } = useContext(AppContext);
    let api = useApi();
    let [intervenciones, updateIntervenciones] = useState([]);
    let refGrilla = React.useRef<GrillaRef>(null);
    let refDialogo = useRef<DialogoConfirmarRef>(null);
    function textoReducer(estado: any, accion: any) {
        if (accion.tipo === 'ventanaCrear') {
            return { ...estado, creando: true, modificando: false, intModificando: null };
        } else if (accion.tipo === 'ventanaModificar') {
            return { ...estado, creando: false, modificando: true, intModificando: accion.int };
        } else if (accion.tipo === 'esconderVentana') {
            return { ...estado, creando: false, modificando: false, intModificando: null };
        }
        return estado;
    }
    let [estadoVentana, updateEstadoVentana] = React.useReducer(textoReducer, { creando: false, modificando: false, intModificando: null });
    const cargarIntervenciones = React.useCallback(async () => {
        try {
            refGrilla.current?.setBloqueado(true);
            let respuesta = await api.getIntervenciones();
            updateIntervenciones(respuesta);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al cargar intervenciones');
            }
        }
    }, [mostrarError, api]);
    async function insertIntervencion(valores: { Descripcion: string }) {
        let nuevaIntervencion;
        if (estadoVentana.intModificando) {
            nuevaIntervencion = {
                ...estadoVentana.intModificando,
                Descripcion: valores.Descripcion,
            };
        } else {
            nuevaIntervencion = {
                Descripcion: valores.Descripcion,
            }
        }
        refGrilla.current?.setBloqueado(true);
        try {
            let { exito, error } = await api.insertIntervencion(nuevaIntervencion);
            if (exito) {
                updateEstadoVentana({ tipo: 'esconderVentana' });
                cargarIntervenciones();
            } else {
                mostrarError(error);
                refGrilla.current?.setBloqueado(false);
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al insertar intervencion', error);
                mostrarError('Hubo un error al insertar la intervencion');
                refGrilla.current?.setBloqueado(false);
            }
        }
    }
    function deleteIntervencion(id: string) {
        refDialogo.current!.mostrar().then(async () => {
            refGrilla.current?.setBloqueado(true);
            try {
                let { exito, error } = await api.deleteIntervencion(id);
                if (exito) {
                    cargarIntervenciones();
                } else {
                    mostrarError(error);
                }
                refGrilla.current?.setBloqueado(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al eliminar intervencion', error);
                    mostrarError('Hubo un error al eliminar la intervencion');
                    refGrilla.current?.setBloqueado(false);
                }
            }
        }).catch(() => { });
    }
    useEffect(() => {
        cargarIntervenciones();
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        refGrilla.current?.setBloqueado(false);
        refGrilla.current?.cambiarPagina(1);
    }, [intervenciones]);
    let esOperador = isInRole(userInfo, 'Operador');
    return (<>
        <Container>
            <h2>Intervenciones</h2>
            <Grilla ref={refGrilla} campos={[{ propiedad: 'Id', titulo: '', visible: false, clave: true },
            { propiedad: 'Descripcion', titulo: 'Descripcion' }]} cargarDatos={cargarDatosGrillaDeArray(intervenciones)}
                eventoAgregar={() => updateEstadoVentana({ tipo: 'ventanaCrear' })}
                deshabilitarBotonAgregar={esOperador}
                eventoDetalle={(item: any) => updateEstadoVentana({ tipo: 'ventanaModificar', int: item })}
                deshabilitarEventoDetalle={esOperador}
                eventoEliminar={(item: any) => deleteIntervencion(item.Id)}
                deshabilitarBotonEliminar={esOperador} />
        </Container>
        <MyModal show={estadoVentana.creando || estadoVentana.modificando}
            onHide={() => updateEstadoVentana({ tipo: 'esconderVentana' })}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>{estadoVentana.creando ? 'Crear Intervencion' : 'Modificar Intervencion'}</h2>
                </Modal.Header>
                <Formik innerRef={formikRef} validationSchema={Yup.object({
                    'Descripcion': Yup.string().nullable().required('Debe ingresar la intervencion')
                })} initialValues={{
                    'Descripcion': estadoVentana.intModificando?.Descripcion ?? '',
                }} onSubmit={insertIntervencion}>
                    {({ isSubmitting, submitForm }) =>
                        <>
                            <Modal.Body>
                                <MyForm>
                                    <Form.Group>
                                        <MyFormControl autoFocus type="text" hideLabel label="Descripcion" name="Descripcion"></MyFormControl>
                                    </Form.Group>
                                </MyForm>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="danger" onClick={() => updateEstadoVentana({ tipo: 'esconderVentana' })}>
                                    Cancelar
                                </Button>
                                <Button disabled={isSubmitting} onClick={submitForm}>
                                    Ingresar
                                </Button>
                            </Modal.Footer>
                        </>}
                </Formik>

            </Modal.Dialog>
        </MyModal>
        <DialogoConfirmar ref={refDialogo} mensaje="¿Está seguro que desea eliminar esta intervención?" textoBotonCancelar="No" textoBotonConfirmar="Sí" />
    </>);
}