import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router";
import { AppContext } from "App";
import Grilla, { GrillaRef } from "../../Grilla";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import { isInRole } from 'Utilidades';
import { useApi, TipoLock } from "ApiHooks";
import { CancelToken } from "SintiaHooks";

export function CatalogosSimple(props: { eventoSeleccionarCatalogo: (item: any) => void }) {
    let refGrilla = useRef<GrillaRef>(null);
    let refDialogo = useRef<DialogoConfirmarRef>(null);
    let history = useHistory();
    let { mostrarError, userInfo } = useContext(AppContext);
    let campos = [{ titulo: 'Nombre/Codigo', propiedad: 'Codigo', clave: true },
    { titulo: "Descripcion", propiedad: 'Descripcion' }, { titulo: 'Sufijo AA', propiedad: 'SufijoAA' }];
    let api = useApi();
    let [busquedaActual, updateBusquedaActual] = useState('');
    let [busqueda, updateBusqueda] = useState('');
    let [mensajeEliminarCatalogo, updateMensajeEliminarCatalogo] = useState('');
    async function cargarDatos(desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await api.getCatalogosPaginado(busquedaActual, desde, hasta, cancelToken);
        return { cantidadItems: respuesta.CantidadTotal, items: respuesta.Items };
    }
    function eventoEliminar(item: any) {
        updateMensajeEliminarCatalogo(`¿Está seguro que desea eliminar el catalogo ${item.Codigo}?`);
        return refDialogo.current!.mostrar().then(async () => {
            refGrilla.current?.setBloqueado(true);
            try {
                let puedeEliminar = await api.obtenerLock(TipoLock.Catalogo, item.Codigo);
                if (puedeEliminar) {
                    let { exito, error } = await api.deleteCatalogoVacio(item.Codigo);
                    if (exito) {
                        refGrilla.current?.recargar();
                    } else {
                        mostrarError(error as string);
                    }
                } else {
                    mostrarError(`No se puede eliminar el catalogo ${item.Codigo} porque otra persona lo está utilizando`);
                }
                refGrilla.current?.setBloqueado(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al eliminar catalogo', error);
                    mostrarError('Hubo un error al eliminar el catalogo');
                    refGrilla.current?.setBloqueado(false);
                }
            }
        }).catch(() => { });
    }
    let esOperador = isInRole(userInfo, 'Operador');
    return (<>
        <h2>Catalogos</h2>
        <Form inline onSubmit={e => {
            updateBusquedaActual(busqueda);
            refGrilla.current?.recargar();
            e.preventDefault();
        }}>
            <Form.Control type="text" id="txtCodigoCatalogo" value={busqueda} className="mr-2 mb-2"
                onChange={e => updateBusqueda(e.target.value)}></Form.Control>
            <Button type="submit" className="mb-2">Buscar</Button>
        </Form>
        <Button disabled={esOperador} onClick={() => history.push('/catalogos/add')} className="mb-2 mr-2">
            <FontAwesomeIcon icon={faPlus} />
            <span>Agregar</span>
        </Button>
        <Grilla campos={campos} cargarDatos={cargarDatos}
            deshabilitarBotonEliminar={esOperador}
            eventoModificar={item => history.push('/catalogos/edit/' + encodeURIComponent(item.Codigo))}
            eventoEliminar={eventoEliminar} ref={refGrilla}
            eventoDetalle={props.eventoSeleccionarCatalogo} />
        <DialogoConfirmar ref={refDialogo} mensaje={mensajeEliminarCatalogo} textoBotonConfirmar="Sí" textoBotonCancelar="No" />
    </>)
}