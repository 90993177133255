import React from "react";
import { CamposCargaMasiva, ImportExcel } from "./ImportExcel";
import { IngresoMapeos, IngresoMapeosRef } from "./IngresoMapeos";
import { VistaPrevia } from "./VistaPrevia";
import { AppContext } from "../../../App";
import { useHistory } from "react-router";
import { MyModal } from "../../../MyModal";
import { Button, Form, Modal, ProgressBar } from "react-bootstrap";
import { DateTime, Duration } from "luxon";
import { useApi, TipoLock } from "ApiHooks";

enum EstadoCargaMasiva {
    IngresandoExcel,
    IngresandoMapeos,
    VistaPrevia,
    Insertando,
    MostrarModalEstadosInexistentes
}

export function CargaMasivaCatalogo(props: any) {
    let { userInfo, mostrarError } = React.useContext(AppContext);
    let ingresoMapeosRef = React.useRef<IngresoMapeosRef>(null);
    let api = useApi();
    let history = useHistory();
    let [estado, updateEstado] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'excelImportado') {
            return {
                estadoCargaMasiva: EstadoCargaMasiva.IngresandoMapeos, catalogo: accion.catalogo,
                articulos: accion.articulos, valoresPaises: accion.valoresPaises,
                valoresUnidades: accion.valoresUnidades, valoresEstadoMerc: accion.valoresEstadoMerc,
                campos: accion.campos, validarNcm: accion.validarNcm
            }
        } else if (accion.tipo === 'mapeosIngresados') {
            let nuevoEstado = estado.articulos.length > 1000 ? EstadoCargaMasiva.Insertando : EstadoCargaMasiva.VistaPrevia;
            return {
                estadoCargaMasiva: nuevoEstado, catalogo: estado.catalogo,
                validarNcm: estado.validarNcm,
                articulos: estado.articulos, mapeosPais: accion.mapeosPais,
                mapeosUnidad: accion.mapeosUnidad, mapeosEstadoMerc: accion.mapeosEstadoMerc,
                articulosInsertados: 0, interval: accion.interval, tiempo: Duration.fromMillis(0),
                campos: estado.campos, estadosArticuloInexistentes: new Set()
            };
        } else if (accion.tipo === 'volverAIngresoExcel') {
            return { estadoCargaMasiva: EstadoCargaMasiva.IngresandoExcel, articulos: null };
        } else if (accion.tipo === 'insertarArticulos') {
            return {
                ...estado, estadoCargaMasiva: EstadoCargaMasiva.Insertando, articulosInsertados: 0,
                interval: accion.interval, tiempo: Duration.fromMillis(0), estadosArticuloInexistentes: new Set()
            };
        } else if (accion.tipo === 'actualizarProgreso') {
            let nuevosEstadosArticuloInexistentes = new Set(estado.estadosArticuloInexistentes);
            for (const item of accion.estadosInexistentes) {
                nuevosEstadosArticuloInexistentes.add(item);
            }
            return {
                ...estado, articulosInsertados: accion.articulosInsertados,
                estadosArticuloInexistentes: nuevosEstadosArticuloInexistentes
            };
        } else if (accion.tipo === 'cancelarInsertar') {
            if (estado.articulos.length > 1000) {
                return { estadoCargaMasiva: EstadoCargaMasiva.IngresandoExcel, articulos: null };
            } else {
                return {
                    estadoCargaMasiva: EstadoCargaMasiva.VistaPrevia, catalogo: estado.catalogo,
                    validarNcm: estado.validarNcm,
                    articulos: estado.articulos, mapeosPais: accion.mapeosPais,
                    mapeosUnidad: accion.mapeosUnidad, mapeosEstadoMerc: accion.mapeosEstadoMerc,
                    campos: estado.campos
                };
            }
        } else if (accion.tipo === 'actualizarTiempo') {
            return { ...estado, tiempo: estado.tiempo?.plus(accion.millis) ?? Duration.fromMillis(accion.millis) }
        } else if (accion.tipo === 'mostrarModalEstadosInexistentes') {
            return { ...estado, estadoCargaMasiva: EstadoCargaMasiva.MostrarModalEstadosInexistentes };
        }
        return { ...estado };
    }, { estadoCargaMasiva: EstadoCargaMasiva.IngresandoExcel, articulos: null });
    function crearInterval(millis: number) {
        return setInterval(() => updateEstado({ tipo: 'actualizarTiempo', millis: millis }), millis);
    }
    React.useEffect(() => {
        if (estado.estadoCargaMasiva === EstadoCargaMasiva.IngresandoMapeos) {
            ingresoMapeosRef.current?.mostrar(estado.valoresPaises, estado.valoresUnidades, estado.valoresEstadoMerc)
                .then(resultado => updateEstado({
                    tipo: 'mapeosIngresados', mapeosPais: resultado.mapeosPais,
                    mapeosUnidad: resultado.mapeosUnidad, mapeosEstadoMerc: resultado.mapeosEstadoMerc,
                    interval: crearInterval(1000)
                })).catch(() => updateEstado({ tipo: 'volverAIngresoExcel' }))
        }
        //eslint-disable-next-line 
    }, [estado.estadoCargaMasiva]);
    const redirigir = () => history.replace('/catalogos/articulos/' + encodeURIComponent(estado.catalogo));
    React.useEffect(() => {
        async function insertarArticulos() {
            try {
                if (estado.articulosInsertados === 0) {
                    let tieneLock = await api.obtenerLock(TipoLock.Catalogo, estado.catalogo);
                    if (!tieneLock) {
                        mostrarError(`No se pudo insertar los articulos al catalogo ${estado.catalogo} porque otra persona lo está usando`);
                        try {
                            clearInterval(estado.interval);
                        } catch (error2) { }
                        updateEstado({ tipo: 'cancelarInsertar' });
                    }
                }
                let tamañoPartes = Math.max(Math.floor(estado.articulos.length / 10), 5000);
                let parte = estado.articulos.filter((valor: any, indice: number) => indice >= estado.articulosInsertados && indice < estado.articulosInsertados + tamañoPartes);
                if (parte.length > 0) {
                    let respuesta = await api.insertArticulosCargaMasiva({
                        Articulos: parte,
                        ValidarNcm: estado.validarNcm,
                        CamposSeleccionados: estado.campos.filter((c: any) => c !== null && c !== undefined)
                    });
                    updateEstado({
                        tipo: 'actualizarProgreso', articulosInsertados: estado.articulosInsertados + parte.length,
                        estadosInexistentes: respuesta.EstadosArticuloInexistentes ?? []
                    });
                } else {
                    //terminado, ejecutar insert mapeos siempre para realizar mapeos pendientes
                    await api.insertMapeos({
                        MapeosPais: estado.mapeosPais.map((mapeo: any) => ({
                            NroClienteAlpha: userInfo.nroClienteAlpha,
                            Valor: mapeo.valor,
                            CodigoPais: mapeo.codigo,
                            CreadoPor: userInfo.claims.nameid,
                            CreadoEl: DateTime.local().toISO(),
                            ModificadoPor: userInfo.claims.nameid,
                            ModificadoEl: DateTime.local().toISO()
                        })),
                        MapeosUnidad: estado.mapeosUnidad.map((mapeo: any) => ({
                            NroClienteAlpha: userInfo.nroClienteAlpha,
                            Valor: mapeo.valor,
                            CodigoUnidad: mapeo.codigo,
                            CreadoPor: userInfo.claims.nameid,
                            CreadoEl: DateTime.local().toISO(),
                            ModificadoPor: userInfo.claims.nameid,
                            ModificadoEl: DateTime.local().toISO()
                        })),
                        MapeosEstadoMercaderia: estado.mapeosEstadoMerc.map((mapeo: any) => ({
                            NroClienteAlpha: userInfo.nroClienteAlpha,
                            Valor: mapeo.valor,
                            CodigoEstadoMercaderia: mapeo.codigo,
                            CreadoPor: userInfo.claims.nameid,
                            CreadoEl: DateTime.local().toISO(),
                            ModificadoPor: userInfo.claims.nameid,
                            ModificadoEl: DateTime.local().toISO()
                        }))
                    });
                    await api.eliminarLock(TipoLock.Catalogo, estado.catalogo);
                    if (estado.estadosArticuloInexistentes.size > 0) {
                        updateEstado({ tipo: 'mostrarModalEstadosInexistentes' });
                    } else {
                        redirigir();
                    }
                }
            } catch (error) {
                try {
                    clearInterval(estado.interval);
                } catch (error2) { }
                if (!api.isCancel(error)) {
                    console.error('Error al insertar articulos carga masiva', error);
                    mostrarError('Error al insertar articulos');
                }
                if (!api.isUnmounted()) {
                    updateEstado({ tipo: 'cancelarInsertar' });
                }
            }
        }
        if (estado.estadoCargaMasiva === EstadoCargaMasiva.Insertando) {
            insertarArticulos();
        }
        //eslint-disable-next-line 
    }, [estado.estadoCargaMasiva, estado.articulosInsertados]);
    function onImport(campos: Array<CamposCargaMasiva>, catalogo: string, validarNcm: boolean,
        articulos: any[], valoresPaises: string[], valoresUnidades: string[], valoresEstadoMerc: string[]) {
        updateEstado({
            tipo: 'excelImportado', catalogo: catalogo, articulos: articulos, validarNcm: validarNcm,
            valoresPaises: valoresPaises, valoresUnidades: valoresUnidades, valoresEstadoMerc: valoresEstadoMerc, campos: campos
        });
    }
    let render = () => {
        switch (estado.estadoCargaMasiva) {
            case EstadoCargaMasiva.IngresandoExcel:
                return (<ImportExcel onImport={onImport}></ImportExcel>);
            case EstadoCargaMasiva.VistaPrevia:
                return (<VistaPrevia articulos={estado.articulos} onContinue={() => updateEstado({ tipo: 'insertarArticulos', interval: crearInterval(1000) })}
                    onCancel={() => updateEstado({ tipo: 'volverAIngresoExcel' })}></VistaPrevia>);
            default:
                return (<></>);
        }
    }
    return (<>
        <h2>Carga Masiva al Catalogo</h2>
        <IngresoMapeos ref={ingresoMapeosRef}></IngresoMapeos>
        <MyModal show={estado.estadoCargaMasiva === EstadoCargaMasiva.Insertando}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead">Insertando articulos...</p>
                    {estado.articulos && (<>
                        <ProgressBar animated max={estado.articulos.length} now={estado.articulosInsertados}>
                        </ProgressBar>
                        <p>{`${estado.articulosInsertados} articulos insertados de ${estado.articulos.length}`}</p>
                        <p>{estado.tiempo?.toFormat('hh:mm:ss')}</p>
                    </>)}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        api.cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estado.estadoCargaMasiva === EstadoCargaMasiva.MostrarModalEstadosInexistentes}>
            <Modal.Dialog>
                <Modal.Header>
                    Estados Inexistentes
                </Modal.Header>
                <Modal.Body>
                    <p className="lead text-warning">Se han cargado los articulos exitosamente, pero los siguientes estados no se cargaron porque no existen</p>
                    <Form>
                        <Form.Control as="textarea">
                            {estado.estadosArticuloInexistentes && Array.from(estado.estadosArticuloInexistentes).join('\r\n')}
                        </Form.Control>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={redirigir}>Continuar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        {render()}
    </>);
}