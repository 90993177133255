import { faFileUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Dispatch, RefObject, SetStateAction } from 'react'
import { Alert, Button, Col, Modal, ProgressBar } from 'react-bootstrap'

interface ModalSubirArchivosProps {
    showModalSubirArchivos: boolean
    handleCloseModalSubirArchivo: () => void
    cargando: boolean
    uploadedFile: File | undefined
    inputRef: RefObject<HTMLInputElement>
    handleDisplayFileDetails: () => void
    formObservacion: () => void
    setReemplazarArchivo: Dispatch<SetStateAction<boolean>>
    reemplazarArchivo: boolean
    progress: number
    habilitarBotonCancelarCarga: boolean
    cancelarCarga: () => void
    subirArchivo: (archivo: File) => void
    setUploadedFile: Dispatch<SetStateAction<File | undefined | unknown>>
    habilitarErrorArchivo: string | null
    habilitarMensajeRespuestaError: string | null
}

const ModalSubirArchivos = ({ showModalSubirArchivos, handleCloseModalSubirArchivo, cargando, uploadedFile, inputRef, handleDisplayFileDetails, formObservacion, setReemplazarArchivo, setUploadedFile, reemplazarArchivo, progress, habilitarBotonCancelarCarga, cancelarCarga, subirArchivo, habilitarErrorArchivo, habilitarMensajeRespuestaError }: ModalSubirArchivosProps) => {

    const handleDrop = (event: { preventDefault: () => void; dataTransfer: { files: Iterable<unknown> | ArrayLike<unknown> } }) => {
        event.preventDefault();
        const droppedFiles = Array.from(event.dataTransfer.files);
        inputRef.current?.files &&
            setUploadedFile(droppedFiles?.[0])
    };

    return (
        <Modal show={showModalSubirArchivos} onHide={handleCloseModalSubirArchivo} dialogClassName="modal-upload"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={cargando ? "static" : true}
        >
            <Modal.Header closeButton={cargando ? false : true}>
                <Modal.Title>Cargar Archivo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Col style={{ display: 'flex', justifyContent: 'center' }} onDragOver={(e: any) => e.preventDefault()}
                    onDrop={(e: any) => handleDrop(e)}>
                    <label className="custum-file-upload" htmlFor="file">
                        <div className="icon"
                        >
                            <img
                                src="/upload.svg"
                                alt="Upload Icon"
                                style={{ marginTop: 5 }}
                            />
                        </div>
                        <div className="text" >
                            <h5 style={{ textAlign: 'center' }}>{uploadedFile?.name ? uploadedFile?.name : 'Click aquí o arrastre un archivo'}</h5>
                        </div>
                        <input type="file" id="file" ref={inputRef}
                            onChange={handleDisplayFileDetails} />
                    </label>
                </Col>
                <div>
                    {formObservacion()}
                </div>
                <div className="checkbox">
                    <p className='reemplazar-text'>Reemplazar archivo si existe</p>
                    <input onChange={() => setReemplazarArchivo(!reemplazarArchivo)} disabled={cargando} type="checkbox" id="cbx" />
                    <label htmlFor="cbx" className="cbx">
                        <div className="flip">
                            <div className="front"></div>
                            <div className="back">
                                <svg viewBox="0 0 16 14" height="14" width="16">
                                    <path d="M2 8.5L6 12.5L14 1.5"></path>
                                </svg>
                            </div>
                        </div>
                    </label>
                </div>
                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                    {cargando ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <p style={{ fontSize: 15, fontWeight: 'bold', color: 'blue' }}>Subiendo archivo..</p>
                        <ProgressBar style={{ width: 270, height: 25 }} striped variant="primary" now={progress} label={<p style={{ fontSize: 15 }}>{`${progress}%`}</p>} />
                        {habilitarBotonCancelarCarga && <Button variant='danger' style={{ marginTop: 3 }} onClick={cancelarCarga}>Cancelar</Button>}
                    </div> : <Button disabled={uploadedFile === undefined || cargando} onClick={() => uploadedFile !== undefined && subirArchivo(uploadedFile)} variant={uploadedFile === undefined ? 'secondary' : 'primary'}><FontAwesomeIcon icon={faFileUpload} style={{ fontSize: 20, marginRight: 3 }} />Subir</Button>}
                </Col>
                {habilitarErrorArchivo !== null && <Alert style={{ marginTop: 5 }} variant='primary' >
                    <b>{habilitarErrorArchivo}</b>
                </Alert>}
                {habilitarMensajeRespuestaError !== null && <Alert style={{ marginTop: 5 }} variant='danger' >
                    <b>{habilitarMensajeRespuestaError}</b>
                </Alert>}
            </Modal.Body>
        </Modal>
    )
}

export default ModalSubirArchivos