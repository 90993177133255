import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { MyModal } from "../../MyModal";
import Grilla, { GrillaRef, TipoCampo } from "../../Grilla";
import BlockUi from "react-block-ui";
import { BaseAsyncSelect as AsyncSelect } from "BaseSelect";
import { mostrarError } from "../../App";
import { isNullOrWhiteSpace } from "Utilidades";
import { useApi } from "ApiHooks";
import { CancelToken } from "SintiaHooks";
import styled from "styled-components";

type SelectOption = { value: string, label: string };

const SelectCustom = styled(AsyncSelect)`
    width:400px;
`;


export type BusquedaArticulosRef = {
    mostrar: (catalogoInicial: string | null | undefined) => Promise<{ codigoCatalogo: string, codigoArticulo: string }>,
    mostrarSeleccionMultiple: (catalogoInicial: string | null | undefined) => Promise<Array<{ codigoCatalogo: string, codigoArticulo: string }>>,
}

export const BusquedaArticulos = React.forwardRef((props: any, ref: any) => {
    let api = useApi();
    let [cargando, updateCargando] = React.useState(true);
    let [ncmValue, setncmValue] = useState<string>('')
    let [optionsCatalogos, updateOptionsCatalogos] = React.useState<SelectOption[]>([]);
    let funcionesPromesa = React.useRef<any>({ resolve: null, reject: null });
    const busquedaInicial = {
        catalogos: [] as string[], codigoArticulo: '', descripcion: '', ncm: '', buscarPorFecha: false,
        fechaDesde: '', fechaHasta: ''
    };
    let [estado, updateEstado] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrar') {
            let nuevaBusqueda = { ...busquedaInicial };
            nuevaBusqueda.catalogos = isNullOrWhiteSpace(accion.catalogoInicial) ? [] : [accion.catalogoInicial];
            return {
                busqueda: nuevaBusqueda, busquedaActual: nuevaBusqueda, mostrar: true,
                seleccionMultiple: accion.seleccionMultiple
            };
        } else if (accion.tipo === 'cerrar') {
            return { ...estado, mostrar: false };
        } else if (accion.tipo === 'updateBusquedaActual') {
            return { ...estado, busquedaActual: estado.busqueda };
        } else if (accion.tipo === 'updateBusqueda') {
            let nuevaBusqueda = { ...estado.busqueda };
            nuevaBusqueda[accion.propiedad] = accion.valor;
            return { ...estado, busqueda: nuevaBusqueda };
        }
        return estado;
    }, {
        busqueda: { ...busquedaInicial }, busquedaActual: { ...busquedaInicial },
        mostrar: false, seleccionMultiple: false
    });
    let refGrilla = React.useRef<GrillaRef>(null);
    const cerrar = () => {
        updateEstado({ tipo: 'cerrar' });
        funcionesPromesa.current.reject();
    }
    React.useImperativeHandle(ref, () => ({
        mostrar: (catalogoInicial: string) => {
            return new Promise((resolve, reject) => {
                funcionesPromesa.current = { resolve: resolve, reject: reject };
                updateEstado({ tipo: 'mostrar', catalogoInicial: catalogoInicial, seleccionMultiple: false });
            });
        },
        mostrarSeleccionMultiple: (catalogoInicial: string) => {
            return new Promise((resolve, reject) => {
                funcionesPromesa.current = { resolve: resolve, reject: reject };
                updateEstado({ tipo: 'mostrar', catalogoInicial: catalogoInicial, seleccionMultiple: true });
            });
        }
    }));
    React.useEffect(() => {
        async function cargarCatalogos() {
            try {
                let respuesta = await api.getCatalogos();
                updateOptionsCatalogos(respuesta.map((catalogo: any) => ({ value: catalogo.Codigo, label: catalogo.Descripcion })));
                updateCargando(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar catalogos', error);
                    mostrarError('Error al cargar los catalogos');
                }
            }
        }
        cargarCatalogos();
        //eslint-disable-next-line
    }, []);
    React.useEffect(() => {
        refGrilla.current?.recargar();
    }, [estado.busquedaActual]);
    async function cargarDatos(desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await api.busquedaArticulosCompletoPaginado(estado.busquedaActual.catalogos,
            estado.busquedaActual.codigoArticulo, estado.busquedaActual.descripcion,
            estado.busquedaActual.ncm, estado.busquedaActual.buscarPorFecha, estado.busquedaActual.fechaDesde,
            estado.busquedaActual.fechaHasta, desde, hasta, cancelToken);
        let paises = await api.getPaises();
        let items = respuesta.Items.map((item: any) => ({
            ...item,
            Id: item.CodigoCatalogo + '-' + item.CodigoArticulo,
            NombrePaisOrigen: paises.find((pais: any) => pais.Codigo === item.CodigoPaisOrigen)?.Descripcion
        }));
        return { cantidadItems: respuesta.CantidadTotal, items: items };
    }
    let campos = [{ propiedad: 'Id', visible: false, clave: true },
    { titulo: 'Catalogo', propiedad: 'CodigoCatalogo' },
    { titulo: "Nro. Articulo", propiedad: 'CodigoArticulo' },
    { titulo: 'Ncm', propiedad: 'Ncm' }, { titulo: 'Descripcion', propiedad: 'Descripcion' },
    { titulo: 'Sufijos', propiedad: 'Sufijos' }, { titulo: 'Pais Origen', propiedad: 'NombrePaisOrigen' },
    { titulo: 'Creado Por', propiedad: 'NombreCreadoPor' },
    { titulo: 'Fecha Creación', propiedad: 'CreadoEl', tipo: TipoCampo.DateTime },
    { titulo: 'Modificado Por', propiedad: 'NombreModificadoPor' },
    { titulo: 'Fecha Modificación', propiedad: 'ModificadoEl', tipo: TipoCampo.DateTime }];

    useEffect(() => {
        if (estado?.mostrar) {
            updateEstado({ tipo: 'updateBusqueda', propiedad: 'codigoArticulo', valor: props?.codigoArticulo })
            updateEstado({ tipo: 'updateBusquedaActual' });
        }
    }, [estado?.mostrar, props?.codigoArticulo])

    return (<MyModal show={estado.mostrar} onHide={cerrar}>
        <Modal.Dialog size="xl">
            <Modal.Header closeButton>
                Buscar Articulo
            </Modal.Header>
            <Modal.Body>
                <BlockUi blocking={cargando}>
                    <Form inline onSubmit={e => {
                        updateEstado({ tipo: 'updateBusquedaActual' });
                        e.preventDefault();
                    }}>
                        <Form.Group className="my-2">
                            <Form.Label htmlFor="cboCatalogos" className="mx-2">Catalogos</Form.Label>
                            <SelectCustom id="cboCatalogos" options={optionsCatalogos} isMulti
                                placeholder="Seleccionar..." isClearable onChange={(option: SelectOption[] | SelectOption | null | undefined) => {
                                    if (Array.isArray(option)) {
                                        updateEstado({ tipo: 'updateBusqueda', propiedad: 'catalogos', valor: option.map(item => item.value) })
                                    } else if (option === null || option === undefined) {
                                        updateEstado({ tipo: 'updateBusqueda', propiedad: 'catalogos', valor: [] })
                                    } else {
                                        updateEstado({ tipo: 'updateBusqueda', propiedad: 'catalogos', valor: [option.value] })
                                    }
                                }} value={optionsCatalogos.filter((c: any) => estado.busqueda.catalogos?.includes(c.value))}
                                getOptionLabel={(option: SelectOption) => option.value + ' - ' + option.label}></SelectCustom>
                        </Form.Group>
                        <Form.Group className="my-2">
                            <Form.Label htmlFor="txtArticulo" className="mx-2">Codigo Articulo</Form.Label>
                            <Form.Control id="txtArticulo" type="text" value={estado.busqueda.codigoArticulo}
                                onChange={e => updateEstado({ tipo: 'updateBusqueda', propiedad: 'codigoArticulo', valor: e.target.value })}></Form.Control>
                        </Form.Group>
                        <Form.Group className="my-2">
                            <Form.Label htmlFor="txtDescripcion" className="mx-2">Descripción</Form.Label>
                            <Form.Control id="txtDescripcion" type="text" value={estado.busqueda.descripcion}
                                onChange={e => updateEstado({ tipo: 'updateBusqueda', propiedad: 'descripcion', valor: e.target.value })}></Form.Control>
                        </Form.Group>
                        <Form.Group className="my-2">
                            <Form.Label htmlFor="txtNcm" className="mx-2">Ncm</Form.Label>
                            <Form.Control style={{ borderColor: ncmValue?.length < 4 && ncmValue?.length !== 0 && ncmValue !== '' ? 'red' : 'black' }} maxLength={15} id="txtNcm" type="text"
                                onChange={(e) => {
                                    setncmValue(e.target.value)
                                    updateEstado({ tipo: 'updateBusqueda', propiedad: 'ncm', valor: e.target.value })

                                }}></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Check custom className="mx-2" label="Buscar por fecha modificación" id="checkBuscarPorFecha" checked={estado.busqueda.buscarPorFecha}
                                onChange={(e: any) => updateEstado({ tipo: 'updateBusqueda', propiedad: 'buscarPorFecha', valor: e.target.checked })}></Form.Check>
                            <Form.Label htmlFor="txtFechaDesde" className="mx-2">Desde</Form.Label>
                            <Form.Control type="date" id="txtFechaDesde" disabled={!estado.busqueda.buscarPorFecha} value={estado.busqueda.fechaDesde} className="mb-2 mr-2"
                                onChange={e => updateEstado({ tipo: 'updateBusqueda', propiedad: 'fechaDesde', valor: e.target.value })}></Form.Control>
                            <Form.Label htmlFor="txtFechaHasta" className="mr-2">Hasta</Form.Label>
                            <Form.Control type="date" id="txtFechaHasta" disabled={!estado.busqueda.buscarPorFecha} value={estado.busqueda.fechaHasta} className="mb-2 mr-2"
                                onChange={e => updateEstado({ tipo: 'updateBusqueda', propiedad: 'fechaHasta', valor: e.target.value })}></Form.Control>
                        </Form.Group>
                        <Button type="submit" className="mb-2" disabled={ncmValue?.length < 4 && ncmValue?.length !== 0 && ncmValue !== ''}>Buscar</Button>
                    </Form>
                </BlockUi>
                {ncmValue?.length < 4 && ncmValue?.length !== 0 && ncmValue !== '' && (
                    <div style={{ marginLeft: 50 }}>
                        <p style={{ color: 'red', fontSize: 15 }}>Ncm minimo 4 digitos </p>
                    </div>
                )}
                {!estado.seleccionMultiple && <p>Haga click en un artículo para seleccionarlo</p>}
                <Grilla responsiva campos={campos} cargarDatos={cargarDatos}
                    eventoDetalle={estado.seleccionMultiple ? undefined : item => {
                        updateEstado({ tipo: 'cerrar' });
                        funcionesPromesa.current?.resolve({ codigoCatalogo: item.CodigoCatalogo, codigoArticulo: item.CodigoArticulo });
                    }} ref={refGrilla} seleccionMultiple={estado.seleccionMultiple} />
            </Modal.Body>
            <Modal.Footer>
                {estado.seleccionMultiple && <Button onClick={() => {
                    let articulos = refGrilla.current?.getClavesSeleccionadas()?.map((id: string) => {
                        let [codigoCatalogo, ...partes] = id.split('-');
                        return { codigoCatalogo, codigoArticulo: partes.join('-') };
                    });
                    updateEstado({ tipo: 'cerrar' });
                    funcionesPromesa.current?.resolve(articulos);
                }}>Ingresar Articulos Seleccionados</Button>}
                <Button variant="danger" onClick={cerrar}>
                    Cancelar
                </Button>
            </Modal.Footer>
        </Modal.Dialog>
    </MyModal>)
});