import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import { useHistory } from "react-router";
import Grilla, { GrillaRef, TipoCampo } from "../../Grilla";
import { AppContext } from "App";
import { Button, Col, Form, Row, Modal } from "react-bootstrap";
import { isNullOrWhiteSpace } from "../../Utilidades";
import { DateTime } from "luxon";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import { MyModal } from "../../MyModal";
import Loader from "react-loaders";
import { CancelToken } from "SintiaHooks";
import { useApi, TipoLock } from "ApiHooks";

export function RecuperarCaratulas() {
    let refGrilla = useRef<GrillaRef>(null);
    let refDialogo = useRef<DialogoConfirmarRef>(null);
    let history = useHistory();
    let { mostrarError } = useContext(AppContext);
    let campos = [{ propiedad: 'Id', clave: true, visible: false }, { titulo: 'Interno', propiedad: 'Carpeta' },
    { titulo: 'Fecha Creación', propiedad: 'FechaCreacion', tipo: TipoCampo.DateTime },
    { titulo: 'Fecha Eliminación', propiedad: 'FechaEliminacion', tipo: TipoCampo.DateTime }];
    let api = useApi();
    let [mensajeSpinner, updateMensajeSpinner] = useState('');
    let [mensajeDialogo, updateMensajeDialogo] = useState('');
    let [mensajeExito, updateMensajeExito] = useState('');
    function busquedaReducer(estado: any, accion: any) {
        if (accion.tipo === 'carpeta') {
            return { ...estado, carpeta: accion.valor };
        } else if (accion.tipo === 'fechaCreacionDesde') {
            return { ...estado, fechaCreacionDesde: accion.valor };
        } else if (accion.tipo === 'fechaCreacionHasta') {
            return { ...estado, fechaCreacionHasta: accion.valor };
        } else if (accion.tipo === 'fechaEliminacionDesde') {
            return { ...estado, fechaEliminacionDesde: accion.valor };
        } else if (accion.tipo === 'fechaEliminacionHasta') {
            return { ...estado, fechaEliminacionHasta: accion.valor };
        }
        return estado;
    }
    let [busqueda, updateBusqueda] = useReducer(busquedaReducer, {
        carpeta: '',
        fechaCreacionDesde: '', fechaCreacionHasta: '',
        fechaEliminacionDesde: '', fechaEliminacionHasta: ''
    });
    let [busquedaActual, updateBusquedaActual] = useState({
        carpeta: '',
        fechaCreacionDesde: '', fechaCreacionHasta: '',
        fechaEliminacionDesde: '', fechaEliminacionHasta: ''
    });
    useEffect(() => {
        refGrilla.current?.recargar();
    }, [busquedaActual]);
    async function cargarDatos(desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await api.busquedaCaratulasEliminadasPaginado(busquedaActual.carpeta,
            busquedaActual.fechaCreacionDesde, busquedaActual.fechaCreacionHasta,
            busquedaActual.fechaEliminacionDesde, busquedaActual.fechaEliminacionHasta, desde, hasta, cancelToken);
        return { items: respuesta.Items, cantidadItems: respuesta.CantidadTotal };
    }
    async function recuperarCaratula(item: any) {
        try {
            updateMensajeSpinner('Recuperando caratula...');
            let respuesta = await api.getCaratula(item.Carpeta);
            if (respuesta) {
                if (respuesta.Bloqueada) {
                    mostrarError(`No se puede recuperar la caratula ${item.Carpeta} ya existe una caratula bloqueada con ese nombre`);
                    updateMensajeSpinner('');
                    return;
                } else {
                    updateMensajeDialogo(`La caratula ${item.Carpeta} ya existe. ¿Desea sobreescribir sobre esta?`);
                    if (!await refDialogo.current!.mostrar()) {
                        updateMensajeSpinner('');
                        return;
                    }
                }
            }
            let puedeModificar = await api.obtenerLock(TipoLock.Caratula, item.Carpeta);
            if (puedeModificar) {
                await api.recuperarCaratulaEliminada(item.Id);
                refGrilla.current?.recargar();
                updateMensajeExito(`Se ha recuperado la caratula ${item.Carpeta} exitosamente`);
            } else {
                mostrarError(`No se puede recuperar la caratula ${item.Carpeta} porque otro usuario esta modificandola`);
            }
            updateMensajeSpinner('');
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al recuperar la caratula', error);
                mostrarError('Error al recuperar la caratula');
            }
            if (!api.isUnmounted()) {
                updateMensajeSpinner('');
            }
        }
    }
    return <>
        <h2>Recuperar Caratulas</h2>
        <Row>
            <Col>
                <p>Buscar por nombre</p>
                <Form inline onSubmit={e => {
                    if (isNullOrWhiteSpace(busqueda.carpeta)) {
                        mostrarError('Debe ingresar la carpeta');
                    } else {
                        updateBusquedaActual({
                            carpeta: busqueda.carpeta,
                            fechaCreacionDesde: '', fechaCreacionHasta: '',
                            fechaEliminacionDesde: '', fechaEliminacionHasta: ''
                        });
                    }
                    e.preventDefault();
                }}>
                    <Form.Group controlId="txtInterno">
                        <Form.Label srOnly>Carpeta</Form.Label>
                        <Form.Control type="text" value={busqueda.carpeta} onChange={e => updateBusqueda({ tipo: 'carpeta', valor: e.target.value })}></Form.Control>
                    </Form.Group>
                    <Button type="submit" className="ml-2">Buscar</Button>
                </Form>
            </Col>
            <Col>
                <p>Buscar por fecha creación</p>
                <Form inline onSubmit={e => {
                    if (DateTime.fromISO(busqueda.fechaCreacionHasta) < DateTime.fromISO(busqueda.fechaCreacionDesde)) {
                        mostrarError('La fecha creación hasta debe ser posterior a la fecha creación desde');
                    } else {
                        updateBusquedaActual({
                            carpeta: '',
                            fechaCreacionDesde: busqueda.fechaCreacionDesde,
                            fechaCreacionHasta: DateTime.fromISO(busqueda.fechaCreacionHasta).plus({ days: 1 }).toISODate(),
                            fechaEliminacionDesde: '', fechaEliminacionHasta: ''
                        });
                    }
                    e.preventDefault();
                }}>
                    <Form.Group >
                        <Form.Label srOnly htmlFor="txtFechaCreacionDesde">Fecha Creación Desde</Form.Label>
                        <Form.Control id="txtFechaCreacionDesde" type="date" value={busqueda.fechaCreacionDesde} onChange={e => updateBusqueda({ tipo: 'fechaCreacionDesde', valor: e.target.value })}></Form.Control>
                        <span className="mx-2">-</span>
                        <Form.Label srOnly htmlFor="txtFechaCreacionHasta">Fecha Creación Hasta</Form.Label>
                        <Form.Control id="txtFechaCreacionHasta" type="date" value={busqueda.fechaCreacionHasta} onChange={e => updateBusqueda({ tipo: 'fechaCreacionHasta', valor: e.target.value })}></Form.Control>
                    </Form.Group>
                    <Button type="submit" className="ml-2">Buscar</Button>
                </Form>
            </Col>
            <Col>
                <p>Buscar por fecha eliminación</p>
                <Form inline onSubmit={e => {
                    if (DateTime.fromISO(busqueda.fechaEliminacionHasta) < DateTime.fromISO(busqueda.fechaEliminacionDesde)) {
                        mostrarError('La fecha eliminación hasta debe ser posterior a la fecha eliminación desde');
                    } else {
                        updateBusquedaActual({
                            carpeta: '',
                            fechaCreacionDesde: '', fechaCreacionHasta: '',
                            fechaEliminacionDesde: busqueda.fechaEliminacionDesde,
                            fechaEliminacionHasta: DateTime.fromISO(busqueda.fechaEliminacionHasta).plus({ days: 1 }).toISODate(),
                        });
                    }
                    e.preventDefault();
                }}>
                    <Form.Group >
                        <Form.Label srOnly htmlFor="txtFechaEliminacionDesde">Fecha Eliminación Desde</Form.Label>
                        <Form.Control id="txtFechaEliminacionDesde" type="date" value={busqueda.fechaEliminacionDesde} onChange={e => updateBusqueda({ tipo: 'fechaEliminacionDesde', valor: e.target.value })}></Form.Control>
                        <span className="mx-2">-</span>
                        <Form.Label srOnly htmlFor="txtFechaEliminacionHasta">Fecha Eliminación Hasta</Form.Label>
                        <Form.Control id="txtFechaEliminacionHasta" type="date" value={busqueda.fechaEliminacionHasta} onChange={e => updateBusqueda({ tipo: 'fechaEliminacionHasta', valor: e.target.value })}></Form.Control>
                    </Form.Group>
                    <Button type="submit" className="ml-2">Buscar</Button>
                </Form>
            </Col>
        </Row>
        <Button variant="danger" className="my-2" onClick={() => history.push('/caratulas')}>Volver a Caratulas</Button>
        <p>Haga click sobre una caratula para recuperarla</p>
        <Grilla cargarDatos={cargarDatos} ref={refGrilla} campos={campos} eventoDetalle={recuperarCaratula}></Grilla>
        <DialogoConfirmar resolverPromesaAlCancelar ref={refDialogo} mensaje={mensajeDialogo} textoBotonConfirmar="Sí" textoBotonCancelar="No" />
        <MyModal show={!!mensajeSpinner}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead">{mensajeSpinner}</p>
                    <div className="loader-container">
                        <Loader type="ball-spin-fade-loader" active></Loader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        api.cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={!!mensajeExito}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead text-success">{mensajeExito}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateMensajeExito('')}>Cerrar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
    </>;
}