import React from "react";
import { MyModal } from "./MyModal";
import { Button, Form, Modal } from "react-bootstrap";
import { AppContext, userClient } from "./App";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import { MyForm, MyFormControl } from "./FormikHooks";
import { ListBox } from "./ListBox";
import Loader from "react-loaders";
import { createQueryString, isNullOrWhiteSpace, reemplazarCaracteresInvalidosSufijos, escapeRegExp } from 'Utilidades';
import { obtenerValorSufijo2Letras, obtenerValorSufijo4Letras } from "Sufijos";
import { useCancel, CancelToken } from "SintiaHooks";

enum EstadoModal {
    Cerrado,
    Cargando,
    Abierto
}

export type IngresoSufijosRef = {
    mostrar: (soloSiInvalido: boolean, ncm?: string, sufijosActual?: string, marca?: string, modelo?: string, nroArticulo?: string) => Promise<string>,
}

export const IngresoSufijos = React.forwardRef((props: any, ref) => {
    let { getCancelToken, cancelCurrentTokens, isCancel } = useCancel();
    let { mostrarError } = React.useContext(AppContext);
    let formikRef = React.useRef<FormikProps<any>>(null);
    let textBoxRef = React.useRef<any>(null);
    function getSufijosDistinct(sufijos: any[]) {
        let sufijosDistinct: { codigo: string; letras: number; }[] = [];
        sufijos.forEach((sufijo: any) => {
            if (sufijo.Codigo.length === 2) {
                sufijosDistinct.push({ codigo: sufijo.Codigo, letras: 2 });
            } else if (sufijo.Codigo.length === 4) {
                if (!sufijosDistinct.map((item: any) => item.codigo).includes(sufijo.Codigo.substring(0, 2))) {
                    sufijosDistinct.push({ codigo: sufijo.Codigo.substring(0, 2), letras: 4 });
                }
            }
        });
        return sufijosDistinct.sort((a, b) => a.codigo > b.codigo ? 1 : (b.codigo > a.codigo ? -1 : 0));
    }
    let [estado, updateEstado] = React.useReducer((estado: any, accion: any) => {
        function getValorDefecto(sufijosAnteriores: string, sufijos: any, sufijosDistinct: { codigo: string, letras: number }[],
            sufijoActual: number, marca: string, modelo: string, nroArticulo: string) {
            if (sufijosDistinct[sufijoActual].letras === 4) {
                let valor = obtenerValorSufijo4Letras(sufijosDistinct[sufijoActual].codigo, sufijosAnteriores);
                if (!isNullOrWhiteSpace(valor)) {
                    return valor;
                }
            } else {
                let valor = obtenerValorSufijo2Letras(sufijosDistinct[sufijoActual].codigo, sufijosAnteriores);
                if (!isNullOrWhiteSpace(valor)) {
                    return valor;
                } else {
                    if (sufijosDistinct[sufijoActual].codigo === 'AA') {
                        return marca;
                    } else if (sufijosDistinct[sufijoActual].codigo === 'AB') {
                        if (sufijosDistinct.map(s => s.codigo).includes('AI')) {
                            return modelo;
                        } else {
                            return !isNullOrWhiteSpace(modelo) ? modelo : nroArticulo;
                        }
                    } else if (sufijosDistinct[sufijoActual].codigo === 'AI') {
                        if (sufijosDistinct.map(s => s.codigo).includes('AB')) {
                            return nroArticulo;
                        } else {
                            return !isNullOrWhiteSpace(nroArticulo) ? nroArticulo : modelo;
                        }
                    }
                }
            }
            return '';
        }
        if (accion.tipo === 'cerrar') {
            return { ...estado, estadoModal: EstadoModal.Cerrado, };
        } else if (accion.tipo === 'iniciar') {
            return {
                ...estado, estadoModal: EstadoModal.Cargando, ncm: accion.ncm,
                soloSiInvalido: accion.soloSiInvalido,
                sufijoActual: 0, sufijosIngresados: '',
                resolve: accion.resolve, reject: accion.reject,
                valorDefecto: '', sufijosAnteriores: accion.sufijosAnteriores,
                marca: accion.marca, modelo: accion.modelo, nroArticulo: accion.nroArticulo, soloAR: false
            };
        }
        else if (accion.tipo === 'setSufijos') {
            let sufijoInicial = accion.soloAR ? accion.sufijosDistinct.findIndex((s: any) => s.codigo === 'AR') : 0;
            let valorDefecto = getValorDefecto(estado.sufijosAnteriores ?? '', accion.sufijos,
                accion.sufijosDistinct, sufijoInicial, estado.marca ?? '', estado.modelo ?? '', estado.nroArticulo ?? '');
            valorDefecto = reemplazarCaracteresInvalidosSufijos(valorDefecto);
            return {
                ...estado, estadoModal: EstadoModal.Abierto, sufijos: accion.sufijos,
                sufijosDistinct: accion.sufijosDistinct, valorDefecto: valorDefecto,
                sufijoActual: sufijoInicial, soloAR: accion.soloAR
            };
        } else if (accion.tipo === 'ingresoSufijo2letras') {
            if (estado.soloAR) {
                let sufijosIngresados = estado.sufijosAnteriores.replace(/AR\(([^)]+)\)/, 'AR(' + accion.valor + ')');
                return {
                    ...estado, sufijoActual: 9999,
                    sufijosIngresados: sufijosIngresados, valorDefecto: ''
                }
            } else {
                let sufijoActual = estado.sufijoActual;
                let valorDefecto = '';
                if (sufijoActual + 1 < estado.sufijosDistinct.length) {
                    valorDefecto = getValorDefecto(estado.sufijosAnteriores ?? '', estado.sufijos, estado.sufijosDistinct,
                        sufijoActual + 1, estado.marca ?? '', estado.modelo ?? '', estado.nroArticulo ?? '');
                    valorDefecto = reemplazarCaracteresInvalidosSufijos(valorDefecto);
                }
                return {
                    ...estado, sufijoActual: sufijoActual + 1,
                    sufijosIngresados: estado.sufijosIngresados + estado.sufijosDistinct[sufijoActual].codigo +
                        '(' + accion.valor + ')-',
                    valorDefecto: valorDefecto
                }
            }
        } else if (accion.tipo === 'ingresoSufijo4letras') {
            let sufijoActual = estado.sufijoActual;
            let valorDefecto = '';
            if (sufijoActual + 1 < estado.sufijosDistinct.length) {
                valorDefecto = getValorDefecto(estado.sufijosAnteriores ?? '', estado.sufijos, estado.sufijosDistinct,
                    sufijoActual + 1, estado.marca ?? '', estado.modelo ?? '', estado.nroArticulo ?? '');
                valorDefecto = reemplazarCaracteresInvalidosSufijos(valorDefecto);
            }
            return {
                ...estado, sufijoActual: estado.sufijoActual + 1,
                sufijosIngresados: estado.sufijosIngresados + accion.valor + '-',
                valorDefecto: valorDefecto
            };
        } else if (accion.tipo === 'setMensaje') {
            return { ...estado, mensaje: accion.valor };
        }
    }, { estadoModal: EstadoModal.Cerrado });

    function eliminarRepetidos(val: string, indice: number, array: string[]) {
        return array.indexOf(val) === indice && val !== '';
    }
    function validarSufijos(valor: string, sufijos: any, sufijosDistinct: string[]) {
        valor = valor ?? '';
        let divididoFull = valor.split('-').filter(str => str !== '');
        let dividido = [];
        let sufijos4Letras = [];
        for (let i = 0; i < divididoFull.length; i++) {
            if (divididoFull[i].charAt(0) === 'A' || divididoFull[i].charAt(0) === 'Z') {
                let matches = new RegExp(divididoFull[i].substring(0, 2) + escapeRegExp('(') + "([^)]+)"
                    + escapeRegExp(')')).exec(divididoFull[i]);
                if (!matches || matches[1].trim() === '') {
                    return false;
                }
            } else if (divididoFull[i].length !== 4) {
                return false;
            } else if (!divididoFull[i].endsWith('00')) {
                sufijos4Letras.push(divididoFull[i]);
            }
            dividido.push(divididoFull[i].substring(0, 2));
        }
        dividido = dividido.filter(eliminarRepetidos);
        if (dividido.length !== sufijosDistinct.length) {
            return false;
        }
        dividido = dividido.sort();
        let sufijosDistinctSort = sufijosDistinct.sort();
        for (let i = 0; i < dividido.length; i++) {
            if (dividido[i] !== sufijosDistinctSort[i]) {
                return false;
            }
        }
        let sufijos4LetrasNcm = sufijos.filter((s: any) => s.Codigo.length === 4).map((s: any) => s.Codigo);
        for (const sufijo4Letras of sufijos4Letras) {
            if (!sufijos4LetrasNcm.includes(sufijo4Letras)) {
                return false;
            }
        }
        return true;
    }
    async function cargarSufijos(ncm: string, soloSiInvalido: boolean, cancelToken: CancelToken) {
        if (ncm) {
            try {
                let respuesta = await userClient.get('/Ncm/Sufijos' +
                    createQueryString({ PosicionArancelaria: ncm }), { cancelToken: cancelToken });
                if (respuesta.data.length === 0) {
                    estado.resolve('');
                    updateEstado({ tipo: 'cerrar' });
                    if (!soloSiInvalido) {
                        updateEstado({ tipo: 'setMensaje', valor: 'La posición arancelaria no tiene sufijos' });
                    }
                } else {
                    let sufijos = respuesta.data;
                    let sufijosDistinct = getSufijosDistinct(sufijos);
                    let codigos = sufijosDistinct.map(item => item.codigo);
                    if (soloSiInvalido && validarSufijos(estado.sufijosAnteriores, sufijos, codigos)) {
                        if (codigos.includes('AR')) {
                            updateEstado({
                                tipo: 'setSufijos', sufijos: sufijos,
                                sufijosDistinct: sufijosDistinct, soloAR: true
                            });
                        } else {
                            estado.resolve(estado.sufijosAnteriores);
                            updateEstado({ tipo: 'cerrar' });
                        }
                    } else {
                        updateEstado({
                            tipo: 'setSufijos', sufijos: sufijos,
                            sufijosDistinct: sufijosDistinct, soloAR: false
                        });
                    }
                }
            } catch (error) {
                if (!isCancel(error)) {
                    console.error('Error al cargar sufijos', error);
                    updateEstado({ tipo: 'cerrar' });
                    mostrarError('Hubo un error al cargar los sufijos');
                }
            }
        } else {
            updateEstado({ tipo: 'cerrar' });
            mostrarError('Debe ingresar el NCM');
        }
    }
    React.useEffect(() => {
        if (estado.estadoModal === EstadoModal.Cargando) {
            cargarSufijos(estado.ncm, estado.soloSiInvalido, getCancelToken());
            return () => cancelCurrentTokens();
        }
        //eslint-disable-next-line 
    }, [estado.estadoModal, estado.ncm]);
    React.useEffect(() => {
        if (estado.estadoModal === EstadoModal.Abierto && estado.sufijoActual >= estado.sufijosDistinct.length) {
            estado.resolve(estado.sufijosIngresados);
            updateEstado({ tipo: 'cerrar' });
        }
        if (estado.estadoModal === EstadoModal.Cerrado && estado.reject) {
            estado.reject();
        }
        //eslint-disable-next-line 
    }, [estado.estadoModal, estado.sufijoActual]);
    React.useEffect(() => {
        formikRef.current?.setFieldValue('Ingreso', estado.valorDefecto);
        formikRef.current?.setFieldTouched('Ingreso', false);
        textBoxRef.current?.focus();
    }, [estado.estadoModal, estado.sufijoActual, estado.valorDefecto]);
    React.useImperativeHandle(ref, () => ({
        mostrar: (soloSiInvalido: boolean, ncm?: string, sufijosActual?: string, marca?: string, modelo?: string, nroArticulo?: string) => {
            return new Promise<string>((resolve, reject) => {
                updateEstado({
                    tipo: 'iniciar', ncm: ncm, sufijosAnteriores: sufijosActual, soloSiInvalido: soloSiInvalido,
                    resolve: resolve, reject: reject, marca: marca, modelo: modelo, nroArticulo: nroArticulo
                });
            });
        }
    }));
    let renderModalBody = () => {
        if (estado.sufijosDistinct && estado.sufijoActual < estado.sufijosDistinct.length) {
            let sufijoActual = estado.sufijosDistinct[estado.sufijoActual];
            if (sufijoActual.letras === 4) {
                let listaSufijos = estado.sufijos.filter((sufijo: any) =>
                    sufijo.Codigo.substring(0, 2) === sufijoActual.codigo);
                listaSufijos.push({ Codigo: sufijoActual.codigo + '00', Detalle: 'NINGUNO' });
                listaSufijos = listaSufijos.sort((a: any, b: any) =>
                    parseInt(a.Codigo.substring(2, 4)) - parseInt(b.Codigo.substring(2, 4)));
                return (
                    <>
                        <Modal.Body>
                            <ListBox list={listaSufijos} itemKey="Codigo" selectedKey={estado.valorDefecto} render={(sufijo: any) => (<span>{sufijo.Codigo + ' - ' + sufijo.Detalle}</span>)}
                                onSelect={(sufijo: any) => updateEstado({ tipo: 'ingresoSufijo4letras', valor: sufijo.Codigo })} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={() => updateEstado({ tipo: 'cerrar' })}>
                                Cancelar
                            </Button>
                        </Modal.Footer>
                    </>)
            } else {
                let schema: any;
                let sufijoActualCompleto = estado.sufijos.find((sufijo: any) => sufijo.Codigo === sufijoActual.codigo);
                if (sufijoActual.codigo.charAt(0) === 'Z') {
                    schema = Yup.object({
                        'Ingreso': Yup.number().nullable().required('Debe ingresar un número')
                            .typeError('Debe ingresar un número')
                            .integer('Debe ingresar un número entero')
                            .test('caracteres', 'El valor debe tener 20 caracteres o menos',
                                function (value: any) {
                                    let obj = this as unknown as { originalValue: string };
                                    return (obj.originalValue ?? '').length <= 20;
                                })
                    });
                } else {
                    schema = Yup.object({
                        'Ingreso': Yup.string().nullable().required('Debe ingresar un valor').max(20, 'El valor debe tener 20 caracteres o menos')
                            .matches(/^[^()-]*$/, 'El valor no puede tener ni parentesis ni guiones')
                    });
                }
                return (
                    <>
                        <Modal.Body>
                            <Formik initialValues={{ Ingreso: estado.valorDefecto }} validationSchema={schema}
                                onSubmit={(values: any, formikBag: FormikHelpers<any>) => {
                                    let ingreso = values.Ingreso;
                                    if (sufijoActual.codigo.charAt(0) === 'Z') {
                                        ingreso = ingreso.padStart('6', '0');
                                    }
                                    updateEstado({
                                        tipo: 'ingresoSufijo2letras',
                                        valor: ingreso
                                    });
                                    formikBag.setSubmitting(false);
                                }} innerRef={formikRef}>
                                <MyForm blockWhenSubmitting={false}>
                                    <Form.Group>
                                        <MyFormControl ref={textBoxRef} name="Ingreso"
                                            type={sufijoActual.codigo.charAt(0) === 'Z' ? 'number' : 'text'}
                                            label={sufijoActualCompleto?.Codigo + ' - ' + sufijoActualCompleto?.Detalle}></MyFormControl>
                                    </Form.Group>
                                </MyForm>
                            </Formik>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={() => updateEstado({ tipo: 'cerrar' })}>
                                Cancelar
                            </Button>
                            <Button type="button" variant="primary" onClick={() => {
                                formikRef.current?.handleSubmit();
                            }}>
                                Ingresar
                            </Button>
                        </Modal.Footer>
                    </>)
            }
        } else {
            return (<></>);
        }

    };
    return (
        <>
            <MyModal show={!!estado.mensaje} onHide={() => updateEstado({ tipo: 'setMensaje', valor: '' })}>
                <Modal.Dialog>
                    <Modal.Body>
                        <p className="lead">{estado.mensaje}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => updateEstado({ tipo: 'setMensaje', valor: '' })}>Cerrar</Button>
                    </Modal.Footer>
                </Modal.Dialog>
            </MyModal>
            <MyModal show={estado.estadoModal === EstadoModal.Cargando}>
                <Modal.Dialog>
                    <Modal.Body>
                        <p className="lead">Validando sufijos...</p>
                        <div className="loader-container">
                            <Loader type="ball-spin-fade-loader" active></Loader>
                        </div>
                    </Modal.Body>
                </Modal.Dialog>
            </MyModal>
            <MyModal show={estado.estadoModal === EstadoModal.Abierto}
                onHide={() => updateEstado({ tipo: 'cerrar' })}>
                <Modal.Dialog>
                    <Modal.Header closeButton>
                        <Modal.Title>Ingresar Sufijos</Modal.Title>
                    </Modal.Header>
                    {renderModalBody()}
                </Modal.Dialog>
            </MyModal>
        </>)
});
