
import React, { useEffect, useReducer, useRef, useState } from "react";
import { Button, Form, Modal, Col } from "react-bootstrap";
import { AppContext, } from "App";
import { GrillaSync, TipoCampo } from "../../Grilla";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import BlockUi from "react-block-ui";
import { MyModal } from "../../MyModal";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import { MyForm, MyFormCheck, MyFormControl, MyFormRadio } from "../../FormikHooks";
import { MonedaConceptoPresupuesto } from "../../Enums";
import { strCmp, isNullOrWhiteSpace } from "../../Utilidades";
import { useApi } from "ApiHooks";

export function ConceptosPresupuesto(props: {
    conceptosExistentes: string[],
    conceptoSeleccionado: (concepto: any) => void
}) {
    let [conceptos, updateConceptos] = useState<any[]>([]);
    let [cargando, updateCargando] = useState(true);
    let dialogoRef = useRef<DialogoConfirmarRef>(null);
    let { mostrarError, userInfo } = React.useContext(AppContext);
    let api = useApi();
    let [mensajeDialogoConfirmar, updateMensajeDialogoConfirmar] = useState('');
    let formikRef = useRef<FormikProps<any>>(null);
    let [estadoModal, updateEstadoModal] = useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrarCrear') {
            return { creando: true, modificando: false, valorModificando: null, habilitarPorcentaje: false };
        } else if (accion.tipo === 'mostrarModificar') {
            return { creando: false, modificando: true, valorModificando: accion.valor, habilitarPorcentaje: accion.valor.Porcentaje > 0 };
        } else if (accion.tipo === 'esconderVentana') {
            return { creando: false, modificando: false, valorModificando: null, habilitarPorcentaje: false };
        } else if (accion.tipo === 'setHabilitarPorcentaje') {
            return { ...estado, habilitarPorcentaje: accion.valor };
        }
    }, { creando: false, modificando: false, valorModificando: null, habilitarPorcentaje: false });
    useEffect(() => {
        async function cargar() {
            try {
                let respuesta = await api.getTiposConceptoPresupuesto();
                updateConceptos(respuesta?.sort((a: any, b: any) => strCmp(a.Nombre, b.Nombre)) ?? []);
                updateCargando(false);
            } catch (error) {
                if (!api.isCancel(true)) {
                    console.error('Error al cargar conceptos presupuesto', error);
                    mostrarError('Error al cargar conceptos presupuesto');
                }
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);
    async function eventoEliminar(concepto: any) {
        updateMensajeDialogoConfirmar(`¿Está seguro que desea eliminar el concepto ${concepto.Nombre}?`);
        dialogoRef.current!.mostrar().then(async () => {
            try {
                updateCargando(true);
                await api.deleteTipoConceptoPresupuesto(concepto.Nombre);
                updateConceptos(conceptos?.filter((c: any) => c.Nombre !== concepto.Nombre)?.sort((a: any, b: any) => strCmp(a.Nombre, b.Nombre)) ?? []);
                updateCargando(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al eliminar concepto', error);
                    mostrarError('Error al eliminar concepto');
                }
                if (!api.isUnmounted()) {
                    updateCargando(false);
                }
            }
        }).catch(() => { });
    }
    return <>
        {/* <h2>Conceptos presupuesto</h2> */}
        <BlockUi blocking={cargando}>
            <GrillaSync datos={conceptos} campos={[{ propiedad: 'Nombre', titulo: 'Nombre', clave: true },
            { propiedad: 'Porcentaje', titulo: '% IVA', tipo: TipoCampo.Number }, {
                propiedad: 'Moneda', titulo: 'Moneda',
                plantillaFormato: (valor: MonedaConceptoPresupuesto) => {
                    if (valor === MonedaConceptoPresupuesto.Peso) {
                        return 'Peso';
                    } else if (valor === MonedaConceptoPresupuesto.DolarA) {
                        return 'Dolar A';
                    } else if (valor === MonedaConceptoPresupuesto.DolarB) {
                        return 'Dolar B';
                    }
                    return '';
                }
            }]} eventoAgregar={() => updateEstadoModal({ tipo: 'mostrarCrear' })}
                eventoDetalle={(item: any) => {
                    if (props.conceptosExistentes.includes(item.Nombre)) {
                        mostrarError('El concepto seleccionado ya fue ingresado');
                    } else {
                        props.conceptoSeleccionado(item);
                    }
                }}
                eventoModificar={(item: any) => updateEstadoModal({ tipo: 'mostrarModificar', valor: item })}
                eventoEliminar={eventoEliminar} />
        </BlockUi>
        <DialogoConfirmar ref={dialogoRef} mensaje={mensajeDialogoConfirmar} textoBotonCancelar="No" textoBotonConfirmar="Sí"></DialogoConfirmar>
        <MyModal show={estadoModal.creando || estadoModal.modificando}
            onHide={() => updateEstadoModal({ tipo: 'esconderVentana' })}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>{estadoModal.creando ? 'Crear Concepto Presupuesto' : 'Modificar Concepto Presupuesto'}</h2>
                </Modal.Header>
                <Formik innerRef={formikRef} validationSchema={Yup.object({
                    'Nombre': Yup.string().nullable().required('Debe ingresar el nombre'),
                    'Gravado': Yup.boolean().nullable().required(),
                    'Porcentaje': Yup.number().when('Gravado', {
                        is: true,
                        then: Yup.number().nullable().required('Debe ingresar el porcentaje')
                            .typeError('Debe ingresar un número')
                            .moreThan(0, 'El porcentaje debe ser mayor a cero')
                            .lessThan(100, 'El porcentaje debe ser menor a 100'),
                        otherwise: Yup.number().nullable().typeError('Debe ingresar un número')
                    }),
                    'Moneda': Yup.number().nullable().required('Debe seleccionar la moneda')
                        .oneOf([MonedaConceptoPresupuesto.Peso, MonedaConceptoPresupuesto.DolarA, MonedaConceptoPresupuesto.DolarB], 'Debe seleccionar la moneda')
                })} initialValues={{
                    'Nombre': estadoModal.valorModificando?.Nombre ?? '',
                    'Gravado': estadoModal.valorModificando === null || estadoModal.valorModificando === undefined ? false : estadoModal.valorModificando.Porcentaje > 0,
                    'Porcentaje': estadoModal.valorModificando?.Porcentaje ?? '',
                    'Moneda': `${estadoModal.valorModificando?.Moneda ?? MonedaConceptoPresupuesto.Peso}`
                }} onSubmit={async (nuevoConcepto: any, actions) => {
                    let conceptosExistentes = conceptos.map((c: any) => c.Nombre);
                    if (estadoModal.modificando) {
                        let indice = conceptosExistentes.indexOf(estadoModal.valorModificando.Nombre);
                        if (indice > -1) {
                            conceptosExistentes.splice(indice, 1);
                        }
                    }
                    if (conceptosExistentes.includes(nuevoConcepto.Nombre)) {
                        actions.setFieldError('Nombre', `El concepto con nombre ${nuevoConcepto.Nombre} ya fue ingresado`);
                    } else {
                        try {
                            nuevoConcepto = {
                                NroClienteAlpha: userInfo.nroClienteAlpha,
                                Nombre: nuevoConcepto.Nombre,
                                Porcentaje: nuevoConcepto.Gravado ? nuevoConcepto.Porcentaje : 0,
                                Moneda: parseInt(nuevoConcepto.Moneda)
                            };
                            await api.insertTipoConceptoPresupuesto(nuevoConcepto);
                            let nuevosConceptos = Array.from(conceptos);
                            nuevosConceptos.push(nuevoConcepto);
                            updateConceptos(nuevosConceptos.sort((a: any, b: any) => strCmp(a.Nombre, b.Nombre)));
                            updateEstadoModal({ tipo: 'esconderVentana' });
                        } catch (error) {
                            if (!api.isCancel(error)) {
                                console.error('Error al guardar concepto', error);
                                mostrarError('Error al guardar concepto');
                            }
                        }
                    }
                }}>{({ isSubmitting, submitForm }) => <>
                    <Modal.Body>
                        <MyForm>
                            <Form.Group>
                                <MyFormControl type="text" name="Nombre" label="Nombre" readOnly={estadoModal.modificando}></MyFormControl>
                            </Form.Group>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <MyFormCheck name="Gravado" label="Gravado" labelOnLeft onCheckedChange={checked => {
                                        updateEstadoModal({ tipo: 'setHabilitarPorcentaje', valor: checked });
                                        if (checked && isNullOrWhiteSpace(formikRef.current?.values?.Porcentaje)) {
                                            formikRef.current?.setFieldValue('Porcentaje', 21);
                                        }
                                    }} className="col-form-label"></MyFormCheck>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <MyFormControl inline type="number" name="Porcentaje" label="Porcentaje" disabled={!estadoModal.habilitarPorcentaje}></MyFormControl>
                                </Form.Group>
                            </Form.Row>
                            <Form.Group>
                                <MyFormRadio inline name="Moneda" id="radioPesos" label="Peso" value={MonedaConceptoPresupuesto.Peso}></MyFormRadio>
                                <MyFormRadio inline name="Moneda" id="radioDolaresA" label="Dolar A" value={MonedaConceptoPresupuesto.DolarA}></MyFormRadio>
                                <MyFormRadio inline name="Moneda" id="radioDolaresB" label="Dolar B" value={MonedaConceptoPresupuesto.DolarB}></MyFormRadio>
                            </Form.Group>
                        </MyForm>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => updateEstadoModal({ tipo: 'esconderVentana' })}>
                            Cancelar
                        </Button>
                        <Button disabled={isSubmitting} onClick={submitForm}>
                            Ingresar
                        </Button>
                    </Modal.Footer>
                </>}
                </Formik>
            </Modal.Dialog>
        </MyModal>
    </>;
}