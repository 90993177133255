
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { AppContext } from "App";
import { GrillaSync } from "../../Grilla";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import BlockUi from "react-block-ui";
import { NotFoundComponent } from "../../App";
import { useApi, TipoLock } from "ApiHooks";

export function Presupuestos({ interno }: { interno: string }) {
    let [presupuestos, updatePresupuestos] = useState<any[]>([]);
    let [notFound, updateNotFound] = useState(false);
    let [cargando, updateCargando] = useState(true);
    let dialogoRef = useRef<DialogoConfirmarRef>(null);
    let history = useHistory();
    let { mostrarError } = useContext(AppContext);
    let api = useApi();
    let [mensajeDialogoConfirmar, updateMensajeDialogoConfirmar] = useState('');
    useEffect(() => {
        async function cargar() {
            try {
                let respuesta = await api.getEstadoCaratula(interno);
                if (!respuesta) {
                    updateNotFound(true);
                    return;
                }
                respuesta = await api.getPresupuestosDeCaratula(interno);
                updatePresupuestos(respuesta ?? []);
                updateCargando(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar presupuestos', error);
                    mostrarError('Error al cargar presupuestos');
                }
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);
    function eliminarPresupuesto(item: any) {
        updateMensajeDialogoConfirmar(`¿Está seguro que desea eliminar el presupuesto ${item.Nombre}?`);
        dialogoRef.current!.mostrar().then(async () => {
            try {
                updateCargando(true);
                let puedeEliminar = await api.obtenerLock(TipoLock.Caratula, interno);
                if (puedeEliminar) {
                    await api.deletePresupuesto(item.Id);
                    await api.eliminarLock(TipoLock.Caratula, interno);
                    updatePresupuestos(presupuestos?.filter((p: any) => p.Id !== item.Id) ?? []);
                } else {
                    mostrarError('No se puede eliminar el presupuesto porque otra persona está modificando la caratula');
                }
                updateCargando(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al eliminar presupuesto', error);
                    mostrarError('Error al eliminar presupuesto');
                    updateCargando(false);
                }
            }
        }).catch(() => { });
    }
    return notFound ? <NotFoundComponent></NotFoundComponent> : <div className="mt-2">
        {/* <Container> */}
        {/* <h2>Presupuestos de <Link to={'/caratulas/' + encodeURIComponent(interno)}>{interno}</Link></h2> */}
        <BlockUi blocking={cargando}>
            <GrillaSync datos={presupuestos}
                campos={[{ propiedad: 'Id', visible: false, clave: true },
                { propiedad: 'Nombre', titulo: 'Nombre' }]}
                eventoAgregar={() => {
                    history.push('/caratulas/' + encodeURIComponent(interno) + '/presupuestos/add', {
                        usarNombreInterno: presupuestos.length === 0
                    });
                }}
                eventoDetalle={(item: any) => history.push('/caratulas/' + encodeURIComponent(interno) + '/presupuestos/edit/' + encodeURIComponent(item.Id))}
                eventoEliminar={eliminarPresupuesto}></GrillaSync>
        </BlockUi>
        {/* </Container> */}
        <DialogoConfirmar ref={dialogoRef} mensaje={mensajeDialogoConfirmar} textoBotonCancelar="No" textoBotonConfirmar="Sí"></DialogoConfirmar>
    </div>;
}