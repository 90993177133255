import React from "react";
import { useHistory } from "react-router";
import { AppContext } from "../../App";
import Grilla, { GrillaRef } from "../../Grilla";
import { cargarDatosGrillaDeArray, isInRole } from "../../Utilidades";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import { useApi, TipoLock } from "ApiHooks";

export function ReglasValidacion() {
    let { mostrarError, userInfo } = React.useContext(AppContext);
    let history = useHistory();
    let api = useApi();
    let refGrilla = React.useRef<GrillaRef>(null);
    let refDialogo = React.useRef<DialogoConfirmarRef>(null);
    let [reglas, updateReglas] = React.useState([]);
    let funcionFormatoBool = (valor: any) => {
        if (valor === null || valor === undefined) {
            return '';
        } else if (valor) {
            return 'Sí';
        } else {
            return 'No';
        }
    }

    const cargarDatos = React.useCallback(async () => {
        refGrilla.current?.setBloqueado(true);
        try {
            let reglas = await api.getReglasValidacion();
            let despachantes = await api.getDespachantes();
            let importadores = await api.getImportadores();
            let items = reglas.map((regla: any) => {
                let nombreDesp = '', nombreImp = '';
                if (regla.CuitDespachante) {
                    nombreDesp = despachantes.find((desp: any) => desp.CUIT === regla.CuitDespachante)?.Nombre ?? regla.CuitDespachante;
                }
                if (regla.CuitImportador) {
                    nombreImp = importadores.find((imp: any) => imp.CUIT === regla.CuitImportador)?.Nombre ?? regla.CuitImportador;
                }
                return { ...regla, NombreDespachante: nombreDesp, NombreImportador: nombreImp };
            });
            updateReglas(items);
            refGrilla.current?.setBloqueado(false);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar reglas validacion', error);
                mostrarError('Error al cargar reglas validación');
                refGrilla.current?.setBloqueado(false);
            }
        }
    }, [mostrarError, api]);
    function eventoEliminar(item: any) {
        refDialogo.current!.mostrar().then(async () => {
            refGrilla.current?.setBloqueado(true);
            try {
                let puedeEliminar = await api.obtenerLock(TipoLock.ReglaValidacionMaria, item.Id);
                if (puedeEliminar) {
                    let { exito, error } = await api.deleteReglaValidacion(item.Id);
                    if (exito) {
                        cargarDatos();
                    } else {
                        mostrarError(error as string);
                        refGrilla.current?.setBloqueado(false);
                    }
                } else {
                    refGrilla.current?.setBloqueado(false);
                    mostrarError(`No se puede eliminar la regla porque otra persona la está modificando`);
                }
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al eliminar regla validacion', error);
                    mostrarError('Hubo un error al eliminar la regla de validación');
                    refGrilla.current?.setBloqueado(false);
                }
            }
        }).catch(() => { });
    }
    React.useEffect(() => {
        cargarDatos();
        //eslint-disable-next-line
    }, []);
    React.useEffect(() => {
        refGrilla.current?.recargar();
    }, [reglas]);
    let campos = [{ propiedad: 'Id', titulo: '', visible: false, clave: true }, { propiedad: 'Subregimen', titulo: 'Subregimen' },
    { propiedad: 'NombreDespachante', titulo: 'Despachante' }, { propiedad: 'NombreImportador', titulo: 'Importador' },
    { propiedad: 'CodigoArticulo', titulo: 'Codigo Articulo' }, { propiedad: 'Ncm', titulo: 'Ncm' },
    { propiedad: 'NcmTieneCAPE', titulo: 'Ncm Tiene CAPE', plantillaFormato: funcionFormatoBool },
    { propiedad: 'NcmTieneLNA', titulo: 'Ncm Tiene LNA', plantillaFormato: funcionFormatoBool },
    { propiedad: 'NcmTieneRegimenAutomotriz', titulo: 'Ncm Tiene Reg. Automotriz', plantillaFormato: funcionFormatoBool },
    { propiedad: 'PaisOrigen', titulo: 'Pais Origen' }, { propiedad: 'PaisProcedencia', titulo: 'Pais Procedencia' },
    { propiedad: 'Ventaja', titulo: 'Ventaja' }, { propiedad: 'Lista', titulo: 'Lista' }, { propiedad: 'InformacionAdicional', titulo: 'Inf. Adicional' }];
    let esOperador = isInRole(userInfo, 'Operador');
    return (<>
        <h2>Reglas Validación María</h2>
        <Grilla ref={refGrilla} campos={campos} cargarDatos={cargarDatosGrillaDeArray(reglas)} responsiva
            eventoAgregar={() => history.push('/reglasValidacion/add')}
            deshabilitarBotonAgregar={esOperador}
            eventoDetalle={(item: any) => history.push('/reglasValidacion/edit/' + encodeURIComponent(item.Id))}
            eventoEliminar={eventoEliminar} deshabilitarBotonEliminar={esOperador}></Grilla>
        <DialogoConfirmar ref={refDialogo} textoBotonConfirmar="Sí" textoBotonCancelar="No" mensaje="¿Está seguro que desea eliminar esta regla?" />
    </>);
}