import React from "react";
import { Button, Modal } from "react-bootstrap";
import { AppContext, NotFoundComponent } from "App";
import Grilla, { GrillaRef } from "../../Grilla";
import { MyModal } from "../../MyModal";
import Loader from "react-loaders";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import FileSaver from "file-saver";
import { useDecodedParams } from 'Utilidades';
import { useApi } from "ApiHooks";

export function ReporteNCMNoVigente() {
    let [notFound, updateNotFound] = React.useState(false);
    let { codigoCatalogo } = useDecodedParams();
    let { mostrarError } = React.useContext(AppContext);
    let api = useApi();
    let reporte = React.useRef([]);
    let refGrilla = React.useRef<GrillaRef>(null);
    let [generandoExcel, updateGenerandoExcel] = React.useState(false);
    React.useEffect(() => {
        async function cargarReporte() {
            try {
                if (!codigoCatalogo) {
                    updateNotFound(true);
                    return;
                }
                refGrilla.current?.setBloqueado(true);
                let respuesta = await api.busquedaReporteArticulosNCMNoVigente(codigoCatalogo);
                reporte.current = respuesta;
                refGrilla.current?.setBloqueado(false);
                refGrilla.current?.recargar();
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error(error);
                    mostrarError('Error al cargar reporte articulos con NCM no vigente');
                }
            }
        };
        cargarReporte();
        //eslint-disable-next-line
    }, [codigoCatalogo]);
    async function exportarAExcel() {
        try {
            updateGenerandoExcel(true);
            let { excel, fileName } = await api.excelReporteArticulosNCMNoVigente(codigoCatalogo as string);
            updateGenerandoExcel(false);
            FileSaver.saveAs(excel, fileName);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al exportar reporte articulos con NCM no vigente a Excel');
            }
            if (!api.isUnmounted()) {
                updateGenerandoExcel(false);
            }
        }
    }
    let camposGrilla = [{ propiedad: 'CodigoCatalogo', titulo: 'Catalogo' },
    { propiedad: 'CodigoArticulo', titulo: 'Articulo', clave: true },
    { propiedad: 'NCM', titulo: 'NCM' }];
    return notFound ? <NotFoundComponent></NotFoundComponent> : (<>
        <h2>Reporte articulos de catalogo {codigoCatalogo} con NCM no vigente</h2>
        <Button variant="success" className="mb-2" onClick={exportarAExcel}>
            <FontAwesomeIcon icon={faFileExcel} />
            <span>Exportar a Excel</span>
        </Button>
        <Grilla cargarDatos={(desde: number, hasta: number) => {
            return Promise.resolve({ cantidadItems: reporte.current.length, items: reporte.current.slice(desde - 1, hasta) });
        }} campos={camposGrilla} ref={refGrilla}></Grilla>
        <MyModal show={generandoExcel}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead">Generando Excel...</p>
                    <div className="loader-container">
                        <Loader type="ball-spin-fade-loader" active></Loader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        api.cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
    </>);
}