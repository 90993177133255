import { isNullOrWhiteSpace, escapeRegExp } from "./Utilidades";

export function obtenerValorSufijo2Letras(sufijo: string, sufijoActual: string | null | undefined) {
    if (isNullOrWhiteSpace(sufijoActual)) {
        return '';
    }
    let regExp = new RegExp(sufijo + escapeRegExp('(') + "([^)]+)" + escapeRegExp(')'));
    let matches = regExp.exec(sufijoActual as string);
    if (matches) {
        return matches[1].trim();
    }
    return '';
}
export function obtenerValorSufijo4Letras(sufijo: string, sufijoActual: string | null | undefined) {
    if (isNullOrWhiteSpace(sufijoActual)) {
        return '';
    }
    let regExp = new RegExp(sufijo + "[0-9]{2}");
    let matches = regExp.exec(sufijoActual as string);
    if (matches) {
        return matches[0].substring(0, 4);
    }
    return '';
}