import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import { GrillaSync, TipoCampo } from "Grilla";
import { useApi } from "ApiHooks";
import { AppContext } from "App";
import BlockUi from "react-block-ui";
import { MyModal } from "MyModal";
import { Button, Modal } from "react-bootstrap";
import { Field, Formik } from "formik";
import { MyForm, MyFormControl, MyFormGroup } from "FormikHooks";
import * as Yup from "yup";
import { DialogoConfirmar, DialogoConfirmarRef } from "DialogoConfirmar";
export function TiposCertificacion(props: {
    eventoTipoSeleccionado?: (tipo: any) => void,
    eventoTiposCargados?: (tipos: any[]) => void
}) {
    const { mostrarError } = useContext(AppContext);
    let api = useApi();
    let [cargando, updateCargando] = useState(true);
    let [tipos, updateTipos] = useState<any[]>([]);
    let [estadoModal, updateEstadoModal] = useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrarCrear') {
            return { mostrar: true, valorModificando: null };
        } else if (accion.tipo === 'mostrarModificar') {
            return { mostrar: true, valorModificando: accion.valor };
        } else if (accion.tipo === 'cerrar') {
            return { mostrar: false, valorModificando: null };
        }
    }, { mostrar: false, valorModificando: null });
    let dialogoConfirmarRef = useRef<DialogoConfirmarRef>(null);
    let [mensajeDialogo, updateMensajeDialogo] = useState('');
    let campos = [{ propiedad: 'Id', clave: true, visible: false },
    { titulo: 'Nombre', propiedad: 'Nombre' }, { titulo: 'Codigo Maria', propiedad: 'CodigoMaria' },
    { titulo: 'Usuario Modificación', propiedad: 'NombreModificadoPor' },
    { titulo: 'Fecha Modificación', propiedad: 'ModificadoEl', tipo: TipoCampo.DateTime }];
    async function cargarTipos() {
        try {
            updateCargando(true);
            let lista = await api.getTiposCertificacion();
            if (props.eventoTiposCargados) {
                props.eventoTiposCargados(lista);
            }
            updateTipos(lista);
            updateCargando(false);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar tipos certificación', error);
                mostrarError('Error al cargar tipos certificación');
                updateCargando(false);
            }
        }
    }
    function eliminarTipo(tipo: any) {
        updateMensajeDialogo(`¿Está seguro que desea eliminar el tipo ${tipo.Nombre}?`);
        dialogoConfirmarRef.current!.mostrar().then(async () => {
            try {
                updateCargando(true);
                await api.deleteTipoCertificacion(tipo.Id);
                cargarTipos();
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al eliminar tipo certificación', error);
                    mostrarError('Error al eliminar tipo certificación');
                    updateCargando(false);
                }
            }
        }).catch(() => { });
    }
    useEffect(() => {
        cargarTipos();
        //eslint-disable-next-line
    }, []);
    async function insertTipoCertificacion(values: any) {
        try {
            await api.insertTipoCertificacion(values);
            updateEstadoModal({ tipo: 'cerrar' });
            cargarTipos();
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al crear o modificar tipo certificación', error);
                mostrarError('Error al crear o modificar tipo certificación');
            }
        }
    }
    const eventoModificar = (item: any) => updateEstadoModal({ tipo: 'mostrarModificar', valor: item });
    return <>
        <BlockUi blocking={cargando}>
            <GrillaSync datos={tipos} campos={campos}
                eventoAgregar={() => updateEstadoModal({ tipo: 'mostrarCrear' })}
                eventoDetalle={props.eventoTipoSeleccionado ?? eventoModificar}
                eventoModificar={props.eventoTipoSeleccionado ? eventoModificar : undefined}
                eventoEliminar={(item: any) => eliminarTipo(item)}></GrillaSync>
        </BlockUi>
        <DialogoConfirmar ref={dialogoConfirmarRef} mensaje={mensajeDialogo} textoBotonConfirmar="Sí" textoBotonCancelar="No"></DialogoConfirmar>
        <MyModal show={estadoModal?.mostrar} onHide={() => updateEstadoModal({ tipo: 'cerrar' })}>
            <Modal.Dialog>
                <Modal.Header>
                    {estadoModal?.valorModificando ? 'Modificar tipo certificación' : 'Crear tipo certificación'}
                </Modal.Header>
                <Formik validationSchema={Yup.object({
                    'Id': Yup.string().nullable(),
                    'Nombre': Yup.string().nullable().required('Debe ingresar el nombre'),
                    'CodigoMaria': Yup.string().nullable()
                })} initialValues={{
                    'Id': estadoModal?.valorModificando?.Id,
                    'Nombre': estadoModal?.valorModificando?.Nombre,
                    'CodigoMaria': estadoModal?.valorModificando?.CodigoMaria
                }} onSubmit={insertTipoCertificacion}>
                    {({ submitForm, isSubmitting }) => <>
                        <Modal.Body>
                            <MyForm>
                                <Field type="hidden" name="Id"></Field>
                                <MyFormGroup>
                                    <MyFormControl type="text" name="Nombre" label="Nombre"></MyFormControl>
                                </MyFormGroup>
                                <MyFormGroup>
                                    <MyFormControl type="text" name="CodigoMaria" label="Codigo María"></MyFormControl>
                                </MyFormGroup>
                            </MyForm>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={() => updateEstadoModal({ tipo: 'cerrar' })}>
                                Cancelar
                            </Button>
                            <Button disabled={isSubmitting} onClick={submitForm}>
                                Ingresar
                            </Button>
                        </Modal.Footer>
                    </>}
                </Formik>
            </Modal.Dialog>
        </MyModal>
    </>
}