import Decimal from "decimal.js";
import { isNullOrWhiteSpace } from "./Utilidades";

//base kilogramo
const escalasMasa = {
    '29': new Decimal(1000),
    '01': new Decimal(1),
    '14': new Decimal(1).div(1000),
    '41': new Decimal(1).div(1_000_000),
    '33': new Decimal(1).div(1_000_000_000),
    '34': new Decimal(1).div(1_000_000_000_000),
    '35': new Decimal(1).div(1_000_000_000_000_000)
}

//TODO: las otras escalas
function sePuedeConvertir(codigoDesde: string, codigoHasta: string) {
    if (isNullOrWhiteSpace(codigoDesde) || isNullOrWhiteSpace(codigoHasta)) {
        throw new Error('Codigo desde y codigo hasta no pueden ser vacios');
    }
    if (codigoDesde === codigoHasta) {
        return true;
    }
    return escalasMasa.hasOwnProperty(codigoDesde) && escalasMasa.hasOwnProperty(codigoHasta);
}

function obtenerEscala(codigoDesde: string, codigoHasta: string) {
    if (isNullOrWhiteSpace(codigoDesde) || isNullOrWhiteSpace(codigoHasta)) {
        throw new Error('Codigo desde y codigo hasta no pueden ser vacios');
    }
    if (codigoDesde === codigoHasta) {
        return new Decimal(1);
    }
    let escalaDesde = new Decimal(1);
    let escalaHasta = new Decimal(1);
    if (escalasMasa.hasOwnProperty(codigoDesde) && escalasMasa.hasOwnProperty(codigoHasta)) {
        escalaDesde = escalasMasa[codigoDesde as keyof typeof escalasMasa];
        escalaHasta = escalasMasa[codigoHasta as keyof typeof escalasMasa];
    } else {
        throw new Error(`No hay conversión entre las unidades ${codigoDesde} y ${codigoHasta}`);
    }
    return escalaHasta.div(escalaDesde);
}

const ConversorUnidadMedida = {
    sePuedeConvertir: sePuedeConvertir,
    obtenerEscala: obtenerEscala
};

export default ConversorUnidadMedida;
