import React from "react";
import { useHistory } from "react-router";
import { AppContext, NotFoundComponent } from "../../App";
import * as Yup from "yup";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import { MyAsyncSelect, MyForm, MyFormControl, MySelect, SelectOption } from "../../FormikHooks";
import { useDecodedParams, isInRole, convertirDatosGenericosAOption, isNullOrWhiteSpace, optionLabelConCodigo, } from "../../Utilidades";
import { DateTime } from "luxon";
import { useApi, TipoLock } from "ApiHooks";

export function CrearEditarReglaValidacion() {
    let formikRef = React.useRef<FormikProps<any>>(null);
    let { id } = useDecodedParams();
    let { mostrarError, userInfo } = React.useContext(AppContext);
    let [cargando, updateCargando] = React.useState(true);
    let [regla, updateRegla] = React.useState<any>({});
    let [mensajeErrorAlert, updateMensajeErrorAlert] = React.useState('');
    let [notFound, updateNotFound] = React.useState(false);
    let schema = Yup.object({
        'Subregimen': Yup.string().nullable(),
        'CuitImportador': Yup.string().nullable(),
        'CuitDespachante': Yup.string().nullable(),
        'CodigoArticulo': Yup.string().nullable(),
        'Ncm': Yup.string().nullable(),
        'NcmTieneCAPE': Yup.string().nullable(),
        'NcmTieneRegimenAutomotriz': Yup.string().nullable(),
        'NcmTieneLNA': Yup.string().nullable(),
        'PaisOrigen': Yup.string().nullable(),
        'PaisProcedencia': Yup.string().nullable(),
        'Ventaja': Yup.string().nullable(),
        'Lista': Yup.string().nullable(),
        'InformacionAdicional': Yup.string().nullable(),
    });
    let history = useHistory();
    let api = useApi();
    let boolAString = (valor: any) => {
        if (valor === null || valor === undefined) {
            return '';
        } else if (valor) {
            return 'S';
        } else {
            return 'N';
        }
    }
    let stringABool = (valor: string | null | undefined) => {
        if (valor === 'S') {
            return true;
        } else if (valor === 'N') {
            return false;
        } else {
            return null;
        }
    }
    React.useEffect(() => {
        async function cargar() {
            try {
                if (id) {
                    let respuesta = await api.getReglaValidacion(id);
                    if (!respuesta) {
                        updateNotFound(true);
                        return;
                    }
                    updateRegla(respuesta);
                    updateCargando(false);
                    formikRef.current?.resetForm({
                        values: {
                            'Subregimen': respuesta.Subregimen,
                            'CuitImportador': respuesta.CuitImportador,
                            'CuitDespachante': respuesta.CuitDespachante,
                            'CodigoArticulo': respuesta.CodigoArticulo,
                            'Ncm': respuesta.Ncm,
                            'NcmTieneCAPE': boolAString(respuesta.NcmTieneCAPE),
                            'NcmTieneRegimenAutomotriz': boolAString(respuesta.NcmTieneRegimenAutomotriz),
                            'NcmTieneLNA': boolAString(respuesta.NcmTieneLNA),
                            'PaisOrigen': respuesta.PaisOrigen,
                            'PaisProcedencia': respuesta.PaisProcedencia,
                            'Ventaja': respuesta.Ventaja,
                            'Lista': respuesta.Lista,
                            'InformacionAdicional': respuesta.InformacionAdicional,
                        }
                    });
                } else {
                    updateCargando(false);
                }
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar regla validacion', error);
                    mostrarError('Error al cargar regla validación');
                }
            }
        }
        cargar();
        //eslint-disable-next-line 
    }, []);
    async function cargarDespachantes() {
        try {
            let respuesta = await api.getDespachantes();
            return respuesta.map((despachante: any) => ({ label: despachante.Nombre, value: despachante.CUIT }));
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar despachantes');
                mostrarError('Error al cargar despachantes');
            }
            return [];
        }
    }
    async function cargarImportadores() {
        try {
            let respuesta = await api.getImportadores();
            return respuesta.map((importador: any) => ({ label: importador.Nombre, value: importador.CUIT }));
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar clientes');
                mostrarError('Error al cargar clientes');
            }
            return [];
        }
    }
    async function submit(values: any, actions: FormikHelpers<any>) {
        if (isNullOrWhiteSpace(values.Subregimen) && isNullOrWhiteSpace(values.Ncm) &&
            isNullOrWhiteSpace(values.CuitImportador) && isNullOrWhiteSpace(values.CuitDespachante) &&
            isNullOrWhiteSpace(values.CodigoArticulo) && isNullOrWhiteSpace(values.NcmTieneCAPE) &&
            isNullOrWhiteSpace(values.NcmTieneCAPE) && isNullOrWhiteSpace(values.NcmTieneRegimenAutomotriz) &&
            isNullOrWhiteSpace(values.NcmTieneLNA) && isNullOrWhiteSpace(values.PaisOrigen) &&
            isNullOrWhiteSpace(values.PaisProcedencia)) {
            updateMensajeErrorAlert('Debe ingresar alguno de los siguientes campos: Despachante,Importador,Subregimen,Codigo Articulo,NCM,NCM Tiene CAPE,NCM Tiene Reg. Automotriz,NCM Tiene LNA, Pais Origen, Pais Procedencia');
            actions.setSubmitting(false);
        } else if (isNullOrWhiteSpace(values.Ventaja) && isNullOrWhiteSpace(values.Lista) &&
            isNullOrWhiteSpace(values.InformacionAdicional)) {
            updateMensajeErrorAlert('Debe ingresar alguno de los siguientes campos: Ventaja, Lista, Información Adicional');
            actions.setSubmitting(false);
        } else {
            let nuevaRegla;
            let boolNcmTieneCape = stringABool(values.NcmTieneCAPE);
            let boolNcmTieneRegimenAutomotriz = stringABool(values.NcmTieneRegimenAutomotriz);
            let boolNcmTieneLna = stringABool(values.NcmTieneLNA);
            if (id) {
                nuevaRegla = {
                    ...values,
                    NroClienteAlpha: userInfo.nroClienteAlpha,
                    Id: id,
                    NcmTieneCAPE: boolNcmTieneCape,
                    NcmTieneRegimenAutomotriz: boolNcmTieneRegimenAutomotriz,
                    NcmTieneLNA: boolNcmTieneLna,
                    CreadoPor: regla.CreadoPor,
                    CreadoEl: regla.CreadoEl,
                    ModificadoPor: userInfo.claims.nameid,
                    ModificadoEl: DateTime.local().toISO(),
                };
            } else {
                nuevaRegla = {
                    ...values,
                    NroClienteAlpha: userInfo.nroClienteAlpha,
                    NcmTieneCAPE: boolNcmTieneCape,
                    NcmTieneRegimenAutomotriz: boolNcmTieneRegimenAutomotriz,
                    NcmTieneLNA: boolNcmTieneLna,
                    CreadoPor: userInfo.claims.nameid,
                    CreadoEl: DateTime.local().toISO(),
                    ModificadoPor: userInfo.claims.nameid,
                    ModificadoEl: DateTime.local().toISO(),
                };
            }
            try {
                let tieneLock = true;
                if (id) {
                    tieneLock = await api.obtenerLock(TipoLock.ReglaValidacionMaria, id);
                }
                if (tieneLock) {
                    let { exito, error } = await api.insertReglaValidacion(nuevaRegla);
                    if (exito) {
                        if (id) {
                            await api.eliminarLock(TipoLock.ReglaValidacionMaria, id);
                        }
                        history.replace('/reglasValidacion');
                    } else {
                        updateMensajeErrorAlert(error as string);
                    }
                } else {
                    updateMensajeErrorAlert('No se pudo modificar la regla porque otro usuario la está modificando');
                }
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al insertar regla de validación', error);
                    updateMensajeErrorAlert('Error al guardar regla de validación');
                }
            }
        }
    }
    let esOperador = isInRole(userInfo, 'Operador');
    return notFound ? (<NotFoundComponent></NotFoundComponent>) : (<>
        <h2>{id ? 'Modificar Regla Validación' : 'Crear Regla Validación'}</h2>
        {mensajeErrorAlert && (<Alert variant="danger">{mensajeErrorAlert}</Alert>)}
        <Formik initialValues={{}} innerRef={formikRef} validationSchema={schema} onSubmit={submit}>
            <MyForm blocking={cargando}>
                <Form.Group>
                    <MySelect isDisabled={esOperador} autoFocus name="Subregimen" label="Subregimen" options={() => api.getSubregimenes().then(convertirDatosGenericosAOption)}
                        getOptionLabel={(option: SelectOption) => option.value}></MySelect>
                </Form.Group>
                <Form.Group>
                    <MySelect isDisabled={esOperador} name="CuitDespachante" label="Despachante" options={cargarDespachantes}></MySelect>
                </Form.Group>
                <Form.Group>
                    <MySelect isDisabled={esOperador} name="CuitImportador" label="Cliente" options={cargarImportadores}></MySelect>
                </Form.Group>
                <Form.Group>
                    <MyFormControl disabled={esOperador} name="CodigoArticulo" label="Codigo Articulo" type="text"></MyFormControl>
                </Form.Group>
                <Form.Group>
                    <MyAsyncSelect isDisabled={esOperador} name="Ncm" label="Ncm" loadOptions={api.cargarNcm()} />
                </Form.Group>
                <Form.Group>
                    <MySelect isDisabled={esOperador} name="NcmTieneCAPE" label="Ncm Tiene CAPE" options={[{ value: 'S', label: 'Sí' }, { value: 'N', label: 'No' }]} />
                </Form.Group>
                <Form.Group>
                    <MySelect isDisabled={esOperador} name="NcmTieneRegimenAutomotriz" label="Ncm Tiene Reg. Automotriz" options={[{ value: 'S', label: 'Sí' }, { value: 'N', label: 'No' }]} />
                </Form.Group>
                <Form.Group>
                    <MySelect isDisabled={esOperador} name="NcmTieneLNA" label="Ncm Tiene LNA" options={[{ value: 'S', label: 'Sí' }, { value: 'N', label: 'No' }]} />
                </Form.Group>
                <Form.Group>
                    <MySelect name="PaisOrigen" isDisabled={esOperador} label="Pais Origen" options={() => api.getPaises().then(convertirDatosGenericosAOption)}
                        getOptionLabel={optionLabelConCodigo} />
                </Form.Group>
                <Form.Group>
                    <MySelect isDisabled={esOperador} name="PaisProcedencia" label="Pais Procedencia" options={() => api.getPaises().then(convertirDatosGenericosAOption)}
                        getOptionLabel={optionLabelConCodigo} />
                </Form.Group>
                <Form.Group>
                    <MySelect isDisabled={esOperador} name="Ventaja" label="Codigo Ventaja"
                        options={() => api.getVentajas().then(convertirDatosGenericosAOption)}
                        getOptionLabel={(option: SelectOption) => option.value} />
                </Form.Group>
                <Form.Group>
                    <MySelect isDisabled={esOperador} name="Lista" label="Lista"
                        options={() => api.getLista().then(convertirDatosGenericosAOption)}
                        getOptionLabel={(option: SelectOption) => option.value} />
                </Form.Group>
                <Form.Group>
                    <MySelect isDisabled={esOperador} name="InformacionAdicional" label="Codigo Inf. Adicional"
                        options={() => api.getInformacionComplementaria().then(convertirDatosGenericosAOption)}
                        getOptionLabel={(option: SelectOption) => option.value} />
                </Form.Group>
                <Button variant="danger" className="mb-2" onClick={() => history.goBack()}>Cancelar</Button>
                <Button type="submit" className="ml-2 mb-2" disabled={esOperador}>Guardar</Button>
            </MyForm>
        </Formik>
    </>);
}