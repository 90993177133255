import React from "react";
import { AppContext } from "App";
import { isNullOrWhiteSpace } from "Utilidades";
import { MyModal } from "MyModal";
import { Modal, Button, Form } from "react-bootstrap";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { MyForm, MyFormControl, SelectOption, MySelect } from "FormikHooks";
import { useApi } from "ApiHooks";
enum EstadoModal {
    Cerrado,
    CargandoTipos,
    Cargando,
    Abierto
}

export type ResetearContadorAutonumericoRef = { mostrar: (conTipo: boolean) => void };

export const ResetearContadorAutonumerico = React.forwardRef((props: any, ref: any) => {
    let { mostrarError } = React.useContext(AppContext);
    let formikResetearContadorRef = React.useRef<FormikProps<any>>(null);
    let api = useApi();
    let [mostrarModalExito, setMostrarModalExito] = React.useState(false);
    let [estado, updateEstado] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrar') {
            return { estadoModal: EstadoModal.CargandoTipos, tipos: [], conTipo: accion.conTipo };
        } else if (accion.tipo === 'setTipos') {
            return { ...estado, estadoModal: EstadoModal.Abierto, tipos: accion.valor ?? [] };
        } else if (accion.tipo === 'cerrar') {
            return { estadoModal: EstadoModal.Cerrado, tipos: [], conTipo: false };
        } else if (accion.tipo === 'setCargando') {
            return { ...estado, estadoModal: accion.valor ? EstadoModal.Cargando : EstadoModal.Abierto };
        }
        return estado;
    }, { estadoModal: EstadoModal.Cerrado, conTipo: false, tipos: [] });
    async function cargarContador(tipo: string | null | undefined = undefined) {
        let respuesta = await api.getContadorAutonumericoActual(tipo);
        if (respuesta) {
            formikResetearContadorRef.current!.setFieldValue('Valor', respuesta.AutonumericoCaratula + 1);
        }
    }
    React.useEffect(() => {
        if (estado.estadoModal === EstadoModal.CargandoTipos) {
            async function cargarTipos() {
                try {
                    let tipos: SelectOption[] = [];
                    if (estado.conTipo) {
                        let respuesta = await api.getTiposOperacion();
                        if (!respuesta || respuesta.length === 0) {
                            mostrarError('Debe cargar un tipo de operación para establecer un valor inicial cuando la mascara tiene tipo');
                            updateEstado({ tipo: 'cerrar' });
                            return;
                        }
                        tipos = respuesta.map((t: any) => ({ value: t.Codigo, label: t.Descripcion }));
                    } else {
                        await cargarContador();
                    }
                    updateEstado({ tipo: 'setTipos', valor: tipos });
                } catch (error) {
                    if (!api.isCancel(error)) {
                        console.error('Error al cargar tipos de operación', error);
                        mostrarError('Error al cargar tipos de operación');
                        updateEstado({ tipo: 'cerrar' });
                    }
                }
            }
            cargarTipos();
            return () => {
                api.cancelCurrentTokens();
            }
        } else if (estado.estadoModal === EstadoModal.Cargando) {
            return () => {
                api.cancelCurrentTokens();
            }
        }
        //eslint-disable-next-line
    }, [estado.estadoModal]);
    React.useImperativeHandle(ref, () => ({
        mostrar: (conTipo: boolean) => updateEstado({ tipo: 'mostrar', conTipo: conTipo })
    }));
    async function resetearContador({ Tipo, Valor }: { Tipo: string | null | undefined, Valor: number | null }) {
        try {
            await api.resetearContadorAutonumerico(Tipo, Valor);
            updateEstado({ tipo: 'cerrar' });
            setMostrarModalExito(true);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al establecer contador autonumerico', error);
                mostrarError('Error al establecer contador autonumerico');
            }
        }
    }
    return <>
        <MyModal show={estado.estadoModal !== EstadoModal.Cerrado}
            onHide={() => updateEstado({ tipo: 'cerrar' })}>
            <Modal.Dialog size="lg">
                <Modal.Header closeButton>
                    Establecer Contador Autonumerico
                </Modal.Header>
                <Formik initialValues={{ Tipo: null, Valor: null }} validationSchema={Yup.object({
                    'Tipo': Yup.string().nullable().test('validar-tipo', 'Debe seleccionar un tipo para establecer el valor inicial', (valor: string | null | undefined) => {
                        if (estado.conTipo) {
                            return !isNullOrWhiteSpace(valor);
                        } else {
                            return true;
                        }
                    }),
                    'Valor': Yup.number().required('Debe ingresar un número')
                        .typeError('Debe ingresar un número')
                        .integer('Debe ingresar un número entero')
                        .moreThan(0, 'Debe ingresar un número mayor a cero')
                })} onSubmit={resetearContador} innerRef={formikResetearContadorRef}>
                    {({ submitForm, isSubmitting }) => (<>
                        <Modal.Body>
                            <MyForm blocking={estado.estadoModal !== EstadoModal.Abierto}>
                                <Form.Group>
                                    <MySelect options={estado.tipos} name="Tipo" label="Tipo Operación" isDisabled={estado.tipos?.length === 0} onValueChange={async valor => {
                                        try {
                                            updateEstado({ tipo: 'setCargando', valor: true });
                                            let str = valor as string | null | undefined;
                                            if (!isNullOrWhiteSpace(str)) {
                                                await cargarContador(str as string);
                                            }
                                            updateEstado({ tipo: 'setCargando', valor: false });
                                        } catch (error) {
                                            if (!api.isCancel(error)) {
                                                console.error('Error al cargar contador autonumerico actual', error);
                                                updateEstado({ tipo: 'setCargando', valor: false });
                                            }
                                        }
                                    }}></MySelect>
                                    <MyFormControl type="number" name="Valor" label="Valor Inicial"></MyFormControl>
                                </Form.Group>
                            </MyForm>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={() => updateEstado({ tipo: 'cerrar' })}>
                                Cancelar
                            </Button>
                            <Button disabled={isSubmitting || estado.estadoModal !== EstadoModal.Abierto} onClick={submitForm}>Establecer Contador</Button>
                        </Modal.Footer>
                    </>)}
                </Formik>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={mostrarModalExito} onHide={() => setMostrarModalExito(false)}>
            <Modal.Dialog>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <p className="lead text-success">Se ha establecido el contador exitosamente</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setMostrarModalExito(false)}>Cerrar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
    </>
});