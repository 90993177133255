import React, { useMemo } from "react";
import ModalContext from "react-bootstrap/esm/ModalContext";
import { Modal } from 'react-overlays';
import { ModalProps, ModalHandle } from "react-overlays/cjs/Modal";
import styled from 'styled-components';
import useEventCallback from "@restart/hooks/useEventCallback";
import { Modal as BootstrapModal, Button } from "react-bootstrap";
import Loader from "react-loaders";

const Backdrop = styled("div")`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;

const BaseModal = styled("div")`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow:auto;
`;


//El modal de react-bootstrap no soporta varios modal al mismo tiempo, por eso se usa este modal custom
export function MyModal(props: JSX.IntrinsicAttributes & ModalProps & React.RefAttributes<ModalHandle>) {
  const handleHide = useEventCallback(props.onHide);
  let memo = useMemo(() => ({ onHide: handleHide }), [handleHide]);
  let renderBackdrop = (propsBack: any) => (<Backdrop {...propsBack}></Backdrop>);
  let renderDialog = (propsDialog: any) => (
    <ModalContext.Provider value={memo}>
      <BaseModal role="dialog" {...propsDialog}>
        {props.children}
      </BaseModal >
    </ModalContext.Provider>
  )
  return (<Modal {...props} renderBackdrop={renderBackdrop} container={document.querySelector('body')}
    renderDialog={renderDialog}></Modal>);
}

export function MySpinnerModal(props: {
  mostrar: boolean,
  mensaje: string,
  onCancel?: () => void
}) {
  return <MyModal show={props.mostrar}>
    <BootstrapModal.Dialog>
      <BootstrapModal.Body>
        <p className="lead">{props.mensaje}</p>
        <div className="loader-container">
          <Loader type="ball-spin-fade-loader" active></Loader>
        </div>
      </BootstrapModal.Body>
      <BootstrapModal.Footer>
        <Button variant="danger" disabled={!props.onCancel} onClick={props.onCancel}>Cancelar</Button>
      </BootstrapModal.Footer>
    </BootstrapModal.Dialog>
  </MyModal>
}