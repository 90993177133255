import React from "react";
import { useHistory } from "react-router";
import { AppContext, NotFoundComponent } from "App";
import * as Yup from "yup";
import { Alert, Button, Form, Col, Container } from "react-bootstrap";
import { Formik, FormikProps } from "formik";
import { MyForm, MyFormControl, MySelect } from "../../FormikHooks";
import { convertirDatosGenericosAOption, isInRole, useDecodedParams } from "../../Utilidades";
import { DateTime } from "luxon";
import { useApi, TipoLock } from "ApiHooks";
export function CrearEditarVendedor() {
    let { mostrarError, userInfo } = React.useContext(AppContext);
    let { codigo } = useDecodedParams();
    let history = useHistory();
    let [cargando, updateCargando] = React.useState(true);
    let vendedor = React.useRef<any>(null);
    let [mensajeErrorAlert, updateMensajeErrorAlert] = React.useState('');
    let [notFound, updateNotFound] = React.useState(false);
    let api = useApi();
    let formikRef = React.useRef<FormikProps<any>>(null);
    let schema = Yup.object({
        'Codigo': Yup.string().nullable().required('Debe ingresar el codigo'),
        'Nombre': Yup.string().nullable().required('Debe ingresar el nombre'),
        'IdTributario': Yup.string().nullable(),
        'Domicilio': Yup.string().nullable(),
        'CodigoPaisExportador': Yup.string().nullable()
    });
    async function submit(values: any) {
        try {
            let nuevoVendedor: any = {
                ...values,
                NroClienteAlpha: userInfo.nroClienteAlpha,
                EmpresaId: userInfo.empresaActual,
                ModificadoPor: userInfo.claims.nameid,
                ModificadoEl: DateTime.local().toISO()
            };
            if (vendedor.current) {
                nuevoVendedor.CreadoPor = vendedor.current!.CreadoPor;
                nuevoVendedor.CreadoEl = vendedor.current!.CreadoEl;
            } else {
                nuevoVendedor.CreadoPor = userInfo.claims.nameid;
                nuevoVendedor.CreadoEl = DateTime.local().toISO();
            }
            let puedeModificar = await api.obtenerLock(TipoLock.Vendedor, values.Codigo);
            if (!puedeModificar) {
                updateMensajeErrorAlert('No se pudo modificar el vendedor porque otra persona lo está modificando');
                return;
            }
            let { exito, error } = await api.insertVendedor(nuevoVendedor);
            if (vendedor.current) {
                await api.eliminarLock(TipoLock.Vendedor, vendedor.current.Codigo);
            }
            if (exito) {
                history.replace('/vendedores');
            } else {
                mostrarError(error as string);
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al insertar vendedor');
                mostrarError('Error al insertar vendedor');
            }
        }
    }
    React.useEffect(() => {
        async function cargar() {
            try {
                if (codigo) {
                    vendedor.current = await api.getVendedor(codigo);
                    if (!vendedor.current) {
                        updateNotFound(true);
                        return;
                    }
                    formikRef.current?.resetForm({
                        values: {
                            'Codigo': vendedor.current.Codigo,
                            'Nombre': vendedor.current.Nombre,
                            'CodigoPaisExportador': vendedor.current.CodigoPaisExportador,
                            'IdTributario': vendedor.current.IdTributario,
                            'Domicilio': vendedor.current.Domicilio
                        }
                    });
                }
                updateCargando(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar vendedor', error);
                    mostrarError('Error al cargar vendedor');
                }
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);
    let esOperador = isInRole(userInfo, 'Operador');
    return notFound ? (<NotFoundComponent></NotFoundComponent>) : (<>
        <Container>
            <h2>{codigo ? 'Modificar vendedor' : 'Crear vendedor'}</h2>
            {mensajeErrorAlert && (<Alert variant="danger">{mensajeErrorAlert}</Alert>)}
            <Formik initialValues={{}} validationSchema={schema} innerRef={formikRef} onSubmit={submit}>
                <MyForm blocking={cargando} submitEnUltimoElemento>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <MyFormControl autoFocus disabled={esOperador} readOnly={!!codigo} name="Codigo" type="text" label="Codigo"></MyFormControl>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <MyFormControl disabled={esOperador} name="IdTributario" type="text" label="Id Tributario"></MyFormControl>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <MyFormControl disabled={esOperador} name="Nombre" type="text" label="Nombre"></MyFormControl>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <MyFormControl disabled={esOperador} name="Domicilio" type="text" label="Domicilio"></MyFormControl>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <MySelect isDisabled={esOperador} name="CodigoPaisExportador" label="Pais Exportador"
                                options={() => api.getPaises().then(convertirDatosGenericosAOption)} />
                        </Form.Group>
                    </Form.Row>
                    <Button variant="danger" className="mt-2 mb-2" onClick={() => history.goBack()}>Cancelar</Button>
                    <Button disabled={esOperador} onClick={() => {
                        formikRef.current?.submitForm();
                    }} className="mt-2 ml-2 mb-2">Guardar</Button>
                </MyForm>
            </Formik>
        </Container>
    </>);
}