import React from "react";
import { useHistory } from "react-router";
import { AppContext, NotFoundComponent } from "../../App";
import * as Yup from "yup";
import { Formik, FormikProps, FormikHelpers, Field } from "formik";
import { Alert, Button, Col, Form, Modal, Tab, Tabs } from "react-bootstrap";
import { MyAsyncSelect, MyForm, MyFormCheck, MyFormControl, MySelect, MySelectRef, OnValueChangeArgument, SelectOption, MyTextarea } from "../../FormikHooks";
import { IngresoSufijos, IngresoSufijosRef } from "../../IngresoSufijos";
import { MyModal } from "../../MyModal";
import { useDecodedParams, cargarDatosGrillaDeArray, convertirDatosGenericosAOption, optionLabelConCodigo, isInRole } from "../../Utilidades";
import Grilla, { GrillaRef } from "../../Grilla";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import { useApi, TipoLock } from "ApiHooks";
//eslint-disable-next-line
import { ArchivosAdjuntosArticuloCatalogo, ArchivosAdjuntosArticuloCatalogoRef } from "./ArchivosAdjuntosArticuloCatalogo";

export function CrearEditarArticuloCatalogo() {
    let formikRef = React.useRef<FormikProps<any>>(null);
    let formikVentajasRef = React.useRef<FormikProps<any>>(null);
    let formikInfCompRef = React.useRef<FormikProps<any>>(null);
    let formikObservacionesRef = React.useRef<FormikProps<any>>(null);
    let formikIntervencionesRef = React.useRef<FormikProps<any>>(null);
    let archivosAdjuntosRef = React.useRef<ArchivosAdjuntosArticuloCatalogoRef>(null);
    let grillaVentajasRef = React.useRef<GrillaRef>(null);
    let grillaInfCompsRef = React.useRef<GrillaRef>(null);
    let grillaObservacionesRef = React.useRef<GrillaRef>(null);
    let grillaIntervencionesRef = React.useRef<GrillaRef>(null);
    let sufijosRef = React.useRef<IngresoSufijosRef>(null);
    let selectIntervencionesRef = React.useRef<MySelectRef>(null);
    let dialogoRef = React.useRef<DialogoConfirmarRef>(null);
    let { codigoCatalogo, codigoArticulo } = useDecodedParams();
    let { mostrarError, userInfo } = React.useContext(AppContext);
    let [cargando, updateCargando] = React.useState(true);
    let [marca, updateMarca] = React.useState('');
    let [articulo, updateArticulo] = React.useState<any>({});
    let [mensajeErrorAlert, updateMensajeErrorAlert] = React.useState('');
    let [mensajeUnidadEstadistica, updateMensajeUnidadEstadistica] = React.useState('');
    let [tabActual, updateTabActual] = React.useState('principal');
    let [notFound, updateNotFound] = React.useState(false);
    let [mensajeDialogo, updateMensajeDialogo] = React.useState('');
    let [estadoVentajas, updateEstadoVentajas] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'inicializar') {
            return { ...estado, ventajas: accion.ventajas };
        } else if (accion.tipo === 'insertVentaja') {
            let nuevasVentajas = Array.from(estado.ventajas);
            if (estado.valorModificando) {
                let indice = nuevasVentajas.findIndex((item: any) => item.Codigo === estado.valorModificando.Codigo);
                nuevasVentajas.splice(indice, 1);
            }
            nuevasVentajas.push(accion.valor);
            return { ...estado, ventajas: nuevasVentajas, creando: false, modificando: false, valorModificando: null };
        } else if (accion.tipo === 'deleteVentaja') {
            let nuevasVentajas = Array.from(estado.ventajas);
            let indice = nuevasVentajas.findIndex((item: any) => item.Codigo === accion.valor);
            if (indice > -1) {
                nuevasVentajas.splice(indice, 1);
            }
            return { ...estado, ventajas: nuevasVentajas, creando: false, modificando: false, valorModificando: null };
        }
        else if (accion.tipo === 'ventanaCrear') {
            return { ...estado, creando: true, modificando: false, valorModificando: null };
        } else if (accion.tipo === 'ventanaModificar') {
            return { ...estado, creando: false, modificando: true, valorModificando: accion.valor };
        } else if (accion.tipo === 'esconderVentana') {
            return { ...estado, creando: false, modificando: false, valorModificando: null };
        }
        return estado;
    }, { ventajas: [], creando: false, modificando: false, valorModificando: null });
    let [estadoInfComps, updateEstadoInfComps] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'inicializar') {
            return { ...estado, infComps: accion.infComps };
        } else if (accion.tipo === 'insertInfComp') {
            let nuevasInfComps = Array.from(estado.infComps);
            if (estado.valorModificando) {
                let indice = nuevasInfComps.findIndex((item: any) => item.Codigo === estado.valorModificando.Codigo);
                nuevasInfComps.splice(indice, 1);
            }
            nuevasInfComps.push(accion.valor);
            return { ...estado, infComps: nuevasInfComps, creando: false, modificando: false, valorModificando: null };
        } else if (accion.tipo === 'deleteInfComp') {
            let nuevasInfComps = Array.from(estado.infComps);
            let indice = nuevasInfComps.findIndex((item: any) => item.Codigo === accion.valor);
            if (indice > -1) {
                nuevasInfComps.splice(indice, 1);
            }
            return { ...estado, infComps: nuevasInfComps, creando: false, modificando: false, valorModificando: null };
        }
        else if (accion.tipo === 'ventanaCrear') {
            return { ...estado, creando: true, modificando: false, valorModificando: null };
        } else if (accion.tipo === 'ventanaModificar') {
            return { ...estado, creando: false, modificando: true, valorModificando: accion.valor };
        } else if (accion.tipo === 'esconderVentana') {
            return { ...estado, creando: false, modificando: false, valorModificando: null };
        }
        return estado;
    }, { infComps: [], creando: false, modificando: false, valorModificando: null });
    let [estadoObservaciones, updateObservaciones] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'inicializar') {
            return {
                ...estado, observaciones: accion.observaciones.map((item: string, indice: number) => ({
                    Indice: indice, Valor: item
                }))
            };
        } else if (accion.tipo === 'insertObservacion') {
            let nuevasObservaciones = estado.observaciones.map((observacion: any) => observacion.Valor);
            if (estado.indiceModificando !== null && estado.indiceModificando !== undefined) {
                nuevasObservaciones.splice(estado.indiceModificando, 1);
            }
            nuevasObservaciones.push(accion.valor);
            return {
                ...estado, observaciones: nuevasObservaciones.map((item: string, indice: number) => ({
                    Indice: indice, Valor: item
                })), creando: false, modificando: false, indiceModificando: null
            };
        } else if (accion.tipo === 'deleteObservacion') {
            let nuevasObservaciones = estado.observaciones.map((observacion: any) => observacion.Valor);
            nuevasObservaciones.splice(accion.valor, 1);
            return {
                ...estado, observaciones: nuevasObservaciones.map((item: string, indice: number) => ({
                    Indice: indice, Valor: item
                })), creando: false, modificando: false, indiceModificando: null
            };
        }
        else if (accion.tipo === 'ventanaCrear') {
            return { ...estado, creando: true, modificando: false, indiceModificando: null };
        } else if (accion.tipo === 'ventanaModificar') {
            return { ...estado, creando: false, modificando: true, indiceModificando: accion.valor };
        } else if (accion.tipo === 'esconderVentana') {
            return { ...estado, creando: false, modificando: false, indiceModificando: null };
        }
        return estado;
    }, { observaciones: [], creando: false, modificando: false, indiceModificando: null });
    let [estadoIntervenciones, updateIntervenciones] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'setIntervencionesArticulo') {
            let intervencionesNcm = estado.intervencionesNcm.map((item: string) => ({ Descripcion: item }));
            return {
                ...estado, intervencionesArticulo: accion.intervenciones,
                listaGrilla: intervencionesNcm.concat(accion.intervenciones).map((item: any, indice: number) => ({ ...item, Indice: indice }))
            };
        } else if (accion.tipo === 'setIntervencionesNcm') {
            return {
                ...estado, intervencionesNcm: accion.intervenciones,
                listaGrilla: accion.intervenciones.map((item: string) => ({ Descripcion: item })).concat(estado.intervencionesArticulo).map((item: any, indice: number) => ({ ...item, Indice: indice }))
            };
        } else if (accion.tipo === 'insertIntervencion') {
            let nuevasIntervenciones = Array.from(estado.intervencionesArticulo);
            let indice = nuevasIntervenciones.findIndex((int: any) => int.Id === accion.valor.Id);
            if (indice === -1) {
                nuevasIntervenciones.push(accion.valor);
            }
            let intervencionesNcm = estado.intervencionesNcm.map((item: string) => ({ Descripcion: item }));
            return {
                ...estado, creando: false,
                intervencionesArticulo: nuevasIntervenciones,
                listaGrilla: intervencionesNcm.concat(nuevasIntervenciones).map((item: any, indice: number) => ({ ...item, Indice: indice }))
            };
        } else if (accion.tipo === 'deleteIntervencion') {
            let nuevasIntervenciones = Array.from(estado.intervencionesArticulo);
            let indice = nuevasIntervenciones.findIndex((int: any) => int.Id === accion.valor);
            if (indice > -1) {
                nuevasIntervenciones.splice(indice, 1);
            }
            let intervencionesNcm = estado.intervencionesNcm.map((item: string) => ({ Descripcion: item }));
            return {
                ...estado, creando: false,
                intervencionesArticulo: nuevasIntervenciones,
                listaGrilla: intervencionesNcm.concat(nuevasIntervenciones).map((item: any, indice: number) => ({ ...item, Indice: indice }))
            };
        }
        else if (accion.tipo === 'ventanaCrear') {
            return { ...estado, creando: true };
        } else if (accion.tipo === 'esconderVentana') {
            return { ...estado, creando: false };
        }
        return estado;
    }, { intervencionesArticulo: [], intervencionesNcm: [], listaGrilla: [], creando: false });
    let schema = Yup.object({
        'ForzarSufijos': Yup.boolean(),
        'Redirigir': Yup.boolean(),
        'CodigoCatalogo': Yup.string().nullable().required('Debe ingresar el codigo de catalogo'),
        'CodigoArticulo': Yup.string().nullable().required('Debe ingresar el codigo de articulo'),
        'Descripcion': Yup.string().nullable(),
        'DescripcionMaria': Yup.string().nullable(),
        'Ncm': Yup.string().nullable().required('Debe ingresar el NCM'),
        'CodigoUnidadDeclarada': Yup.string().nullable(),
        'CodigoPaisOrigen': Yup.string().nullable(),
        'Sufijos': Yup.string().nullable(),
        'CodigoEstadoMercaderia': Yup.string().nullable(),
        'PrecioUnitario': Yup.number().nullable().typeError('Debe ingresar un número')
            .min(0, 'El precio unitario debe ser mayor a 0'),
        'KgNetoUnitario': Yup.number().nullable().typeError('Debe ingresar un número')
            .min(0, 'El kg neto unitario debe ser mayor o igual a 0'),
        'BienUso': Yup.string().nullable(),
        'Otros': Yup.string().nullable(),
        'CertificadoOrigen': Yup.boolean().nullable(),
        'Naladisa': Yup.string().nullable(),
        'Estado': Yup.string().nullable()
    });
    let history = useHistory();
    let api = useApi();
    React.useEffect(() => {
        async function cargar() {
            try {
                if (codigoCatalogo && codigoArticulo) {
                    let catalogo = await api.getCatalogo(codigoCatalogo);
                    if (!catalogo) {
                        updateNotFound(true);
                        return;
                    }
                    updateMarca(catalogo.SufijoAA ?? '');
                    let respuesta = await api.getArticulo(codigoCatalogo, codigoArticulo);
                    if (!respuesta) {
                        updateNotFound(true);
                        return;
                    }
                    updateArticulo(respuesta.Articulo);
                    updateIntervenciones({ tipo: 'setIntervencionesArticulo', intervenciones: respuesta.Intervenciones ?? [] });
                    updateEstadoVentajas({ tipo: 'inicializar', ventajas: respuesta.Articulo?.Ventajas ?? [] });
                    updateEstadoInfComps({ tipo: 'inicializar', infComps: respuesta.Articulo?.InformacionComplementaria ?? [] });
                    updateObservaciones({ tipo: 'inicializar', observaciones: respuesta.Articulo?.Observaciones ?? [] });
                    updateCargando(false);
                    formikRef.current?.resetForm({
                        values: {
                            'ForzarSufijos': false,
                            'CodigoCatalogo': respuesta.Articulo.CodigoCatalogo,
                            'CodigoArticulo': respuesta.Articulo.CodigoArticulo,
                            'Ncm': respuesta.Articulo.Ncm,
                            'Descripcion': respuesta.Articulo.Descripcion,
                            'DescripcionMaria': respuesta.Articulo.DescripcionMaria,
                            'KgNetoUnitario': respuesta.Articulo.KgNetoUnitario,
                            'CodigoUnidadDeclarada': respuesta.Articulo.CodigoUnidadDeclarada,
                            'CodigoPaisOrigen': respuesta.Articulo.CodigoPaisOrigen,
                            'Sufijos': respuesta.Articulo.Sufijos,
                            'CodigoEstadoMercaderia': respuesta.Articulo.CodigoEstadoMercaderia,
                            'PrecioUnitario': respuesta.Articulo.PrecioUnitario,
                            'BienUso': respuesta.Articulo.BienUso,
                            'Otros': respuesta.Articulo.Otros,
                            'CertificadoOrigen': respuesta.Articulo.CertificadoOrigen,
                            'Naladisa': respuesta.Articulo.Naladisa,
                            'Estado': respuesta.Articulo.Estado
                        }
                    });
                    cargarUnidadEstadisticaEIntervenciones(respuesta.Articulo.Ncm);
                } else {
                    updateCargando(false);
                }
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar articulo', error);
                    mostrarError('Error al cargar articulo');
                }
            }
        }
        cargar();
        //eslint-disable-next-line 
    }, []);
    React.useEffect(() => {
        grillaVentajasRef.current?.recargar();
    }, [estadoVentajas.ventajas]);
    React.useEffect(() => {
        grillaInfCompsRef.current?.recargar();
    }, [estadoInfComps.infComps]);
    React.useEffect(() => {
        grillaObservacionesRef.current?.recargar();
    }, [estadoObservaciones.observaciones]);
    React.useEffect(() => {
        grillaIntervencionesRef.current?.recargar();
    }, [estadoIntervenciones.listaGrilla]);
    function submit(values: any, helpers: FormikHelpers<any>) {
        let { ForzarSufijos, Redirigir, ...values2 } = values;
        return sufijosRef.current?.mostrar(!ForzarSufijos, values2.Ncm, values2.Sufijos, marca, values2.CodigoArticulo)
            .then(async sufijosFinal => {
                let nuevoArticulo = {
                    ...articulo,
                    ...values2,
                    Sufijos: sufijosFinal,
                    Ventajas: Array.from(estadoVentajas.ventajas),
                    InformacionComplementaria: Array.from(estadoInfComps.infComps),
                    Observaciones: estadoObservaciones.observaciones.map((item: any) => item.Valor),
                    IdsIntervencion: estadoIntervenciones.intervencionesArticulo.map((item: any) => item.Id)
                };
                try {
                    updateCargando(true);
                    let tieneLock = await api.obtenerLock(TipoLock.Catalogo, nuevoArticulo.CodigoCatalogo);
                    if (tieneLock) {
                        let { exito, error } = await api.insertArticulo(nuevoArticulo);
                        if (exito) {
                            await api.eliminarLock(TipoLock.Catalogo, nuevoArticulo.CodigoCatalogo);
                            if (Redirigir) {
                                history.replace('/catalogos/articulos/' + encodeURIComponent(nuevoArticulo.CodigoCatalogo));
                            } else {
                                updateArticulo(nuevoArticulo);
                                updateCargando(false);
                            }
                            return { exito: true, articulo: nuevoArticulo };
                        } else {
                            updateMensajeErrorAlert(error);
                            updateCargando(false);
                            return { exito: false };
                        }
                    } else {
                        updateMensajeErrorAlert('No se pudo modificar el catalogo porque otro usuario lo está usando');
                        updateCargando(false);
                        return { exito: false };
                    }
                } catch (error) {
                    if (!api.isCancel(error)) {
                        console.error('Error al insertar articulo', error);
                        updateMensajeErrorAlert('Error al guardar articulo');
                        updateCargando(false);
                    }
                    return { exito: false };
                }
            }).catch(() => ({ exito: false }));
    }
    async function cargarUnidadEstadisticaEIntervenciones(ncm: string) {
        try {
            let respuesta = await api.validarNcmCompleto(ncm);
            if (respuesta) {
                formikRef.current?.setFieldValue('Ncm', respuesta.Ncm.POSICION_ARANCELARIA, true);
                updateIntervenciones({ tipo: 'setIntervencionesNcm', intervenciones: respuesta.Intervenciones ?? [] });
                let codigoUnidadEstadistica = respuesta.Ncm.m012;
                try {
                    let unidades = await api.getUnidades();
                    let unidad = unidades.find((unidad: any) => unidad.Codigo === codigoUnidadEstadistica);
                    if (unidad) {
                        updateMensajeUnidadEstadistica(codigoUnidadEstadistica + ' - ' + unidad.Descripcion);
                    } else {
                        updateMensajeUnidadEstadistica(codigoUnidadEstadistica);
                    }
                } catch (error) {
                    if (!api.isCancel(error)) {
                        console.error('Error al buscar unidad estadistica de ncm', error);
                        updateMensajeUnidadEstadistica(codigoUnidadEstadistica);
                    }
                }
            } else {
                formikRef.current?.setFieldValue('Ncm', '', true);
                updateMensajeUnidadEstadistica('');
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al buscar unidad estadistica de ncm', error);
                formikRef.current?.setFieldValue('Ncm', '', true);
                updateMensajeUnidadEstadistica('');
            }
        }
    }
    async function cargarIntervenciones() {
        try {
            let respuesta = await api.getIntervenciones();
            return respuesta.map((item: any) => ({ value: item.Id, label: item.Descripcion }));
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar intervenciones', error);
                mostrarError('Error al cargar las intervenciones disponibles');
            }
            return [];
        }
    }
    async function cargarEstados() {
        try {
            let respuesta = await api.getEstadosArticulo();
            return respuesta.map((item: any) => ({ value: item.Nombre, label: item.Nombre }));
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar los estados disponibles', error);
                mostrarError('Error al cargar los estados disponibles');
            }
            return [];
        }
    }
    let ventajasSchema = Yup.object({
        'Codigo': Yup.string().nullable().required('Debe seleccionar la ventaja'),
        'Subregimenes': Yup.array().of(Yup.string())
    });
    let infCompsSchema = Yup.object({
        'Codigo': Yup.string().nullable().required('Debe seleccionar el codigo de la información complementaria'),
        'Subregimenes': Yup.array().of(Yup.string()),
        'Valor': Yup.string().nullable()
    });
    let esOperador = isInRole(userInfo, 'Operador');
    return codigoCatalogo && !notFound ?
        (<>
            <h2>{codigoArticulo ? 'Modificar Articulo' : 'Crear Articulo'}</h2>
            {mensajeErrorAlert && (<Alert variant="danger">{mensajeErrorAlert}</Alert>)}
            <Formik initialValues={{
                CodigoCatalogo: codigoCatalogo, CodigoArticulo: codigoArticulo, Ncm: '',
                KgNetoUnitario: 0, ForzarSufijos: false, Redirigir: true
            }} innerRef={formikRef} validationSchema={schema} onSubmit={submit}>
                <MyForm blocking={cargando}>
                    <Field type="hidden" name="ForzarSufijos"></Field>
                    <Field type="hidden" name="Redirigir"></Field>
                    <Tabs id="tabsCrearEditarArticulo" activeKey={tabActual} onSelect={async (selectedTab) => {
                        if (selectedTab) {
                            if (selectedTab === 'archivos') {
                                let errores = await formikRef.current!.validateForm();
                                if (Object.keys(errores).length > 0) {
                                    mostrarError('Hay errores de validación. Debe corregir los errores y guardar el articulo antes de poder entrar a la pestaña de Archivos');
                                } else {
                                    formikRef.current?.setFieldValue('ForzarSufijos', false);
                                    formikRef.current?.setFieldValue('Redirigir', false);
                                    let promesa = formikRef.current!.submitForm() as unknown as Promise<any>;
                                    let { exito, articulo } = await promesa;
                                    if (exito) {
                                        updateTabActual(selectedTab);
                                        archivosAdjuntosRef.current!.cargar(articulo.CodigoCatalogo, articulo.CodigoArticulo);
                                    }
                                }
                            } else {
                                updateTabActual(selectedTab);
                            }
                        }
                    }}>
                        <Tab eventKey="principal" title="Datos">
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <MyFormControl type="text" readOnly name="CodigoCatalogo" label="Catalogo"></MyFormControl>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <MyFormControl disabled={esOperador} autoFocus type="text" name="CodigoArticulo" label="Codigo Articulo" readOnly={!!articulo.CodigoArticulo}></MyFormControl>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <MyTextarea disabled={esOperador} name="Descripcion" label="Descripción Catalogo"></MyTextarea>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <MyTextarea disabled={esOperador} name="DescripcionMaria" label="Descripción SEDI"></MyTextarea>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <MyAsyncSelect isDisabled={esOperador} name="Ncm" label="Posición"
                                        loadOptions={api.cargarNcm()} onValueChange={(option: OnValueChangeArgument) => {
                                            if (option) {
                                                let ncm = option as string;
                                                cargarUnidadEstadisticaEIntervenciones(ncm);
                                            } else {
                                                updateMensajeUnidadEstadistica('');
                                            }
                                        }}></MyAsyncSelect>
                                </Form.Group>
                                <Form.Group as={Col} controlId="unidadEstadistica">
                                    <Form.Label>Unidad Estadistica</Form.Label>
                                    <Form.Control type="text" disabled value={mensajeUnidadEstadistica}></Form.Control>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <MyFormControl disabled={esOperador} type="number" name="PrecioUnitario" label="Precio Unitario"></MyFormControl>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <MyFormControl disabled={esOperador} type="number" name="KgNetoUnitario" label="Kg Neto Unitario"></MyFormControl>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <MySelect isDisabled={esOperador} name="CodigoPaisOrigen" label="Pais Origen/Provincia" options={() => api.getPaises().then(convertirDatosGenericosAOption)}
                                        getOptionLabel={optionLabelConCodigo} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <MySelect isDisabled={esOperador} name="CodigoUnidadDeclarada" label="Unidad Declarada" options={() => api.getUnidades().then(convertirDatosGenericosAOption)}
                                        getOptionLabel={optionLabelConCodigo} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <MySelect isDisabled={esOperador} name="CodigoEstadoMercaderia" label="Estado Mercaderia" options={() => api.getEstadosMercaderia().then(convertirDatosGenericosAOption)}
                                        getOptionLabel={optionLabelConCodigo} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <MySelect isDisabled={esOperador} name="BienUso" label="Bien de Uso" options={[{ value: 'S', label: 'S' }, { value: 'N', label: 'N' }]} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <MyFormControl disabled={esOperador} name="Naladisa" label="Naladisa" type="text"></MyFormControl>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <MyFormControl disabled={esOperador} name="Otros" label="Otros" type="text"></MyFormControl>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <MySelect isDisabled={esOperador} name="Estado" label="Estado Articulo"
                                        options={cargarEstados}></MySelect>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <div style={{
                                        position: 'relative',
                                        top: '2.5em'
                                    }}>
                                        <MyFormCheck disabled={esOperador} name="CertificadoOrigen" label="Certificado Origen"></MyFormCheck>
                                    </div>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <MyTextarea disabled={esOperador} name="Sufijos" label="Sufijos" readOnly
                                        onKeyDown={(e: any) => {
                                            if (!esOperador && e.key === 'F8') {
                                                sufijosRef.current?.mostrar(false, formikRef.current?.values?.Ncm,
                                                    formikRef.current?.values?.Sufijos, marca,
                                                    formikRef.current?.values?.CodigoArticulo)?.then(ingreso => {
                                                        formikRef.current?.setFieldValue('Sufijos', ingreso);
                                                    }).catch(() => { });
                                            }
                                        }}></MyTextarea>
                                </Form.Group>
                            </Form.Row>
                        </Tab>
                        <Tab eventKey="ventajas" title="Ventajas">
                            <div className="mt-2"><Grilla ref={grillaVentajasRef} cargarDatos={cargarDatosGrillaDeArray(estadoVentajas.ventajas)}
                                campos={[{ propiedad: 'Codigo', titulo: 'Codigo Ventaja', clave: true }, {
                                    propiedad: 'Subregimenes', titulo: 'Subregimenes',
                                    plantillaFormato: (subregimenes: any) => subregimenes?.join(',')
                                }]} eventoAgregar={() => updateEstadoVentajas({ tipo: 'ventanaCrear' })}
                                eventoDetalle={(item: any) => updateEstadoVentajas({ tipo: 'ventanaModificar', valor: item })}
                                eventoEliminar={(item: any) => {
                                    updateMensajeDialogo("¿Está seguro que desea eliminar esta ventaja?");
                                    dialogoRef.current!.mostrar()
                                        .then(() => updateEstadoVentajas({ tipo: 'deleteVentaja', valor: item.Codigo })).catch(() => { });
                                }} mostrarFormCambiarPagina={false}
                                deshabilitarBotonAgregar={esOperador}
                                deshabilitarEventoDetalle={esOperador}
                                deshabilitarBotonEliminar={esOperador} /></div>

                        </Tab>
                        <Tab eventKey="infComp" title="Información Complementaria">
                            <div className="mt-2">
                                <Grilla ref={grillaInfCompsRef} cargarDatos={cargarDatosGrillaDeArray(estadoInfComps.infComps)}
                                    campos={[{ propiedad: 'Codigo', titulo: 'Codigo Inf. Comp.', clave: true },
                                    { propiedad: 'Valor', titulo: 'Valor' }, {
                                        propiedad: 'Subregimenes', titulo: 'Subregimenes',
                                        plantillaFormato: (subregimenes: any) => subregimenes?.join(',')
                                    }]} eventoAgregar={() => updateEstadoInfComps({ tipo: 'ventanaCrear' })}
                                    deshabilitarBotonAgregar={esOperador}
                                    eventoDetalle={(item: any) => updateEstadoInfComps({ tipo: 'ventanaModificar', valor: item })}
                                    deshabilitarEventoDetalle={esOperador}
                                    eventoEliminar={(item: any) => {
                                        updateMensajeDialogo("¿Está seguro que desea eliminar esta información complementaria?");
                                        dialogoRef.current!.mostrar()
                                            .then(() => updateEstadoInfComps({ tipo: 'deleteInfComp', valor: item.Codigo })).catch(() => { });
                                    }} deshabilitarBotonEliminar={esOperador} mostrarFormCambiarPagina={false} /></div>
                        </Tab>
                        <Tab eventKey="observaciones" title="Observaciones">
                            <div className="mt-2">
                                <Grilla ref={grillaObservacionesRef} cargarDatos={cargarDatosGrillaDeArray(estadoObservaciones.observaciones)}
                                    campos={[{ propiedad: 'Indice', titulo: '', visible: false, clave: true },
                                    { propiedad: 'Valor', titulo: 'Valor' }]}
                                    eventoAgregar={() => updateObservaciones({ tipo: 'ventanaCrear' })}
                                    deshabilitarBotonAgregar={esOperador}
                                    eventoDetalle={(item: any) => updateObservaciones({ tipo: 'ventanaModificar', valor: item.Indice })}
                                    deshabilitarEventoDetalle={esOperador}
                                    eventoEliminar={(item: any) => {
                                        updateMensajeDialogo("¿Está seguro que desea eliminar esta observación?");
                                        dialogoRef.current!.mostrar()
                                            .then(() => updateObservaciones({ tipo: 'deleteObservacion', valor: item.Indice })).catch(() => { });
                                    }} deshabilitarBotonEliminar={esOperador} mostrarFormCambiarPagina={false} /></div>
                        </Tab>
                        <Tab eventKey="intervenciones" title="Intervenciones">
                            <div className="mt-2">
                                <Grilla ref={grillaIntervencionesRef} cargarDatos={cargarDatosGrillaDeArray(estadoIntervenciones.listaGrilla)}
                                    campos={[{ propiedad: 'Indice', titulo: '', visible: false, clave: true }, {
                                        propiedad: 'Descripcion', titulo: 'Intervencion',
                                        plantillaFormato: (valor: any, item: any) => item.Id ? valor : `* ${valor} *`
                                    }]}
                                    eventoAgregar={() => updateIntervenciones({ tipo: 'ventanaCrear' })}
                                    deshabilitarBotonAgregar={esOperador}
                                    eventoEliminar={(item: any) => {
                                        if (item.Id) {
                                            updateMensajeDialogo("¿Está seguro que desea eliminar esta intervención?");
                                            dialogoRef.current!.mostrar()
                                                .then(() => updateIntervenciones({ tipo: 'deleteIntervencion', valor: item.Id })).catch(() => { });
                                        } else {
                                            mostrarError('No se puede eliminar una intervención de la NCM');
                                        }
                                    }} deshabilitarBotonEliminar={esOperador} mostrarFormCambiarPagina={false} />
                            </div>
                        </Tab>
                        {/* <Tab eventKey="archivos" title="Archivos">
                            <ArchivosAdjuntosArticuloCatalogo ref={archivosAdjuntosRef}></ArchivosAdjuntosArticuloCatalogo>
                        </Tab> */}
                    </Tabs>
                    <Button variant="danger" className="mt-2 mb-2" onClick={() => history.goBack()}>Cancelar</Button>
                    <Button disabled={esOperador} onClick={() => {
                        formikRef.current?.setFieldValue('ForzarSufijos', false);
                        formikRef.current?.setFieldValue('Redirigir', true);
                        formikRef.current?.submitForm();
                    }} className="mt-2 ml-2 mb-2">Guardar</Button>
                    <Button disabled={esOperador} onClick={() => {
                        formikRef.current?.setFieldValue('ForzarSufijos', true);
                        formikRef.current?.setFieldValue('Redirigir', true);
                        formikRef.current?.submitForm();
                    }} className="ml-2 mt-2 mb-2">Guardar con sufijos nuevos</Button>
                </MyForm>
            </Formik>
            <IngresoSufijos ref={sufijosRef}></IngresoSufijos>
            <MyModal show={estadoVentajas.creando || estadoVentajas.modificando}
                onHide={() => updateEstadoVentajas({ tipo: 'esconderVentana' })}>
                <Modal.Dialog>
                    <Modal.Header closeButton>
                        <h2>{estadoVentajas.creando ? 'Crear Ventaja de Articulo' : 'Modificar Ventaja de Articulo'}</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik innerRef={formikVentajasRef} validationSchema={ventajasSchema} initialValues={{
                            'Codigo': estadoVentajas.valorModificando?.Codigo ?? '',
                            'Subregimenes': estadoVentajas.valorModificando?.Subregimenes ?? []
                        }} onSubmit={(nuevaVentaja: { Codigo: string, Subregimenes?: string[] }, actions) => {
                            let ventajasExistentes = estadoVentajas.ventajas.map((ventaja: any) => ventaja.Codigo);
                            if (estadoVentajas.modificando) {
                                let indice = ventajasExistentes.indexOf(estadoVentajas.valorModificando.Codigo);
                                if (indice > -1) {
                                    ventajasExistentes.splice(indice, 1);
                                }
                            }
                            if (ventajasExistentes.includes(nuevaVentaja.Codigo)) {
                                actions.setFieldError('Codigo', `La ventaja con codigo ${nuevaVentaja.Codigo} ya fue ingresada`);
                            } else {
                                updateEstadoVentajas({ tipo: 'insertVentaja', valor: nuevaVentaja });
                            }
                            actions.setSubmitting(false);
                        }}>
                            <MyForm>
                                <Form.Group>
                                    <MySelect name="Codigo" label="Codigo Ventaja"
                                        options={() => api.getVentajas().then(convertirDatosGenericosAOption)}
                                        getOptionLabel={(option: SelectOption) => option.value}></MySelect>
                                </Form.Group>
                                <Form.Group>
                                    <MySelect name="Subregimenes" label="Subregimenes" options={() => api.getSubregimenes().then(convertirDatosGenericosAOption)}
                                        isMulti getOptionLabel={(option: SelectOption) => option.value}></MySelect>
                                </Form.Group>
                            </MyForm>
                        </Formik>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => updateEstadoVentajas({ tipo: 'esconderVentana' })}>
                            Cancelar
                        </Button>
                        <Button onClick={() => formikVentajasRef.current?.submitForm()}>
                            Ingresar
                        </Button>

                    </Modal.Footer>
                </Modal.Dialog>
            </MyModal>
            <MyModal show={estadoInfComps.creando || estadoInfComps.modificando}
                onHide={() => updateEstadoInfComps({ tipo: 'esconderVentana' })}>
                <Modal.Dialog>
                    <Modal.Header closeButton>
                        <h2>{estadoInfComps.creando ? 'Crear Inf. Complementaria de Articulo' : 'Modificar Inf. Complementaria de Articulo'}</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik innerRef={formikInfCompRef} validationSchema={infCompsSchema} initialValues={{
                            'Codigo': estadoInfComps.valorModificando?.Codigo ?? '',
                            'Valor': estadoInfComps.valorModificando?.Valor ?? '',
                            'Subregimenes': estadoInfComps.valorModificando?.Subregimenes ?? []
                        }} onSubmit={(nuevaInfComp: { Codigo: string, Valor?: string, Subregimenes?: string[] }, actions) => {
                            let infCompsExistentes = estadoInfComps.infComps.map((infComp: any) => infComp.Codigo);
                            if (estadoInfComps.modificando) {
                                let indice = infCompsExistentes.indexOf(estadoInfComps.valorModificando.Codigo);
                                if (indice > -1) {
                                    infCompsExistentes.splice(indice, 1);
                                }
                            }
                            if (infCompsExistentes.includes(nuevaInfComp.Codigo)) {
                                actions.setFieldError('Codigo', `La inf. complementaria con codigo ${nuevaInfComp.Codigo} ya fue ingresada`);
                            } else {
                                updateEstadoInfComps({ tipo: 'insertInfComp', valor: nuevaInfComp });
                            }
                            actions.setSubmitting(false);
                        }}>
                            <MyForm>
                                <Form.Group>
                                    <MySelect name="Codigo" label="Codigo Inf. Complementaria"
                                        options={() => api.getInformacionComplementaria().then(convertirDatosGenericosAOption)}
                                        getOptionLabel={(option: SelectOption) => option.value}></MySelect>
                                </Form.Group>
                                <Form.Group>
                                    <MyFormControl type="text" name="Valor" label="Valor"></MyFormControl>
                                </Form.Group>
                                <Form.Group>
                                    <MySelect name="Subregimenes" label="Subregimenes" options={() => api.getSubregimenes().then(convertirDatosGenericosAOption)}
                                        isMulti getOptionLabel={(option: SelectOption) => option.value}></MySelect>
                                </Form.Group>
                            </MyForm>
                        </Formik>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => updateEstadoInfComps({ tipo: 'esconderVentana' })}>
                            Cancelar
                        </Button>
                        <Button onClick={() => formikInfCompRef.current?.submitForm()}>
                            Ingresar
                        </Button>
                    </Modal.Footer>
                </Modal.Dialog>
            </MyModal>
            <MyModal show={estadoObservaciones.creando || estadoObservaciones.modificando}
                onHide={() => updateObservaciones({ tipo: 'esconderVentana' })}>
                <Modal.Dialog>
                    <Modal.Header closeButton>
                        <h2>{estadoObservaciones.creando ? 'Crear Observación' : 'Modificar Observación'}</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik innerRef={formikObservacionesRef} validationSchema={Yup.object({
                            'Valor': Yup.string().nullable().required('Debe ingresar la observación')
                        })} initialValues={{
                            'Valor': estadoObservaciones.indiceModificando !== null && estadoObservaciones.indiceModificando !== undefined ? estadoObservaciones.observaciones[estadoObservaciones.indiceModificando].Valor : '',
                        }} onSubmit={(nuevoItem: { Valor: string }, actions) => {
                            updateObservaciones({ tipo: 'insertObservacion', valor: nuevoItem.Valor });
                            actions.setSubmitting(false);
                        }}>
                            <MyForm>
                                <Form.Group>
                                    <MyTextarea autoFocus hideLabel label="Observación" name="Valor"></MyTextarea>
                                </Form.Group>
                            </MyForm>
                        </Formik>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => updateObservaciones({ tipo: 'esconderVentana' })}>
                            Cancelar
                        </Button>
                        <Button onClick={() => formikObservacionesRef.current?.submitForm()}>
                            Ingresar
                        </Button>
                    </Modal.Footer>
                </Modal.Dialog>
            </MyModal>
            <MyModal show={estadoIntervenciones.creando}
                onHide={() => updateIntervenciones({ tipo: 'esconderVentana' })}>
                <Modal.Dialog>
                    <Modal.Header closeButton>
                        <h2>Agregar Intervención</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik innerRef={formikIntervencionesRef} validationSchema={Yup.object({
                            'Id': Yup.string().nullable().required('Debe seleccionar la intervención')
                        })} initialValues={{ 'Id': '' }} onSubmit={(valor: { Id: string }, actions) => {
                            let valorSeleccionado = (selectIntervencionesRef.current?.getCurrentOptions()) as SelectOption | null | undefined;
                            updateIntervenciones({
                                tipo: 'insertIntervencion', valor: {
                                    Id: valor.Id,
                                    Descripcion: valorSeleccionado?.label
                                }
                            });
                            actions.setSubmitting(false);
                        }}>
                            <MyForm>
                                <Form.Group>
                                    <MySelect name="Id" label="Intervención"
                                        options={cargarIntervenciones} ref={selectIntervencionesRef}></MySelect>
                                </Form.Group>
                            </MyForm>
                        </Formik>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="danger" onClick={() => updateIntervenciones({ tipo: 'esconderVentana' })}>
                            Cancelar
                        </Button>
                        <Button onClick={() => formikIntervencionesRef.current?.submitForm()}>
                            Ingresar
                        </Button>

                    </Modal.Footer>
                </Modal.Dialog>
            </MyModal>
            <DialogoConfirmar ref={dialogoRef} mensaje={mensajeDialogo} textoBotonCancelar="No" textoBotonConfirmar="Sí" />
        </>) : (<NotFoundComponent />);
}