import React, { useEffect, useRef,  useState } from "react";
import { Button, Container, Form, Col, Table, Alert } from "react-bootstrap";
//import { BaseSelect } from "BaseSelect";
//import styled from "styled-components";
import { MyForm, MyFormControl, MySelect, SelectOption } from "../../FormikHooks";
import * as Yup from "yup";
//import { useApi } from "ApiHooks";
import { useAdvAPI } from './AdvancedApi';
import { useApi } from "ApiHooks";
import { convertirDatosGenericosAOptionIdDescripcion} from "Utilidades";
//import { useHistory } from "react-router";
import { LoginAFIP, LoginAFIPRef } from './sesionafip';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, FormikProps } from "formik";
import BlockUi from "react-block-ui";
//import { DateTime } from "luxon";


/*const CustomSelect = styled(BaseSelect)`
    width:100%;
`;*/

export function SubCuentaMaria() {

    let api = useAdvAPI();
    //let refGrilla = useRef<GrillaRef>(null);
    //let history = useHistory();
    let apiSintia = useApi();
    let [optionsDespachantes, updateOptionsDespachantes] = useState<SelectOption[]>([]);
    let [optionsImportadores, updateOptionsImportadores] = useState<SelectOption[]>([]);
    let [optionsAduana, updateoptionsAduana] = useState<SelectOption[]>([]);

    let [ConsultaSubError, updateConsultaSubError] =  useState(false);
    let [ConsultaSubMsg, updateConsultaSubMsg] =  useState('');

    let [optionsaduanaReg, updateoptionsAduanaReg] = useState<SelectOption[]> ([{ value: '000', label: '000 - ADUANA RECAUDADORA' }]);

    let [cargando, updateCargando] = useState(false);
    let [despachosNoResult, updateDespachosNoResult] = useState(false);
    let [resulado, updateREsulado] = useState([]);
    let loginAFIPRef = useRef<LoginAFIPRef>(null);

    let formikRef = React.useRef<FormikProps<any>>(null);
    let huboCambios = React.useRef<{ valor: boolean }>({ valor: false });

    const eventoHuboCambios = () => {
        huboCambios.current.valor = true;
    }

    const tipoSubCuenta = [
        { value: 'DOL', label: 'DOL - DOLARES' },
        { value: 'PES', label: 'PES - PESOS' }
    ]

    let schema = Yup.object({
        'aduanaconexion': Yup.string().nullable().required('Debe seleccionar una aduana de conexion'),
        'aduanaregistro': Yup.string().nullable().required('Debe seleccionar una aduana de registro'),
        'cuitDespachante': Yup.string().nullable().required('Debe seleeccionar Despachante'),
        'cuitImpoExpo': Yup.string().nullable().required('Debe Importador'),
        'depsaldopendiente': Yup.string().nullable().required('Debe seleccionar con saldo pendiente si o no'),
        'fechaDesde': Yup.date().required('Debe ingresar Fecha Desde'),
        'fechaHasta':  Yup.date().required('Debe ingresar Fecha Hasta'),
        'tiposubcuenta': Yup.string().nullable().required('Debe seleccionar tipo de cuenta')
    });


    var d = new Date();
    let hoy = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');


    useEffect(() => {
        async function cargar() {
            try {
                let despachantes = await apiSintia.getDespachantes();
                updateOptionsDespachantes(despachantes.map((item: any) => ({ value: item.CUIT, label: item.Nombre })));
                let importadores = await apiSintia.getImportadores();
                updateOptionsImportadores(importadores.map((item: any) => ({ value: item.CUIT, label: item.Nombre })));
                let aduana = await apiSintia.getAduanas().then(convertirDatosGenericosAOptionIdDescripcion);
                updateoptionsAduana(aduana)
                updateCargando(false);

            } catch (error) {
                if (!apiSintia.isCancel(error)) {
                    console.error('Error al cargar caratulas', error);
                }
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);
    

    async function cargarDatos(pbusqueda: any) {

            updateDespachosNoResult(false);
            updateCargando(true);
            updateConsultaSubError(false);
            updateConsultaSubMsg('');


                try {
                    
                    let respuesta = await api.getSubCuentaMaria(
                        pbusqueda.aduanaconexion,
                        pbusqueda.aduanaregistro,
                        pbusqueda.cuitDespachante,
                        pbusqueda.cuitImpoExpo,
                        pbusqueda.depsaldopendiente,
                        pbusqueda.fechaDesde,
                        pbusqueda.fechaHasta,
                        pbusqueda.tiposubcuenta
                        );

                    if (respuesta.ListSubCuenta?.length >0) {
                            updateREsulado(respuesta.ListSubCuenta);
                            updateCargando(false);
                        }
                    else {
                        updateREsulado([]);
                        updateDespachosNoResult(true);
                        updateCargando(false);
                    }

                } catch (error) {
                    console.log(error);
                    updateConsultaSubMsg(error.response.data);
                    updateREsulado([]);
                    updateDespachosNoResult(true);
                    updateCargando(false);
                    updateConsultaSubError(true);

                }

    }

    /*
    Anio
    TipoSubCuenta
    MedioDeposito
    NroDeposito
    ImporteDeposito
    FechaAcreditacion
    SaldoPendienteAfectacion
    */

        function GrillaDespachos(props: {despachos: any[]}) 
        {
            const estiloTabla = {
                fontSize: '0.9rem', // Puedes ajustar este valor según tus necesidades
                width: '100%',
              };

            const estiloCelda = {
                border: '1px solid #dddddd',
                padding: '1px',
              };
    
            return <>
                <Table style={estiloTabla} className="text-nowrap middle-vertical-align table table-sm table-striped table-bordered">
                    <thead>
                        <tr>
                            <th style={estiloCelda}>Fecha Acred.</th>
                            {/*<th>Año</th>*/}
                            <th style={estiloCelda}>Tipo</th>
                            <th style={estiloCelda}>Medio Dep.</th>
                            <th style={estiloCelda}>Nro Dep.</th>
                            <th style={estiloCelda} className="text-right">Importe</th>
                            <th style={estiloCelda} className="text-right">Saldo</th>
                        </tr>
                    </thead>
                    <tbody>{props.despachos.map((t: any, indice: number) =>
                    (
                        <tr style={estiloCelda} className="cursor-pointer " key={t.NroDeposito} onClick={e => {
                            //let element = e.target as HTMLElement;
                            //ejecutar solamente cuando el target sea una celda de la tabla y no un botón o checkbox
                            /*if (element.nodeName === 'TD' || element.nodeName === 'TR') {
                                props.eventoAbrir(t);
                            }*/
                        }}>

                            <td>{t.FechaAcreditacion}</td>
                            {/*<td>{DateTime.fromFormat(t.FechaAcreditacion,"dd-MMM-yy").toFormat('dd/MM/yyyy')} {t.FechaAcreditacion}</td>*/}
                            {/*<td>{t.Anio}</td>*/}
                            <td>{t.TipoSubCuenta}</td>
                            <td>{t.MedioDeposito}</td>
                            <td>{t.NroDeposito}</td>

                            <td className="text-right">{Number(t.ImporteDeposito).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                            <td className="text-right">{ Number(t.SaldoPendienteAfectacion).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }</td>
                            {/*<td className="text-right">{toFixedDecimal(t.SaldoPendienteAfectacion,2)}</td>*/}

                        </tr>
                    ))}
                    </tbody>
                </Table>
            </>;
        }

        let initialvalues = {
            cuitDespachante: '',
            cuitImpoExpo: '',
            aduanaconexion: '',
            aduanaregistro: '',
            fechaDesde: hoy,
            fechaHasta: hoy
        };

        //si cambia aduana conexion recargar aduana registro agregando la aduana conexion seleccionada
        const onAduanaOptionChange = (option: any) => {
            if (option) {
            updateoptionsAduanaReg([
                { value: '000', label: '000 - ADUANA RECAUDADORA' },
                { value: option.value, label: option.label }
            ]);
            } else {
                updateoptionsAduanaReg([{ value: '000', label: '000 - ADUANA RECAUDADORA' }]);
            }

        }


    return (
    <>
        <Container>
            <br/><p className="h3">Consulta de SubCuenta Maria</p><br/>
            <Formik initialValues={initialvalues} validationSchema={schema} innerRef={formikRef} onSubmit={cargarDatos}>
                <BlockUi blocking={cargando}>

                    <MyForm className="col-md-12">


                        <Form.Group as={Col} className="col-md-12">
                                    {ConsultaSubError && 
                                        <Alert variant="danger" onClose={() => updateConsultaSubError(false)} dismissible>
                                        <Alert.Heading>Error al consultar Sub Cuenta</Alert.Heading>
                                            <p><b>{ConsultaSubMsg}</b></p>
                                        </Alert>
                                    }
                        </Form.Group>


                        <Form.Row className="col-md-12">
                                <Form.Group as={Col} className="col-md-6">
                                    <MySelect 
                                        name="aduanaconexion" label="Aduana Conexion"
                                        options={optionsAduana} onValueChange={onAduanaOptionChange}
                                        onOptionChange={onAduanaOptionChange}
                                        />
                                </Form.Group>
                                <Form.Group as={Col} className="col-md-6">
                                    <MySelect 
                                        name="aduanaregistro" label="Registro"
                                        options={optionsaduanaReg} onValueChange={eventoHuboCambios} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="col-md-12">
                                <Form.Group className="col-md-12">
                                        <MySelect 
                                            name="cuitImpoExpo" label="Importador"
                                            options={optionsImportadores} onValueChange={eventoHuboCambios} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="col-md-12">
                                <Form.Group as={Col}>
                                    <MySelect 
                                        name="cuitDespachante" label="Despachante"
                                        options={optionsDespachantes} onValueChange={eventoHuboCambios} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row className="col-md-12">
                            <Form.Group as={Col}  className="col-md-6">
                            <label> . </label><br/>
                                <MySelect 
                                    name="tiposubcuenta" label="Tipo de Sub Cuenta"
                                    options={tipoSubCuenta} onValueChange={eventoHuboCambios} /><br/>
                                <MySelect 
                                    name="depsaldopendiente" 
                                    label="Solo depositos con saldo pendientes"
                                    options={[{ value: 'S', label: 'SI' },{ value: 'N', label: 'NO' }]} 
                                    onValueChange={eventoHuboCambios} /><br/>
                            </Form.Group>
                            <Form.Group as={Col} className="col-md-3">
                                <label>Acreditacion</label><br/>
                                <MyFormControl name="fechaDesde" type="date" label="Fecha Desde" ></MyFormControl><br/>
                                <MyFormControl name="fechaHasta" type="date" label="Fecha Hasta" ></MyFormControl><br/>
                            </Form.Group>


                        </Form.Row>

                        <Form.Group className="mr-2  mb-2 col-md-12">
                            <Button type="submit" className="mb-2">Buscar</Button>
                        </Form.Group>

                        <Form.Group className="mr-2  mb-2 col-md-12">
                            {despachosNoResult && 
                                <Alert variant="warning">
                                    <FontAwesomeIcon icon={faExclamationTriangle}></FontAwesomeIcon>
                                        <span className="ml-3">No hay Declaraciones para los paremetros seleccionados</span>
                                </Alert>
                            }

                        </Form.Group>

                    </MyForm>
                </BlockUi>
            </Formik>

            <div className="mt-2">
                        <GrillaDespachos despachos={resulado}></GrillaDespachos>
                    </div>

        </Container>

        <LoginAFIP ref={loginAFIPRef} />    
    </>
    
    )
}