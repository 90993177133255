import { CancelToken } from "axios";
import { DateTime } from "luxon";
import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AppContext, userClient } from "../../App";
import Grilla, { GrillaRef, TipoCampo } from "../../Grilla";
import { MyModal } from "../../MyModal";
import Loader from "react-loaders";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import contentDisposition from "content-disposition";
import FileSaver from "file-saver";
import { createQueryString, isNullOrWhiteSpace, toFixedDecimal } from 'Utilidades';
import { BaseSelect } from "BaseSelect";
import BlockUi from "react-block-ui";
import { useCancel } from "SintiaHooks";
import styled from "styled-components";

type SelectOption = { value: string, label: string };

const SelectCustom = styled(BaseSelect)`
    width:400px;
`;

export function ReporteContableCuentaCorrienteCliente() {
    let { mostrarError } = React.useContext(AppContext);
    let { getCancelToken, cancelCurrentTokens, isUnmounted, isCancel } = useCancel();
    let refGrilla = React.useRef<GrillaRef>(null);
    let [saldos, updateSaldos] = React.useState({ anterior: 0, posterior: 0 });
    let [generandoExcel, updateGenerandoExcel] = React.useState(false);
    function busquedaReducer(estado: any, accion: any) {
        if (accion.tipo === 'fechaDesde') {
            return { ...estado, fechaDesde: accion.valor };
        } else if (accion.tipo === 'fechaHasta') {
            return { ...estado, fechaHasta: accion.valor };
        } else if (accion.tipo === 'idCliente') {
            return { ...estado, idCliente: accion.valor };
        }
        return estado;
    }
    let busquedaInicial = { idCliente: null, fechaDesde: DateTime.local().minus({ months: 1 }).toISODate(), fechaHasta: DateTime.local().toISODate() };
    let [busquedaActual, updateBusquedaActual] = React.useState(busquedaInicial);
    let [busqueda, updateBusqueda] = React.useReducer(busquedaReducer, { ...busquedaInicial });
    let [clientes, updateClientes] = React.useState<any[]>([]);
    let [cargando, updateCargando] = React.useState(true);
    const cargarReporte = React.useCallback(async (desde: number, hasta: number, cancelToken: CancelToken) => {
        let fechaDesde = busquedaActual.fechaDesde;
        let fechaHasta = DateTime.fromISO(busquedaActual.fechaHasta).plus({ days: 1 }).toISODate();
        let respuesta = await userClient.get('/ReportesContable/ReporteCuentaCorrienteClientes' + createQueryString({
            FechaDesde: fechaDesde,
            FechaHasta: fechaHasta,
            IdCliente: busquedaActual.idCliente,
            Desde: desde,
            Hasta: hasta
        }), { cancelToken: cancelToken });
        updateSaldos({ anterior: respuesta.data.SaldoAnterior, posterior: respuesta.data.SaldoPosterior });
        return { cantidadItems: respuesta.data.CantidadTotal, items: respuesta.data.Items };
    }, [busquedaActual]);
    React.useEffect(() => {
        async function cargarClientes() {
            try {
                let respuesta = await userClient.get('/ReportesContable/ClientesReporteCuentaCorriente',
                    { cancelToken: getCancelToken() });
                updateClientes(respuesta.data.map((c: any) => ({ value: c.Id, label: c.Nombre })));
                updateCargando(false);
            } catch (error) {
                if (!isCancel(error)) {
                    console.error('Error al cargar listado de clientes', error);
                    mostrarError('Error al cargar listado de clientes');
                    updateCargando(false);
                }
            }
        }
        cargarClientes();
        //eslint-disable-next-line 
    }, []);
    async function exportarAExcel() {
        try {
            updateGenerandoExcel(true);
            let fechaDesde = busquedaActual.fechaDesde;
            let fechaHasta = DateTime.fromISO(busquedaActual.fechaHasta).plus({ days: 1 }).toISODate();
            let respuesta = await userClient.get('/ReportesContable/ExcelReporteCuentaCorrienteClientes' + createQueryString({
                FechaDesde: fechaDesde,
                FechaHasta: fechaHasta,
                IdCliente: busquedaActual.idCliente,
            }), {
                cancelToken: getCancelToken(),
                responseType: 'blob',
            });
            updateGenerandoExcel(false);
            let parsedContentDisposition = contentDisposition.parse(respuesta.headers['content-disposition']);
            let fileName = parsedContentDisposition.parameters.filename || 'Reporte Cuenta Corriente Clientes.xlsx';
            FileSaver.saveAs(respuesta.data, fileName);
        } catch (error) {
            if (!isCancel(error)) {
                console.error(error);
                mostrarError('Error al exportar reporte cuenta corriente a Excel');
            }
            if (!isUnmounted()) {
                updateGenerandoExcel(false);
            }
        }
    }
    let camposGrilla = [{ propiedad: 'Id', visible: false, clave: true },
    { propiedad: 'F_Comprobante', titulo: 'Fecha', tipo: TipoCampo.Date },
    { propiedad: 'ComprobanteNombre', titulo: 'Tipo Comprobante' },
    { propiedad: 'N_ComprobanteFiscal_Editado', titulo: 'Numero' },
    { propiedad: 'Debe', titulo: 'Debe', tipo: TipoCampo.Number },
    { propiedad: 'Haber', titulo: 'Haber', tipo: TipoCampo.Number },
    { propiedad: 'Saldo', titulo: 'Saldo', tipo: TipoCampo.Number },
    { propiedad: 'Observaciones', titulo: 'Observaciones' },
    { propiedad: 'NombreAgente', titulo: 'Cliente' },
    { propiedad: 'Carpeta', titulo: 'Carpeta' },
    { propiedad: 'SG_VuestraReferencia', titulo: 'Vuestra Referencia' },
    { propiedad: 'ClienteCarpetaSeguimiento', titulo: 'Cliente Carpeta Segumiento' }];
    return (<>
        <h2>Reporte Cuenta Corriente Clientes</h2>
        <BlockUi blocking={cargando}>
            <Form inline onSubmit={e => {
                if (isNullOrWhiteSpace(busqueda.fechaDesde) || isNullOrWhiteSpace(busqueda.fechaHasta)) {
                    mostrarError('Debe ingresar la fecha desde y hasta');
                } else if (busqueda.idCliente !== null && busqueda.idCliente !== undefined) {
                    updateBusquedaActual(busqueda);
                    refGrilla.current?.recargar();
                } else {
                    mostrarError('Debe seleccionar un cliente para ver el reporte');
                }
                e.preventDefault();
            }}>
                <Form.Group>
                    <Form.Label htmlFor="txtFechaDesde" className="mx-2">Fecha Desde</Form.Label>
                    <Form.Control type="date" id="txtFechaDesde" value={busqueda.fechaDesde} className="mb-2 mr-2"
                        onChange={e => updateBusqueda({ tipo: 'fechaDesde', valor: e.target.value })}></Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label htmlFor="txtFechaHasta" className="mr-2">Fecha Hasta</Form.Label>
                    <Form.Control type="date" id="txtFechaHasta" value={busqueda.fechaHasta} className="mb-2 mr-2"
                        onChange={e => updateBusqueda({ tipo: 'fechaHasta', valor: e.target.value })}></Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label htmlFor="cboCliente" className="mr-2">Cliente</Form.Label>
                    <SelectCustom id="cboCliente" options={clientes} placeholder="Seleccionar..."
                        onChange={(option: SelectOption | null | undefined) =>
                            updateBusqueda({ tipo: 'idCliente', valor: option?.value })}></SelectCustom>
                </Form.Group>
                <Button type="submit" className="ml-2 mb-2">Buscar</Button>
            </Form>
            {busquedaActual.idCliente !== null && busquedaActual.idCliente !== null && <>
                <Button variant="success" className="mb-2" onClick={exportarAExcel}>
                    <FontAwesomeIcon icon={faFileExcel} />
                    <span>Exportar a Excel</span>
                </Button>
                <dl className="row">
                    <div className="col-2">
                        <dt>Saldo Anterior</dt>
                        <dd>{toFixedDecimal(saldos.anterior, 2)}</dd>
                    </div>
                    <div className="col-2">
                        <dt>Saldo Periodo</dt>
                        <dd>{toFixedDecimal(saldos.posterior, 2)}</dd>
                    </div>
                </dl>
                <Grilla responsiva cargarDatos={cargarReporte} campos={camposGrilla} ref={refGrilla}></Grilla>
            </>}
        </BlockUi>
        <MyModal show={generandoExcel}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead">Generando Excel...</p>
                    <div className="loader-container">
                        <Loader type="ball-spin-fade-loader" active></Loader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
    </>);
}