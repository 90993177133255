import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from "react";
import { includesObject, toHtmlDate, useDecodedParams } from "Utilidades";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { Formik, FormikProps } from "formik";
import BlockUi from "react-block-ui";
import { MyForm, MyFormControl, MyFormGroup, MySelectConOptionABM, MyTextarea, SelectOption } from "FormikHooks";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { useApi } from "ApiHooks";
import { AppContext, NotFoundComponent } from "App";
import { GrillaRef, GrillaSync } from "Grilla";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { MyModal } from "MyModal";
import { TiposCertificacion } from "Paginas/Catalogos/Certificaciones/TiposCertificacion";
import { BusquedaArticulos, BusquedaArticulosRef } from "Paginas/Caratulas/BusquedaArticulos";
import { useRedirectEventCallback } from "SintiaHooks";
import { DialogoConfirmar, DialogoConfirmarRef } from "DialogoConfirmar";
export function CrearEditarCertificacion() {
    let busquedaArticulosRef = useRef<BusquedaArticulosRef>(null);
    let grillaRef = useRef<GrillaRef>(null);
    let formikRef = useRef<FormikProps<any>>(null);
    let huboCambios = React.useRef<{ valor: boolean }>({ valor: false });
    let dialogoRef = React.useRef<DialogoConfirmarRef>(null);
    let [mensajeDialogo, updateMensajeDialogo] = React.useState('');
    let { mostrarError } = useContext(AppContext);
    let api = useApi();
    let history = useHistory();
    let { id } = useDecodedParams();
    let [mostrarModalTipos, updateMostrarModalTipos] = useState(false);
    let [notFound, updateNotFound] = useState(false);
    let [cargando, updateCargando] = useState(true);
    let [optionsTipos, updateOptionsTipos] = useState<SelectOption[]>([]);
    let [estadoArticulos, updateEstadoArticulos] = useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'inicializar') {
            return {
                articulos: accion.articulos?.map((a: any, indice: number) => ({
                    Indice: indice,
                    IdCatalogo: a.IdCatalogo,
                    IdArticulo: a.IdArticulo
                })) ?? []
            };
        } else if (accion.tipo === 'agregar') {
            let nuevoArray = estado.articulos.map((item: any) => {
                let { Indice, ...otros } = item;
                return otros;
            });
            if (accion.articulos?.length > 0) {
                for (let item of accion.articulos) {
                    if (!includesObject(nuevoArray, item)) {
                        nuevoArray.push(item);
                    }
                }
            }
            return {
                articulos: nuevoArray.map((a: any, indice: number) => ({
                    Indice: indice,
                    IdCatalogo: a.IdCatalogo,
                    IdArticulo: a.IdArticulo
                }))
            }
        } else if (accion.tipo === 'eliminar') {
            return {
                articulos: estado.articulos.filter((a: any) => !accion.indices.includes(a.Indice))
                    .map((a: any, indice: number) => ({
                        Indice: indice,
                        IdCatalogo: a.IdCatalogo,
                        IdArticulo: a.IdArticulo
                    }))
            }
        }
        return estado;
    }, { articulos: [] });
    let schema = Yup.object({
        'Numero': Yup.string().nullable().required('Debe ingresar el número'),
        'Descripcion': Yup.string().nullable(),
        'TipoId': Yup.string().nullable().required('Debe seleccionar un tipo'),
        'FechaVencimiento': Yup.date().nullable().required('Debe ingresar la fecha de vencimiento')
            .typeError('Debe ingresar una fecha valida')
    });
    useEffect(() => {
        async function cargar() {
            try {
                let tipos = await api.getTiposCertificacion();
                updateOptionsTipos(tipos.map((t: any) => ({ value: t.Id, label: t.Nombre })));
                if (id) {
                    let certificacion = await api.getCertificacion(id);
                    if (!certificacion) {
                        updateNotFound(true);
                        return;
                    }
                    let tipoId = null;
                    if (tipos.map((t: any) => t.Id).includes(certificacion.TipoId)) {
                        tipoId = certificacion.TipoId;
                    }
                    formikRef.current?.resetForm({
                        values: {
                            TipoId: tipoId,
                            Numero: certificacion.Numero,
                            FechaVencimiento: toHtmlDate(certificacion.FechaVencimiento),
                            Descripcion: certificacion.Descripcion
                        }
                    });
                    updateEstadoArticulos({ tipo: 'inicializar', articulos: certificacion.Articulos });
                }
                updateCargando(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar certificación', error);
                    mostrarError('Error al cargar certificación');
                }
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);
    const eventoTiposCargados = useCallback((tipos: any[]) => {
        updateOptionsTipos(tipos.map((t: any) => ({ value: t.Id, label: t.Nombre })));
    }, [updateOptionsTipos]);
    async function onSubmit(values: any) {
        try {
            let { exito, error } = await api.insertCertificacion({
                ...values,
                Id: id,
                Articulos: estadoArticulos.articulos.map((a: any) => ({ IdCatalogo: a.IdCatalogo, IdArticulo: a.IdArticulo }))
            });
            if (exito) {
                huboCambios.current.valor = false;
                history.replace('/certificaciones');
                return true;
            } else {
                mostrarError(error);
                return false;
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al crear/modificar certificación', error);
                mostrarError('Error al crear/modificar certificación');
            }
            return false;
        }
    }
    function preguntarGuardarCambios() {
        if (huboCambios.current.valor) {
            if (formikRef.current!.isValid) {
                updateMensajeDialogo('¿Desea guardar los cambios en la certificación?');
                return dialogoRef.current!.mostrar().then(() => {
                    let promise = formikRef.current!.submitForm() as unknown as Promise<boolean>;
                    return promise.catch(() => false);
                }).catch(() => true);
            } else {
                updateMensajeDialogo('Hay errores en la certificación. ¿Está seguro que desea salir?');
                return dialogoRef.current!.mostrar().then(() => true).catch(() => false);
            }
        } else {
            return Promise.resolve(true);
        }
    }
    let { disableRedirectEventCallback } = useRedirectEventCallback(preguntarGuardarCambios);
    const eventoHuboCambios = () => {
        huboCambios.current.valor = true;
    }
    return notFound ? <NotFoundComponent></NotFoundComponent> : <>
        <h2>{id ? 'Modificar certificación' : 'Crear certificación'}</h2>
        <Formik validationSchema={schema} initialValues={{}} innerRef={formikRef} onSubmit={onSubmit}>
            {({ submitForm, isSubmitting }) =>
                <BlockUi blocking={cargando || isSubmitting}>
                    <Row>
                        <Col>
                            <MyForm>
                                <MyFormGroup>
                                    <MySelectConOptionABM options={optionsTipos} name="TipoId" label="Tipo"
                                        labelOptionABM="Nuevo tipo certificación..."
                                        onValueChange={eventoHuboCambios}
                                        onSelectABM={() => updateMostrarModalTipos(true)}></MySelectConOptionABM>
                                </MyFormGroup>
                                <MyFormGroup>
                                    <MyFormControl type="text" name="Numero" label="Número" onValueChange={eventoHuboCambios}></MyFormControl>
                                </MyFormGroup>
                                <MyFormGroup>
                                    <MyTextarea name="Descripcion" label="Descripción" onValueChange={eventoHuboCambios}></MyTextarea>
                                </MyFormGroup>
                                <MyFormGroup>
                                    <MyFormControl type="date" name="FechaVencimiento" label="Fecha Vencimiento" onValueChange={eventoHuboCambios}></MyFormControl>
                                </MyFormGroup>
                                <Button variant="danger" className="my-2 mr-2" onClick={() => {
                                    disableRedirectEventCallback();
                                    history.goBack();
                                }}>Cancelar</Button>
                                <Button variant="primary" onClick={submitForm}>Guardar</Button>
                            </MyForm>
                        </Col>
                        <Col>
                            <h4>Articulos</h4>
                            <Button className="mb-2 mr-2" onClick={() => {
                                busquedaArticulosRef.current!.mostrarSeleccionMultiple(null).then(articulos => {
                                    if (articulos?.length > 0) {
                                        eventoHuboCambios();
                                        updateEstadoArticulos({
                                            tipo: 'agregar',
                                            articulos: articulos.map(a => ({
                                                IdCatalogo: a.codigoCatalogo,
                                                IdArticulo: a.codigoArticulo
                                            }))
                                        })
                                    }
                                }).catch(() => { });
                            }}>
                                <FontAwesomeIcon icon={faPlus} />
                                <span>Agregar</span>
                            </Button>
                            <Button className="mb-2" onClick={() => {
                                eventoHuboCambios();
                                updateEstadoArticulos({ tipo: 'eliminar', indices: grillaRef.current?.getClavesSeleccionadas() ?? [] })
                            }}>
                                <FontAwesomeIcon icon={faTrash} />
                                <span>Eliminar Seleccionados</span>
                            </Button>
                            <GrillaSync ref={grillaRef} datos={estadoArticulos.articulos}
                                seleccionMultiple
                                campos={[{ propiedad: 'Indice', clave: true, visible: false }, { propiedad: 'IdCatalogo', titulo: 'Catalogo' }, { propiedad: 'IdArticulo', titulo: 'Articulo' }]}
                                eventoEliminar={(item: any) => {
                                    eventoHuboCambios();
                                    updateEstadoArticulos({ tipo: 'eliminar', indices: [item.Indice] });
                                }}
                            ></GrillaSync>
                        </Col>
                    </Row>
                </BlockUi>
            }
        </Formik>
        <MyModal show={mostrarModalTipos} onHide={() => updateMostrarModalTipos(false)}>
            <Modal.Dialog size="xl">
                <Modal.Header closeButton>
                    <h2>Tipos certificación</h2>
                </Modal.Header>
                <Modal.Body>
                    <TiposCertificacion eventoTipoSeleccionado={(tipo: any) => {
                        updateMostrarModalTipos(false);
                        formikRef.current?.setFieldValue('TipoId', tipo.Id);
                    }} eventoTiposCargados={eventoTiposCargados}></TiposCertificacion>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateMostrarModalTipos(false)}>Cerrar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <BusquedaArticulos ref={busquedaArticulosRef}></BusquedaArticulos>
        <DialogoConfirmar ref={dialogoRef} mensaje={mensajeDialogo} textoBotonCancelar="No" textoBotonConfirmar="Sí" />
    </>;
}