import { faEdit, faExclamationTriangle, faFile, faFileArchive, faFileDownload, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppContext } from 'App'
import { useAdvAPI } from 'Paginas/advanced/AdvancedApi'
import { isNullOrWhiteSpace } from 'Utilidades'
import React, { useContext, useEffect, useRef, useState } from 'react'
import BlockUi from 'react-block-ui'
import { Alert, Button, Col, Form, Table } from 'react-bootstrap'
import './styleModalSubirArchivo.css'
import { useApi } from 'ApiHooks'
import Loader from 'react-loaders'
import { setTimeout } from 'timers'
import { ListArchivos } from 'modelos/Advanced'
//import { DialogoConfirmarRef } from "DialogoConfirmar";
import axios, { CancelTokenSource } from 'axios'
import ModalSubirArchivos from 'Paginas/Caratulas/ArchivosAdjuntosCaratula/ModalSubirArchivos'
import ModalEliminarArchivo from 'Paginas/Caratulas/ArchivosAdjuntosCaratula/ModalEliminarArchivo'
import ModalDescargarArchivo from 'Paginas/Caratulas/ArchivosAdjuntosCaratula/ModalDescargarArchivo'
import ModalEditarObservacion from 'Paginas/Caratulas/ArchivosAdjuntosCaratula/ModalEditarObservacion'
import ModalSubirMultiplesArchivos from 'Paginas/Caratulas/ArchivosAdjuntosCaratula/ModalSubirMultiplesArchivos'

interface PropsArchivosAdjuntosCaratula {
    interno: string
    activeTabKey: string
}

const ArchivosAdjuntosCaratula = ({ interno, activeTabKey }: PropsArchivosAdjuntosCaratula) => {

    const [tablaListaArchivos, setTablaListaArchivos] = useState<ListArchivos[]>()
    const [cargando, setCargando] = useState<boolean>(false)
    const [showModalSubirArchivos, setShowModalSubirArchivos] = useState(false);
    const [showModalEliminarArchivo, setShowModalEliminarArchivo] = useState(false)
    const [showModalDescargandoArchivo, setShowModalDescargandoArchivo] = useState(false)
    const [uploadedFile, setUploadedFile] = useState<File | undefined | any>()
    const [habilitarErrorArchivo, setHabilitarMensajeRespuesta] = useState<string | null>(null)
    const [habilitarMensajeRespuestaError, setHabilitarMensajeRespuestaError] = useState<string | null>(null)
    const [reemplazarArchivo, setReemplazarArchivo] = useState<boolean>(false)
    const [cuitImportador, setCuitImportador] = useState<string>()
    const [cuitDespachante, setCuitDespachante] = useState<string>()
    const [idArchivo, setIdArchivo] = useState<number>()
    const [mostrarMensajeSinClienteDespachante, setMostrarMensajeSinClienteDespachante] = useState(false)
    const [progress, setProgress] = useState<number>(0)
    const [habilitarBotonCancelarCarga, setHabilitarBotonCancelarCarga] = useState<boolean>(false)
    const [observacionValue, setObservacionValue] = useState<string>('')
    const [showModalEditarObservacion, setShowModalEditarObservacion] = useState<boolean>(false)
    const [showModalSubirMultiplesArchivos, setShowModalSubirMultiplesArchivos] = useState<boolean>(false)
    const api = useAdvAPI();
    const apiCaratula = useApi();
    const { userInfo } = useContext(AppContext);
    const cancelTokenSource = useRef<CancelTokenSource | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const archivosMultiples = useRef<unknown[]>([]);

    //TablaGrilla Componenente estilo
    const TablaGrilla = (props: React.PropsWithChildren<{ className?: string }>) => {
        let className;
        if (!isNullOrWhiteSpace(props.className)) {
            className = props.className + ' middle-vertical-align';
        } else {
            className = 'middle-vertical-align';
        }
        return (<Table bordered striped hover size="sm" className={className}>{props.children}</Table >);
    }

    //Modal Handlers
    const handleShowModalSubirArchivo = () => setShowModalSubirArchivos(true);
    const handleCloseModalEliminarArchivo = () => setShowModalEliminarArchivo(false);
    const handleShowModalDescargandoArchivo = () => setShowModalDescargandoArchivo(true);
    const handleCloseModalSubirArchivo = () => {
        setUploadedFile(null!)
        setHabilitarMensajeRespuesta(null)
        setHabilitarMensajeRespuestaError(null)
        setShowModalSubirArchivos(false)
        setReemplazarArchivo(false)
        obtenerListaArchivos()
    }
    const handleShowModalEliminarArchivo = (id: number) => {
        setIdArchivo(id)
        setShowModalEliminarArchivo(true)
    };
    const handleCloseModalDescargandoArchivo = () => {
        setShowModalDescargandoArchivo(false)
        setProgress(0)
    }
    const handleCloseModalEditarObservacion = () => {
        setShowModalEditarObservacion(false)
        setObservacionValue('')
    }
    const handleShowModalEditarObservacion = (showModal: boolean, idArchivo: number, observacion: string) => {
        setIdArchivo(idArchivo)
        setShowModalEditarObservacion(showModal)
        setObservacionValue(observacion)
    }
    const handleCloseModalSubirMultiplesArchivos = () => {
        obtenerListaArchivos()
        setShowModalSubirMultiplesArchivos(false)
    }

    //File Handlers
    const handleDisplayFileDetails = () => {
        inputRef.current?.files &&
            setUploadedFile(inputRef?.current?.files?.[0])
    };

    //Peticiones
    const obtenerListaArchivos = (cuit?: string, despachante?: string) => {
        let body = {
            "ID_ClienteAlpha": userInfo?.nroClienteAlpha!,
            "CuitDespachante": despachante || cuitDespachante,
            "CuitImpoExpo": cuit || cuitImportador,
            "ID_Empresa": userInfo?.empresaActual!,
            "Interno": interno
        }
        api.obtenerListaArchivos(body).then((resp) => setTablaListaArchivos(resp?.listArchivos))
    }

    const descargarArchivo = async (archivo: ListArchivos) => {
        setCargando(true)
        handleShowModalDescargandoArchivo()
        cancelTokenSource.current = axios.CancelToken.source();

        const options = {
            onDownloadProgress: (progressEvent: ProgressEvent) => {
                const { loaded, total } = progressEvent
                let percentage = Math.floor(loaded * 100 / total)
                setProgress(percentage)
            },
            cancelToken: cancelTokenSource.current.token,
        }

        try {
            // Realizar una solicitud GET al endpoint
            const response = await api.descargarArchivo(archivo.id, options);
            // Decodificar el archivo base64
            const base64String = response;
            const byteCharacters = atob(base64String);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            // Crear un Blob a partir del array de bytes
            const blob = new Blob(byteArrays, { type: 'application/octet-stream' });

            // Crear un enlace en memoria y descargar el archivo
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `${archivo?.nombre}.${archivo?.extension}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            setCargando(false)
            setTimeout(() => {
                handleCloseModalDescargandoArchivo()
            }, 2000);
        } catch (error) {
            setCargando(false)
            console.error('Error al descargar el archivo:', error);
        }
    }

    const borrarArchivo = (idArchivo: number) => {
        setCargando(true)
        api.eliminarArchivo(idArchivo).then((resp) => {
            obtenerListaArchivos()
            setCargando(false)
            handleCloseModalEliminarArchivo()
        }).catch((error) => setCargando(false)
        )
    }

    const subirArchivo = (archivo: File) => {
        setCargando(true);
        setHabilitarBotonCancelarCarga(true)
        function fileToBase64(archivo: File, callback: CallableFunction) {
            const reader = new FileReader();
            reader.onload = function (event) {
                const base64String = event?.target?.result;
                callback(base64String);
            };
            reader.readAsDataURL(archivo);
        }
        fileToBase64(archivo, function (base64String: string) {
            postSubirNuevoArchivo(base64String?.split(",")?.[1]!, false)

        });
    };

    const editarObservacionArchivo = (idArchivo: number) => {
        setCargando(true)
        api.editarObservacionArchivo(idArchivo, observacionValue).then((resp) => {
            obtenerListaArchivos()
            setCargando(false)
            handleCloseModalEditarObservacion()
        }).catch((error) => setCargando(false)
        )
    }
    const descargarZipArchivos = async () => {
        setCargando(true)
        handleShowModalDescargandoArchivo()
        cancelTokenSource.current = axios.CancelToken.source();
        /*
        const options = {
            onDownloadProgress: (progressEvent: ProgressEvent) => {
                const { loaded, total } = progressEvent
                let percentage = Math.floor(loaded * 100 / total)
                setProgress(percentage)
            },
            cancelToken: cancelTokenSource.current.token,
        }*/

        const body = {
            "ID_ClienteAlpha": userInfo?.nroClienteAlpha!,
            "CuitDespachante": cuitDespachante,
            "CuitImpoExpo": cuitImportador,
            "ID_Empresa": userInfo?.empresaActual!,
            "interno": interno
        }

        try {
            // Realizar una solicitud GET al endpoint
            const response = await api.descargarInternoCompleto(body);
            // Decodificar el archivo base64
            const base64String = response;
            const byteCharacters = atob(base64String);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            // Crear un Blob a partir del array de bytes
            const blob = new Blob(byteArrays, { type: 'application/octet-stream' });

            // Crear un enlace en memoria y descargar el archivo
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `Archivos-${interno}.zip`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            setCargando(false)
            setTimeout(() => {
                handleCloseModalDescargandoArchivo()
            }, 2000);
        } catch (error) {
            setCargando(false)
            console.error('Error al descargar el archivo:', error);
        }
    }

    //Utils
    const obtenerExtension = (nombreArchivo: string) => {
        let ultimoPunto = nombreArchivo?.lastIndexOf('.');
        let extension = nombreArchivo.substring(ultimoPunto + 1).toLowerCase();
        return extension;
    }

    const cancelarDescarga = () => {
        setCargando(false)
        if (cancelTokenSource.current) {
            cancelTokenSource.current.cancel('Descarga cancelada por el usuario.');
            handleCloseModalDescargandoArchivo()
            cancelTokenSource.current = null;
        }
    };

    const cancelarCarga = () => {
        setCargando(false)
        if (cancelTokenSource.current) {
            cancelTokenSource.current.cancel('Carga cancelada por el usuario.');
            handleCloseModalSubirArchivo()
            setHabilitarBotonCancelarCarga(false)
            setHabilitarMensajeRespuestaError(null)
            setProgress(0)
            cancelTokenSource.current = null;
        }
    };

    const postSubirNuevoArchivo = async (base64String: string, multiplesArchivos: boolean, archivo?: File) => {
        setProgress(0)
        setHabilitarMensajeRespuesta(null)
        setHabilitarMensajeRespuestaError(null)
        cancelTokenSource.current = axios.CancelToken.source();
        const options = {
            onUploadProgress: (progressEvent: ProgressEvent) => {
                const { loaded, total } = progressEvent
                let percentage = Math.floor(loaded * 100 / total)
                setProgress(percentage)
            },
            cancelToken: cancelTokenSource.current.token,
        }
        await api.subirArchivosVarios({
            "ID_ClienteAlpha": userInfo?.nroClienteAlpha,
            "Nombre": multiplesArchivos ? archivo?.name?.slice(0, -4)! : uploadedFile?.name?.slice(0, -4)!,
            "Extension": multiplesArchivos ? obtenerExtension(archivo?.name!) : obtenerExtension(uploadedFile?.name!),
            "ArchivoBytesBase64": base64String,
            "Interno": interno,
            "CuitDespachante": cuitDespachante,
            "CuitImpoExpo": cuitImportador,
            "id_empresa": userInfo?.empresaActual!,
            "Observacion": observacionValue,
            "ReemplazarArchivoSiExiste": multiplesArchivos ? true : reemplazarArchivo
        }, options).then((resp) => {
            if (resp?.mensaje === `El archivo ${uploadedFile?.name?.slice(0, -4)} se ha guardado correctamente.` || resp?.mensaje === `El archivo ${uploadedFile?.name?.slice(0, -4)} se ha pisado correctamente.`) {
                setObservacionValue('')
                setCargando(false)
                setHabilitarMensajeRespuesta(resp?.mensaje)
                setReemplazarArchivo(false)
                setHabilitarBotonCancelarCarga(false)
                setTimeout(() => {
                    setProgress(0)
                    setHabilitarMensajeRespuesta(null)
                    obtenerListaArchivos()
                    handleCloseModalSubirArchivo()
                }, 1500);
            }
            else if (resp?.mensaje === "Ya existe un archivo con el mismo nombre y extension.") {
                setCargando(false)
                setHabilitarMensajeRespuesta(resp?.mensaje)
                setReemplazarArchivo(false)
                setProgress(0)
                setHabilitarBotonCancelarCarga(false)
            } else if (resp?.mensajeError) {
                setCargando(false)
                setReemplazarArchivo(false)
                setHabilitarMensajeRespuestaError(resp?.mensajeError)
                setProgress(0)
                setHabilitarBotonCancelarCarga(false)
            }
        }).catch((error) => {
            setHabilitarBotonCancelarCarga(false)
            setCargando(false)
        })
    }

    const formObservacion = () => {
        return <>
            <Form.Group controlId="observacionControl">
                <Form.Label>Observación</Form.Label>
                <Form.Control maxLength={2500} placeholder='Ingrese una observación..' value={observacionValue} onChange={(e) => setObservacionValue(e.target.value)} as="textarea" rows={3} />
            </Form.Group></>
    }

    const handleDrop = (event: { preventDefault: () => void; dataTransfer: { files: Iterable<unknown> | ArrayLike<unknown> } }) => {
        setShowModalSubirMultiplesArchivos(true)
        event.preventDefault();
        const droppedFiles = Array.from(event.dataTransfer.files);
        archivosMultiples.current = droppedFiles
    };


    //UseEffect para obtener archivos al montar el componente
    useEffect(() => {
        if (activeTabKey === 'archivos') {
            setCargando(true)
            apiCaratula.getCaratula(interno).then((resp) => {
                setCuitImportador(resp?.CuitImportador)
                setCuitDespachante(resp?.CuitDespachante)
                if ((resp?.CuitImportador === null || resp?.CuitDespachante === null)) {
                    setMostrarMensajeSinClienteDespachante(true)
                    setCargando(false)
                    setTablaListaArchivos(null!)
                } else {
                    obtenerListaArchivos(resp?.CuitImportador, resp?.CuitDespachante)
                    setCargando(false)
                }
            })
        }
        // eslint-disable-next-line
    }, [activeTabKey])

    return (
        <>
            <hr />
            <Col style={{ display: 'flex', flexDirection: 'row' }} >
                <Button onClick={handleShowModalSubirArchivo} disabled={cuitImportador === null || cuitDespachante === null} variant={cuitImportador === null || cuitDespachante === null ? "secondary" : "primary"}><FontAwesomeIcon icon={faFile} style={{ fontSize: 20, marginRight: 3 }} />
                    Cargar Archivo</Button>
                <Button style={{ marginLeft: 10 }} onClick={() => descargarZipArchivos()} disabled={cuitImportador === null || cuitDespachante === null || tablaListaArchivos?.length === 0} variant={cuitImportador === null || cuitDespachante === null ? "secondary" : "success"}><FontAwesomeIcon icon={faFileArchive} style={{ fontSize: 20, marginRight: 3 }} />
                    Descargar Todos</Button>
                {mostrarMensajeSinClienteDespachante && <p style={{ padding: 10, fontWeight: 'bold', fontSize: 15 }}>Asegurese de tener un Despachante y Cliente seleccionados en la pestaña "Carpeta" para poder subir archivos.</p>
                }
            </Col>

            <hr />
            {tablaListaArchivos?.length === 0 || tablaListaArchivos === null ?
                <Col onDragOver={(e: { preventDefault: () => void }) => e.preventDefault()}
                    onDrop={(e: { preventDefault: () => void; dataTransfer: { files: Iterable<unknown> | ArrayLike<unknown> } }) => handleDrop(e)}>
                    <Alert variant="dark">
                        <Col style={{ display: 'flex', flexDirection: 'column', marginTop: 10, alignItems: 'center' }}>
                            <FontAwesomeIcon icon={faExclamationTriangle} style={{ fontSize: 30 }} />
                            <h4> No existen archivos cargados para este interno.</h4>
                            <h4> Arrastre aquí los archivos a cargar.</h4>
                        </Col>
                    </Alert>
                </Col> :
                <BlockUi blocking={cargando} loader={<Loader active={cargando} type='ball-beat' />} message="Cargando archivos.." >
                    <Col onDragOver={(e: { preventDefault: () => void }) => e.preventDefault()}
                        onDrop={(e: { preventDefault: () => void; dataTransfer: { files: Iterable<unknown> | ArrayLike<unknown> } }) => handleDrop(e)} style={{
                            maxHeight: "400px",
                            overflowY: "auto"
                        }}>
                        <TablaGrilla>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Nombre Archivo</th>
                                    <th>Observación</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tablaListaArchivos?.map((archivo: ListArchivos) => (
                                    <tr>
                                        <td>{archivo?.id}</td>
                                        <td>{`${archivo?.nombre}.${archivo?.extension}`}</td>
                                        <td>{archivo?.observacion}</td>
                                        <td>
                                            <Button title='Descargar' variant="link" onClick={() => descargarArchivo(archivo)} style={{ fontSize: 20, color: 'green' }}><FontAwesomeIcon icon={faFileDownload} /></Button>
                                            <Button title='Editar observación' variant="link" onClick={() => handleShowModalEditarObservacion(true, archivo?.id, archivo?.observacion)} style={{ fontSize: 20, color: 'blue' }}><FontAwesomeIcon icon={faEdit} /></Button>
                                            <Button title='Borrar' variant="link" onClick={() => handleShowModalEliminarArchivo(archivo?.id)} style={{ fontSize: 20, color: '#db3939' }}><FontAwesomeIcon icon={faTrash} /></Button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </TablaGrilla>

                    </Col>
                    <Col>
                        {tablaListaArchivos?.length! > 0 && <h5>Arrastre archivos a la grilla para subirlos</h5>}
                    </Col>
                </BlockUi>
            }
            <Col>
                <ModalSubirArchivos
                    cancelarCarga={cancelarCarga}
                    cargando={cargando}
                    formObservacion={formObservacion}
                    habilitarBotonCancelarCarga={habilitarBotonCancelarCarga}
                    habilitarErrorArchivo={habilitarErrorArchivo}
                    habilitarMensajeRespuestaError={habilitarMensajeRespuestaError}
                    handleCloseModalSubirArchivo={handleCloseModalSubirArchivo}
                    handleDisplayFileDetails={handleDisplayFileDetails}
                    inputRef={inputRef}
                    setUploadedFile={setUploadedFile}
                    progress={progress}
                    reemplazarArchivo={reemplazarArchivo}
                    setReemplazarArchivo={setReemplazarArchivo}
                    showModalSubirArchivos={showModalSubirArchivos}
                    subirArchivo={subirArchivo}
                    uploadedFile={uploadedFile}
                />
            </Col>
            <Col>
                <ModalEliminarArchivo
                    borrarArchivo={borrarArchivo}
                    cargando={cargando}
                    handleCloseModalEliminarArchivo={handleCloseModalEliminarArchivo}
                    idArchivo={idArchivo}
                    showModalEliminarArchivo={showModalEliminarArchivo}
                />

                <ModalDescargarArchivo
                    cancelarDescarga={cancelarDescarga}
                    cargando={cargando}
                    handleCloseModalDescargandoArchivo={handleCloseModalDescargandoArchivo}
                    progress={progress}
                    showModalDescargandoArchivo={showModalDescargandoArchivo}
                />

                <ModalEditarObservacion
                    cargando={cargando}
                    editarObservacionArchivo={editarObservacionArchivo}
                    formObservacion={formObservacion}
                    handleCloseModalEditarObservacion={handleCloseModalEditarObservacion}
                    idArchivo={idArchivo}
                    progress={progress}
                    showModalEditarObservacion={showModalEditarObservacion}
                />

                <ModalSubirMultiplesArchivos
                    showModalSubirMultiplesArchivos={showModalSubirMultiplesArchivos}
                    cargando={cargando}
                    archivos={archivosMultiples}
                    handleCloseModalSubirMultiplesArchivos={handleCloseModalSubirMultiplesArchivos}
                    TablaGrilla={TablaGrilla}
                    postSubirNuevoArchivo={postSubirNuevoArchivo}
                    setCargando={setCargando}
                />
            </Col>
        </>)
}

export default ArchivosAdjuntosCaratula