import React from "react";
import { CamposProcesoExcel, ImportExcel } from "./ImportExcel";
import { IngresoMapeos, IngresoMapeosRef } from "Paginas/Catalogos/CargaMasiva/IngresoMapeos";
import { AppContext } from "../../App";
import { useHistory } from "react-router";
import { MyModal } from "../../MyModal";
import { Button, Modal } from "react-bootstrap";
import { Duration } from "luxon";
import Loader from "react-loaders";
import { useDecodedParams } from "../../Utilidades";
import { useApi, TipoLock } from "ApiHooks";

enum EstadoProcesoExcel {
    IngresandoExcel,
    IngresandoMapeos,
    Insertando
}

export function CargarExcel(props: any) {
    let { interno } = useDecodedParams();
    let { mostrarError } = React.useContext(AppContext);
    let ingresoMapeosRef = React.useRef<IngresoMapeosRef>(null);
    let api = useApi();
    let history = useHistory();
    let [estado, updateEstado] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'excelImportado') {
            return {
                estadoProcesoExcel: EstadoProcesoExcel.IngresandoMapeos, catalogo: accion.catalogo,
                caratula: accion.caratula,
                articulos: accion.articulos, valoresPaises: accion.valoresPaises,
                valoresUnidades: accion.valoresUnidades, valoresEstadoMerc: accion.valoresEstadoMerc,
                campos: accion.campos, cruzarConCatalogo: accion.cruzarConCatalogo
            }
        } else if (accion.tipo === 'mapeosIngresados') {
            return {
                estadoProcesoExcel: EstadoProcesoExcel.Insertando, catalogo: estado.catalogo,
                articulos: estado.articulos, mapeosPais: accion.mapeosPais,
                mapeosUnidad: accion.mapeosUnidad, mapeosEstadoMerc: accion.mapeosEstadoMerc,
                articulosInsertados: 0, interval: accion.interval, tiempo: Duration.fromMillis(0),
                campos: estado.campos, caratula: estado.caratula,
                cruzarConCatalogo: estado.cruzarConCatalogo
            };
        } else if (accion.tipo === 'volverAIngresoExcel') {
            return { estadoProcesoExcel: EstadoProcesoExcel.IngresandoExcel, articulos: null };
        } else if (accion.tipo === 'insertarArticulos') {
            return {
                ...estado, estadoProcesoExcel: EstadoProcesoExcel.Insertando, articulosInsertados: 0,
                interval: accion.interval, tiempo: Duration.fromMillis(0)
            };
        } else if (accion.tipo === 'actualizarProgreso') {
            return { ...estado, articulosInsertados: accion.articulosInsertados };
        } else if (accion.tipo === 'cancelarInsertar') {
            return { estadoProcesoExcel: EstadoProcesoExcel.IngresandoExcel, articulos: null };
        } else if (accion.tipo === 'actualizarTiempo') {
            return { ...estado, tiempo: estado.tiempo?.plus(accion.millis) ?? Duration.fromMillis(accion.millis) }
        }
        return { ...estado };
    }, { estadoProcesoExcel: EstadoProcesoExcel.IngresandoExcel, articulos: null });
    // function crearInterval(millis: number) {
    //     return setInterval(() => updateEstado({ tipo: 'actualizarTiempo', millis: millis }), millis);
    // }
    React.useEffect(() => {
        if (estado.estadoProcesoExcel === EstadoProcesoExcel.IngresandoMapeos) {
            ingresoMapeosRef.current?.mostrar(estado.valoresPaises, estado.valoresUnidades, estado.valoresEstadoMerc)
                .then(resultado => updateEstado({
                    tipo: 'mapeosIngresados', mapeosPais: resultado.mapeosPais,
                    mapeosUnidad: resultado.mapeosUnidad, mapeosEstadoMerc: resultado.mapeosEstadoMerc,
                    interval: null
                })).catch(() => updateEstado({ tipo: 'volverAIngresoExcel' }))
        }
        //eslint-disable-next-line 
    }, [estado.estadoProcesoExcel]);
    React.useEffect(() => {
        async function insertarArticulos() {
            try {
                let tieneLock = await api.obtenerLock(TipoLock.CaratulaProcesoExcel, estado.caratula.Carpeta);
                if (!tieneLock) {
                    mostrarError(`No se pudo guardar la caratula ${estado.caratula.Carpeta} porque otra persona la está usando`);
                    updateEstado({ tipo: 'cancelarInsertar' });
                    return;
                }
                await api.updateCruzarConCatalogoEnProcesoExcel(estado.cruzarConCatalogo);
                await api.insertCaratulaProcesoExcel(estado.caratula, estado.articulos,
                    estado.mapeosPais.map((mapeo: any) => ({ Valor: mapeo.valor, CodigoPais: mapeo.codigo })),
                    estado.mapeosUnidad.map((mapeo: any) => ({ Valor: mapeo.valor, CodigoUnidad: mapeo.codigo })),
                    estado.mapeosEstadoMerc.map((mapeo: any) => ({ Valor: mapeo.valor, CodigoEstadoMercaderia: mapeo.codigo })));
                await api.eliminarLock(TipoLock.CaratulaProcesoExcel, estado.caratula.Carpeta);
                history.replace('/procesoExcel');
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al insertar caratula Proceso Excel', error);
                    mostrarError('Error al insertar caratula Proceso Excel');
                }
                if (!api.isUnmounted()) {
                    updateEstado({ tipo: 'cancelarInsertar' });
                }
            }
        }
        if (estado.estadoProcesoExcel === EstadoProcesoExcel.Insertando) {
            insertarArticulos();
        }
        //eslint-disable-next-line 
    }, [estado.estadoProcesoExcel, estado.articulosInsertados]);
    function onImport(campos: Array<CamposProcesoExcel>, catalogo: string, caratula: any, articulos: any[], valoresPaises: string[], valoresUnidades: string[], valoresEstadoMerc: string[], cruzarConCatalogo: boolean) {
        updateEstado({
            tipo: 'excelImportado', catalogo: catalogo, caratula: caratula, articulos: articulos, valoresPaises: valoresPaises,
            valoresUnidades: valoresUnidades, valoresEstadoMerc: valoresEstadoMerc, campos: campos, cruzarConCatalogo: cruzarConCatalogo
        });
    }
    return (<>
        <h2>Proceso Excel</h2>
        <IngresoMapeos ref={ingresoMapeosRef}></IngresoMapeos>
        <MyModal show={estado.estadoProcesoExcel === EstadoProcesoExcel.Insertando}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead">Guardando caratula...</p>
                    <div className="loader-container">
                        <Loader type="ball-spin-fade-loader" active></Loader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        api.cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        {estado.estadoProcesoExcel === EstadoProcesoExcel.IngresandoExcel && <ImportExcel interno={interno} onImport={onImport}></ImportExcel>}
    </>);
}