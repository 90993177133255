import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../App";
import Grilla, { GrillaRef, TipoCampo } from "../../../Grilla";
import { cargarDatosGrillaDeArray, isNullOrWhiteSpace, obtenerSufijoModelo, useDecodedParams } from "../../../Utilidades";
import { MyModal } from "../../../MyModal";
import { Modal, Button } from "react-bootstrap";
import FileSaver from "file-saver";
import Loader from "react-loaders";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { useApi } from "ApiHooks";

export function ReporteSubitemsMineria() {
    let { interno } = useDecodedParams() as { interno: string };
    let { mostrarError } = useContext(AppContext);
    let [cargando, updateCargando] = useState(true);
    let api = useApi();
    let refGrilla = useRef<GrillaRef>(null);
    let [reporte, updateReporte] = useState([]);
    let [generandoExcel, updateGenerandoExcel] = useState(false);
    useEffect(() => {
        async function cargar() {
            try {
                refGrilla.current?.setBloqueado(true);
                let subitems = await api.getSubitemsCaratula(interno);
                let paises = await api.getPaises();
                let unidades = await api.getUnidades();
                //delfino pidió que en el reporte mineria esté el valor que está en los sufijos
                updateReporte(subitems.sort((a: any, b: any) => a.Orden - b.Orden).map((item: any) => {
                    let modelo = obtenerSufijoModelo(item.Sufijos);
                    let idArticulo = isNullOrWhiteSpace(modelo) ? item.IdArticulo : modelo;
                    let unidad = unidades.find((itemU: any) => itemU.Codigo === item.CodigoUnidadDeclarada);
                    let pais = paises.find((itemP: any) => itemP.Codigo === item.CodigoPaisOrigen);
                    return {
                        ...item, IdArticulo: idArticulo,
                        NombreUnidadDeclarada: unidad?.Descripcion ?? item.CodigoUnidadDeclarada,
                        NombrePaisOrigen: pais?.Descripcion ?? item.CodigoPaisOrigen
                    };
                }));
                updateCargando(false);
                refGrilla.current?.setBloqueado(false);
                refGrilla.current?.recargar();
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error(error);
                    mostrarError('Error al cargar reporte subitems mineria');
                }
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);
    async function exportarAExcel() {
        try {
            updateGenerandoExcel(true);
            let { excel, fileName } = await api.exportarReporteMineriaAExcel(interno);
            updateGenerandoExcel(false);
            FileSaver.saveAs(excel, fileName);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al exportar reporte subitems mineria a Excel');
            }
            if (!api.isUnmounted()) {
                updateGenerandoExcel(false);
            }
        }
    }
    let campos = [{ propiedad: 'Orden', visible: false, clave: true },
    { titulo: 'Cantidad', propiedad: 'CantidadDeclarada', tipo: TipoCampo.Number },
    { titulo: 'Unidad Declarada', propiedad: 'NombreUnidadDeclarada' },
    { titulo: 'Catalogo', propiedad: 'IdCatalogo' },
    { titulo: 'Articulo', propiedad: 'IdArticulo' },
    { titulo: 'Descripcion Maria', propiedad: 'DescripcionMaria' },
    { titulo: 'Unitario', propiedad: 'PrecioUnitario', tipo: TipoCampo.Number, decimales: 5 },
    { titulo: 'Total FOB', propiedad: 'TotalFob', tipo: TipoCampo.Number },
    { titulo: 'Pais Origen', propiedad: 'NombrePaisOrigen' },
    { titulo: 'Nomenclador', propiedad: 'Ncm' }];
    return (<>
        <h2>Reporte Subitems Minería de <Link to={'/caratulas/' + encodeURIComponent(interno)}>{interno}</Link></h2>
        <Button variant="success" className="mb-2" onClick={exportarAExcel} disabled={cargando}>
            <FontAwesomeIcon icon={faFileExcel} />
            <span>Exportar a Excel</span>
        </Button>
        <Grilla ref={refGrilla} campos={campos} responsiva cargarDatos={cargarDatosGrillaDeArray(reporte)}></Grilla>
        <MyModal show={generandoExcel}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead">Generando Excel...</p>
                    <div className="loader-container">
                        <Loader type="ball-spin-fade-loader" active></Loader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        api.cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
    </>);
}