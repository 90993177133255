import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Form, Col, Table, Alert } from "react-bootstrap";
//import { BaseSelect } from "BaseSelect";
//import styled from "styled-components";
import { MyForm, MyFormControl, MySelect, SelectOption } from "../../FormikHooks";
import * as Yup from "yup";
//import { useApi } from "ApiHooks";
import { useAdvAPI } from './AdvancedApi';
import { useApi } from "ApiHooks";
import { convertirDatosGenericosAOptionIdDescripcion } from "Utilidades";
//import { useHistory } from "react-router";
//import { LoginAFIP, LoginAFIPRef } from './sesionafip';
import { faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, FormikProps } from "formik";
import BlockUi from "react-block-ui";
import "../../modelos/Advanced";
import { ObtenerDespachoFiltrosRequest, ObtenerDespachoFiltrosResponse } from "../../modelos/Advanced";
import { Link } from "react-router-dom";
// import ModalSubirTXT from "Paginas/advanced/SubirTXTForm/ModalSubirTXT";
//import { DateTime } from "luxon";


/*const CustomSelect = styled(BaseSelect)`
    width:100%;
`;*/

export function VerDestincaciones() {

    let api = useAdvAPI();
    let apiSintia = useApi();
    let [optionsDespachantes, updateOptionsDespachantes] = useState<SelectOption[]>([]);
    let [optionsImportadores, updateOptionsImportadores] = useState<SelectOption[]>([]);
    let [optionsAduana, updateoptionsAduana] = useState<SelectOption[]>([]);

    let [ConsultaSubError, updateConsultaSubError] = useState(false);
    let [ConsultaSubMsg, updateConsultaSubMsg] = useState('');

    //let [optionsaduanaReg, updateoptionsAduanaReg] = useState<SelectOption[]> ([{ value: '000', label: '000 - ADUANA RECAUDADORA' }]);

    let [cargando, updateCargando] = useState(false);
    let [despachosNoResult, updateDespachosNoResult] = useState(false);

    let [resulado, updateREsulado] = useState<ObtenerDespachoFiltrosResponse[]>([]);

    //let loginAFIPRef = useRef<LoginAFIPRef>(null);

    let formikRef = useRef<FormikProps<any>>(null);
    let huboCambios = useRef<{ valor: boolean }>({ valor: false });

    const eventoHuboCambios = () => {
        huboCambios.current.valor = true;
    }


    let schema = Yup.object({
        'aduanaconexion': Yup.string().nullable(),
        'cuitDespachante': Yup.string().nullable(),
        'cuitImpoExpo': Yup.string().nullable(),
        'identificadorPropio': Yup.string().nullable(),
        'nroDestinacion': Yup.string().nullable(),
        'fechaDesde': Yup.date().required('Debe ingresar Fecha Desde'),
        'fechaHasta': Yup.date().required('Debe ingresar Fecha Hasta'),
    });



    var d = new Date();
    let hoy = [
        d.getFullYear(),
        ('0' + (d.getMonth() + 1)).slice(-2),
        ('0' + d.getDate()).slice(-2)
    ].join('-');


    useEffect(() => {
        async function cargar() {
            try {
                let despachantes = await apiSintia.getDespachantes();
                updateOptionsDespachantes(despachantes.map((item: any) => ({ value: item.CUIT, label: item.Nombre })));
                let importadores = await apiSintia.getImportadores();
                updateOptionsImportadores(importadores.map((item: any) => ({ value: item.CUIT, label: item.Nombre })));
                let aduana = await apiSintia.getAduanas().then(convertirDatosGenericosAOptionIdDescripcion);
                updateoptionsAduana(aduana)
                updateCargando(false);

            } catch (error) {
                if (!apiSintia.isCancel(error)) {
                    console.error('Error al cargar caratulas', error);
                }
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);


    async function cargarDatos(pbusqueda: any) {

        updateDespachosNoResult(false);
        updateCargando(true);
        updateConsultaSubError(false);
        updateConsultaSubMsg('');

        try {

            const param: ObtenerDespachoFiltrosRequest = {
                cuitsDespachantes: pbusqueda.cuitDespachante !== "" ? [pbusqueda.cuitDespachante] : [],
                cuitsImpoExpo: pbusqueda.cuitImpoExpo !== "" ? [pbusqueda.cuitImpoExpo] : [],
                aduanas: pbusqueda.aduanaconexion !== "" ? [pbusqueda.aduanaconexion] : [],
                identificadorPropio: pbusqueda.identificadorPropio !== "" ? pbusqueda.identificadorPropio : null,
                referencia: null,
                despachoID: null,
                nroDespacho: pbusqueda.nroDestinacion !== "" ? pbusqueda.nroDestinacion : null,
                FechaCreacionDesde: pbusqueda.fechaDesde,
                FechaCreacioHasta: pbusqueda.fechaHasta
            };


            let respuesta = await api.getObtenerDespachosFiltros(param);

            console.log(JSON.stringify(respuesta));

            if (respuesta.length > 0) {
                updateREsulado(respuesta);
                updateCargando(false);
            }
            else {
                updateREsulado([]);
                updateDespachosNoResult(true);
                updateCargando(false);
            }

        } catch (error) {
            console.log(error);
            updateConsultaSubMsg(error.response.data);
            updateREsulado([]);
            updateDespachosNoResult(true);
            updateCargando(false);
            updateConsultaSubError(true);

        }

    }

    /*
    Anio
    TipoSubCuenta
    MedioDeposito
    NroDeposito
    ImporteDeposito
    FechaAcreditacion
    SaldoPendienteAfectacion
    */

    function GrillaDespachos(props: { despachos: ObtenerDespachoFiltrosResponse[] }) {
        const estiloTabla = {
            fontSize: '0.9rem', // Puedes ajustar este valor según tus necesidades
            width: '100%',
        };

        /*            const estiloCelda = {
                        border: '1px solid #dddddd',
                        padding: '1px',
                      };*/

        return <>
            <div className="table-responsive">
                <Table style={estiloTabla} className="text-nowrap middle-vertical-align table table-sm table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>Id Propio</th>
                            <th>Declaracion</th>
                            <th>Despachante</th>
                            <th>ImpoExpo</th>
                            <th>Comprador/Vendedor</th>
                            <th>Estado</th>
                            <th>Vista</th>
                        </tr>
                    </thead>
                    <tbody>{props.despachos.map((t: ObtenerDespachoFiltrosResponse, indice: number) =>
                    (
                        <tr className="cursor-pointer " key={t.ID} onClick={e => {
                            //let element = e.target as HTMLElement;
                            //ejecutar solamente cuando el target sea una celda de la tabla y no un botón o checkbox
                            /*if (element.nodeName === 'TD' || element.nodeName === 'TR') {
                                props.eventoAbrir(t);
                            }*/
                        }}>

                            <td>{t.IdentificadorPropio}</td>
                            <td>{t.NumeroProvisorio}</td>
                            <td>{t.Despachante}</td>
                            <td>{t.ImpoExpo}</td>
                            <td>{t.CompradorVendedor}</td>
                            <td>{t.EstadoDescripcion}</td>
                            <td><Link to={{
                                pathname: `/provisorio/${t.ID}`,
                                state: { estado: t.EstadoDescripcion }
                            }}><button type="button" className="btn btn-success">Ver</button></Link>
                            </td>

                            {/*
                            <td className="text-right">{Number(t.ImporteDeposito).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                            <td className="text-right">{ Number(t.SaldoPendienteAfectacion).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }</td>
                            <td>{DateTime.fromFormat(t.FechaAcreditacion,"dd-MMM-yy").toFormat('dd/MM/yyyy')} {t.FechaAcreditacion}</td>*/}

                            {/*<td className="text-right">{toFixedDecimal(t.SaldoPendienteAfectacion,2)}</td>*/}

                        </tr>
                    ))}
                    </tbody>
                </Table>

            </div>
        </>;
    }

    let initialvalues = {
        cuitDespachante: '',
        cuitImpoExpo: '',
        aduanaconexion: '',
        identificadorPropio: '',
        nroDestinacion: '',
        fechaDesde: hoy,
        fechaHasta: hoy
    };

    return (
        <>
            <Container>
                <br /><p className="h3">Destinaciones</p><br />
                <Container>
                        {/* <ModalSubirTXT/> */}
                </Container>
                <Formik initialValues={initialvalues} validationSchema={schema} innerRef={formikRef} onSubmit={cargarDatos}>
                    <BlockUi blocking={cargando}>
                        <MyForm className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12 col-lg-12">
                                    <Form.Group as={Col} className="col-md-12">
                                        {ConsultaSubError &&
                                            <Alert variant="danger" onClose={() => updateConsultaSubError(false)} dismissible>
                                                <Alert.Heading>Error al consultar Sub Cuenta</Alert.Heading>
                                                <p><b>{ConsultaSubMsg}</b></p>
                                            </Alert>
                                        }
                                    </Form.Group>
                                    <Form.Row className="row">
                                        <Form.Group as={Col} className="col-lg-6 col-12 col-md-6">
                                            <MySelect
                                                name="cuitImpoExpo" label="Importador"
                                                options={optionsImportadores} onValueChange={eventoHuboCambios} />
                                        </Form.Group>
                                        <Form.Group as={Col} className="col-lg-6">
                                            <MySelect
                                                name="cuitDespachante" label="Despachante"
                                                options={optionsDespachantes} onValueChange={eventoHuboCambios} />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="row">
                                        <Form.Group as={Col} className="col-md-4 col-12">
                                            <MySelect
                                                name="aduanaconexion" label="Aduana"
                                                options={optionsAduana}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} className="col-md-4">
                                            <MyFormControl style={{ height: 38 }} name="fechaDesde" type="date" label="Fecha Desde" ></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col} className="col-md-4">
                                            <MyFormControl style={{ height: 38 }} name="fechaHasta" type="date" label="Fecha Hasta" ></MyFormControl>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="row">
                                        <Form.Group as={Col} className="col-md-4 col-12">
                                            <MyFormControl name="nroDestinacion" type="text" label="Nro Destinacion" ></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col} className="col-md-4">
                                            <MyFormControl name="identificadorPropio" type="text" label="Identificador Propio" ></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col} className="col-md-4">
                                            <Button type="submit" className="align-self-end my-4">Buscar</Button>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Group className="mr-2  mb-2 col-md-12">
                                        {despachosNoResult &&
                                            <Alert variant="warning">
                                                <FontAwesomeIcon icon={faExclamationTriangle}></FontAwesomeIcon>
                                                <span className="ml-3">No hay Declaraciones para los paremetros seleccionados</span>
                                            </Alert>
                                        }
                                    </Form.Group>
                                </div>
                            </div>
                        </MyForm>
                    </BlockUi>
                </Formik>
                <div className="mt-2">
                    <GrillaDespachos despachos={resulado}></GrillaDespachos>
                </div>
            </Container>

        </>

    )
}