import React, { useState, useContext } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { DateTime } from "luxon";
import { MyForm, MySelect, MyFormControl } from "FormikHooks";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import BlockUi from "react-block-ui";
import { ResponsiveContainer, PieChart, Pie, Cell, CartesianGrid, BarChart, XAxis, YAxis, Bar, Legend, Tooltip } from "recharts";
import { useApi } from "ApiHooks";
import { AppContext } from "App";
const optionsMes = [{ value: '1', label: 'Enero' }, { value: '2', label: 'Febrero' }, { value: '3', label: 'Marzo' },
{ value: '4', label: "Abril" }, { value: '5', label: "Mayo" }, { value: '6', label: 'Junio' }, { value: '7', label: 'Julio' },
{ value: '8', label: 'Agosto' }, { value: '9', label: 'Septiembre' }, { value: '10', label: 'Octubre' },
{ value: "11", label: 'Noviembre' }, { value: '12', label: 'Diciembre' }];

const coloresCanal: Record<string, string> = {
    'Rojo': '#dc3545',
    'Naranja': '#d2932d',
    'Verde': '#29a875'
};
const coloresTipoDespacho: Record<string, string> = {
    'Impo': '#908ce2',
    'Expo': '#2fe18a',
    'Otros': '#ef8a88',
    'SIMI': '#8fcddd'
}

function convertirALista(record: Record<string, number> | null | undefined) {
    if (record) {
        return Object.keys(record).map(key => ({ name: key, value: record[key] }));
    } else {
        return [];
    }
}
type ParametrosRenderCustomizedLabel = {
    cx: number, cy: number, midAngle: number,
    innerRadius: number, outerRadius: number, value: any,
    color: string, startAngle: number, endAngle: number
};
function renderCustomizedLabel({
    cx, cy, midAngle, innerRadius, outerRadius, value, color, startAngle, endAngle
}: ParametrosRenderCustomizedLabel) {
    const RADIAN = Math.PI / 180;
    const diffAngle = endAngle - startAngle;
    const delta = ((360 - diffAngle) / 15) - 1;
    const radius = innerRadius + (outerRadius - innerRadius);
    const x = cx + (radius + delta) * Math.cos(-midAngle * RADIAN);
    // const y = cy + (radius + (delta * delta)) * Math.sin(-midAngle * RADIAN);
    const y = cy + (radius + (delta)) * Math.sin(-midAngle * RADIAN);
    return value > 0 ? (
        <text x={x} y={y} fill={color} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" fontWeight="normal">
            {value}
        </text>
    ) : '';
};
function renderCustomizedLabelLine(props: ParametrosRenderCustomizedLabel) {
    let { cx, cy, midAngle, innerRadius, outerRadius, color, startAngle, endAngle, value } = props;
    const RADIAN = Math.PI / 180;
    const diffAngle = endAngle - startAngle;
    const radius = innerRadius + (outerRadius - innerRadius);
    let path = '';
    for (let i = 0; i < ((360 - diffAngle) / 15); i++) {
        // path += `${(cx + (radius + i) * Math.cos(-midAngle * RADIAN))},${(cy + (radius + i * i) * Math.sin(-midAngle * RADIAN))} `
        path += `${(cx + (radius + i) * Math.cos(-midAngle * RADIAN))},${(cy + (radius + i) * Math.sin(-midAngle * RADIAN))} `
    }
    return value > 0 ? (
        <polyline points={path} stroke={color ?? '#000000'} fill="none" />
    ) : <></>;
}
export function InformeEstadisticas() {
    let { mostrarError } = useContext(AppContext);
    let api = useApi();
    let schema = Yup.object({
        'Año': Yup.number().nullable().required('Debe ingresar el año').typeError('Debe ingresar un número')
            .integer('Debe ingresar un número entero'),
        'Mes': Yup.string().nullable().required('Debe seleccionar el mes')
    });
    const ahora = DateTime.local();
    let [estado, updateEstado] = useState({
        notFound: false,
        estadisticas: null as any
    });
    let [cargando, updateCargando] = useState(false);
    const listaCanal = convertirALista(estado.estadisticas?.Canal);
    const listaTipoDespacho = convertirALista(estado.estadisticas?.TipoDespacho);
    const listaInternosPorUsuario = convertirALista(estado.estadisticas?.InternosPorUsuario?.Valor);
    async function submit(values: any) {
        try {
            updateCargando(true);
            let respuesta = await api.getEstadisticas(parseInt(values.Año), parseInt(values.Mes));
            if (respuesta) {
                updateEstado({ notFound: false, estadisticas: respuesta });
            } else {
                updateEstado({ notFound: true, estadisticas: null });
            }
            updateCargando(false);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al consultar estadisticas', error);
                mostrarError('Error al consultar estadisticas');
                updateEstado({ notFound: false, estadisticas: null });
                updateCargando(false);
            }
        }
    }
    return <>
        <h2>Estadisticas</h2>
        <Formik validationSchema={schema} initialValues={{ "Año": ahora.year, "Mes": ahora.month }} onSubmit={submit}>
            <MyForm inline>
                <Form.Group className="mr-2">
                    <MyFormControl type="number" name="Año" label="Año"></MyFormControl>
                </Form.Group>
                <Form.Group className="mr-2">
                    <MySelect name="Mes" options={optionsMes} label="Mes" width="15em"></MySelect>
                </Form.Group>
                <Button type="submit">Ver Estadisticas</Button>
            </MyForm>
        </Formik>
        <BlockUi blocking={cargando} renderChildren={false}>
            {estado.notFound ? <p className="lead">No se encontraron las estadisticas para el periodo ingresado</p> : <>
                {estado.estadisticas && <>
                    <Container>
                        <Row>
                            <Col>
                                <p className="text-center lead">Despachos por Canal</p>
                                <ResponsiveContainer width="100%" height={500}>
                                    <PieChart margin={{ top: 100, right: 100, left: 100, bottom: 100 }}>
                                        <Pie data={listaCanal} dataKey="value" nameKey="name" legendType="circle"
                                            label={renderCustomizedLabel} labelLine={renderCustomizedLabelLine} minAngle={1}>
                                            {listaCanal.map(item => <Cell key={`cell-${item.name}`}
                                                fill={coloresCanal[item.name]}></Cell>)}
                                        </Pie>
                                        <Legend></Legend>
                                        <Tooltip></Tooltip>
                                    </PieChart>
                                </ResponsiveContainer>
                            </Col>
                            <Col>
                                <p className="text-center lead">Despachos por Tipo de Operación</p>
                                <ResponsiveContainer width="100%" height={500}>
                                    <PieChart width={500} margin={{ top: 100, right: 100, left: 100, bottom: 100 }}>
                                        <Pie data={listaTipoDespacho} dataKey="value" nameKey="name" legendType="circle"
                                            label={renderCustomizedLabel} labelLine={renderCustomizedLabelLine} minAngle={1}>
                                            {listaTipoDespacho.map(item => <Cell key={`cell-${item.name}`}
                                                fill={coloresTipoDespacho[item.name]}></Cell>)}
                                        </Pie>
                                        <Legend></Legend>
                                        <Tooltip></Tooltip>
                                    </PieChart>
                                </ResponsiveContainer>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className="text-center lead">Carpetas por Usuario</p>
                                {listaInternosPorUsuario.length > 0 ?
                                    <ResponsiveContainer width="100%" height={500}>
                                        <BarChart data={listaInternosPorUsuario}
                                            margin={{ left: 150 }} layout="vertical">
                                            <CartesianGrid></CartesianGrid>
                                            <XAxis type="number"></XAxis>
                                            <YAxis type="category" dataKey="name"></YAxis>
                                            <Tooltip></Tooltip>
                                            <Legend></Legend>
                                            <Bar dataKey="value" name="Cantidad"></Bar>
                                        </BarChart>
                                    </ResponsiveContainer>
                                    : <p className="text-center">No hubo ningún interno creado este mes</p>}
                            </Col>
                        </Row>
                    </Container>
                </>}
            </>}
        </BlockUi>
    </>;
}