import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../App";
import Grilla, { GrillaRef, TipoCampo } from "../../../Grilla";
import { cargarDatosGrillaDeArray, useDecodedParams } from "../../../Utilidades";
import { MyModal } from "../../../MyModal";
import { Modal, Button } from "react-bootstrap";
import FileSaver from "file-saver";
import Loader from "react-loaders";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { useApi } from "ApiHooks";

export function ReporteSubitemsValorCriterio() {
    let { interno } = useDecodedParams() as { interno: string };
    let { mostrarError } = useContext(AppContext);
    let [cargando, updateCargando] = useState(true);
    let api = useApi();
    let refGrilla = useRef<GrillaRef>(null);
    let [reporte, updateReporte] = useState([]);
    let [generandoExcel, updateGenerandoExcel] = useState(false);
    useEffect(() => {
        async function cargar() {
            try {
                refGrilla.current?.setBloqueado(true);
                let reporte = await api.getReporteSubitemsCaratulaConValorCriterio(interno);
                let unidades = await api.getUnidades();
                let paises = await api.getPaises();
                updateReporte(reporte.sort((a: any, b: any) => a.Orden - b.Orden).map((item: any) => ({
                    ...item,
                    NombreUnidadDeclarada: unidades.find((unidad: any) => unidad.Codigo === item.CodigoUnidadDeclarada)?.Descripcion ?? item.CodigoUnidadDeclarada,
                    NombreUnidadValorCriterio: unidades.find((unidad: any) => unidad.Codigo === item.CodigoUnidadValorCriterio)?.Descripcion ?? item.CodigoUnidadValorCriterio,
                    NombrePaisOrigen: paises.find((pais: any) => pais.Codigo === item.CodigoPaisOrigen)?.Descripcion ?? item.CodigoPaisOrigen
                })));
                updateCargando(false);
                refGrilla.current?.setBloqueado(false);
                refGrilla.current?.recargar();
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error(error);
                    mostrarError('Error al cargar reporte subitems detallado');
                }
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);
    async function exportarAExcel() {
        try {
            updateGenerandoExcel(true);
            let { excel, fileName } = await api.exportarReporteSubitemsCaratulaConValorCriterioAExcel(interno);
            updateGenerandoExcel(false);
            FileSaver.saveAs(excel, fileName);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al exportar reporte subitems valor criterio a Excel');
            }
            if (!api.isUnmounted()) {
                updateGenerandoExcel(false);
            }
        }
    }
    let campos = [{ propiedad: 'Orden', titulo: 'Orden', clave: true }, { titulo: 'Catalogo', propiedad: 'Catalogo' },
    { titulo: 'Articulo', propiedad: 'Articulo' }, { titulo: 'Nomenclador', propiedad: 'Ncm' },
    { titulo: 'Pais Origen', propiedad: 'NombrePaisOrigen' },
    { titulo: 'Sufijos', propiedad: 'Sufijos' }, { titulo: 'Unidad Declarada', propiedad: 'NombreUnidadDeclarada' },
    { titulo: 'Precio Unitario', propiedad: 'PrecioUnitario', tipo: TipoCampo.Number, decimales: 5 },
    { titulo: 'Unidad Valor Criterio', propiedad: 'NombreUnidadValorCriterio' },
    { titulo: 'Precio Unitario Valor Criterio', propiedad: 'PrecioUnitarioValorCriterio', tipo: TipoCampo.Number, decimales: 5 }];
    return (<>
        <h2>Reporte Subitems Valor Criterio de <Link to={'/caratulas/' + encodeURIComponent(interno)}>{interno}</Link></h2>
        <Button variant="success" className="mb-2" onClick={exportarAExcel} disabled={cargando}>
            <FontAwesomeIcon icon={faFileExcel} />
            <span>Exportar a Excel</span>
        </Button>
        <Grilla ref={refGrilla} campos={campos} responsiva cargarDatos={cargarDatosGrillaDeArray(reporte)}></Grilla>
        <MyModal show={generandoExcel}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead">Generando Excel...</p>
                    <div className="loader-container">
                        <Loader type="ball-spin-fade-loader" active></Loader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        api.cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
    </>);
}