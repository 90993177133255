import React from "react";
import BlockUi from "react-block-ui";
import { Col, Form, Button, Nav, Table, Modal } from "react-bootstrap";
import Worker from "worker-loader!./CargaMasivaWorker"; //eslint-disable-line import/no-webpack-loader-syntax
import * as Comlink from "comlink";
import XLSX from "xlsx";
import { BaseSelect as Select } from "BaseSelect";
import { Formik, useFormikContext, ErrorMessage, FormikHelpers, FormikProps } from "formik";
import { MyForm, MyFormCheck, MyFormControl, MySelect, SelectOption } from "../../../FormikHooks";
import * as Yup from "yup";
import { mostrarError } from "../../../App";
import { intersect, isNullOrWhiteSpace, strCmp } from "../../../Utilidades";
import bsCustomFileInput from "bs-custom-file-input";
import { MyModal } from "MyModal";
import { AltaBajaMapeosPais, AltaBajaMapeosPaisRef } from "./AltaBajaMapeosPais";
import { AltaBajaMapeosUnidad, AltaBajaMapeosUnidadRef } from "./AltaBajaMapeosUnidad";
import { AltaBajaMapeosEstadoMerc, AltaBajaMapeosEstadoMercRef } from "./AltaBajaMapeosEstadoMerc";
import { useApi, TipoLock } from "ApiHooks";

const FormCol = (props: React.PropsWithChildren<any>) => {
    let { className, children, ...otrosProps } = props;
    className = (className ?? '') + ' col d-flex flex-column justify-content-end';
    return <div className={className} {...otrosProps}>
        {children}
    </div>
}

class ErrorCargaMasiva extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'ErrorCargaMasiva';
    }
}

export enum CamposCargaMasiva {
    CodigoArticulo = 1,
    Descripcion,
    DescripcionMaria,
    EstadoMercaderia,
    NCM,
    KiloUnitario,
    PaisOrigen,
    Unidad,
    Sufijos,
    Marca,
    Modelo,
    Observaciones,
    DJCP,
    PrecioUnitario,
    CertificadoOrigen,
    EstadoArticulo,
    SufijoABAI,
    SufijoAS,
    SufijoZA,
    SufijoZB,
    SufijoZC,
    SufijosParcial
}

const opcionesCampos = [
    { value: CamposCargaMasiva.CodigoArticulo, label: 'Codigo Articulo' },
    { value: CamposCargaMasiva.Descripcion, label: 'Descripción Catalogo' },
    { value: CamposCargaMasiva.DescripcionMaria, label: 'Descripción María' },
    { value: CamposCargaMasiva.EstadoMercaderia, label: 'Estado Mercadería' },
    { value: CamposCargaMasiva.NCM, label: 'NCM' },
    { value: CamposCargaMasiva.KiloUnitario, label: 'Kg Unitario' },
    { value: CamposCargaMasiva.PaisOrigen, label: 'País Origen' },
    { value: CamposCargaMasiva.Unidad, label: 'Unidad' },
    { value: CamposCargaMasiva.Sufijos, label: 'Sufijos' },
    { value: CamposCargaMasiva.Marca, label: 'Marca' },
    { value: CamposCargaMasiva.Modelo, label: 'Modelo' },
    { value: CamposCargaMasiva.Observaciones, label: 'Observaciones' },
    { value: CamposCargaMasiva.DJCP, label: 'DJCP' },
    { value: CamposCargaMasiva.PrecioUnitario, label: 'Precio Unitario' },
    { value: CamposCargaMasiva.CertificadoOrigen, label: 'Certificado Origen' },
    { value: CamposCargaMasiva.EstadoArticulo, label: 'Estado Articulo' },
    { value: CamposCargaMasiva.SufijoABAI, label: 'Sufijo AB/AI' },
    { value: CamposCargaMasiva.SufijoAS, label: 'Sufijo AS' },
    { value: CamposCargaMasiva.SufijoZA, label: 'Sufijo ZA' },
    { value: CamposCargaMasiva.SufijoZB, label: 'Sufijo ZB' },
    { value: CamposCargaMasiva.SufijoZC, label: 'Sufijo ZC' },
    { value: CamposCargaMasiva.SufijosParcial, label: 'Sufijos (Parcial)' }
];

const camposParteSufijos = [CamposCargaMasiva.SufijoABAI, CamposCargaMasiva.SufijoAS,
CamposCargaMasiva.SufijoZA, CamposCargaMasiva.SufijoZB, CamposCargaMasiva.SufijoZC,
CamposCargaMasiva.SufijosParcial, CamposCargaMasiva.Marca, CamposCargaMasiva.Modelo];

function TablaExcel(props: { hoja: XLSX.Sheet }) {
    let primerHoja = React.useRef(true);
    let formikContext = useFormikContext();
    React.useEffect(() => {
        if (primerHoja.current) {
            primerHoja.current = false;
        } else {
            formikContext.setFieldValue('campos', []);
        }
        //eslint-disable-next-line
    }, [props.hoja]);
    if (!props.hoja['!ref']) {
        return (<p className="lead">Esta hoja está vacía</p>);
    }
    let rango = XLSX.utils.decode_range(props.hoja['!ref']);
    let columnasHead = [];
    let filasBody = [];
    let ultimaColumna = 0;
    let negrita = true;
    for (let fila = rango.s.r; fila <= Math.min(rango.s.r + 50, rango.e.r); fila++) {
        let columnas = [];
        for (let columna = rango.s.c; columna <= Math.min(rango.s.c + 50, rango.e.c); columna++) {
            if (columna > ultimaColumna) {
                ultimaColumna = columna;
            }
            let celda = props.hoja[XLSX.utils.encode_cell({ c: columna, r: fila })];
            columnas.push(<td className={negrita ? 'font-weight-bold' : ''} key={columna}>{celda?.w || celda?.v}</td>);
        }
        filasBody.push((<tr key={fila}>{columnas}</tr>));
        negrita = false;
    }
    for (let i = 0; i <= ultimaColumna; i++) {
        let idCampo = `campos[${i}]`;
        let values = formikContext.values as any;
        columnasHead.push(<th key={i}><Select name={idCampo} id={idCampo} options={opcionesCampos.sort((a, b) => strCmp(a.label, b.label))}
            onBlur={() => formikContext.setFieldTouched(idCampo)}
            onChange={(option: any) => formikContext.setFieldValue(idCampo, option?.value)}
            value={opcionesCampos.find(c => c.value === values.campos[i]) ?? null}
            isClearable placeholder="Seleccionar..."></Select></th>)
    }
    return (
        <div className="table-responsive" style={{ height: '40vh' }}>
            <Table bordered size="sm" className="tablaExcel">
                <thead>
                    <tr>
                        {columnasHead}
                    </tr>
                </thead>
                <tbody>
                    {filasBody}
                </tbody>
            </Table>
        </div>);
}
export function ImportExcel(props: {
    onImport: (campos: Array<CamposCargaMasiva>,
        catalogo: string, validarNcm: boolean, articulos: any[], valoresPaisesSinMapeo: string[],
        valoresUnidadesSinMapeo: string[], valoresEstadosMercSinMapeo: string[]) => void
}) {
    let api = useApi();
    let formikRef = React.useRef<FormikProps<any>>(null);
    let formikCrearCatalogoRef = React.useRef<FormikProps<any>>(null);
    let altaBajaMapeosPaisRef = React.useRef<AltaBajaMapeosPaisRef>(null);
    let altaBajaMapeosUnidadRef = React.useRef<AltaBajaMapeosUnidadRef>(null);
    let altaBajaMapeosEstadoMercRef = React.useRef<AltaBajaMapeosEstadoMercRef>(null);
    let [estado, updateEstado] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'cargarExcel') {
            return { ...estado, cargando: true, mensajeCarga: "Cargando Excel..." };
        } else if (accion.tipo === 'errorFaltaArchivo') {
            return { ...estado, cargando: false, errorArchivo: 'Debe seleccionar un archivo Excel' };
        } else if (accion.tipo === 'errorLectura') {
            return { ...estado, cargando: false, errorArchivo: 'Hubo un error al leer el archivo' };
        } else if (accion.tipo === 'cancelarCarga') {
            return { ...estado, cargando: false, errorArchivo: '' };
        } else if (accion.tipo === 'excelCargado') {
            return {
                ...estado, cargando: false, workbook: accion.workbook,
                hojaActual: accion.workbook.SheetNames[0]
            };
        } else if (accion.tipo === 'cambiarHoja') {
            return { ...estado, hojaActual: accion.hoja };
        } else if (accion.tipo === 'primeraCarga') {
            return {
                cargando: false, mensajeCarga: "", catalogos: accion.catalogos, paises: accion.paises, unidades: accion.unidades,
                estadosMerc: accion.estadosMerc, mapeoPaises: accion.mapeoPaises, mapeoUnidades: accion.mapeoUnidades,
                mapeoEstadosMerc: accion.mapeoEstadosMerc,
                optionsCatalogos: accion.catalogos.map((catalogo: any) => ({ value: catalogo.Codigo, label: catalogo.Descripcion })),
                mostrarModalCrearCatalogo: false, catalogoInsertado: '',
            };
        } else if (accion.tipo === 'setMostrarModalCrearCatalogo') {
            return { ...estado, mostrarModalCrearCatalogo: accion.valor };
        } else if (accion.tipo === 'insertCatalogo') {
            let catalogos = Array.from(estado.catalogos);
            let optionsCatalogos = Array.from(estado.optionsCatalogos);
            catalogos.push(accion.catalogo);
            optionsCatalogos.push({ value: accion.catalogo.Codigo, label: accion.catalogo.Descripcion });
            optionsCatalogos = optionsCatalogos.sort((a: any, b: any) => strCmp(a.Codigo, b.Codigo));
            return { ...estado, catalogos: catalogos, optionsCatalogos: optionsCatalogos, catalogoInsertado: accion.catalogo.Codigo };
        }
        return { ...estado };
    }, { cargando: true, mensajeCarga: "Cargando catalogos...", catalogoInsertado: '' });
    let updateEstadoSiNoCancelado = (accion: any) => {
        if (!api.isUnmounted()) {
            updateEstado(accion);
        }
    };
    React.useEffect(() => {
        async function cargar() {
            try {
                let catalogos = await api.getCatalogos();
                let paises = await api.getPaises();
                let unidades = await api.getUnidades();
                let estadosMerc = await api.getEstadosMercaderia();
                let mapeoPaises = await api.busquedaMapeosPais();
                let mapeoUnidades = await api.busquedaMapeosUnidad();
                let mapeoEstadosMerc = await api.busquedaMapeosUnidad();
                updateEstado({
                    tipo: 'primeraCarga', catalogos: catalogos, paises: paises,
                    unidades: unidades, estadosMerc: estadosMerc, mapeoPaises: mapeoPaises,
                    mapeoUnidades: mapeoUnidades, mapeoEstadosMerc: mapeoEstadosMerc
                });
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar datos', error);
                    mostrarError('Error al cargar datos');
                }
            }
        }
        bsCustomFileInput.init();
        cargar();
        //eslint-disable-next-line
    }, []);
    React.useEffect(() => {
        if (!isNullOrWhiteSpace(estado.catalogoInsertado)) {
            formikRef.current?.setFieldValue('catalogo', estado.catalogoInsertado);
        }
    }, [estado.catalogoInsertado]);
    function excelSeleccionado(event: React.ChangeEvent<HTMLInputElement>) {
        if (!event.target.files || event.target.files.length === 0) {
            updateEstado({ tipo: 'errorFaltaArchivo' });
            return;
        }
        const reader = new FileReader();
        reader.onload = async loadEvent => {
            if (!loadEvent.target) {
                console.error('Loadevent.target es null');
                updateEstadoSiNoCancelado({ tipo: 'errorLectura' });
                return;
            }
            if (!loadEvent.target.result) {
                console.error('Loadevent.target.result es null');
                updateEstadoSiNoCancelado({ tipo: 'errorLectura' });
                return;
            }
            const leerExcel = Comlink.wrap<(buffer: ArrayBuffer) => any>(new Worker());
            try {
                let workbook = await leerExcel(Comlink.transfer(loadEvent.target.result as ArrayBuffer, [loadEvent.target.result as ArrayBuffer]));
                updateEstadoSiNoCancelado({ tipo: 'excelCargado', workbook: workbook });
            } catch (error) {
                console.error('Error al leer Excel', error);
                updateEstadoSiNoCancelado({ tipo: 'errorLectura' });
            }
        }
        reader.onerror = errorEvent => {
            console.error('Error al leer el archivo excel', errorEvent.target?.error);
            updateEstadoSiNoCancelado({ tipo: 'errorLectura' });
        }
        updateEstado({ tipo: 'cargarExcel' });
        reader.readAsArrayBuffer(event.target.files[0]);
    }
    function hojaSeleccionada(nombreHoja: string | null, event: React.SyntheticEvent<unknown>) {
        if (nombreHoja) {
            updateEstado({ tipo: 'cambiarHoja', hoja: nombreHoja });
        }
    }
    let validationSchema = null;
    if (estado.workbook) {
        let hoja = estado.workbook.Sheets[estado.hojaActual];
        let ultimaFila = Number.POSITIVE_INFINITY;
        if (hoja['!ref']) {
            let rango = XLSX.utils.decode_range(hoja['!ref']);
            ultimaFila = rango.e.r;
        }
        validationSchema = Yup.object({
            campos: Yup.array().required('Debe seleccionar los campos que corresponden a cada columna del Excel')
                .test('codigo-articulo', 'Debe seleccionar la columna con el codigo de articulo',
                    (valores: any) => valores?.includes(CamposCargaMasiva.CodigoArticulo))
                .test('campos-sufijos', 'El campo Sufijos no puede seleccionarse con ninguno de los siguientes campos: Marca,Modelo,Sufijo AB/AI,Sufijos Parcial,Sufijo AS,Sufijo ZA,Sufijo ZB,Sufijo ZC',
                    (valores: any) => {
                        if (valores === null || valores === undefined) {
                            return true;
                        }
                        if (valores.includes(CamposCargaMasiva.Sufijos) && intersect(valores, camposParteSufijos).length > 0) {
                            return false;
                        }
                        return true;
                    })
                .test('validar-ncm', 'Los siguientes campos solo se pueden seleccionar si se valida el NCM: Marca,Modelo,Sufijo AB/AI,Sufijos Parcial,Sufijo AS,Sufijo ZA,Sufijo ZB,Sufijo ZC',
                    function (valores: any) {
                        if (valores === null || valores === undefined) {
                            return true;
                        }
                        const validarNcm = this.resolve(Yup.ref('validarNcm'));
                        if (!validarNcm && intersect(valores, camposParteSufijos).length > 0) {
                            return false;
                        }
                        return true;
                    }).test('sin-duplicados', 'No puede seleccionar el mismo campo en dos columnas distintas',
                        (valores: any) => {
                            if (valores === null || valores === undefined) {
                                return true;
                            }
                            let lista: any[] = [];
                            let valido = true;
                            valores.forEach((valor: any) => {
                                if (valor !== null && valor !== undefined) {
                                    if (lista.includes(valor)) {
                                        valido = false;
                                    } else {
                                        lista.push(valor);
                                    }
                                }
                            });
                            return valido;
                        }),
            catalogo: Yup.string().nullable().required('Debe seleccionar el catalogo'),
            primeraFila: Yup.number().nullable().typeError('Debe ingresar un número')
                .required('Debe ingresar la primera fila')
                .integer('Debe ser un número entero')
                .min(1, 'La primera fila debe ser mayor o igual a 1')
                .max(ultimaFila, 'La primera fila debe ser menor o igual a ' + ultimaFila),
            ultimaFila: Yup.number().nullable().typeError('Debe ingresar un número')
                .integer('Debe ser un número entero')
                .moreThan(Yup.ref('primeraFila'), 'La ultima fila debe ser mayor a la primera fila'),
            validarNcm: Yup.boolean().nullable()
        });
    };
    function importarArticulos(catalogo: string, primeraFila: number, ultimaFila: number | undefined, campos: Array<CamposCargaMasiva>) {
        let articulos: any[] = [];
        let valoresPaisesSinMapeo: string[] = [];
        let valoresUnidadesSinMapeo: string[] = [];
        let valoresEstadosMercSinMapeo: string[] = [];
        let hoja = estado.workbook.Sheets[estado.hojaActual] as XLSX.Sheet;
        if (!hoja['!ref']) {
            throw new ErrorCargaMasiva('La hoja está vacía. Seleccione otra hoja.');
        }
        let rango = XLSX.utils.decode_range(hoja['!ref']);
        ultimaFila = ultimaFila ?? rango.e.r;
        if (ultimaFila > rango.e.r) {
            ultimaFila = rango.e.r;
        }
        for (let i = primeraFila; i <= ultimaFila; i++) {
            //saltear filas vacias
            let filaVacia = true;
            for (let j = 0; j < campos.length; j++) {
                let celda = hoja[XLSX.utils.encode_cell({ c: j, r: i })];
                if (celda !== undefined && (celda.t !== 'z' || (celda.t === 's' && isNullOrWhiteSpace(celda.v)))) {
                    filaVacia = false;
                }
            }
            if (filaVacia) {
                continue;
            }
            let articulo = { CodigoCatalogo: catalogo } as any;
            campos.forEach((valor: CamposCargaMasiva, indice: number) => {
                let celda = hoja[XLSX.utils.encode_cell({ c: indice, r: i })];
                const mensajeErrorNumero = 'Se intentó obtener un número de la celda ' + XLSX.utils.encode_cell({ c: indice, r: i });
                switch (valor) {
                    case CamposCargaMasiva.CodigoArticulo:
                        if (isNullOrWhiteSpace(celda?.w)) {
                            throw new ErrorCargaMasiva(`El codigo de articulo en la fila ${i + 1} está en blanco`);
                        }
                        articulo.CodigoArticulo = celda.w.trim();
                        break;
                    case CamposCargaMasiva.Descripcion:
                        articulo.Descripcion = celda?.w?.trim();
                        break;
                    case CamposCargaMasiva.DescripcionMaria:
                        articulo.DescripcionMaria = celda?.w?.trim();
                        break;
                    case CamposCargaMasiva.KiloUnitario:
                        if (celda?.t === 'n') {
                            articulo.KgNetoUnitario = celda?.v;
                        } else {
                            throw new ErrorCargaMasiva(mensajeErrorNumero);
                        }
                        break;
                    case CamposCargaMasiva.NCM:
                        articulo.Ncm = celda?.w?.replace(/\s+/g, '')?.toUpperCase();
                        break;
                    case CamposCargaMasiva.PaisOrigen:
                        if (!isNullOrWhiteSpace(celda?.w)) {
                            let valor = celda.w.trim();
                            let pais = estado.paises.find((pais: any) => pais.Codigo === valor);
                            if (pais) {
                                articulo.CodigoPaisOrigen = valor;
                            } else {
                                if (!valoresPaisesSinMapeo.includes(valor)) {
                                    let mapeo = estado.mapeoPaises.find((mapeo: any) => mapeo.Valor === valor);
                                    if (mapeo) {
                                        articulo.CodigoPaisOrigen = mapeo.CodigoPais;
                                    } else {
                                        valoresPaisesSinMapeo.push(valor);
                                    }
                                }
                                articulo.ValorPaisOrigenExcel = valor;
                            }
                        }
                        break;
                    case CamposCargaMasiva.Unidad:
                        if (!isNullOrWhiteSpace(celda?.w)) {
                            let valor = celda.w.trim();
                            let unidad = estado.unidades.find((unidad: any) => unidad.Codigo === valor);
                            if (unidad) {
                                articulo.CodigoUnidadDeclarada = valor;
                            } else {
                                if (!valoresUnidadesSinMapeo.includes(valor)) {
                                    let mapeo = estado.mapeoUnidades.find((mapeo: any) => mapeo.Valor === valor);
                                    if (mapeo) {
                                        articulo.CodigoUnidadDeclarada = valor.CodigoUnidad;
                                    } else {
                                        valoresUnidadesSinMapeo.push(valor);
                                    }
                                }
                                articulo.ValorUnidadExcel = valor;
                            }
                        }
                        break;
                    case CamposCargaMasiva.EstadoMercaderia:
                        if (!isNullOrWhiteSpace(celda?.w)) {
                            let valor = celda.w.trim();
                            let estadoMerc = estado.estadosMerc.find((estadoMerc: any) => estadoMerc.Codigo === valor);
                            if (estadoMerc) {
                                articulo.CodigoEstadoMercaderia = valor;
                            } else {
                                if (!valoresEstadosMercSinMapeo.includes(valor)) {
                                    let mapeo = estado.mapeoEstadosMerc.find((mapeo: any) => mapeo.Valor === valor);
                                    if (mapeo) {
                                        articulo.CodigoEstadoMercaderia = mapeo.CodigoEstadoMercaderia;
                                    } else {
                                        valoresEstadosMercSinMapeo.push(valor);
                                    }
                                }
                                articulo.ValorEstadoMercaderiaExcel = valor;
                            }
                        }
                        break;
                    case CamposCargaMasiva.Sufijos:
                        articulo.Sufijos = celda?.w?.trim();
                        break;
                    case CamposCargaMasiva.Marca:
                        articulo.Marca = celda?.w?.trim();
                        break;
                    case CamposCargaMasiva.Modelo:
                        articulo.Modelo = celda?.w?.trim();
                        break;
                    case CamposCargaMasiva.Observaciones:
                        articulo.Observaciones = isNullOrWhiteSpace(celda?.w) ? [] : [celda.w.trim()];
                        break;
                    case CamposCargaMasiva.DJCP:
                        articulo.DJCP = celda?.w?.trim();
                        break;
                    case CamposCargaMasiva.PrecioUnitario:
                        if (celda?.t === 'n') {
                            articulo.PrecioUnitario = celda.v;
                        } else {
                            throw new ErrorCargaMasiva(mensajeErrorNumero);
                        }
                        break;
                    case CamposCargaMasiva.CertificadoOrigen:
                        let valor = celda?.w?.trim()?.toUpperCase();
                        if (valor === 'SI' || valor === 'SÍ') {
                            articulo.CertificadoOrigen = true;
                        } else if (valor === 'NO') {
                            articulo.CertificadoOrigen = false;
                        } else {
                            throw new ErrorCargaMasiva('La celda ' + XLSX.utils.encode_cell({ c: indice, r: i }) + ' tiene un valor invalido. Para el campo certificado origen el valor de la celda debe ser SI o NO');
                        }
                        break;
                    case CamposCargaMasiva.EstadoArticulo:
                        articulo.Estado = celda?.w?.trim();
                        break;
                    case CamposCargaMasiva.SufijoABAI:
                        articulo.SufijoABAI = celda?.w?.trim();
                        break;
                    case CamposCargaMasiva.SufijoAS:
                        articulo.SufijoAS = celda?.w?.trim();
                        break;
                    case CamposCargaMasiva.SufijosParcial:
                        articulo.SufijosParcial = celda?.w?.trim();
                        break;
                    case CamposCargaMasiva.SufijoZA:
                        if (celda?.t === 'n') {
                            articulo.SufijoZA = Math.floor(celda.v);
                        } else {
                            throw new ErrorCargaMasiva(mensajeErrorNumero);
                        }
                        break;
                    case CamposCargaMasiva.SufijoZB:
                        if (celda?.t === 'n') {
                            articulo.SufijoZB = Math.floor(celda.v);
                        } else {
                            throw new ErrorCargaMasiva(mensajeErrorNumero);
                        }
                        break;
                    case CamposCargaMasiva.SufijoZC:
                        if (celda?.t === 'n') {
                            articulo.SufijoZC = Math.floor(celda.v);
                        } else {
                            throw new ErrorCargaMasiva(mensajeErrorNumero);
                        }
                        break;
                }
            });
            articulos.push(articulo);
        }
        return {
            articulos: articulos, valoresPaisesSinMapeo: valoresPaisesSinMapeo,
            valoresUnidadesSinMapeo: valoresUnidadesSinMapeo,
            valoresEstadosMercSinMapeo: valoresEstadosMercSinMapeo
        };
    }
    function submit(values: any, helpers: FormikHelpers<any>) {
        try {
            let resultado = importarArticulos(values.catalogo, values.primeraFila, values.ultimaFila, values.campos);
            if (!resultado.articulos) {
                mostrarError('El excel no tiene ningún artículo');
            } else {
                props.onImport(values.campos, values.catalogo, values.validarNcm ?? false,
                    resultado.articulos, resultado.valoresPaisesSinMapeo, resultado.valoresUnidadesSinMapeo, resultado.valoresEstadosMercSinMapeo);
            }
            helpers.setSubmitting(false);
        } catch (error) {
            if (error instanceof ErrorCargaMasiva) {
                mostrarError(error.message);
            } else {
                console.error('Error al importar articulos del excel', error);
                mostrarError('Error al importar articulos del excel');
                updateEstado({ tipo: 'cancelarCarga' });
            }
            helpers.setSubmitting(false);
        }
    }
    async function crearCatalogo(values: { Codigo: string, Descripcion: string, SufijoAA: string }, helpers: FormikHelpers<any>) {
        try {
            if (estado.catalogos.map((c: any) => c.Codigo).includes(values.Codigo)) {
                helpers.setFieldError('Codigo', `El catalogo ${values.Codigo} ya existe`);
                return;
            }
            let puedeModificar = await api.obtenerLock(TipoLock.Catalogo, values.Codigo);
            if (puedeModificar) {
                let { exito, error } = await api.insertCatalogo(values);
                if (exito) {
                    await api.eliminarLock(TipoLock.Catalogo, values.Codigo);
                    updateEstado({ tipo: 'insertCatalogo', catalogo: values });
                    updateEstado({ tipo: 'setMostrarModalCrearCatalogo', valor: false });
                } else {
                    mostrarError(error as string);
                }
            } else {
                mostrarError(`El catalogo ${values.Codigo} ya existe`);
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al crear catalogo', error);
                mostrarError('Error al crear catalogo');
            }
        }
    }
    return (<>
        <BlockUi keepInView blocking={estado.cargando} message={estado.mensajeCarga}>
            <div style={{ minHeight: '5em' }}>
                <div className="mb-2">
                    <Button variant="secondary" className="mr-2" onClick={() => altaBajaMapeosPaisRef.current!.mostrar()}>Asignaciones de países</Button>
                    <Button variant="secondary" className="mr-2" onClick={() => altaBajaMapeosUnidadRef.current!.mostrar()}>Asignaciones de unidades</Button>
                    <Button variant="secondary" onClick={() => altaBajaMapeosEstadoMercRef.current!.mostrar()}>Asignaciones de estados mercadería</Button>
                </div>
                <Form>
                    <Form.File custom>
                        <Form.File.Input accept=".xls,.xlsx" isInvalid={estado.errorArchivo} onChange={excelSeleccionado}></Form.File.Input>
                        <Form.File.Label data-browse="Seleccionar">Archivo Excel</Form.File.Label>
                        <Form.Control.Feedback type="invalid">{estado.errorArchivo}</Form.Control.Feedback>
                    </Form.File>
                </Form>
                {estado.workbook && (
                    <Formik initialValues={{
                        campos: [],
                        catalogo: '',
                        primeraFila: 1,
                        ultimaFila: null
                    }} validationSchema={validationSchema} onSubmit={submit} innerRef={formikRef}>
                        <MyForm>
                            <Form.Row>
                                <Col xs={6}>
                                    <Form.Row>
                                        <Form.Group as={FormCol}>
                                            <MySelect name="catalogo" label="Catalogo"
                                                options={estado.optionsCatalogos ?? []}
                                                getOptionLabel={(option: SelectOption) => option.value + ' - ' + option.label}></MySelect>
                                        </Form.Group>
                                        <Form.Group as={FormCol}>
                                            <Button className="w-100" onClick={() => updateEstado({ tipo: 'setMostrarModalCrearCatalogo', valor: true })}>Crear catalogo</Button>
                                        </Form.Group>
                                    </Form.Row>
                                </Col>
                                <Form.Group as={Col} xs="auto" className="d-flex">
                                    <MyFormCheck name="validarNcm" label="Validar NCM" className="align-self-end mb-1"></MyFormCheck>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <MyFormControl type="number" name="primeraFila" label="Fila del encabezado"></MyFormControl>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <MyFormControl type="number" name="ultimaFila" label="Última fila"></MyFormControl>
                                </Form.Group>
                                <Form.Group as={Col} xs="auto" className="d-flex">
                                    <Button type="submit" className="align-self-end mb-1" disabled={!estado.workbook.Sheets[estado.hojaActual]['!ref']}>Procesar Datos</Button>
                                </Form.Group>
                            </Form.Row>
                            <ErrorMessage name="campos">
                                {msg => (<div className="invalid-feedback" style={{ display: 'block' }}> {msg}</div>)}
                            </ErrorMessage>
                            <Nav variant="tabs" activeKey={estado.hojaActual} onSelect={hojaSeleccionada} className="my-2">
                                {estado.workbook.SheetNames.map((nombreHoja: string) => (
                                    <Nav.Item key={nombreHoja}>
                                        <Nav.Link eventKey={nombreHoja}>{nombreHoja}</Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                            <TablaExcel hoja={estado.workbook.Sheets[estado.hojaActual]}></TablaExcel>
                        </MyForm>
                    </Formik>
                )}
            </div>
        </BlockUi>
        <MyModal show={estado.mostrarModalCrearCatalogo} onHide={() => updateEstado({ tipo: 'setMostrarModalCrearCatalogo', valor: false })}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    Crear catalogo
                </Modal.Header>
                <Formik innerRef={formikCrearCatalogoRef} onSubmit={crearCatalogo} validationSchema={Yup.object({
                    'Codigo': Yup.string().nullable().required('Debe ingresar el codigo'),
                    'Descripcion': Yup.string().nullable(),
                    'SufijoAA': Yup.string().nullable()
                })} initialValues={{ 'Codigo': '', 'Descripcion': '', 'SufijoAA': '' }}>
                    {({ isSubmitting, submitForm }) => <>
                        <Modal.Body>
                            <MyForm>
                                <Form.Group>
                                    <MyFormControl type="text" name="Codigo" label="Codigo"></MyFormControl>
                                </Form.Group>
                                <Form.Group>
                                    <MyFormControl type="text" name="Descripcion" label="Descripción"></MyFormControl>
                                </Form.Group>
                                <Form.Group>
                                    <MyFormControl type="text" name="SufijoAA" label="Sufijo AA"></MyFormControl>
                                </Form.Group>
                            </MyForm>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={() => updateEstado({ tipo: 'setMostrarModalCrearCatalogo', valor: false })}>
                                Cancelar
                            </Button>
                            <Button disabled={isSubmitting} onClick={submitForm}>
                                Ingresar
                            </Button>
                        </Modal.Footer>
                    </>}

                </Formik>

            </Modal.Dialog>
        </MyModal>
        <AltaBajaMapeosPais ref={altaBajaMapeosPaisRef}></AltaBajaMapeosPais>
        <AltaBajaMapeosUnidad ref={altaBajaMapeosUnidadRef}></AltaBajaMapeosUnidad>
        <AltaBajaMapeosEstadoMerc ref={altaBajaMapeosEstadoMercRef}></AltaBajaMapeosEstadoMerc>
    </>)
}