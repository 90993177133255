import React from "react";
import { Table } from "react-bootstrap";

export type ListBoxProps = {
    list: Array<any>,
    //key: string | ((item: any) => any),
    itemKey: any,
    render: (item: any) => JSX.Element,
    onSelect: (item: any) => void,
    selectedKey?: any
}

function getKeyFunction(key: string | ((item: any) => any)) {
    if (typeof key === 'string') {
        return (item: any) => {
            item = item ?? {};
            return item[key];
        };
    } else {
        return key;
    }
}

export function ListBox(props: ListBoxProps) {
    let tableRef = React.useRef<HTMLTableElement | null>(null);
    let getKey = React.useRef(getKeyFunction(props.itemKey));
    let getIndexOfSelectedKey = React.useCallback(() => {
        let index = props.list.map(getKey.current).indexOf(props.selectedKey);
        return index > -1 ? index : 0;
    }, [props.list, props.selectedKey]);
    let [selectedIndex, updateSelectedIndex] = React.useState(getIndexOfSelectedKey());
    React.useEffect(() => {
        tableRef.current?.focus();
    }, []);
    React.useEffect(() => {
        getKey.current = getKeyFunction(props.itemKey);
        updateSelectedIndex(getIndexOfSelectedKey());
    }, [props.list, props.selectedKey, props.itemKey, getIndexOfSelectedKey]);
    return (
        <Table ref={tableRef} bordered hover tabIndex={0} onKeyDown={(e: React.KeyboardEvent<HTMLTableElement>) => {
            if (e.key === 'ArrowUp') {
                if (selectedIndex > 0) {
                    updateSelectedIndex(selectedIndex - 1);
                }
                e.preventDefault();
            } else if (e.key === 'ArrowDown') {
                if (selectedIndex < props.list.length - 1) {
                    updateSelectedIndex(selectedIndex + 1);
                }
                e.preventDefault();
            } else if (e.key === 'Enter') {
                if (props.list.length > 0) {
                    props.onSelect(props.list[selectedIndex]);
                }
                e.preventDefault();
            }
        }}>
            <tbody>
                {props.list.map((item: any, index: number) => {
                    let className = index === selectedIndex ? 'selected' : '';
                    return (<tr key={getKey.current(item)} onClick={() => props.onSelect(item)} className={className}>
                        <td>
                            {props.render(item)}
                        </td>
                    </tr>)
                })}
            </tbody>
        </Table>)
}