import React, { forwardRef, useContext, useEffect, useImperativeHandle, useReducer, useRef, useState } from "react";
import { AppContext } from "App";
import { optionLabelConCodigo, convertirDatosGenericosAOption, toHtmlDate, isNullOrWhiteSpace, toDate, abrirPdf } from "Utilidades";
import * as Yup from "yup";
import { Field, Formik, FormikHelpers, FormikProps } from "formik";
import { MyForm, MyFormControl, MySelect, MySelectConOptionABM, MyTextarea, SelectOption } from "../../FormikHooks";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { MyModal } from "../../MyModal";
import { GrillaSync, TipoCampo } from "../../Grilla";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import { ReferenciasCaratula } from "./ReferenciasCaratula";
import { PlantillasCaratula, PlantillasCaratulaRef } from "./PlantillasCaratula";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useRedirectEventCallback } from "SintiaHooks";
import Loader from "react-loaders";
import { ModalImportadores } from "Paginas/Importadores/Importadores";
import { RutasVendedor } from "Paginas/Vendedores/Vendedores";
import InnerRouter from "InnerRouter";
import { Switch, Route } from "react-router-dom";
import { TipoLock, useApi } from "ApiHooks";
import styled from "styled-components";
import { DateTime } from "luxon";
enum EstadoModal {
    Cerrado,
    Creando,
    Modificando
}

enum EstadoCargando {
    CargaInicial,
    Cargando,
    Listo
}

const GrillaSyncReferencias = styled(GrillaSync)`
    & {
        th:nth-child(2),td:nth-child(2){
            min-width:10rem;
        }
    }
`;

export type EditarCarpetaRef = {
    preguntarGuardarCambios: () => Promise<boolean>
};
export const EditarCarpeta = forwardRef((props: {
    interno: string, bloquear: boolean,
    onPlantillaSeleccionada: () => void
}, ref: any) => {
    let bloquear = props.bloquear;
    let huboCambios = useRef<{ valor: boolean }>({ valor: false });
    let formikRef = useRef<FormikProps<any>>(null);
    let formikReferenciasRef = useRef<FormikProps<any>>(null);
    let dialogoRef = useRef<DialogoConfirmarRef>(null);
    let plantillasCaratulaRef = useRef<PlantillasCaratulaRef>(null);
    //eslint-disable-next-line
    let [interno, ...ignorar] = useState(props.interno);
    let { mostrarError, userInfo } = useContext(AppContext);
    let api = useApi();
    let [cargando, updateCargando] = useState(EstadoCargando.CargaInicial);
    let [camposSubregimen, updateCamposSubregimen] = React.useState<any>({});
    let [puedeModificarTipoOperacion, updatePuedeModificarTipoOperacion] = useState(true);
    let [optionsTipoOperacion, updateOptionsTipoOperacion] = useState<SelectOption[]>([]);
    let [fechaCreacion, updateFechaCreacion] = useState('');
    let [mostrarMensajeExito, updateMostrarMensajeExito] = useState(false);
    let [mensajeDialogo, updateMensajeDialogo] = useState('');
    let [generandoPdf, updateGenerandoPdf] = useState(false);
    let [ultimoBlobUrl, updateUltimoBlobUrl] = React.useState('');
    const estadoModalReducer = (estado: any, accion: any) => {
        if (accion.tipo === 'mostrar') {
            return { abierto: true, valorSeleccionado: null };
        } else if (accion.tipo === 'cerrar') {
            return { abierto: false, valorSeleccionado: accion.valor };
        }
        return estado;
    };
    const valorInicialEstadoModal = { abierto: false, valorSeleccionado: null };
    let [estadoModalImportadores, updateEstadoModalImportadores] = React.useReducer(estadoModalReducer, valorInicialEstadoModal);
    let [estadoModalDespachantes, updateEstadoModalDespachantes] = React.useReducer(estadoModalReducer, valorInicialEstadoModal);
    let [optionsImportadores, updateOptionsImportadores] = React.useState<SelectOption[]>([]);
    let [optionsDespachantes, updateOptionsDespachantes] = React.useState<SelectOption[]>([]);
    let [estadoModalVendedores, updateEstadoModalVendedores] = React.useReducer(estadoModalReducer, valorInicialEstadoModal);
    let [optionsVendedores, updateOptionsVendedores] = React.useState<SelectOption[]>([]);
    let [estadoReferencias, updateEstadoReferencias] = useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'setReferencias') {
            return { ...estado, referencias: accion.valor ?? [] };
        } else if (accion.tipo === 'insertReferencia') {
            let nuevasReferencias = Array.from(estado.referencias);
            let indice = nuevasReferencias.findIndex((r: any) => r.Nombre === accion.valor.Nombre);
            if (indice > -1) {
                nuevasReferencias.splice(indice, 1);
            }
            nuevasReferencias.push(accion.valor);
            return { referencias: nuevasReferencias, modal: EstadoModal.Cerrado, valorModificando: null, modalCargando: false };
        } else if (accion.tipo === 'insertVariasReferencias') {
            let nuevasReferencias = Array.from(estado.referencias);
            let nombresReferenciasQueExisten = nuevasReferencias.map((r: any) => r.Nombre);
            let referenciasAAgregar = accion.referencias?.filter((r: any) => !nombresReferenciasQueExisten.includes(r.Nombre)) ?? [];
            for (let item of referenciasAAgregar) {
                nuevasReferencias.push(item);
            }
            return { ...estado, referencias: nuevasReferencias };
        } else if (accion.tipo === 'deleteReferencia') {
            return {
                referencias: estado.referencias.filter((r: any) => r.Nombre !== accion.valor),
                modal: EstadoModal.Cerrado, valorModificando: null, modalCargando: false
            };
        } else if (accion.tipo === 'mostrarModalCrear') {
            return { ...estado, modal: EstadoModal.Creando, valorModificando: null, modalCargando: false };
        } else if (accion.tipo === 'mostrarModalModificar') {
            return { ...estado, modal: EstadoModal.Modificando, valorModificando: accion.valor, modalCargando: false };
        } else if (accion.tipo === 'cerrarModal') {
            return { ...estado, modal: EstadoModal.Cerrado, valorModificando: null, modalCargando: false };
        } else if (accion.tipo === 'setModalCargando') {
            return { ...estado, modalCargando: accion.valor };
        }
        return estado;
    }, { referencias: [], modal: EstadoModal.Cerrado, valorModificando: null, modalCargando: false });
    let [estadoModalTiposReferencia, updateEstadoModalTiposReferencia] = useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrar') {
            return { mostrar: true, valorSeleccionado: null, seleccion: accion.seleccion };
        } else if (accion.tipo === 'cerrar') {
            return { mostrar: false, valorSeleccionado: accion.valor, seleccion: false };
        }
        return estado;
    }, { mostrar: false, valorSeleccionado: null, cargando: false });
    let [optionsTiposReferencia, updateOptionsTiposReferencia] = useState<SelectOption[]>([]);
    useEffect(() => {
        async function cargar() {
            try {
                let caratula = await api.getCaratula(interno);
                if (!caratula) {
                    throw new Error(`Caratula con interno ${interno} no existe`);
                }
                await cargarImportadores();
                await cargarDespachantes();
                await cargarVendedores();
                await cargarTiposReferencia();
                let camposSubregimen = await api.getCamposSubregimen();
                let item = camposSubregimen.find((c: any) => c.Subregimen === caratula.CodigoSubregimen);
                if (item) {
                    updateCamposSubregimen(item.Campos);
                }
                let tiposOperacion = await api.getTiposOperacion();
                let options = tiposOperacion?.map((t: any) => ({ value: t.Codigo, label: t.Descripcion })) ?? [];
                if (!isNullOrWhiteSpace(caratula.CodigoTipoOperacion) && !options.map((t: any) => t.value).includes(caratula.CodigoTipoOperacion)) {
                    options.push({ value: caratula.CodigoTipoOperacion, label: caratula.TipoOperacion });
                }
                updateOptionsTipoOperacion(options);
                updatePuedeModificarTipoOperacion(caratula.PermitirModificarTipoOperacion ?? true);
                updateFechaCreacion(toDate(caratula.CreadoEl));
                updateEstadoReferencias({ tipo: 'setReferencias', valor: caratula.Referencias });
                formikRef.current?.resetForm({
                    values: {
                        'CodigoTipoOperacion': caratula.CodigoTipoOperacion,
                        'CuitImportador': caratula.CuitImportador,
                        'VuestraReferencia': caratula.IdentificadorExterno,
                        'CodigoAduana': caratula.CodigoAduana,
                        'CodigoPaisOrigen': caratula.CodigoPaisOrigen,
                        'CodigoVendedor': caratula.CodigoVendedor,
                        'FechaArriboAprox': toHtmlDate(caratula.FechaArriboAprox),
                        'DescripcionMercaderiaCaratula': caratula.DescripcionMercaderiaCaratula,
                        'CuitDespachante': caratula.CuitDespachante
                    }
                });
                huboCambios.current.valor = false;
                updateCargando(EstadoCargando.Listo);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar datos de carpeta', error);
                    mostrarError('Error al cargar datos de carpeta');
                }
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);
    React.useEffect(() => {
        async function cargar() {
            try {
                updateCargando(EstadoCargando.Cargando);
                await cargarDespachantes(estadoModalDespachantes.valorSeleccionado);
                updateCargando(EstadoCargando.Listo);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar despachantes', error);
                    mostrarError('Error al cargar despachantes');
                    updateCargando(EstadoCargando.Listo);
                }
            }
        }
        if (!estadoModalDespachantes.abierto && cargando !== EstadoCargando.CargaInicial) {
            cargar();
        }
        //eslint-disable-next-line
    }, [estadoModalDespachantes.abierto]);
    React.useEffect(() => {
        async function cargar() {
            try {
                updateCargando(EstadoCargando.Cargando);
                await cargarImportadores(estadoModalImportadores.valorSeleccionado);
                updateCargando(EstadoCargando.Listo);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar clientes', error);
                    mostrarError('Error al cargar clientes');
                    updateCargando(EstadoCargando.Listo);
                }
            }
        }
        if (!estadoModalImportadores.abierto && cargando !== EstadoCargando.CargaInicial) {
            cargar();
        }
        //eslint-disable-next-line
    }, [estadoModalImportadores.abierto]);
    React.useEffect(() => {
        async function cargar() {
            try {
                updateCargando(EstadoCargando.Cargando);
                await cargarVendedores(estadoModalVendedores.valorSeleccionado);
                updateCargando(EstadoCargando.Listo);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar vendedores', error);
                    mostrarError('Error al cargar vendedores');
                    updateCargando(EstadoCargando.Listo);
                }
            }
        }
        if (!estadoModalVendedores.abierto && cargando !== EstadoCargando.CargaInicial) {
            cargar();
        }
        //eslint-disable-next-line
    }, [estadoModalVendedores.abierto]);
    React.useEffect(() => {
        async function cargar() {
            try {
                updateEstadoReferencias({ tipo: 'setModalCargando', valor: true });
                await cargarTiposReferencia();
                if (!isNullOrWhiteSpace(estadoModalTiposReferencia.valorSeleccionado)) {
                    formikReferenciasRef.current?.setFieldValue('Nombre', estadoModalTiposReferencia.valorSeleccionado);
                }
                updateEstadoReferencias({ tipo: 'setModalCargando', valor: false });
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar tipos referencia', error);
                    mostrarError('Error al cargar referencias');
                }
                if (!api.isUnmounted()) {
                    updateEstadoReferencias({ tipo: 'setModalCargando', valor: false });
                }
            }
        }
        if (!estadoModalTiposReferencia.mostrar && cargando !== EstadoCargando.CargaInicial) {
            cargar();
        }
        //eslint-disable-next-line
    }, [estadoModalTiposReferencia.mostrar]);
    useEffect(() => {
        if (!isNullOrWhiteSpace(ultimoBlobUrl)) {
            return () => {
                URL.revokeObjectURL(ultimoBlobUrl);
            }
        }
    }, [ultimoBlobUrl]);
    async function cargarVendedores(valorSeleccionado?: string) {
        let respuesta = await api.getVendedores();
        let array = respuesta.map((item: any) => ({ value: item.Codigo, label: item.Nombre }));
        updateOptionsVendedores(array);
        if (!isNullOrWhiteSpace(valorSeleccionado)) {
            formikRef.current?.setFieldValue('CodigoVendedor', valorSeleccionado);
        }
    }
    async function cargarTiposReferencia() {
        let respuesta = await api.getTiposReferencia();
        updateOptionsTiposReferencia(respuesta.map((item: any) => ({ value: item.Nombre, label: item.Nombre })));
    }
    async function cargarImportadores(valorSeleccionado?: string) {
        let respuesta = await api.getImportadores();
        let array = respuesta.map((item: any) => ({ value: item.CUIT, label: item.Nombre }));
        updateOptionsImportadores(array);
        if (!isNullOrWhiteSpace(valorSeleccionado)) {
            formikRef.current?.setFieldValue('CuitImportador', valorSeleccionado);
        }
    }
    async function cargarDespachantes(valorSeleccionado?: string) {
        let respuesta = await api.getDespachantes();
        let array = respuesta.map((item: any) => ({ value: item.CUIT, label: item.Nombre }));
        updateOptionsDespachantes(array);
        if (!isNullOrWhiteSpace(valorSeleccionado)) {
            formikRef.current?.setFieldValue('CuitDespachante', valorSeleccionado);
        }
    }
    let schema = Yup.object({
        'CodigoTipoOperacion': Yup.string().nullable(),
        'CuitImportador': Yup.string().nullable(),
        'CuitDespachante': Yup.string().nullable(),
        'VuestraReferencia': Yup.string().nullable(),
        'CodigoAduana': Yup.string().nullable(),
        'CodigoPaisOrigen': Yup.string().nullable(),
        'CodigoVendedor': Yup.string().nullable(),
        'FechaArriboAprox': Yup.date().nullable(),
        'DescripcionMercaderiaCaratula': Yup.string().nullable(),
        'Imprimir': Yup.boolean()
    });
    async function imprimir() {
        try {
            updateGenerandoPdf(true);
            let respuesta = await api.exportarCaratulaPdf(interno);
            updateGenerandoPdf(false);
            let blobUrl = URL.createObjectURL(respuesta);
            updateUltimoBlobUrl(blobUrl);
            abrirPdf(blobUrl).catch(() => {
                mostrarError('Tiene habilitado el bloqueador de pop-ups. Por favor deshabilitelo y vuelva a intentarlo');
            });
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al imprimir presupuesto', error);
                mostrarError('Error al imprimir presupuesto');
            }
            if (!api.isUnmounted()) {
                updateGenerandoPdf(false);
            }
        }
    }
    async function submit(values: any) {
        try {
            let { Imprimir, ...values2 } = values;
            let puedeModificar = await api.obtenerLock(TipoLock.Caratula, interno);
            if (puedeModificar) {
                await api.updateDatosCarpeta({
                    ...values2,
                    NroClienteAlpha: userInfo.nroClienteAlpha,
                    EmpresaId: userInfo.empresaActual,
                    Carpeta: interno,
                    Referencias: estadoReferencias.referencias.map((r: any) => ({ Nombre: r.Nombre, Valor: r.Valor }))
                });
                await api.eliminarLock(TipoLock.Caratula, interno);
                huboCambios.current.valor = false;
                if (Imprimir) {
                    await imprimir();
                } else {
                    updateMostrarMensajeExito(true);
                }
                return true;
            } else {
                mostrarError('No se puede modificar esta caratula porque otro usuario la está modificando');
                return false;
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al guardar cambios en datos de carpeta', error);
                mostrarError('Error al guardar cambios');
            }
            return false;
        }
    }
    function submitReferencia(values: any, helpers: FormikHelpers<any>) {
        let error = false;
        if (estadoReferencias.modal === EstadoModal.Creando) {
            if (estadoReferencias.referencias.map((r: any) => r.Nombre).includes(values.Nombre)) {
                helpers.setFieldError('Nombre', 'Ya existe una referencia con este nombre');
                error = true;
            }
        }
        helpers.setSubmitting(false);
        if (!error) {
            updateEstadoReferencias({
                tipo: 'insertReferencia', valor: {
                    ...values,
                    ModificadoPor: userInfo.claims.nameid,
                    NombreModificadoPor: userInfo.claims.unique_name,
                    ModificadoEl: DateTime.local().toISO()
                }
            });
            huboCambios.current.valor = true;
        }
    }
    async function submitSeleccionPlantilla(NombrePlantilla: string) {
        try {
            let puedeModificar = await api.obtenerLock(TipoLock.Caratula, interno);
            if (puedeModificar) {
                let respuesta = await api.cargarPlantillaEnCaratula(interno, NombrePlantilla, true);
                updateEstadoReferencias({ tipo: 'insertVariasReferencias', referencias: respuesta.Referencias });
                huboCambios.current.valor = true;
                props.onPlantillaSeleccionada();
                await api.eliminarLock(TipoLock.Caratula, interno);
            } else {
                mostrarError('No se puede cargar la plantilla porque otro usuario está modificando la caratula');
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar plantilla', error);
                mostrarError('Error al cargar plantilla');
            }
        }
    }
    function preguntarGuardarCambios() {
        if (huboCambios.current.valor) {
            if (formikRef.current!.isValid) {
                updateMensajeDialogo('¿Desea guardar los cambios en los datos de carpeta?');
                return dialogoRef.current!.mostrar().then(() => {
                    formikRef.current!.setFieldValue('Imprimir', false);
                    let promise = formikRef.current!.submitForm() as unknown as Promise<boolean>;
                    return promise.catch(() => false);
                }).catch(() => true);
            } else {
                updateMensajeDialogo('Hay errores en los datos de carpeta. ¿Está seguro que desea salir?');
                return dialogoRef.current!.mostrar().then(() => true).catch(() => false);
            }
        } else {
            return Promise.resolve(true);
        }
    }
    useRedirectEventCallback(preguntarGuardarCambios);
    useImperativeHandle(ref, () => ({ preguntarGuardarCambios: preguntarGuardarCambios }));
    return <>
        <Formik innerRef={formikRef} initialValues={{ Imprimir: false }} validationSchema={schema} onSubmit={submit}>
            <MyForm blocking={cargando !== EstadoCargando.Listo}>
                <Form.Row>
                    {/* <Form.Group as={Col} controlId="txtInterno">
                        <Form.Label>Carpeta</Form.Label>
                        <Form.Control type="text" disabled plaintext value={interno}></Form.Control>
                    </Form.Group> */}
                    <Form.Group as={Col}>
                        <Field type="hidden" name="Imprimir"></Field>
                        <MySelect name="CodigoTipoOperacion" label="Tipo Operación"
                            getOptionLabel={optionLabelConCodigo} isDisabled={bloquear || !puedeModificarTipoOperacion}
                            options={optionsTipoOperacion} onValueChange={() => {
                                huboCambios.current.valor = true;
                            }}></MySelect>
                    </Form.Group>
                    <Form.Group as={Col} controlId="txtFechaCreacion">
                        <Form.Label>Fecha Creación</Form.Label>
                        <Form.Control type="text" disabled plaintext value={fechaCreacion}></Form.Control>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <MySelectConOptionABM name="CuitDespachante" label="Despachante"
                            getOptionLabel={optionLabelConCodigo} 
                            isDisabled={bloquear || camposSubregimen?.txtCuitDespachante}
                            options={optionsDespachantes}
                            labelOptionABM="Nuevo despachante..."
                            onSelectABM={() => updateEstadoModalDespachantes({ tipo: 'mostrar' })}
                            onValueChange={valor => {
                                huboCambios.current.valor = true;
                            }}
                            ></MySelectConOptionABM>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <MySelectConOptionABM name="CuitImportador" label="Clientes"
                            getOptionLabel={optionLabelConCodigo}
                            isDisabled={bloquear || camposSubregimen?.txtCuitImpoExpo}
                            options={optionsImportadores}
                            labelOptionABM="Nuevo cliente..."
                            onSelectABM={() => updateEstadoModalImportadores({ tipo: 'mostrar' })}
                            onValueChange={valor => {
                                huboCambios.current.valor = true;
                            }}></MySelectConOptionABM>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <MyFormControl type="text" label="Referencia Cliente" name="VuestraReferencia"
                            disabled={bloquear} onValueChange={() => {
                                huboCambios.current.valor = true;
                            }}></MyFormControl>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <MySelectConOptionABM name="CodigoVendedor" label="Vendedor"
                            getOptionLabel={optionLabelConCodigo}
                            isDisabled={bloquear || camposSubregimen?.txtCodigoVendedor}
                            labelOptionABM="Nuevo vendedor..."
                            onSelectABM={() => updateEstadoModalVendedores({ tipo: 'mostrar' })}
                            options={optionsVendedores} onValueChange={option => {
                                huboCambios.current.valor = true;
                            }}></MySelectConOptionABM>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <MySelect name="CodigoAduana" label="Aduana"
                            getOptionLabel={optionLabelConCodigo}
                            options={() => api.getAduanas().then(convertirDatosGenericosAOption)}
                            isDisabled={bloquear} onValueChange={() => {
                                huboCambios.current.valor = true;
                            }}></MySelect>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <MySelect name="CodigoPaisOrigen" label="País Origen" isDisabled={bloquear || camposSubregimen?.checkForzarPaisOrigen}
                            getOptionLabel={optionLabelConCodigo} options={() => api.getPaises().then(convertirDatosGenericosAOption)}
                            onValueChange={() => {
                                huboCambios.current.valor = true;
                            }}></MySelect>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <MyFormControl type="date" name="FechaArriboAprox" label="Fecha arribo aprox." onValueChange={() => {
                            huboCambios.current.valor = true;
                        }} disabled={bloquear || camposSubregimen?.dateFechaArriboAprox}></MyFormControl>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <MyTextarea name="DescripcionMercaderiaCaratula" label="Descripción Mercadería de Carpeta" onValueChange={() => {
                            huboCambios.current.valor = true;
                        }} disabled={bloquear}></MyTextarea>
                    </Form.Group>
                </Form.Row>
                <h4>Referencias</h4>
                <Button className="mb-2 mr-2" disabled={bloquear} onClick={() => updateEstadoReferencias({ tipo: 'mostrarModalCrear' })}>
                    <FontAwesomeIcon icon={faPlus} />
                    <span>Agregar</span>
                </Button>
                <Button className="mb-2 mr-2" onClick={() => updateEstadoModalTiposReferencia({ tipo: 'mostrar', seleccion: false })}>
                    <span>Referencias</span>
                </Button>
                <Button className="mb-2" onClick={() => plantillasCaratulaRef.current?.mostrar()}>
                    <span>Plantillas</span>
                </Button>
                <GrillaSyncReferencias datos={estadoReferencias.referencias} campos={[{ titulo: 'Nombre', propiedad: 'Nombre', clave: true },
                { titulo: 'Valor', propiedad: 'Valor' }, { titulo: 'Usuario', propiedad: 'NombreModificadoPor' },
                { titulo: 'Fecha', propiedad: 'ModificadoEl', tipo: TipoCampo.DateTime }]}
                    eventoDetalle={(item: any) => updateEstadoReferencias({ tipo: 'mostrarModalModificar', valor: item })}
                    deshabilitarEventoDetalle={bloquear}
                    eventoEliminar={(item: any) => {
                        updateMensajeDialogo(`¿Está seguro que desea eliminar la referencia ${item.Nombre}?`);
                        dialogoRef.current!.mostrar().then(() => {
                            updateEstadoReferencias({ tipo: 'deleteReferencia', valor: item.Nombre });
                            huboCambios.current.valor = true;
                        }).catch(() => { });
                    }} deshabilitarBotonEliminar={bloquear} mostrarFormCambiarPagina={false}></GrillaSyncReferencias>
                <Button onClick={() => {
                    formikRef.current?.setFieldValue('Imprimir', false);
                    formikRef.current?.submitForm();
                }} disabled={bloquear} className="mr-2 mb-2">Guardar</Button>
                <Button onClick={async () => {
                    if (bloquear) {
                        await imprimir();
                    } else {
                        formikRef.current?.setFieldValue('Imprimir', true);
                        formikRef.current?.submitForm();
                    }
                }} className="mb-2">{bloquear ? 'Imprimir' : 'Guardar e Imprimir'}</Button>
            </MyForm>
        </Formik>
        <MyModal show={estadoReferencias.modal !== EstadoModal.Cerrado} onHide={() => {
            if (!estadoReferencias.modalCargando) {
                updateEstadoReferencias({ tipo: 'cerrarModal' });
            }
        }}>
            <Modal.Dialog size="lg">
                <Modal.Header closeButton>
                    {estadoReferencias.modal === EstadoModal.Modificando ? 'Modificar referencia' : 'Nueva referencia'}
                </Modal.Header>
                <Modal.Body>
                    <Formik innerRef={formikReferenciasRef} initialValues={{
                        'Nombre': estadoReferencias.valorModificando?.Nombre ?? '',
                        'Valor': estadoReferencias.valorModificando?.Valor ?? ''
                    }} validationSchema={Yup.object({
                        'Nombre': Yup.string().nullable().required('Debe ingresar el nombre de la referencia'),
                        'Valor': Yup.string().nullable()
                    })} onSubmit={submitReferencia}>
                        <MyForm blocking={estadoReferencias.modalCargando} blockWhenSubmitting={false}>
                            <Form.Group>
                                {estadoReferencias.modal === EstadoModal.Modificando ?
                                    <MyFormControl type="text" name="Nombre" label="Nombre" readOnly plaintext></MyFormControl>
                                    : <MySelectConOptionABM name="Nombre" label="Nombre"
                                        options={optionsTiposReferencia}
                                        labelOptionABM="Nueva referencia..."
                                        onSelectABM={() => updateEstadoModalTiposReferencia({ tipo: 'mostrar', seleccion: true })}></MySelectConOptionABM>}
                            </Form.Group>
                            <Form.Group>
                                <MyFormControl type="text" name="Valor" label="Valor"></MyFormControl>
                            </Form.Group>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" disabled={estadoReferencias.modalCargando} onClick={() => updateEstadoReferencias({ tipo: 'cerrarModal' })}>Cancelar</Button>
                    <Button disabled={estadoReferencias.modalCargando} onClick={() => formikReferenciasRef.current?.submitForm()}>Ingresar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={mostrarMensajeExito} onHide={() => updateMostrarMensajeExito(false)}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead text-success">Se han guardado los cambios exitosamente</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => updateMostrarMensajeExito(false)}>Cerrar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estadoModalTiposReferencia.mostrar} onHide={() => updateEstadoModalTiposReferencia({ tipo: 'cerrar' })}>
            <Modal.Dialog size="lg">
                <Modal.Header closeButton>
                    Referencias Caratula
                </Modal.Header>
                <Modal.Body>
                    <ReferenciasCaratula onReferenciaSeleccionada={estadoModalTiposReferencia.seleccion ?
                        (item: any) => {
                            updateEstadoModalTiposReferencia({ tipo: 'cerrar', valor: item.Nombre });
                        } : undefined}></ReferenciasCaratula>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstadoModalTiposReferencia({ tipo: 'cerrar' })}>Cerrar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={generandoPdf}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead">Generando PDF...</p>
                    <div className="loader-container">
                        <Loader type="ball-spin-fade-loader" active></Loader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        api.cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <ModalImportadores mostrar={estadoModalImportadores.abierto}
            eventoCerrarModal={() => updateEstadoModalImportadores({ tipo: 'cerrar' })}
            eventoSeleccionarImportador={(item: any) => updateEstadoModalImportadores({ tipo: 'cerrar', valor: item.CUIT })}></ModalImportadores>
        <MyModal show={estadoModalVendedores.abierto} onHide={() => updateEstadoModalVendedores({ tipo: 'cerrar' })}>
            <Modal.Dialog size="xl">
                <Modal.Body>
                    <InnerRouter initialRoute="/vendedores">
                        <Switch>
                            <Route path="/vendedores">
                                <RutasVendedor eventoSeleccionarVendedor={(item: any) => updateEstadoModalVendedores({ tipo: 'cerrar', valor: item.Codigo })}></RutasVendedor>
                            </Route>
                        </Switch>
                    </InnerRouter>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstadoModalVendedores({ tipo: 'cerrar' })}>Cerrar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <PlantillasCaratula ref={plantillasCaratulaRef} onSelectPlantilla={submitSeleccionPlantilla}></PlantillasCaratula>
        <DialogoConfirmar ref={dialogoRef} mensaje={mensajeDialogo} textoBotonConfirmar="Sí" textoBotonCancelar="No"></DialogoConfirmar>
    </>;
});

