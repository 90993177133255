import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useRef, useState } from "react";
import { Button, Form, Container } from "react-bootstrap";
import { AppContext } from "App";
import Grilla, { GrillaRef } from "../../Grilla";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import { isInRole } from 'Utilidades';
import { CrearEditarTransportista, CrearEditarTransportistaRef } from "./CrearEditarTransportista";
import { useApi } from "ApiHooks";
import { CancelToken } from "SintiaHooks";

export function Transportistas() {
    let refGrilla = useRef<GrillaRef>(null);
    let refDialogo = useRef<DialogoConfirmarRef>(null);
    let refCrearEditarTransportista = useRef<CrearEditarTransportistaRef>(null);
    let { mostrarError, userInfo } = useContext(AppContext);
    let campos = [{ titulo: 'CUIT', propiedad: 'CUIT', visible: true, clave: true },
    { titulo: "Nombre", propiedad: 'Nombre' }];
    let api = useApi();
    let [busquedaActual, updateBusquedaActual] = useState('');
    let [busqueda, updateBusqueda] = useState('');
    let [mensajeEliminarTransportista, updateMensajeEliminarTransportista] = useState('');
    async function cargarDatos(desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await api.getTransportistasPaginado(busquedaActual, desde, hasta, cancelToken);
        return { cantidadItems: respuesta.CantidadTotal, items: respuesta.Items };
    }

    function eventoEliminar(item: any) {
        updateMensajeEliminarTransportista(`¿Está seguro que desea eliminar el transportista ${item.Nombre}?`);
        return refDialogo.current!.mostrar().then(async () => {
            refGrilla.current?.setBloqueado(true);
            try {
                let { exito, error } = await api.deleteTransportista(item.CUIT);
                if (exito) {
                    refGrilla.current?.recargar();
                } else {
                    mostrarError(error as string);
                }
                refGrilla.current?.setBloqueado(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al eliminar transportista', error);
                    mostrarError('Hubo un error al eliminar el transportista');
                    refGrilla.current?.setBloqueado(false);
                }
            }
        }).catch(() => { });
    }
    let esOperador = isInRole(userInfo, 'Operador');
    return (<>
        <Container>
            <h2>Transportistas</h2>
            <Form inline onSubmit={e => {
                updateBusquedaActual(busqueda);
                refGrilla.current?.recargar();
                e.preventDefault();
            }}>
                <Form.Control type="text" id="txtNombreTransportista" value={busqueda} className="mr-2 mb-2"
                    onChange={e => updateBusqueda(e.target.value)}></Form.Control>
                <Button type="submit" className="mb-2">Buscar</Button>
            </Form>
            <Button disabled={esOperador} onClick={() => refCrearEditarTransportista.current!.mostrarCrear()
                .then(() => refGrilla.current?.recargar()).catch(() => { })} className="mb-2 mr-2">
                <FontAwesomeIcon icon={faPlus} />
                <span>Agregar</span>
            </Button>
            <Grilla campos={campos} cargarDatos={cargarDatos}
                eventoDetalle={item => refCrearEditarTransportista.current!.mostrarModificar(item)
                    .then(() => refGrilla.current?.recargar()).catch(() => { })}
                deshabilitarEventoDetalle={esOperador}
                eventoEliminar={eventoEliminar}
                deshabilitarBotonEliminar={esOperador} ref={refGrilla} />
        </Container>
        <CrearEditarTransportista ref={refCrearEditarTransportista}></CrearEditarTransportista>
        <DialogoConfirmar ref={refDialogo} mensaje={mensajeEliminarTransportista} textoBotonConfirmar="Sí" textoBotonCancelar="No" />    </>)
}