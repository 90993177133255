import React from "react";
import { useHistory } from "react-router";
import { AppContext, NotFoundComponent } from "App";
import { Alert, Button, Form, Modal, Tab, Tabs, Col } from "react-bootstrap";
import { Formik, FormikProps } from "formik";
import { MyAsyncSelect, MyForm, MyFormCheck, MyFormControl, MySelect, MyTextarea, SelectOption } from "../../FormikHooks";
import { useDecodedParams, isInRole, convertirDatosGenericosAOption, isNullOrWhiteSpace, optionLabelConCodigo, validarCUIT, toHtmlDateLocal } from "../../Utilidades";
import { GrillaRef, GrillaSync } from "Grilla";
import { DialogoConfirmar, DialogoConfirmarRef } from "DialogoConfirmar";
import * as Yup from "yup";
import { DateTime } from "luxon";
import { MyModal } from "MyModal";
import { CrearEditarPlantillaCalculoImportador, CrearEditarPlantillaCalculoImportadorRef } from "./CrearEditarPlantillaCalculoImportador";
import { useRedirectEventCallback } from "SintiaHooks";
import { useApi, TipoLock } from "ApiHooks";

export function CrearEditarImportador() {
    let { mostrarError, userInfo } = React.useContext(AppContext);
    let { id } = useDecodedParams();
    let history = useHistory();
    let [cargando, updateCargando] = React.useState(true);
    let importador = React.useRef<any>(null);
    let [mensajeErrorAlert, updateMensajeErrorAlert] = React.useState('');
    let [notFound, updateNotFound] = React.useState(false);
    let api = useApi();
    let [paises, updatePaises] = React.useState<any[]>([]);
    let grillaVentajasRef = React.useRef<GrillaRef>(null);
    let crearEditarPlantillaRef = React.useRef<CrearEditarPlantillaCalculoImportadorRef>(null);
    let huboCambios = React.useRef<{ valor: boolean }>({ valor: false });
    let [estadoVentajas, updateEstadoVentajas] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'inicializar') {
            return {
                ...estado, huboCambios: false,
                ventajas: accion.ventajas.map((item: any, indice: number) => ({ ...item, Indice: indice }))
            };
        } else if (accion.tipo === 'insertVentaja') {
            let nuevasVentajas = Array.from(estado.ventajas);
            if (estado.valorModificando) {
                let indice = nuevasVentajas.findIndex((item: any) => item.Indice === estado.valorModificando.Indice);
                nuevasVentajas.splice(indice, 1);
            }
            let nuevoIndice = (nuevasVentajas.map((item: any) => item.Indice).sort((a: number, b: number) => b - a)[0] ?? 0) + 1;
            nuevasVentajas.push({ ...accion.valor, Indice: nuevoIndice });
            return {
                ...estado, huboCambios: true, ventajas: nuevasVentajas, creando: false,
                modificando: false, valorModificando: null
            };
        } else if (accion.tipo === 'deleteVentaja') {
            let nuevasVentajas = Array.from(estado.ventajas);
            let indice = nuevasVentajas.findIndex((item: any) => item.Indice === accion.valor);
            if (indice > -1) {
                nuevasVentajas.splice(indice, 1);
            }
            return {
                ...estado, huboCambios: true, ventajas: nuevasVentajas, creando: false,
                modificando: false, valorModificando: null
            };
        }
        else if (accion.tipo === 'ventanaCrear') {
            return { ...estado, creando: true, modificando: false, valorModificando: null };
        } else if (accion.tipo === 'ventanaModificar') {
            return { ...estado, creando: false, modificando: true, valorModificando: accion.valor };
        } else if (accion.tipo === 'esconderVentana') {
            return { ...estado, creando: false, modificando: false, valorModificando: null };
        }
        return estado;
    }, { ventajas: [], huboCambios: false, creando: false, modificando: false, valorModificando: null });
    let [estadoDomiciliosEstampillado, updateEstadoDomiciliosEstampillado] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'inicializar') {
            return {
                ...estado, huboCambios: false,
                domicilios: accion.domicilios.map((item: string, indice: number) => ({ Dato: item, Indice: indice }))
            };
        } else if (accion.tipo === 'insertDomicilio') {
            let nuevosDomicilios = Array.from(estado.domicilios);
            if (estado.valorModificando) {
                let indice = nuevosDomicilios.findIndex((item: any) => item.Indice === estado.valorModificando.Indice);
                nuevosDomicilios.splice(indice, 1);
            }
            let nuevoIndice = (nuevosDomicilios.map((item: any) => item.Indice).sort((a: number, b: number) => b - a)[0] ?? 0) + 1;
            nuevosDomicilios.push({ Dato: accion.valor, Indice: nuevoIndice });
            return {
                ...estado, huboCambios: true,
                domicilios: nuevosDomicilios, creando: false, modificando: false, valorModificando: null
            };
        } else if (accion.tipo === 'deleteDomicilio') {
            let nuevosDomicilios = Array.from(estado.domicilios);
            let indice = nuevosDomicilios.findIndex((item: any) => item.Indice === accion.valor);
            if (indice > -1) {
                nuevosDomicilios.splice(indice, 1);
            }
            return {
                ...estado, huboCambios: true,
                domicilios: nuevosDomicilios, creando: false, modificando: false, valorModificando: null
            };
        } else if (accion.tipo === 'ventanaCrear') {
            return { ...estado, creando: true, modificando: false, valorModificando: null };
        } else if (accion.tipo === 'ventanaModificar') {
            return { ...estado, creando: false, modificando: true, valorModificando: accion.valor };
        } else if (accion.tipo === 'esconderVentana') {
            return { ...estado, creando: false, modificando: false, valorModificando: null };
        }
        return estado;
    }, { domicilios: [], huboCambios: false, creando: false, modificando: false, valorModificando: null });
    let [estadoPlantillas, updateEstadoPlantillas] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'inicializar') {
            return { huboCambios: false, plantillas: accion.plantillas ?? [] };
        } else if (accion.tipo === 'insertPlantilla') {
            let nuevasPlantillas = Array.from(estado.plantillas);
            if (!isNullOrWhiteSpace(accion.valor.Id)) {
                let indice = nuevasPlantillas.findIndex((item: any) => item.Id === accion.valor.Id);
                nuevasPlantillas.splice(indice, 1, accion.valor);
            } else {
                let indice = nuevasPlantillas.findIndex((item: any) => item.TempId === accion.valor.TempId);
                if (indice > -1) {
                    nuevasPlantillas.splice(indice, 1, accion.valor);
                } else {
                    nuevasPlantillas.push(accion.valor);
                }
            }
            return { huboCambios: true, plantillas: nuevasPlantillas };
        } else if (accion.tipo === 'deletePlantilla') {
            let nuevasPlantillas;
            if (!isNullOrWhiteSpace(accion.id)) {
                nuevasPlantillas = estado.plantillas.filter((item: any) => item.Id !== accion.id);
            } else {
                nuevasPlantillas = estado.plantillas.filter((item: any) => item.TempId !== accion.tempId);
            }
            return {
                huboCambios: true,
                plantillas: nuevasPlantillas
            };
        }
        return estado;
    }, { huboCambios: false, plantillas: [] });
    let [habilitarReduccionIva, updateHabilitarReduccionIva] = React.useState(false);
    let [habilitarReduccionGanancias, updateHabilitarReduccionGanancias] = React.useState(false);
    let dialogoRef = React.useRef<DialogoConfirmarRef>(null);
    let [mensajeDialogo, updateMensajeDialogo] = React.useState('');
    let formikRef = React.useRef<FormikProps<any>>(null);
    let formikDomiciliosEstampilladoRef = React.useRef<FormikProps<any>>(null);
    let formikVentajasRef = React.useRef<FormikProps<any>>(null);
    let schema = Yup.object({
        'CUIT': Yup.string().nullable().required('Debe ingresar el CUIT')
            .test('cuit-valido', 'El CUIT ingresado no es válido', validarCUIT),
        'Nombre': Yup.string().nullable().required('Debe ingresar el nombre'),
        'Domicilio': Yup.string().nullable(),
        'Localidad': Yup.string().nullable(),
        'CodigoPostal': Yup.string().nullable(),
        'Telefono': Yup.string().nullable(),
        'Email': Yup.string().nullable(),
        'CodigoTipoResponsable': Yup.string().nullable(),
        'InicioActividad': Yup.date().nullable()
            .min(new Date(1900, 0, 1), 'Debe ingresar una fecha válida'),
        'PorcentajeIngresosBrutos': Yup.number().nullable().typeError('Debe ingresar un número')
            .min(0, 'El valor debe ser entre 0 y 100')
            .max(100, 'El valor debe ser entre 0 y 100'),
        'EsAeroNavegante': Yup.boolean(),
        'TieneReduccionIva': Yup.boolean(),
        'PorcentajeReduccionIva': Yup.number().nullable().when('TieneReduccionIva', {
            is: true,
            then: Yup.number().nullable().typeError('Debe ingresar un número')
                .required('Debe ingresar el porcentaje de reducción')
                .min(0, 'El valor debe ser entre 0 y 100').max(100, 'El valor debe ser entre 0 y 100'),
            otherwise: Yup.number().nullable().typeError('Debe ingresar un número')
        }),
        'PorcentajeReduccionIvaTemporal': Yup.number().nullable().when('TieneReduccionIva', {
            is: true,
            then: Yup.number().nullable().typeError('Debe ingresar un número')
                .required('Debe ingresar el porcentaje de reducción')
                .min(0, 'El valor debe ser entre 0 y 100').max(100, 'El valor debe ser entre 0 y 100'),
            otherwise: Yup.number().nullable().typeError('Debe ingresar un número')
        }),
        'FechaVencimientoReduccionIva': Yup.date().nullable().when('TieneReduccionIva', {
            is: true,
            then: Yup.date().nullable().required('Debe ingresar una fecha válida')
                .test('fecha-iva', 'Debe ingresar una fecha posterior a este momento', valor => {
                    if (valor) {
                        return DateTime.fromJSDate(valor) > DateTime.local();
                    } else {
                        return true;
                    }
                }),
            otherwise: Yup.date().nullable()
        }),
        'TieneReduccionGanancias': Yup.boolean(),
        'PorcentajeReduccionGanancias': Yup.number().nullable().when('TieneReduccionGanancias', {
            is: true,
            then: Yup.number().nullable().typeError('Debe ingresar un número')
                .required('Debe ingresar el porcentaje de reducción')
                .min(0, 'El valor debe ser entre 0 y 100').max(100, 'El valor debe ser entre 0 y 100'),
            otherwise: Yup.number().nullable().typeError('Debe ingresar un número')
        }),
        'PorcentajeReduccionGananciasTemporal': Yup.number().nullable().when('TieneReduccionGanancias', {
            is: true,
            then: Yup.number().nullable().typeError('Debe ingresar un número')
                .required('Debe ingresar el porcentaje de reducción')
                .min(0, 'El valor debe ser entre 0 y 100').max(100, 'El valor debe ser entre 0 y 100'),
            otherwise: Yup.number().nullable().typeError('Debe ingresar un número')
        }),
        'FechaVencimientoReduccionGanancias': Yup.date().nullable().when('TieneReduccionGanancias', {
            is: true,
            then: Yup.date().nullable().required('Debe ingresar una fecha válida')
                .test('fecha-ganancias', 'Debe ingresar una fecha posterior a este momento', valor => {
                    if (valor) {
                        return DateTime.fromJSDate(valor) > DateTime.local();
                    } else {
                        return true;
                    }
                }),
            otherwise: Yup.date().nullable()
        }),
        'IvaInscriptoMaria': Yup.string().nullable().required('Debe seleccionar un valor')
    });
    async function submit(values: any) {
        try {
            let nuevoImportador: any = {
                NroClienteAlpha: userInfo.nroClienteAlpha,
                CUIT: values.CUIT,
                Nombre: values.Nombre,
                Domicilio: values.Domicilio,
                Localidad: values.Localidad,
                CodigoPostal: values.CodigoPostal,
                Telefono: values.Telefono,
                Email: values.Email,
                CodigoTipoResponsable: values.CodigoTipoResponsable,
                InicioActividad: values.InicioActividad,
                EsAeroNavegante: values.EsAeroNavegante,
                PorcentajeIngresosBrutos: values.PorcentajeIngresosBrutos,
                PorcentajeReduccionIva: values.TieneReduccionIva ? values.PorcentajeReduccionIva : 0,
                PorcentajeReduccionIvaTemporal: values.TieneReduccionIva ? values.PorcentajeReduccionIvaTemporal : 0,
                FechaVencimientoReduccionIva: values.TieneReduccionIva ? values.FechaVencimientoReduccionIva : null,
                PorcentajeReduccionGanancias: values.TieneReduccionGanancias ? values.PorcentajeReduccionGanancias : 0,
                PorcentajeReduccionGananciasTemporal: values.TieneReduccionGanancias ? values.PorcentajeReduccionGananciasTemporal : 0,
                FechaVencimientoReduccionGanancias: values.TieneReduccionGanancias ? values.FechaVencimientoReduccionGanancias : null,
                IvaInscriptoMaria: values.IvaInscriptoMaria,
                ModificadoPor: userInfo.claims.nameid,
                ModificadoEl: DateTime.local().toISO(),
                DomiciliosEstampillado: estadoDomiciliosEstampillado.domicilios.map((item: any) => item.Dato)
            };
            if (importador.current) {
                nuevoImportador.Id = importador.current.Id;
                nuevoImportador.CreadoPor = importador.current.CreadoPor;
                nuevoImportador.CreadoEl = importador.current.CreadoEl;
                let puedeModificar = await api.obtenerLock(TipoLock.Importador, importador.current.Id);
                if (!puedeModificar) {
                    updateMensajeErrorAlert('No se pudo modificar el cliente porque otra persona lo está modificando');
                    return false;
                }
            } else {
                nuevoImportador.CreadoPor = userInfo.claims.nameid;
                nuevoImportador.CreadoEl = DateTime.local().toISO();
            }
            let ventajas = estadoVentajas.ventajas.map((ventaja: any) => {
                let { Indice, ...resto } = ventaja;
                return { ...resto, CuitImportador: nuevoImportador.CUIT };
            });
            let plantillas = estadoPlantillas.plantillas.map((item: any) => {
                let { TempId, ...resto } = item;
                return {
                    ...resto,
                    CuitImportador: nuevoImportador.CUIT,
                    CreadoPor: userInfo.claims.nameid,
                    CreadoEl: DateTime.local().toISODate(),
                    ModificadoPor: userInfo.claims.nameid,
                    ModificadoEl: DateTime.local().toISODate(),
                };
            });
            let { exito, error } = await api.insertImportador(nuevoImportador, ventajas, plantillas);
            if (exito) {
                if (importador.current) {
                    await api.eliminarLock(TipoLock.Importador, importador.current.Id);
                }
                huboCambios.current.valor = false;
                return true;
            } else {
                mostrarError(error as string);
                return false;
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al insertar importador');
                mostrarError('Error al insertar importador');
            }
            return false;
        }
    }
    React.useEffect(() => {
        async function cargar() {
            try {
                let arrayPaises = await api.getPaises();
                updatePaises(arrayPaises);
                if (id) {
                    importador.current = await api.getImportadorPorId(id);
                    if (!importador.current) {
                        updateNotFound(true);
                        return;
                    }
                    updateEstadoDomiciliosEstampillado({ tipo: 'inicializar', domicilios: importador.current.DomiciliosEstampillado ?? [] });
                    let ventajas = await api.getVentajasImportador(importador.current.CUIT);
                    updateEstadoVentajas({ tipo: 'inicializar', ventajas: ventajas });
                    let plantillas = await api.getPlantillasImportador(importador.current.CUIT);
                    updateEstadoPlantillas({ tipo: 'inicializar', plantillas: plantillas });
                    if (importador.current.PorcentajeReduccionIva > 0) {
                        updateHabilitarReduccionIva(true);
                    }
                    if (importador.current.PorcentajeReduccionGanancias > 0) {
                        updateHabilitarReduccionGanancias(true);
                    }
                    formikRef.current?.resetForm({
                        values: {
                            'CUIT': importador.current.CUIT,
                            'Nombre': importador.current.Nombre,
                            'Domicilio': importador.current.Domicilio,
                            'Localidad': importador.current.Localidad,
                            'CodigoPostal': importador.current.CodigoPostal,
                            'Telefono': importador.current.Telefono,
                            'Email': importador.current.Email,
                            'CodigoTipoResponsable': importador.current.CodigoTipoResponsable,
                            'InicioActividad': toHtmlDateLocal(importador.current.InicioActividad),
                            'PorcentajeIngresoBrutos': importador.current.PorcentajeIngresoBrutos,
                            'TieneReduccionIva': importador.current.PorcentajeReduccionIva > 0,
                            'PorcentajeReduccionIva': importador.current.PorcentajeReduccionIva,
                            'PorcentajeReduccionIvaTemporal': importador.current.PorcentajeReduccionIvaTemporal,
                            'FechaVencimientoReduccionIva': toHtmlDateLocal(importador.current.FechaVencimientoReduccionIva),
                            'TieneReduccionGanancias': importador.current.PorcentajeReduccionGanancias > 0,
                            'PorcentajeReduccionGanancias': importador.current.PorcentajeReduccionGanancias,
                            'PorcentajeReduccionGananciasTemporal': importador.current.PorcentajeReduccionGananciasTemporal,
                            'FechaVencimientoReduccionGanancias': toHtmlDateLocal(importador.current.FechaVencimientoReduccionGanancias),
                            'IvaInscriptoMaria': importador.current.IvaInscriptoMaria,
                            'EsAeroNavegante': importador.current.EsAeroNavegante
                        }
                    });
                } else {
                    formikRef.current?.resetForm({
                        values: {
                            'PorcentajeIngresosBrutos': '2.5',
                            'PorcentajeReduccionIvaTemporal': '0',
                            'PorcentajeReduccionGananciasTemporal': '0',
                            'CodigoTipoResponsable': '1'
                        }
                    })
                }
                updateCargando(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar importador', error);
                    mostrarError('Error al cargar importador');
                }
            }
        }
        cargar();
        //eslint-disable-next-line 
    }, []);
    function preguntarGuardarCambios() {
        if (huboCambios.current.valor) {
            if (formikRef.current!.isValid) {
                updateMensajeDialogo('¿Desea guardar los cambios en el cliente?');
                return dialogoRef.current!.mostrar().then(() => {
                    let promise = formikRef.current!.submitForm() as unknown as Promise<boolean>;
                    return promise.catch(() => false);
                }).catch(() => true);
            } else {
                updateMensajeDialogo('Hay errores en el cliente. ¿Está seguro que desea salir?');
                return dialogoRef.current!.mostrar().then(() => true).catch(() => false);
            }
        } else {
            return Promise.resolve(true);
        }
    }
    let { disableRedirectEventCallback } = useRedirectEventCallback(preguntarGuardarCambios);
    const eventoHuboCambios = () => {
        huboCambios.current.valor = true;
    }
    React.useEffect(() => {
        if (estadoVentajas.huboCambios || estadoDomiciliosEstampillado.huboCambios
            || estadoPlantillas.huboCambios) {
            huboCambios.current.valor = true;
        }
    }, [estadoVentajas.huboCambios, estadoDomiciliosEstampillado.huboCambios, estadoPlantillas.huboCambios]);
    let schemaVentajas = Yup.object({
        'CodigoVentaja': Yup.string().nullable().required('Debe seleccionar la ventaja')
        .matches(/^((?!IVAADREDUCCION|GANANEXIMICION).)*$/,'Debe tildar: TIENE REDUCCION IVA o GANANCIAS en la solapa DATOS'),
        'CodigoSubregimen': Yup.string().nullable(),
        'CodigoPaisOrigen': Yup.string().nullable(),
        'ExcluirPaisOrigen': Yup.boolean().nullable(),
        'Ncm': Yup.string().nullable(),
        'ExcluirNcm': Yup.boolean().nullable()
    });
    let esOperador = isInRole(userInfo, 'Operador');



    return notFound ? (<NotFoundComponent></NotFoundComponent>) : (<>
        <h2>{id ? 'Modificar cliente' : 'Crear cliente'}</h2>
        {mensajeErrorAlert && (<Alert variant="danger">{mensajeErrorAlert}</Alert>)}
        <Formik initialValues={{}} validationSchema={schema} innerRef={formikRef} onSubmit={submit}>
            <MyForm blocking={cargando} submitEnUltimoElemento>
                <Tabs id="tabsCrearEditarImportador">
                    <Tab eventKey="Principal" title="Datos">
                        <Form.Row>
                            <Form.Group as={Col}>
                                <MyFormControl disabled={esOperador} readOnly={!!id} name="CUIT" type="text" label="CUIT" maxLength={11} onValueChange={eventoHuboCambios}></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <MyFormControl disabled={esOperador} name="Nombre" type="text" label="Nombre" onValueChange={eventoHuboCambios}></MyFormControl>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <MyFormControl disabled={esOperador} name="Domicilio" type="text" label="Domicilio" onValueChange={eventoHuboCambios}></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <MyFormControl disabled={esOperador} name="Localidad" type="text" label="Localidad" onValueChange={eventoHuboCambios}></MyFormControl>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <MyFormControl disabled={esOperador} name="CodigoPostal" type="text" label="Codigo Postal" onValueChange={eventoHuboCambios}></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <MyFormControl disabled={esOperador} name="Telefono" type="text" label="Telefono" onValueChange={eventoHuboCambios}></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <MyFormControl disabled={esOperador} name="Email" type="email" label="E-mail" onValueChange={eventoHuboCambios}></MyFormControl>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <MySelect isDisabled={esOperador} name="CodigoTipoResponsable" label="Tipo Responsable"
                                    options={() => api.getTiposResponsable().then(convertirDatosGenericosAOption)} onValueChange={eventoHuboCambios} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <MySelect isDisabled={esOperador} name="IvaInscriptoMaria" label="Iva Inscripto"
                                    options={[{ value: 'S', label: 'SÍ' }, { value: 'N', label: 'NO' }, { value: 'M', label: 'MT' }]}
                                    onValueChange={eventoHuboCambios} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <MyFormControl disabled={esOperador} type="date" name="InicioActividad" label="Fecha Inicio Actividad" onValueChange={eventoHuboCambios}></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <MyFormControl disabled={esOperador} type="number" name="PorcentajeIngresosBrutos" label="Porcentaje Ingresos Brutos" onValueChange={eventoHuboCambios}></MyFormControl>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col}>
                                <MyFormCheck disabled={esOperador} name="EsAeroNavegante" label="Presta servicios de Aeronavegacion (Impuesto Pais)"></MyFormCheck>
                            </Form.Group>
                        </Form.Row>


                        <Form.Row>
                            <Form.Group as={Col}>
                                <MyFormCheck disabled={esOperador} name="TieneReduccionIva" label="Tiene Reducción IVA (IVAADREDUCCION)" onCheckedChange={checked => {
                                    updateHabilitarReduccionIva(checked);
                                    eventoHuboCambios();
                                }}></MyFormCheck>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <MyFormControl disabled={esOperador || !habilitarReduccionIva} type="number" name="PorcentajeReduccionIva" label="Porcentaje Reducción IVA" onValueChange={eventoHuboCambios}></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <MyFormControl disabled={esOperador || !habilitarReduccionIva} type="number" name="PorcentajeReduccionIvaTemporal" label="Porcentaje Reducción IVA Temporal" onValueChange={eventoHuboCambios}></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <MyFormControl disabled={esOperador || !habilitarReduccionIva} type="date" name="FechaVencimientoReduccionIva" label="Vencimiento Reducción IVA" onValueChange={eventoHuboCambios}></MyFormControl>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <MyFormCheck disabled={esOperador} name="TieneReduccionGanancias" label="Tiene Reducción Ganancias (EXIMGAN)" onCheckedChange={checked => {
                                    updateHabilitarReduccionGanancias(checked);
                                    eventoHuboCambios();
                                }}></MyFormCheck>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <MyFormControl disabled={esOperador || !habilitarReduccionGanancias} type="number" name="PorcentajeReduccionGanancias" label="Porcentaje Reducción IVA" onValueChange={eventoHuboCambios}></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <MyFormControl disabled={esOperador || !habilitarReduccionGanancias} type="number" name="PorcentajeReduccionGananciasTemporal" label="Porcentaje Reducción Ganancias Temporal" onValueChange={eventoHuboCambios}></MyFormControl>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <MyFormControl disabled={esOperador || !habilitarReduccionGanancias} type="date" name="FechaVencimientoReduccionGanancias" label="Vencimiento Reducción Ganancias" onValueChange={eventoHuboCambios}></MyFormControl>
                            </Form.Group>
                        </Form.Row>
                    </Tab>
                    <Tab eventKey="ventajas" title="Ventajas">
                        <div className="mt-2">
                            <GrillaSync ref={grillaVentajasRef} datos={estadoVentajas.ventajas}
                                campos={[{ propiedad: 'Indice', visible: false, clave: true },
                                { propiedad: 'CodigoVentaja', titulo: 'Codigo Ventaja' },
                                { propiedad: 'CodigoSubregimen', titulo: 'Subregimen', }, { propiedad: 'Ncm', titulo: 'NCM' },
                                {
                                    propiedad: 'ExcluirNcm', titulo: 'Excluir NCM', plantillaFormato: (valor: any) => {
                                        if (valor === null || valor === undefined) {
                                            return 'No';
                                        } else {
                                            return valor ? 'Sí' : 'No';
                                        }
                                    }
                                }, {
                                    propiedad: 'CodigoPaisOrigen', titulo: 'Pais Origen', plantillaFormato: (valor: any) => {
                                        if (isNullOrWhiteSpace(valor)) {
                                            return '';
                                        } else {
                                            let pais = paises.find((item: any) => item.Codigo === valor);
                                            return pais?.Descripcion ?? valor;
                                        }
                                    }
                                }, {
                                    propiedad: 'ExcluirPaisOrigen', titulo: 'Excluir Pais Origen',
                                    plantillaFormato: (valor: any) => {
                                        if (valor === null || valor === undefined) {
                                            return '';
                                        } else {
                                            return valor ? 'Sí' : 'No';
                                        }
                                    }
                                }]} eventoAgregar={() => updateEstadoVentajas({ tipo: 'ventanaCrear' })}
                                deshabilitarBotonAgregar={esOperador}
                                eventoDetalle={(item: any) => updateEstadoVentajas({ tipo: 'ventanaModificar', valor: item })}
                                deshabilitarEventoDetalle={esOperador}
                                eventoEliminar={(item: any) => {
                                    updateMensajeDialogo("¿Está seguro que desea eliminar esta ventaja?");
                                    dialogoRef.current!.mostrar()
                                        .then(() => updateEstadoVentajas({ tipo: 'deleteVentaja', valor: item.Indice })).catch(() => { });
                                }} deshabilitarBotonEliminar={esOperador} mostrarFormCambiarPagina={false} /></div>
                    </Tab>
                    <Tab eventKey="domiciliosEstampillado" title="Domicilios Estampillado">
                        <div className="mt-2">
                            <GrillaSync datos={estadoDomiciliosEstampillado.domicilios}
                                campos={[{ propiedad: 'Indice', visible: false, clave: true },
                                { propiedad: 'Dato', titulo: 'Domicilio' },]}
                                eventoAgregar={() => updateEstadoDomiciliosEstampillado({ tipo: 'ventanaCrear' })}
                                deshabilitarBotonAgregar={esOperador}
                                eventoDetalle={(item: any) => updateEstadoDomiciliosEstampillado({ tipo: 'ventanaModificar', valor: item })}
                                deshabilitarEventoDetalle={esOperador}
                                eventoEliminar={(item: any) => {
                                    updateMensajeDialogo("¿Está seguro que desea eliminar este domicilio de estampillado?");
                                    dialogoRef.current!.mostrar()
                                        .then(() => updateEstadoDomiciliosEstampillado({ tipo: 'deleteDomicilio', valor: item.Indice })).catch(() => { });
                                }} deshabilitarBotonEliminar={esOperador} mostrarFormCambiarPagina={false} />
                        </div>
                    </Tab>
                    <Tab eventKey="plantillasCalculo" title="Plantillas Calculo Automatico">
                        <div className="mt-2">
                            <GrillaSync datos={estadoPlantillas.plantillas}
                                campos={[{ propiedad: 'Nombre', titulo: 'Plantilla', clave: true }]}
                                eventoAgregar={() => crearEditarPlantillaRef.current!.mostrarCrear().then((plantilla: any) => {
                                    updateEstadoPlantillas({ tipo: 'insertPlantilla', valor: plantilla });
                                }).catch(() => { })}
                                deshabilitarBotonAgregar={esOperador}
                                eventoDetalle={(plantilla: any) => crearEditarPlantillaRef.current!.mostrarModificar(plantilla).then((nuevaPlantilla: any) => {
                                    updateEstadoPlantillas({ tipo: 'insertPlantilla', valor: nuevaPlantilla });
                                }).catch(() => { })}
                                deshabilitarEventoDetalle={esOperador}
                                eventoEliminar={(plantilla: any) => {
                                    updateMensajeDialogo("¿Está seguro que desea eliminar esta plantilla?");
                                    dialogoRef.current!.mostrar()
                                        .then(() => updateEstadoPlantillas({ tipo: 'deletePlantilla', id: plantilla.Id, tempId: plantilla.TempId })).catch(() => { });
                                }} deshabilitarBotonEliminar={esOperador} mostrarFormCambiarPagina={false} />
                        </div>
                    </Tab>
                </Tabs>
                <Button variant="danger" className="mt-2 mb-2" onClick={() => {
                    disableRedirectEventCallback();
                    history.goBack();
                }}>Cancelar</Button>
                <Button disabled={esOperador} onClick={async () => {
                    let promesa = formikRef.current!.submitForm() as unknown as Promise<boolean>;
                    let result = await promesa;
                    if (result) {
                        history.replace('/importadores');
                    }
                }} className="mt-2 ml-2 mb-2">Guardar</Button>
            </MyForm>
        </Formik>


        

        <MyModal show={estadoVentajas.creando || estadoVentajas.modificando}>
            <Modal.Dialog>
                <Modal.Header>
                    <h2>{estadoVentajas.creando ? 'Crear Ventaja de Importador' : 'Modificar Ventaja de Importador'}</h2>
                </Modal.Header>
                <Modal.Body>
                    <Formik initialValues={{
                        'CodigoVentaja': estadoVentajas.valorModificando?.CodigoVentaja ?? '',
                        'CodigoSubregimen': estadoVentajas.valorModificando?.CodigoSubregimen ?? '',
                        'Ncm': estadoVentajas.valorModificando?.Ncm ?? '',
                        'CodigoPaisOrigen': estadoVentajas.valorModificando?.CodigoPaisOrigen ?? '',
                        'ExcluirPaisOrigen': estadoVentajas.valorModificando?.ExcluirPaisOrigen ?? false,
                        'ExcluirNcm': estadoVentajas.valorModificando?.ExcluirNcm ?? false
                    }} innerRef={formikVentajasRef} validationSchema={schemaVentajas} onSubmit={(values: any, actions) => {
                        if (estadoVentajas.valorModificando) {
                            updateEstadoVentajas({
                                tipo: 'insertVentaja',
                                valor: {
                                    ...values,
                                    NroClienteAlpha: userInfo.nroClienteAlpha,
                                    Id: estadoVentajas.valorModificando.Id,
                                    CreadoPor: estadoVentajas.valorModificando.CreadoPor,
                                    CreadoEl: estadoVentajas.valorModificando.CreadoEl,
                                    ModificadoPor: userInfo.claims.nameid,
                                    ModificadoEl: DateTime.local().toISO()
                                }
                            });
                        } else {
                            updateEstadoVentajas({
                                tipo: 'insertVentaja',
                                valor: {
                                    ...values,
                                    NroClienteAlpha: userInfo.nroClienteAlpha,
                                    CreadoPor: userInfo.claims.nameid,
                                    CreadoEl: DateTime.local().toISO(),
                                    ModificadoPor: userInfo.claims.nameid,
                                    ModificadoEl: DateTime.local().toISO()
                                }
                            });
                        }
                        actions.setSubmitting(false);
                    }}>
                        <MyForm blockWhenSubmitting={false}>
                            <Form.Group>
                                <MySelect name="CodigoVentaja" label="Codigo Ventaja"
                                    options={() => api.getVentajas().then(convertirDatosGenericosAOption)}
                                    getOptionLabel={(option: SelectOption) => option.value} />
                            </Form.Group>
                            <Form.Group>
                                <MySelect name="CodigoSubregimen" label="Subregimen" options={() => api.getSubregimenes().then(convertirDatosGenericosAOption)}
                                    getOptionLabel={(option: SelectOption) => option.value} />
                            </Form.Group>
                            <Form.Group>
                                <MyAsyncSelect name="Ncm" label="NCM" loadOptions={api.cargarNcm()} />
                                <MyFormCheck className="mt-2" name="ExcluirNcm" label="Excluir NCM"></MyFormCheck>
                            </Form.Group>
                            <Form.Group>
                                <MySelect name="CodigoPaisOrigen" label="Pais Origen" options={() => api.getPaises().then(convertirDatosGenericosAOption)}
                                    getOptionLabel={optionLabelConCodigo} />
                                <MyFormCheck className="mt-2" name="ExcluirPaisOrigen" label="Excluir Pais Origen"></MyFormCheck>
                            </Form.Group>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstadoVentajas({ tipo: 'esconderVentana' })}>
                        Cancelar
                    </Button>
                    <Button onClick={() => formikVentajasRef.current?.submitForm()}>
                        Ingresar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estadoDomiciliosEstampillado.creando || estadoDomiciliosEstampillado.modificando}>
            <Modal.Dialog>
                <Modal.Header>
                    <h2>{estadoDomiciliosEstampillado.creando ? 'Crear Domicilio de Estampillado' : 'Modificar Domicilio de Estampillado'}</h2>
                </Modal.Header>
                <Modal.Body>
                    <Formik initialValues={{
                        'Dato': estadoDomiciliosEstampillado.valorModificando?.Dato ?? '',
                    }} innerRef={formikDomiciliosEstampilladoRef}
                        validationSchema={Yup.object({
                            'Dato': Yup.string().nullable().required('Debe ingresar el domicilio de estampillado')
                        })} onSubmit={(values: any, actions) => {
                            let domiciliosExistentes = Array.from(estadoDomiciliosEstampillado.domicilios);
                            if (estadoDomiciliosEstampillado.valorModificando) {
                                domiciliosExistentes = domiciliosExistentes.filter((item: any) => item.Indice !== estadoDomiciliosEstampillado.valorModificando.Indice);
                            }
                            if (domiciliosExistentes.map((item: any) => item.Dato).includes(values.Dato)) {
                                actions.setFieldError('Dato', 'El domicilio ingresado ya existe');
                            } else {
                                updateEstadoDomiciliosEstampillado({ tipo: 'insertDomicilio', valor: values.Dato });
                            }
                            actions.setSubmitting(false);
                        }}>
                        <MyForm blockWhenSubmitting={false}>
                            <Form.Group>
                                <MyTextarea name="Dato"></MyTextarea>
                            </Form.Group>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstadoDomiciliosEstampillado({ tipo: 'esconderVentana' })}>
                        Cancelar
                    </Button>
                    <Button onClick={() => formikDomiciliosEstampilladoRef.current?.submitForm()}>
                        Ingresar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <CrearEditarPlantillaCalculoImportador ref={crearEditarPlantillaRef} plantillasExistentes={estadoPlantillas.plantillas}></CrearEditarPlantillaCalculoImportador>
        <DialogoConfirmar ref={dialogoRef} mensaje={mensajeDialogo} textoBotonCancelar="No" textoBotonConfirmar="Sí" />
    </>);
}