import React from "react";
import { useHistory } from "react-router";
import { AppContext, NotFoundComponent } from "../../App";
import { Alert, Button, Dropdown, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grilla, { GrillaRef, TipoCampo } from "../../Grilla";
import { faFileExcel, faPlus } from "@fortawesome/free-solid-svg-icons";
import { BaseAsyncSelect as AsyncSelect } from "BaseSelect";
import Loader from "react-loaders";
import { MyModal } from "../../MyModal";
import FileSaver from "file-saver";
import { Link } from "react-router-dom";
import { MyAsyncSelect, MyForm, MyFormControl, MySelect } from "../../FormikHooks";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { optionLabelConCodigo, isInRole, useDecodedParams, convertirDatosGenericosAOption, } from "../../Utilidades";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import { useApi, TipoLock } from "ApiHooks";
import { CancelToken } from "SintiaHooks";
import styled from "styled-components";

const AsyncSelectCustom = styled(AsyncSelect)`
    width:250px;
`;

export function ArticulosCatalogo() {
    let establecerNcmFormikRef = React.useRef<FormikProps<any>>(null);
    let copiarArticuloFormikRef = React.useRef<FormikProps<any>>(null);
    let moverArticulosFormikRef = React.useRef<FormikProps<any>>(null);
    let dialogoConfirmarEliminarArticuloRef = React.useRef<DialogoConfirmarRef>(null);
    let dialogoConfirmarEliminarVariosArticulosRef = React.useRef<DialogoConfirmarRef>(null);
    let { codigoCatalogo } = useDecodedParams() as { codigoCatalogo: string };
    let { mostrarError, userInfo } = React.useContext(AppContext);
    let history = useHistory();
    let api = useApi();
    let [generandoExcel, updateGenerandoExcel] = React.useState(false);
    let [estadoCopiandoArticulo, updateCopiandoArticulo] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrarModal') {
            return { mostrarModal: true, codigoArticuloCopiando: accion.valor, errorCopiarArticulo: '' };
        } else if (accion.tipo === 'esconderModal') {
            return { mostrarModal: false, codigoArticuloCopiando: '', errorCopiarArticulo: '' };
        } else if (accion.tipo === 'setMensajeError') {
            return { ...estado, errorCopiarArticulo: accion.valor };
        }
        return { ...estado };
    }, { mostrarModal: false, codigoArticuloCopiando: '', errorCopiarArticulo: '' });
    let [estadoMoviendoArticulos, updateMoviendoArticulos] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrarModal') {
            return { mostrarModal: true, articulosAMover: accion.valor, errorMoverArticulos: '' };
        } else if (accion.tipo === 'esconderModal') {
            return { mostrarModal: false, articulosAMover: [], errorMoverArticulos: '' };
        } else if (accion.tipo === 'setMensajeError') {
            return { ...estado, errorMoverArticulos: accion.valor };
        }
        return { ...estado };
    }, { mostrarModal: false, articulosAMover: [], errorMoverArticulos: '' });
    let [estadoAgregarEliminarIntervencion, updateEstadoAgregarEliminarIntervencion] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrarAgregar') {
            return { mostrar: true, eliminar: false, mensajeError: '' };
        } else if (accion.tipo === 'mostrarEliminar') {
            return { mostrar: true, eliminar: true, mensajeError: '' };
        } else if (accion.tipo === 'esconderModal') {
            return { mostrar: false, eliminar: false, mensajeError: '' };
        } else if (accion.tipo === 'setMensajeError') {
            return { ...estado, mensajeError: accion.valor };
        }
        return estado;
    }, { mostrar: false, eliminar: false, mensajeError: '' });
    let [estableciendoNcm, updateEstableciendoNcm] = React.useState(false);
    let refGrilla = React.useRef<GrillaRef>(null);
    let [mensajeExito, updateMensajeExito] = React.useState('');
    let [mensajeEliminarArticulo, updateMensajeEliminarArticulo] = React.useState('');
    function busquedaReducer(estado: any, accion: any) {
        if (accion.tipo === 'codigoArticulo') {
            return { ...estado, codigoArticulo: accion.valor };
        } else if (accion.tipo === 'descripcion') {
            return { ...estado, descripcion: accion.valor };
        } else if (accion.tipo === 'ncm') {
            return { ...estado, ncm: accion.valor };
        } else if (accion.tipo === 'buscarPorFecha') {
            return { ...estado, buscarPorFecha: accion.valor };
        } else if (accion.tipo === 'fechaDesde') {
            return { ...estado, fechaDesde: accion.valor };
        } else if (accion.tipo === 'fechaHasta') {
            return { ...estado, fechaHasta: accion.valor };
        }
        return estado;
    }
    let busquedaInicial = {
        codigoArticulo: '', descripcion: '', ncm: null, buscarPorFecha: false, fechaDesde: '', fechaHasta: ''
    };
    let [busquedaActual, updateBusquedaActual] = React.useState(busquedaInicial);
    let [busqueda, updateBusqueda] = React.useReducer(busquedaReducer, { ...busquedaInicial });
    let [campos, updateCampos] = React.useState([{ titulo: 'Catalogo', propiedad: 'CodigoCatalogo' },
    { titulo: "Nro. Articulo", propiedad: 'CodigoArticulo', clave: true },
    { titulo: 'Ncm', propiedad: 'Ncm' }, { titulo: 'Descripción Catalogo', propiedad: 'Descripcion' },
    { titulo: 'Sufijos', propiedad: 'Sufijos' }, { titulo: 'Pais Origen', propiedad: 'NombrePaisOrigen' },
    { titulo: 'Creado Por', propiedad: 'NombreCreadoPor' },
    { titulo: 'Fecha Creación', propiedad: 'CreadoEl', tipo: TipoCampo.DateTime },
    { titulo: 'Modificado Por', propiedad: 'NombreModificadoPor' },
    { titulo: 'Fecha Modificación', propiedad: 'ModificadoEl', tipo: TipoCampo.DateTime }]);
    let establecerNcmSchema = Yup.object({
        'ncmViejo': Yup.string().nullable().required('Debe ingresar el NCM a reemplazar'),
        'ncmNuevo': Yup.string().nullable().required('Debe ingresar el NCM nuevo')
    });
    let copiarArticuloSchema = Yup.object({
        'articuloACopiar': Yup.string().nullable().required(),
        'catalogoCopia': Yup.string().nullable().required('Debe seleccionar el catalogo donde se va a copiar el articulo'),
        'codigoArticuloCopia': Yup.string().nullable().required('Debe ingresar el codigo de articulo de la copia')
            .test('codigos-distintos', 'El catalogo o el codigo de articulo deben ser distintos al del articulo que va a copiar', function (valor) {
                return this.resolve(Yup.ref('catalogoCopia')) !== codigoCatalogo || valor !== this.resolve(Yup.ref('articuloACopiar'));
            })
    });
    let moverArticuloSchema = Yup.object({
        'nuevoCatalogo': Yup.string().nullable().required('Debe seleccionar el catalogo donde se va a mover el articulo')
            .test('catalogo-distinto', 'Debe seleccionar un catalogo distinto al actual', valor => valor !== codigoCatalogo)
    });
    let agregarEliminarIntervencionSchema = Yup.object({
        'CodigoCatalogo': Yup.string(),
        'IntervencionId': Yup.string().nullable().required('Debe seleccionar la intervención'),
        'Ncm': Yup.string().nullable(),
        'CodigoPaisOrigen': Yup.string().nullable()
    });
    React.useEffect(() => {
        async function cargarConfiguracionEmpresa() {
            try {
                let respuesta = await api.getConfiguracionEmpresa();
                if (respuesta.Configuracion?.MostrarEstadoEnGrillaArticuloCatalogo) {
                    updateCampos(campos => {
                        let nuevosCampos = Array.from(campos);
                        nuevosCampos.splice(6, 0, { titulo: 'Estado Articulo', propiedad: 'EstadoArticulo' });
                        return nuevosCampos;
                    });
                }
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar configuración empresa', error);
                }
            }
        }
        cargarConfiguracionEmpresa();
        //eslint-disable-next-line 
    }, []);
    React.useEffect(() => {
        refGrilla.current?.recargar();
    }, [busquedaActual]);
    React.useEffect(() => {
        if (estadoCopiandoArticulo?.mostrarModal) {
            copiarArticuloFormikRef.current?.setFieldValue('catalogoCopia', codigoCatalogo);
            copiarArticuloFormikRef.current?.setFieldValue('articuloACopiar', estadoCopiandoArticulo.codigoArticuloCopiando);
        }
        //eslint-disable-next-line
    }, [estadoCopiandoArticulo.mostrarModal]);
    async function cargarDatos(desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await api.busquedaArticulosCompletoPaginado([codigoCatalogo], busquedaActual.codigoArticulo,
            busquedaActual.descripcion, busquedaActual.ncm, busquedaActual.buscarPorFecha, busquedaActual.fechaDesde,
            busquedaActual.fechaHasta, desde, hasta, cancelToken);
        let paises = await api.getPaises();
        let items = respuesta.Items.map((item: any) => ({
            ...item,
            NombrePaisOrigen: paises.find((pais: any) => pais.Codigo === item.CodigoPaisOrigen)?.Descripcion
        }));
        return { cantidadItems: respuesta.CantidadTotal, items: items };
    }
    function eventoEliminar(item: any) {
        updateMensajeEliminarArticulo(`¿Está seguro que desea eliminar el articulo ${item.CodigoArticulo}?`);
        return dialogoConfirmarEliminarArticuloRef.current!.mostrar().then(async () => {
            refGrilla.current?.setBloqueado(true);
            try {
                let puedeEliminar = await api.obtenerLock(TipoLock.Catalogo, item.CodigoCatalogo);
                if (puedeEliminar) {
                    let { exito, error } = await api.deleteArticulo(item.CodigoCatalogo, item.CodigoArticulo);
                    if (exito) {
                        await api.eliminarLock(TipoLock.Catalogo, codigoCatalogo);
                        refGrilla.current?.recargar();
                    } else {
                        mostrarError(error as string);
                    }
                } else {
                    mostrarError(`No se puede eliminar el articulo ${item.CodigoArticulo} porque otra persona está utilizando el catalogo ${item.CodigoCatalogo}`);
                }
                refGrilla.current?.setBloqueado(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al eliminar articulo', error);
                    mostrarError('Hubo un error al eliminar el articulo');
                    refGrilla.current?.setBloqueado(false);
                }
            }
        }).catch(() => { });
    }
    function eliminarVariosArticulos() {
        let articulosSeleccionados = refGrilla.current?.getClavesSeleccionadas();
        if (!articulosSeleccionados || articulosSeleccionados.length === 0) {
            mostrarError('Debe seleccionar al menos un articulo para eliminarlo');
        } else {
            return dialogoConfirmarEliminarVariosArticulosRef.current!.mostrar().then(async () => {
                refGrilla.current?.setBloqueado(true);
                try {
                    let puedeEliminar = await api.obtenerLock(TipoLock.Catalogo, codigoCatalogo);
                    if (puedeEliminar) {
                        let { exito, error } = await api.deleteVariosArticulos(codigoCatalogo, articulosSeleccionados as string[]);
                        if (exito) {
                            await api.eliminarLock(TipoLock.Catalogo, codigoCatalogo);
                            updateMensajeExito('Se han eliminado los articulos exitosamente');
                            refGrilla.current?.recargar();
                        } else {
                            mostrarError(error as string);
                        }
                    } else {
                        mostrarError(`No se puede eliminar los articulos porque otra persona está utilizando el catalogo ${codigoCatalogo}`);
                    }
                } catch (error) {
                    if (!api.isCancel(error)) {
                        console.error('Error al eliminar articulos', error);
                        mostrarError('Hubo un error al eliminar los articulos');
                    }
                }
                refGrilla.current?.setBloqueado(false);
            }).catch(() => { });
        }
    }

    async function exportarAExcel() {
        try {
            updateGenerandoExcel(true);
            let { fileName, excel } = await api.exportarBusquedaArticulosAExcel([codigoCatalogo],
                busquedaActual.codigoArticulo, busquedaActual.descripcion, busquedaActual.ncm,
                busquedaActual.buscarPorFecha, busquedaActual.fechaDesde, busquedaActual.fechaHasta, true);
            updateGenerandoExcel(false);
            FileSaver.saveAs(excel, fileName);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al exportar listado de articulos a Excel');
            }
            if (!api.isUnmounted()) {
                updateGenerandoExcel(false);
            }
        }
    }
    async function establecerNcm(values: { ncmViejo: string, ncmNuevo: string }) {
        try {
            updateEstableciendoNcm(false);
            refGrilla.current?.setBloqueado(true);
            let puedeModificar = await api.obtenerLock(TipoLock.Catalogo, codigoCatalogo);
            if (puedeModificar) {
                let { exito, error } = await api.establecerNcmArticulos(codigoCatalogo, values.ncmViejo, values.ncmNuevo);
                if (exito) {
                    await api.eliminarLock(TipoLock.Catalogo, codigoCatalogo);
                    updateMensajeExito('Se ha establecido el NCM exitosamente');
                    refGrilla.current?.recargar();
                } else {
                    mostrarError(error as string);
                }
            } else {
                mostrarError(`No se puede reemplazar el NCM porque otra persona está utilizando el catalogo ${codigoCatalogo}`);
            }
            refGrilla.current?.setBloqueado(false);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al reemplazar NCM', error);
                mostrarError('Hubo un error al reemplazar el NCM');
                refGrilla.current?.setBloqueado(false);
            }
        }
    }
    async function cargarCatalogos() {
        try {
            let respuesta = await api.getCatalogos();
            return respuesta.map((catalogo: any) => ({ value: catalogo.Codigo, label: catalogo.Descripcion }));
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar catalogos', error);
                mostrarError('Error al cargar los catalogos');
            }
            return [];
        }
    }
    async function cargarIntervenciones() {
        try {
            let respuesta = await api.getIntervenciones();
            return respuesta.map((item: any) => ({ value: item.Id, label: item.Descripcion }));
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar intervenciones', error);
                mostrarError('Error al cargar las intervenciones disponibles');
            }
            return [];
        }
    }
    async function copiarArticulo(values: { articuloACopiar: string, catalogoCopia: string, codigoArticuloCopia: string }) {
        try {
            let puedeModificar = await api.obtenerLock(TipoLock.Catalogo, values.catalogoCopia);
            if (puedeModificar) {
                let { exito, error } = await api.copiarArticulo(codigoCatalogo, values.articuloACopiar,
                    values.catalogoCopia, values.codigoArticuloCopia);
                if (exito) {
                    updateCopiandoArticulo({ tipo: 'esconderModal' });
                    updateMensajeExito('Se ha copiado el articulo exitosamente');
                    refGrilla.current?.recargar();
                } else {
                    updateCopiandoArticulo({ tipo: 'setMensajeError', valor: error });
                }
            } else {
                updateCopiandoArticulo({ tipo: 'setMensajeError', valor: `No se puede modificar el catalogo ${values.catalogoCopia} porque otro usuario lo está modificando` });
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al copiar articulo', error);
                updateCopiandoArticulo({ tipo: 'setMensajeError', valor: 'Hubo un error al copiar el articulo' });
            }
        }
    }
    async function moverArticulos(values: { nuevoCatalogo: string }) {
        try {
            let puedeModificar = await api.obtenerLock(TipoLock.Catalogo, codigoCatalogo);
            if (puedeModificar) {
                puedeModificar = await api.obtenerLock(TipoLock.Catalogo, values.nuevoCatalogo);
                if (puedeModificar) {
                    let { exito, error } = await api.moverArticulos(codigoCatalogo, estadoMoviendoArticulos.articulosAMover,
                        values.nuevoCatalogo);
                    if (exito) {
                        await api.eliminarLock(TipoLock.Catalogo, codigoCatalogo);
                        await api.eliminarLock(TipoLock.Catalogo, values.nuevoCatalogo);
                        updateMoviendoArticulos({ tipo: 'esconderModal' });
                        updateMensajeExito('Se han movido los articulos exitosamente');
                        refGrilla.current?.recargar();
                    } else {
                        updateMoviendoArticulos({ tipo: 'setMensajeError', valor: error });
                    }
                } else {
                    await api.eliminarLock(TipoLock.Catalogo, codigoCatalogo);
                    updateMoviendoArticulos({ tipo: 'setMensajeError', valor: `No se puede modificar el catalogo ${values.nuevoCatalogo} porque otro usuario lo está modificando` });
                }
            } else {
                updateMoviendoArticulos({ tipo: 'setMensajeError', valor: `No se puede modificar el catalogo ${codigoCatalogo} porque otro usuario lo está modificando` });
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al mover articulos', error);
                updateMoviendoArticulos({ tipo: 'setMensajeError', valor: 'Hubo un error al mover los articulos' });
            }
        }
    }
    async function agregarEliminarIntervencion(values: {
        CodigoCatalogo: string,
        IntervencionId: string,
        Ncm?: string,
        CodigoPaisOrigen?: string
    }) {
        try {
            let puedeModificar = await api.obtenerLock(TipoLock.Catalogo, values.CodigoCatalogo);
            if (puedeModificar) {
                if (estadoAgregarEliminarIntervencion.eliminar) {
                    let { exito, error } = await api.eliminarIntervencionArticulos(values.CodigoCatalogo, values.IntervencionId,
                        values.Ncm, values.CodigoPaisOrigen);
                    if (exito) {
                        await api.eliminarLock(TipoLock.Catalogo, values.CodigoCatalogo);
                        updateMensajeExito('Se ha eliminado la intervención de articulos exitosamente');
                        updateEstadoAgregarEliminarIntervencion({ tipo: 'esconderModal' });
                        refGrilla.current?.recargar();
                    } else {
                        updateEstadoAgregarEliminarIntervencion({ tipo: 'setMensajeError', valor: error });
                    }
                } else {
                    let { exito, error } = await api.agregarIntervencionArticulos(values.CodigoCatalogo, values.IntervencionId,
                        values.Ncm, values.CodigoPaisOrigen);
                    if (exito) {
                        await api.eliminarLock(TipoLock.Catalogo, values.CodigoCatalogo);
                        updateMensajeExito('Se ha agregado la intervención de articulos exitosamente');
                        updateEstadoAgregarEliminarIntervencion({ tipo: 'esconderModal' });
                        refGrilla.current?.recargar();
                    } else {
                        updateEstadoAgregarEliminarIntervencion({ tipo: 'setMensajeError', valor: error });
                    }
                }
            } else {
                updateEstadoAgregarEliminarIntervencion({ tipo: 'setMensajeError', valor: `No se puede modificar el catalogo ${values.CodigoCatalogo} porque otro usuario lo está modificando` });
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al agregar/eliminar intervención', error);
                updateEstadoAgregarEliminarIntervencion({ tipo: 'setMensajeError', valor: `Hubo un error al ${estadoAgregarEliminarIntervencion.eliminar ? 'eliminar' : 'agregar'} la intervención` });
            }
        }
    }
    let esOperador = isInRole(userInfo, 'Operador');
    return codigoCatalogo ? (<>
        <h2>Articulos de catalogo {codigoCatalogo}</h2>
        <Form inline onSubmit={e => {
            updateBusquedaActual(busqueda);
            e.preventDefault();
        }}>
            <Form.Group>
                <Form.Label htmlFor="txtCodigoArticulo" className="mr-2">Codigo Articulo</Form.Label>
                <Form.Control type="text" id="txtCodigoArticulo" value={busqueda.codigoArticulo} className="mb-2 mr-2"
                    onChange={e => updateBusqueda({ tipo: 'codigoArticulo', valor: e.target.value })}></Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label htmlFor="txtDescripcion" className="mr-2">Descripción Catalogo</Form.Label>
                <Form.Control type="text" id="txtDescripcion" value={busqueda.descripcion} className="mb-2 mr-2"
                    onChange={e => updateBusqueda({ tipo: 'descripcion', valor: e.target.value })}></Form.Control>
            </Form.Group>
            <Form.Group className="mr-2">
                <Form.Label htmlFor="txtNcm" className="mr-2">Ncm</Form.Label>
                <AsyncSelectCustom isClearable placeholder="Seleccionar..." value={busqueda.ncm ? { label: busqueda.ncm, value: busqueda.ncm } : null}
                    onChange={(e: any) => {
                        updateBusqueda({ tipo: 'ncm', valor: e?.value });
                    }} loadOptions={api.cargarNcm()}></AsyncSelectCustom>
            </Form.Group>
            <Form.Group>
                <Form.Check custom label="Buscar por fecha modificación" id="checkBuscarPorFecha" checked={busqueda.buscarPorFecha}
                    onChange={(e: any) => updateBusqueda({ tipo: 'buscarPorFecha', valor: e.target.checked })}></Form.Check>
                <Form.Label htmlFor="txtFechaDesde" className="mx-2">Desde</Form.Label>
                <Form.Control type="date" id="txtFechaDesde" disabled={!busqueda.buscarPorFecha} value={busqueda.fechaDesde} className="mb-2 mr-2"
                    onChange={e => updateBusqueda({ tipo: 'fechaDesde', valor: e.target.value })}></Form.Control>
                <Form.Label htmlFor="txtFechaHasta" className="mr-2">Hasta</Form.Label>
                <Form.Control type="date" id="txtFechaHasta" disabled={!busqueda.buscarPorFecha} value={busqueda.fechaHasta} className="mb-2 mr-2"
                    onChange={e => updateBusqueda({ tipo: 'fechaHasta', valor: e.target.value })}></Form.Control>
            </Form.Group>
            <Button type="submit" className="mb-2">Buscar</Button>
        </Form>
        <Button disabled={esOperador} className="mb-2 mr-2" onClick={() => history.push(`/catalogos/articulos/${encodeURIComponent(codigoCatalogo)}/add`)}>
            <FontAwesomeIcon icon={faPlus} />
            <span>Agregar</span>
        </Button>
        <Button variant="success" className="mr-2 mb-2" onClick={exportarAExcel}>
            <FontAwesomeIcon icon={faFileExcel} />
            <span>Exportar a Excel</span>
        </Button>
        <Button disabled={esOperador} className="mb-2 mr-2" variant="secondary" onClick={eliminarVariosArticulos}>
            Eliminar Articulos
        </Button>
        <Button disabled={esOperador} className="mb-2 mr-2" variant="secondary" onClick={() => updateEstableciendoNcm(true)}>
            Reemplazar NCM
        </Button>
        <Button disabled={esOperador} className="mb-2 mr-2" variant="secondary" onClick={() => {
            let articulosSeleccionados = refGrilla.current?.getClavesSeleccionadas();
            if (!articulosSeleccionados || articulosSeleccionados.length === 0) {
                mostrarError('Debe seleccionar un articulo para copiarlo');
            } else if (articulosSeleccionados.length > 1) {
                mostrarError('Debe seleccionar solamente un articulo para copiarlo');
            } else {
                updateCopiandoArticulo({ tipo: 'mostrarModal', valor: articulosSeleccionados[0] });
            }
        }}>
            Copiar Articulo
        </Button>
        <Button disabled={esOperador} className="mb-2 mr-2" variant="secondary" onClick={() => {
            let articulosSeleccionados = refGrilla.current?.getClavesSeleccionadas();
            if (!articulosSeleccionados || articulosSeleccionados.length === 0) {
                mostrarError('Debe seleccionar un articulo para copiarlo');
            } else {
                updateMoviendoArticulos({ tipo: 'mostrarModal', valor: articulosSeleccionados });
            }
        }}>
            Mover Articulos a Catalogo
        </Button>
        <Dropdown className="d-inline">
            <Dropdown.Toggle id="menuReportes" className="mb-2 mr-2">Reportes</Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item as={Link} to={`/catalogos/articulos/${encodeURIComponent(codigoCatalogo)}/reporteLNA`}>LNA</Dropdown.Item>
                <Dropdown.Item as={Link} to={`/catalogos/articulos/${encodeURIComponent(codigoCatalogo)}/reporteLNA?conNovedades=true`}>LNA con Novedades</Dropdown.Item>
                <Dropdown.Item as={Link} to={`/catalogos/articulos/${encodeURIComponent(codigoCatalogo)}/reporteDetallado`}>Detallado</Dropdown.Item>
                <Dropdown.Item as={Link} to={`/catalogos/articulos/${encodeURIComponent(codigoCatalogo)}/reporteNCMNoVigente`}>NCM No Vigente</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
        <Button disabled={esOperador} className="mb-2 mr-2" variant="secondary"
            onClick={() => updateEstadoAgregarEliminarIntervencion({ tipo: 'mostrarAgregar' })}>
            Agregar Intervención
        </Button>
        <Button disabled={esOperador} className="mb-2 mr-2" variant="secondary"
            onClick={() => updateEstadoAgregarEliminarIntervencion({ tipo: 'mostrarEliminar' })}>
            Eliminar Intervención
        </Button>
        <Grilla seleccionMultiple campos={campos} cargarDatos={cargarDatos}
            eventoDetalle={item => history.push(`/catalogos/articulos/${encodeURIComponent(codigoCatalogo)}/edit/` + encodeURIComponent(item.CodigoArticulo))}
            eventoEliminar={eventoEliminar} ref={refGrilla}
            deshabilitarBotonEliminar={esOperador} />
        <MyModal show={generandoExcel}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead">Generando Excel...</p>
                    <div className="loader-container">
                        <Loader type="ball-spin-fade-loader" active></Loader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        api.cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estableciendoNcm}>
            <Modal.Dialog>
                <Modal.Header closeButton onHide={() => updateEstableciendoNcm(false)}>
                    <h2>Reemplazar NCM</h2>
                </Modal.Header>
                <Modal.Body>
                    <Formik initialValues={{ ncmViejo: '', ncmNuevo: '' }} validationSchema={establecerNcmSchema}
                        onSubmit={establecerNcm} innerRef={establecerNcmFormikRef}>
                        <MyForm>
                            <Form.Row>
                                <MyFormControl type="text" name="ncmViejo" label="NCM a reemplazar"></MyFormControl>
                            </Form.Row>
                            <Form.Row>
                                <MyAsyncSelect name="ncmNuevo" label="NCM nuevo"
                                    loadOptions={api.cargarNcm()}></MyAsyncSelect>
                            </Form.Row>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstableciendoNcm(false)}>Cancelar</Button>
                    <Button variant="primary" onClick={() => establecerNcmFormikRef.current?.submitForm()}>
                        Ingresar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estadoCopiandoArticulo?.mostrarModal}>
            <Modal.Dialog>
                <Modal.Header closeButton onHide={() => updateCopiandoArticulo({ tipo: 'esconderModal' })}>
                    <h2>Copiar Articulo</h2>
                </Modal.Header>
                <Formik initialValues={{ articuloACopiar: '', catalogoCopia: '', codigoArticuloCopia: '' }}
                    validationSchema={copiarArticuloSchema}
                    onSubmit={copiarArticulo} innerRef={copiarArticuloFormikRef}>
                    {({ isSubmitting, submitForm }) => <>
                        <Modal.Body>
                            {estadoCopiandoArticulo.errorCopiarArticulo && (<Alert variant="danger">{estadoCopiandoArticulo.errorCopiarArticulo}</Alert>)}
                            <MyForm>
                                <Form.Row>
                                    <MyFormControl type="text" readOnly name="articuloACopiar" label="Articulo A Copiar"></MyFormControl>
                                </Form.Row>
                                <Form.Row>
                                    <MySelect name="catalogoCopia" label="Catalogo de la Copia"
                                        options={cargarCatalogos} getOptionLabel={optionLabelConCodigo}></MySelect>
                                </Form.Row>
                                <Form.Row>
                                    <MyFormControl type="text" name="codigoArticuloCopia" label="Codigo de la Copia"></MyFormControl>
                                </Form.Row>
                            </MyForm>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={() => updateCopiandoArticulo({ tipo: 'esconderModal' })}>Cancelar</Button>
                            <Button variant="primary" disabled={isSubmitting} onClick={submitForm}>
                                Ingresar
                            </Button>
                        </Modal.Footer>
                    </>}
                </Formik>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estadoMoviendoArticulos?.mostrarModal}>
            <Modal.Dialog>
                <Modal.Header closeButton onHide={() => updateMoviendoArticulos({ tipo: 'esconderModal' })}>
                    <h2>Mover Articulos</h2>
                </Modal.Header>
                <Formik initialValues={{ nuevoCatalogo: '' }} validationSchema={moverArticuloSchema} onSubmit={moverArticulos} innerRef={moverArticulosFormikRef}>
                    {({ isSubmitting, submitForm }) => <>
                        <Modal.Body>
                            {estadoMoviendoArticulos.errorMoverArticulos && (<Alert variant="danger">{estadoMoviendoArticulos.errorMoverArticulos}</Alert>)}
                            <MyForm>
                                <Form.Row>
                                    <MySelect name="nuevoCatalogo" label="Catalogo donde se mueven los articulos"
                                        options={cargarCatalogos} getOptionLabel={optionLabelConCodigo}></MySelect>
                                </Form.Row>
                            </MyForm>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={() => updateMoviendoArticulos({ tipo: 'esconderModal' })}>Cancelar</Button>
                            <Button variant="primary" disabled={isSubmitting} onClick={submitForm}>
                                Ingresar
                            </Button>
                        </Modal.Footer>
                    </>}
                </Formik>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estadoAgregarEliminarIntervencion?.mostrar}>
            <Modal.Dialog>
                <Modal.Header closeButton onHide={() => updateEstadoAgregarEliminarIntervencion({ tipo: 'esconderModal' })}>
                    <h2>{estadoAgregarEliminarIntervencion.eliminar ? 'Eliminar Intervención de Articulos' : 'Agregar Intervención a Articulos'}</h2>
                </Modal.Header>
                <Formik initialValues={{
                    CodigoCatalogo: codigoCatalogo,
                    IntervencionId: '',
                    Ncm: '',
                    CodigoPaisOrigen: ''
                }} validationSchema={agregarEliminarIntervencionSchema} onSubmit={agregarEliminarIntervencion}>
                    {({ isSubmitting, submitForm }) => <>
                        <Modal.Body>
                            {estadoAgregarEliminarIntervencion.mensajeError && (<Alert variant="danger">{estadoAgregarEliminarIntervencion.mensajeError}</Alert>)}
                            <MyForm>
                                <Form.Row>
                                    <MyFormControl name="CodigoCatalogo" label="Catalogo" type="text" readOnly plaintext></MyFormControl>
                                </Form.Row>
                                <Form.Row>
                                    <MySelect name="IntervencionId" label="Intervención" options={cargarIntervenciones}></MySelect>
                                </Form.Row>
                                <Form.Row>
                                    <MyAsyncSelect name="Ncm" label="Posición"
                                        loadOptions={api.cargarNcm()}></MyAsyncSelect>
                                </Form.Row>
                                <Form.Row>
                                    <MySelect name="CodigoPaisOrigen" label="País Origen"
                                        options={() => api.getPaises().then(convertirDatosGenericosAOption)} getOptionLabel={optionLabelConCodigo}></MySelect>
                                </Form.Row>
                            </MyForm>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={() => updateEstadoAgregarEliminarIntervencion({ tipo: 'esconderModal' })}>Cancelar</Button>
                            <Button variant="primary" disabled={isSubmitting} onClick={submitForm}>
                                Ingresar
                            </Button>
                        </Modal.Footer>
                    </>}
                </Formik>

            </Modal.Dialog>
        </MyModal>
        <MyModal show={!!mensajeExito} onHide={() => updateMensajeExito('')}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="text-success lead">{mensajeExito}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => updateMensajeExito('')}>Cerrar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <DialogoConfirmar mensaje="¿Está seguro que desea eliminar los artículos seleccionados?"
            textoBotonConfirmar="Sí" textoBotonCancelar="No" ref={dialogoConfirmarEliminarVariosArticulosRef}></DialogoConfirmar>
        <DialogoConfirmar mensaje={mensajeEliminarArticulo}
            textoBotonConfirmar="Sí" textoBotonCancelar="No" ref={dialogoConfirmarEliminarArticuloRef}></DialogoConfirmar>
    </>) : (<NotFoundComponent></NotFoundComponent>)
}