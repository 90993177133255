import React from "react";
import { AppContext } from "../../App";
import * as Yup from "yup";
import { Button, Form, Modal } from "react-bootstrap";
import { MyModal } from "../../MyModal";
import { Formik } from "formik";
import { MyForm, MyFormControl } from "../../FormikHooks";
import { validarCUIT } from "Utilidades";
import { useApi } from "ApiHooks";

enum EstadoModal {
    Cerrado,
    Creando,
    Modificando
}
export type CrearEditarTransportistaRef = {
    mostrarCrear: () => Promise<any>,
    mostrarModificar: (transportista: any) => Promise<any>
}

export const CrearEditarTransportista = React.forwardRef((props: any, ref: any) => {
    let { mostrarError } = React.useContext(AppContext);
    let api = useApi();
    let [estado, updateEstado] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrarCrear') {
            return { estadoModal: EstadoModal.Creando, valorModificando: null, resolve: accion.resolve, reject: accion.reject };
        } else if (accion.tipo === 'mostrarModificar') {
            return { estadoModal: EstadoModal.Modificando, valorModificando: accion.transportista, resolve: accion.resolve, reject: accion.reject };
        } else if (accion.tipo === 'cerrar') {
            return { estadoModal: EstadoModal.Cerrado, valorModificando: null, resolve: null, reject: null };
        }
        return estado;
    }, { estadoModal: EstadoModal.Cerrado, valorModificando: null, resolve: null, reject: null });
    React.useImperativeHandle(ref, () => ({
        mostrarCrear: () => new Promise<any>((resolve, reject) => {
            updateEstado({ tipo: 'mostrarCrear', resolve: resolve, reject: reject });
        }),
        mostrarModificar: (transportista: any) => new Promise<any>((resolve, reject) => {
            updateEstado({ tipo: 'mostrarModificar', transportista: transportista, resolve: resolve, reject: reject });
        })
    }));

    React.useEffect(() => {
        if (estado.estadoModal === EstadoModal.Cerrado) {
            api.cancelCurrentTokens();
        }
        //eslint-disable-next-line
    }, [estado.estadoModal]);
    let schema = Yup.object({
        'CUIT': Yup.string().nullable().required('Debe ingresar el CUIT')
            .test('cuit-valido', 'El CUIT ingresado no es válido', validarCUIT),
        'Nombre': Yup.string().nullable().required('Debe ingresar el nombre')
    });
    async function submit(values: any) {
        try {
            let { exito, error, respuesta } = await api.insertTransportista(values);
            if (exito) {
                estado.resolve(respuesta);
                updateEstado({ tipo: 'cerrar' });
            } else {
                mostrarError(error as string);
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al crear/modificar transportista', error);
                mostrarError('Error al crear/modificar transportista');
            }
        }
    }
    const cerrar = () => {
        estado.reject();
        updateEstado({ tipo: 'cerrar' });
    }
    return <MyModal show={estado.estadoModal !== EstadoModal.Cerrado} onHide={cerrar}>
        <Modal.Dialog size="lg">
            <Modal.Header closeButton>
                {estado.estadoModal === EstadoModal.Modificando ? 'Modificar transportista' : 'Crear transportista'}
            </Modal.Header>
            <Formik validationSchema={schema} initialValues={{
                'CUIT': estado.valorModificando?.CUIT ?? '',
                'Nombre': estado.valorModificando?.Nombre ?? ''
            }} onSubmit={submit}>
                {({ submitForm, isSubmitting }) => (<>
                    <Modal.Body>
                        <MyForm>
                            <Form.Group>
                                <MyFormControl type="text" label="CUIT" name="CUIT" readOnly={estado.estadoModal === EstadoModal.Modificando} maxLength={11}></MyFormControl>
                            </Form.Group>
                            <Form.Group>
                                <MyFormControl type="text" label="Nombre" name="Nombre" maxLength={20}></MyFormControl>
                            </Form.Group>
                        </MyForm>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={cerrar}>Cerrar</Button>
                        <Button disabled={isSubmitting} onClick={submitForm}>Ingresar</Button>
                    </Modal.Footer>
                </>)}
            </Formik>
        </Modal.Dialog>
    </MyModal>
});