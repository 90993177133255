/*
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useApi } from 'ApiHooks';
import { useAdvAPI } from 'Paginas/advanced/AdvancedApi';
import ModalFormCaratula from 'Paginas/advanced/ProvisorioTable/ModalFormCaratula';
import { ObtenerArticulosVistaResponse, ObtenerArticuosLiqVistaResponse, ObtenerItemVistaResponse } from 'modelos/Advanced';
import React, { useEffect, useState } from 'react'
import BlockUi from 'react-block-ui';
import { Alert, Button, Container,  Table } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router';
import './styleTable.css'
import ModalFormItem from 'Paginas/advanced/ProvisorioTable/ModalFormItem';
*/

const ProvisorioTable = () => {
/*
  interface params {
    id: string,
  }

  let params: Params = useParams();
  let api = useAdvAPI();
  let apiSintia = useApi();
  const location: any = useLocation()

  const [tableDataItems, setTableDataItems] = useState<ObtenerItemVistaResponse[]>([])
  const [tableDataArticulos, setTableDataArticulos] = useState<ObtenerArticulosVistaResponse[]>([])
  const [tableDataArticulosLiq, setTableDataArticulosLiq] = useState<ObtenerArticuosLiqVistaResponse[]>([])
  const [cargando, updateCargando] = useState(false);
  const [buttonSelected, setButtonSelected] = useState('Items')

  const cargarTablaItems = async () => {
    try {
      updateCargando(true)
      let items = await api.getObtenerItemVista(params.id)
      setTableDataItems(items)
      updateCargando(false);

    } catch (error) {
      if (!apiSintia.isCancel(error)) {
        console.error('Error al cargar vistas', error);
      }
    }
  }

  const cargarTablaArticulos = async () => {
    try {
      updateCargando(true)
      let articulos = await api.getObtenerArticulosVista(params.id)
      console.log('articulos', articulos)
      setTableDataArticulos(articulos)
      updateCargando(false);
    } catch (error) {
      if (!apiSintia.isCancel(error)) {
        console.error('Error al cargar vistas', error);
      }
    }
  }

  const cargarTablaLiq = async () => {
    try {
      updateCargando(true)
      let articulosLiq = await api.getObtenerArticulosLiq(params.id)
      setTableDataArticulosLiq(articulosLiq)
      updateCargando(false);
    } catch (error) {
      if (!apiSintia.isCancel(error)) {
        console.error('Error al cargar vistas', error);
      }
    }
  }

  const tableChange = (botonSelect: any) => {
    const tablaFunctions: any = {
      'Items': cargarTablaItems,
      'Articulos': cargarTablaArticulos,
      'Liquidación': cargarTablaLiq
    };

    const selectedFunction = tablaFunctions[botonSelect];

    if (selectedFunction) {
      setButtonSelected(botonSelect);
      selectedFunction();
    } else {
      console.error('Función no encontrada para el botón seleccionado');
    }
  }

  const sinResultados = () => {
    return (<>
    <Alert variant="warning">
          <FontAwesomeIcon icon={faExclamationTriangle}></FontAwesomeIcon>
          <span className="ml-3">No hay resultados.</span>
        </Alert>
        </>)
  }

  useEffect(() => {
    cargarTablaItems();
    //eslint-disable-next-line
  }, []);

  const renderSwitch = (value: string) => {
    switch (value) {
      case 'Items':
        return tableDataItems?.length > 0 ? <div className="table-responsive">
          <Table style={{
            'fontSize': '0.9rem',
            'width': '100%',
          }}
            className="text-nowrap middle-vertical-align table table-sm table-striped table-bordered">
            <thead>
              <tr>
                <th>ITEM</th>
                <th>NCM</th>
                <th>Lista</th>
                <th>Origen</th>
                <th>Procedencia</th>
                <th>Unidad</th>
                <th>Cantidad</th>
                <th>Estadistica</th>
                <th>Cantidad Est.</th>
                <th>Kilo Neto</th>
                <th>FOB</th>
                <th>FLETE</th>
                <th>SEGURO</th>
                <th>Aj Inc</th>
                <th>Aj Ded</th>
                <th>Ventajas</th>
              </tr>
            </thead>
            <tbody>{tableDataItems?.map((item) =>
            (
              <tr className="cursor-pointer" key={item.ID}>
                <ModalFormItem id={item.NARTEXT} despachoID={params?.id} />
                <td>{item.IESPNCE}</td>
                <td>{item.LISTA}</td>
                <td>{item.CARTPAYORI}</td>
                <td>{item.CARTPAYPRC}</td>
                <td>{item.CARTUNTDCL}</td>
                <td>{item.QARTUNTDCL}</td>
                <td>{item.CARTUNTEST}</td>
                <td>{item.QARTUNTEST}</td>
                <td>{item.QARTKGRNET}</td>
                <td>{item.MARTFOB}</td>
                <td>{item.MARTFLE}</td>
                <td>{item.MARTASS}</td>
                <td>{item.MARTAJUINC}</td>
                <td>{item.MARTAJUDED}</td>
              </tr>
            ))}
            </tbody>
          </Table>
        </div> : sinResultados();
      case 'Articulos':
        return tableDataArticulos?.length > 0 ? <div className="table-responsive">
          <Table style={{
            'fontSize': '0.9rem',
            'width': '100%',
          }}
            className="text-nowrap middle-vertical-align table table-sm table-striped table-bordered">
            <thead>
              <tr>
                <th>Código</th>
                <th>Item</th>
                <th>SubIt</th>
                <th>NCM</th>
                <th>Sufijos</th>
                <th>Unidad</th>
                <th>Cantidad</th>
                <th>Estadistica</th>
                <th>Cantidad Est.</th>
                <th>Unitario</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>{tableDataArticulos?.map((articulo, index) =>
            (
              <tr className="cursor-pointer " key={index}>
                <td>{articulo.IEXT}</td>
                <td>{articulo.NARTEXT}</td>
                <td>{articulo.ISBT}</td>
                <td>{articulo.IESPNCE}</td>
                <td>{articulo.CSBTSVL}</td>
                <td>{articulo.CARTUNTDCL}</td>
                <td>{articulo.CANTIDAD}</td>
                <td>{articulo.CARTUNTEST}</td>
                <td>{articulo.CANTIDAD_ESTADISTICA}</td>
                <td>{articulo.FOB_UNITARIO}</td>
                <td>{articulo.FOB_TOTAL}</td>
              </tr>
            ))}
            </tbody>
          </Table>
        </div> : sinResultados();
      case 'Liquidación':
        return tableDataArticulosLiq?.length > 0 ? <div className="table-responsive">
          <Table style={{
            'fontSize': '0.9rem',
            'width': '100%',
          }}
            className="text-nowrap middle-vertical-align table table-sm table-striped table-bordered">
            <thead>
              <tr>
                <th>ITEM</th>
                <th>NCM</th>
                <th>FOB Dol</th>
                <th>Seguro</th>
                <th>Flete</th>
                <th>Aj Inc</th>
                <th>Aj Ded</th>
                <th>Lista</th>
                <th>Código</th>
                <th>Descripción</th>
                <th>Tipo</th>
                <th>Base</th>
                <th>Coef</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>{tableDataArticulosLiq?.map((articulo, index) =>
            (
              <tr className="cursor-pointer " key={index}>
                <th>{articulo.NARTEXT}</th>
                <th>{articulo.IESPNCE}</th>
                <th>{articulo.IESPNCE}</th>
                <th>{articulo.MARTASS}</th>
                <th>{articulo.MARTFLE}</th>
                <th>{articulo.MARTAJUINC}</th>
                <th>{articulo.MARTAJUDED}</th>
                <th>{articulo.LISTA}</th>
                <th>{articulo.NART}</th>
                <th>{articulo.LTAXABR}</th>
                <th>{articulo.CLQATYP}</th>
                <th>{articulo.MLQABAS}</th>
                <th>{articulo.QLQACOEFIC}</th>
                <th>{articulo.MLQA}</th>
              </tr>
            ))}
            </tbody>
          </Table>
        </div> : sinResultados(); default:
        return <><p>TEST</p></>;
    }
  }
  return (
    <Container>
      <br /><p className="h3">Provisorios</p><br />
      <div className="btn-group row mb-3" style={{ display: 'flex' }}>
        <Button disabled variant={location.state.estado === 'Sin Validar' ? "secondary" : location.state.estado === 'Oficializado' ? 'warning' : 'success'} className=''>{location.state.estado}</Button>
        <ModalFormCaratula id={params.id} />
        <Button onClick={() => tableChange('Items')} variant="outline-primary">Items</Button>
        <Button onClick={() => tableChange('Articulos')} variant="outline-primary">Articulos</Button>
        <Button onClick={() => tableChange('Liquidación')} variant="outline-primary">Liquidación</Button>
        <Button>Validar</Button>
        <Button onClick={() => tableChange('Impresion')} variant="outline-primary">Impresion Malvina</Button>
        <Button onClick={() => tableChange('Oficializar')} variant="danger">Oficializar</Button>
      </div>
      <BlockUi blocking={cargando}>
        {renderSwitch(buttonSelected)}
      </BlockUi>

    </Container>
  )
    */
}

export default ProvisorioTable