import React from "react";
import axios from "axios";
import { AppContext } from "App";
import { MyModal } from "MyModal";
import { Modal, Button, Form } from "react-bootstrap";
import { GrillaSync } from "Grilla";
import { DialogoConfirmar, DialogoConfirmarRef } from "DialogoConfirmar";
import BlockUi from "react-block-ui";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { MyForm, MyFormCheck, MyFormControl } from "FormikHooks";
import { useApi } from "ApiHooks";
enum EstadoModal {
    Cerrado,
    CargandoTipos,
    Cargando,
    Abierto
}

enum EstadoModalCrearModificar {
    Creando,
    Modificando,
    Cerrado
}

export type TiposOperacionRef = { mostrar: () => void };

export const TiposOperacion = React.forwardRef((props: any, ref: any) => {
    let { mostrarError } = React.useContext(AppContext);
    let cancelTokenSourceModalCrear = React.useRef(axios.CancelToken.source());
    let api = useApi();
    let dialogoRef = React.useRef<DialogoConfirmarRef>(null);
    let [estado, updateEstado] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrar') {
            return { estadoModal: EstadoModal.CargandoTipos, tipos: [] };
        } else if (accion.tipo === 'setTipos') {
            return { estadoModal: EstadoModal.Abierto, tipos: accion.valor ?? [] };
        } else if (accion.tipo === 'cerrar') {
            return { estadoModal: EstadoModal.Cerrado, tipos: [] };
        } else if (accion.tipo === 'deleteTipo') {
            return { estadoModal: EstadoModal.Abierto, tipos: estado.tipos.filter((t: any) => t.Codigo !== accion.valor) };
        } else if (accion.tipo === 'setCargando') {
            return { ...estado, estadoModal: accion.valor ? EstadoModal.Cargando : EstadoModal.Abierto };
        }
        return estado;
    }, { estadoModal: EstadoModal.Cerrado, tipos: [] });
    let [estadoModalCrearModificar, updateEstadoModalCrearModificar] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrarCrear') {
            return { estadoModal: EstadoModalCrearModificar.Creando, valorModificando: null };
        } else if (accion.tipo === 'mostrarModificar') {
            return { estadoModal: EstadoModalCrearModificar.Modificando, valorModificando: accion.valor };
        } else if (accion.tipo === 'cerrar') {
            return { estadoModal: EstadoModalCrearModificar.Cerrado, valorModificando: null };
        }
        return estado;
    }, { estadoModal: EstadoModalCrearModificar.Cerrado, valorModificando: null });
    React.useEffect(() => {
        return () => {
            cancelTokenSourceModalCrear.current.cancel();
        }
    }, []);
    React.useEffect(() => {
        if (estadoModalCrearModificar.estadoModal === EstadoModalCrearModificar.Cerrado) {
            cancelTokenSourceModalCrear.current.cancel();
            cancelTokenSourceModalCrear.current = axios.CancelToken.source();
        }
    }, [estadoModalCrearModificar.estadoModal]);
    React.useEffect(() => {
        if (estado.estadoModal === EstadoModal.CargandoTipos) {
            async function cargarTipos() {
                try {
                    let respuesta = await api.getTiposOperacion();
                    updateEstado({ tipo: 'setTipos', valor: respuesta });
                } catch (error) {
                    if (!api.isCancel(error)) {
                        console.error('Error al cargar tipos de operación', error);
                        mostrarError('Error al cargar tipos de operación');
                        updateEstado({ tipo: 'cerrar' });
                    }
                }
            }
            cargarTipos();
            return () => {
                api.cancelCurrentTokens();
            }
        }
        //eslint-disable-next-line
    }, [estado.estadoModal]);
    React.useImperativeHandle(ref, () => ({
        mostrar: () => updateEstado({ tipo: 'mostrar' })
    }));
    async function insertTipo(values: any, actions: FormikHelpers<any>) {
        try {
            if (estadoModalCrearModificar.estadoModal === EstadoModalCrearModificar.Modificando
                && estado.tipos.map((t: any) => t.Codigo.toUpperCase()).includes(values.Codigo.toUpperCase)) {
                actions.setFieldError('Codigo', 'Ya existe un tipo de operación con este codigo');
                return;
            }
            await api.insertTipoOperacion(values, cancelTokenSourceModalCrear.current.token);
            updateEstado({ tipo: 'mostrar' });
            updateEstadoModalCrearModificar({ tipo: 'cerrar' });
        } catch (error) {
            if (!api.isCancel(error)) {
                let mensajeError;
                if (estadoModalCrearModificar.estadoModal === EstadoModalCrearModificar.Modificando) {
                    mensajeError = 'Error al modificar tipo';
                } else {
                    mensajeError = 'Error al crear tipo';
                }
                console.error(mensajeError, error);
                mostrarError(mensajeError);
            }
        }
    }
    function eliminarTipo(tipo: any) {
        dialogoRef.current!.mostrar().then(async () => {
            try {
                updateEstado({ tipo: 'setCargando', valor: true });
                await api.deleteTipoOperacion(tipo.Codigo);
                updateEstado({ tipo: 'deleteTipo', valor: tipo.Codigo });
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al eliminar tipo de operación', error);
                    mostrarError('Error al eliminar tipo de operación');
                    updateEstado({ tipo: 'setCargando', valor: false });
                }
            }
        }).catch(() => { });
    }
    return <>
        <MyModal show={estado.estadoModal === EstadoModal.CargandoTipos || estado.estadoModal === EstadoModal.Cargando || estado.estadoModal === EstadoModal.Abierto}
            onHide={() => updateEstado({ tipo: 'cerrar' })}>
            <Modal.Dialog size="xl">
                <Modal.Header closeButton>
                    Tipos de Operación
                </Modal.Header>
                <Modal.Body>
                    <BlockUi blocking={estado.estadoModal === EstadoModal.Cargando || estado.estadoModal === EstadoModal.CargandoTipos}>
                        <GrillaSync datos={estado.tipos} campos={[{ propiedad: 'Codigo', titulo: 'Codigo', clave: true },
                        { propiedad: 'Descripcion', titulo: 'Descripción' },
                        { propiedad: 'PorDefecto', titulo: 'Por Defecto', plantillaFormato: (valor: any) => valor ? 'Sí' : 'No' }]}
                            eventoAgregar={() => updateEstadoModalCrearModificar({ tipo: 'mostrarCrear' })}
                            eventoDetalle={(tipo: any) => {
                                updateEstadoModalCrearModificar({ tipo: 'mostrarModificar', valor: tipo });
                            }} eventoEliminar={eliminarTipo}></GrillaSync>
                    </BlockUi>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstado({ tipo: 'cerrar' })}>Cerrar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estadoModalCrearModificar.estadoModal === EstadoModalCrearModificar.Creando || estadoModalCrearModificar.estadoModal === EstadoModalCrearModificar.Modificando}
            onHide={() => updateEstadoModalCrearModificar({ tipo: 'cerrar' })}>
            <Modal.Dialog size="lg">
                <Modal.Header closeButton>
                    <h2>{estadoModalCrearModificar.estadoModal === EstadoModalCrearModificar.Modificando ? 'Modificar tipo operación' : 'Crear tipo operación'}</h2>
                </Modal.Header>
                <Formik initialValues={{
                    'Codigo': estadoModalCrearModificar.valorModificando?.Codigo ?? '',
                    'Descripcion': estadoModalCrearModificar.valorModificando?.Descripcion ?? '',
                    'PorDefecto': estadoModalCrearModificar.valorModificando?.PorDefecto ?? false
                }} validationSchema={Yup.object({
                    'Codigo': Yup.string().nullable().required('Debe ingresar el codigo'),
                    'Descripcion': Yup.string().nullable().required('Debe ingresar la descripción'),
                    'PorDefecto': Yup.boolean()
                })} onSubmit={insertTipo}>
                    {({ isSubmitting, submitForm }) => (<>
                        <Modal.Body>
                            <MyForm>
                                <Form.Group>
                                    <MyFormControl type="text" name="Codigo" label="Codigo" autoFocus
                                        readOnly={estadoModalCrearModificar.estadoModal === EstadoModalCrearModificar.Modificando}></MyFormControl>
                                </Form.Group>
                                <Form.Group>
                                    <MyFormControl type="text" name="Descripcion" label="Descripción"></MyFormControl>
                                </Form.Group>
                                <Form.Group>
                                    <MyFormCheck name="PorDefecto" label="Por Defecto"></MyFormCheck>
                                </Form.Group>
                            </MyForm>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={() => updateEstadoModalCrearModificar({ tipo: 'cerrar' })}>Cancelar</Button>
                            <Button disabled={isSubmitting} onClick={submitForm}>Ingresar</Button>
                        </Modal.Footer>
                    </>)}
                </Formik>
            </Modal.Dialog>
        </MyModal>
        <DialogoConfirmar ref={dialogoRef} mensaje="¿Está seguro que desea eliminar este tipo?" textoBotonConfirmar="Sí" textoBotonCancelar="No"></DialogoConfirmar>
    </>
});