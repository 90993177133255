import React, { useContext, useRef, useState, useReducer, useEffect } from "react";
import BlockUi from "react-block-ui";
import { useHistory } from "react-router";
import { AppContext, NotFoundComponent } from "../../App";
import { useDecodedParams, convertirDatosGenericosAOption, optionLabelConCodigo, isNullOrWhiteSpace, toFixedDecimal } from "../../Utilidades";
import { EstadoArticuloProcesoExcel } from "Enums";
import { Button, Form, Modal, Container } from "react-bootstrap";
import { MyModal } from "MyModal";
import * as Yup from "yup";
import { Field, Formik, FormikProps } from "formik";
import { MyForm, MyFormControl, MySelect } from "FormikHooks";
import Loader from "react-loaders";
import { useApi, TipoLock } from "ApiHooks";
import Decimal from "decimal.js";

enum AccionRealizadaDialogoSobreescribirInterno {
    Cancelar,
    Sobreescribir,
    AgregarSubitems
}
const DialogoSobreescribirInterno = React.forwardRef((props: {
    mensaje: string,
}, ref: any) => {
    let [mostrar, updateMostrar] = React.useState(false);
    let funcionesPromesa = React.useRef<any>({ resolve: null, reject: null });
    const cerrar = () => {
        updateMostrar(false);
        funcionesPromesa.current.resolve(AccionRealizadaDialogoSobreescribirInterno.Cancelar);
    }
    React.useImperativeHandle(ref, () => ({
        mostrar: () => {
            return new Promise((resolve, reject) => {
                funcionesPromesa.current = { resolve: resolve, reject: reject };
                updateMostrar(true);
            });
        }
    }));
    return (<MyModal show={mostrar} onHide={cerrar}>
        <Modal.Dialog>
            <Modal.Body>
                <p className="lead">{props.mensaje}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => {
                    updateMostrar(false);
                    funcionesPromesa.current.resolve(AccionRealizadaDialogoSobreescribirInterno.Cancelar);
                }}>
                    Cancelar
                </Button>
                <Button type="button" variant="primary" onClick={() => {
                    updateMostrar(false);
                    funcionesPromesa.current.resolve(AccionRealizadaDialogoSobreescribirInterno.AgregarSubitems);
                }}>
                    Agregar Subitems a Caratula
                </Button>
                <Button type="button" variant="primary" onClick={() => {
                    updateMostrar(false);
                    funcionesPromesa.current.resolve(AccionRealizadaDialogoSobreescribirInterno.Sobreescribir);
                }}>
                    Sobreescribir
                </Button>
            </Modal.Footer>
        </Modal.Dialog>
    </MyModal>)
});

export function PantallaCaratulaProcesoExcel() {
    let formikRef = useRef<FormikProps<any>>(null);
    let formikAutonumericoRef = useRef<FormikProps<any>>(null);
    let dialogoSobreescribirInternoRef = useRef<any>(null);
    let history = useHistory();
    let { mostrarError } = useContext(AppContext);
    let api = useApi();
    let { interno } = useDecodedParams() as { interno: string };
    let [notFound, updateNotFound] = useState(false);
    let [estado, updateEstado] = useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'cargarCaratula') {
            return { ...estado, caratula: accion.caratula, articulos: accion.articulos, cargando: false };
        } else if (accion.tipo === 'setMostrarModalCrearInternoSintia') {
            return {
                ...estado, mostrarModalCrearInternoSintia: accion.mostrar,
                tiposOperacion: accion.tiposOperacion ?? [], cargando: false
            };
        } else if (accion.tipo === 'setMostrarModalCrearInternoSintiaAutonumerico') {
            return {
                ...estado, mostrarModalCrearInternoSintiaAutonumerico: accion.mostrar,
                tiposOperacion: accion.tiposOperacion ?? [], cargando: false
            };
        } else if (accion.tipo === 'setMensajeModalSobreescribir') {
            return { ...estado, mensajeModalSobreescribir: accion.valor };
        } else if (accion.tipo === 'setGenerandoInterno') {
            return { ...estado, generandoInterno: accion.valor };
        } else if (accion.tipo === 'setCargando') {
            return { ...estado, cargando: accion.valor };
        }
        return { ...estado };
    }, {
        caratula: null, articulos: [], mostrarModalCrearInternoSintia: false,
        mostrarModalCrearInternoSintiaAutonumerico: false,
        mensajeModalSobreescribir: '', generandoInterno: false, cargando: true,
        tiposOperacion: []
    });
    useEffect(() => {
        async function cargar() {
            try {
                let respuesta = await api.getCaratulaProcesoExcel(interno);
                if (!respuesta) {
                    updateNotFound(true);
                    return;
                }
                updateEstado({ tipo: 'cargarCaratula', caratula: respuesta.Caratula, articulos: respuesta.Articulos ?? [] });
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar caratula proceso excel', error);
                    mostrarError('Hubo un error al cargar la caratula del Proceso Excel');
                }
            }
        }
        cargar();
        //eslint-disable-next-line 
    }, []);
    async function eventoBotonGenerarInterno() {
        let usarAutonumerico: boolean;
        let mascaraAutonumerico: string | null | undefined;
        try {
            updateEstado({ tipo: 'setCargando', valor: true });
            let respuesta = await api.getConfiguracionSintia();
            if (respuesta.ConfiguracionAdmin?.AutonumericoPorEmpresa) {
                try {
                    let respuesta = await api.getConfiguracionEmpresa();
                    if (respuesta.ConfiguracionAdmin) {
                        usarAutonumerico = respuesta.ConfiguracionAdmin.UsarAutonumerico ?? false;
                        mascaraAutonumerico = respuesta.ConfiguracionAdmin.MascaraAutonumerico;
                    } else {
                        usarAutonumerico = false;
                        mascaraAutonumerico = undefined;
                    }
                } catch (error) {
                    if (!api.isCancel(error)) {
                        console.error('Error al obtener configuración de empresa', error);
                        mostrarError('Error al obtener configuración de empresa');
                        updateEstado({ tipo: 'setCargando', valor: false });
                    }
                    return;
                }
            } else {
                usarAutonumerico = respuesta.ConfiguracionAdmin?.UsarAutonumerico ?? false;
                mascaraAutonumerico = respuesta.ConfiguracionAdmin?.MascaraAutonumerico;
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al obtener configuración', error);
                mostrarError('Error al obtener configuración');
                updateEstado({ tipo: 'setCargando', valor: false });
            }
            return;
        }
        try {
            let tipos = await api.getTiposOperacion() ?? [];
            if (usarAutonumerico) {
                if (isNullOrWhiteSpace(mascaraAutonumerico)) {
                    mostrarError('Se ha habilitado crear caratulas con autonumerico pero no hay una máscara creada. Debe crear una máscara para crear carpetas con autonumerico');
                    updateEstado({ tipo: 'setCargando', valor: false });
                } else if (mascaraAutonumerico!.includes('##tipo##') && tipos.length === 0) {
                    mostrarError('La máscara tiene tipo pero no hay ningún tipo creado');
                    updateEstado({ tipo: 'setCargando', valor: false });
                } else {
                    updateEstado({
                        tipo: 'setMostrarModalCrearInternoSintiaAutonumerico', mostrar: true,
                        tiposOperacion: tipos
                    });
                }
            } else {
                updateEstado({
                    tipo: 'setMostrarModalCrearInternoSintia', mostrar: true,
                    tiposOperacion: tipos
                });
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al obtener tipos de operación', error);
                mostrarError('Error al obtener tipos de operación');
                updateEstado({ tipo: 'setCargando', valor: false });
            }
        }
    }
    async function generarInterno(values: { InternoSintia: string, TipoOperacion?: string, CodigoSubregimen: string }) {
        updateEstado({ tipo: 'setMostrarModalCrearInternoSintia', mostrar: false });
        updateEstado({ tipo: 'setGenerandoInterno', valor: true });
        try {
            let caratula = await api.getCaratula(values.InternoSintia);
            let sobreescribir = false;
            if (caratula) {
                if (caratula.Bloqueada) {
                    mostrarError(`No se puede transferir el interno ${values.InternoSintia} porque la caratula está bloqueada`);
                    updateEstado({ tipo: 'setGenerandoInterno', valor: false });
                    return;
                }
                updateEstado({ tipo: 'setMensajeModalSobreescribir', valor: `El interno ${values.InternoSintia} ya existe. ¿Desea sobreescribirlo o agregar los subitems a la caratula?` });
                let accion = await dialogoSobreescribirInternoRef.current!.mostrar();
                if (accion === AccionRealizadaDialogoSobreescribirInterno.Cancelar) {
                    updateEstado({ tipo: 'setGenerandoInterno', valor: false });
                    return;
                }
                sobreescribir = accion === AccionRealizadaDialogoSobreescribirInterno.Sobreescribir;
            }
            let puedeModificar = await api.obtenerLock(TipoLock.Caratula, values.InternoSintia);
            if (puedeModificar) {
                await api.transferirCaratulaProcesoExcelASintia(interno, values.InternoSintia,
                    values.CodigoSubregimen, sobreescribir, values.TipoOperacion);
                await api.eliminarLock(TipoLock.Caratula, values.InternoSintia);
                history.push('/caratulas/' + encodeURIComponent(values.InternoSintia), { mostrarTabInicial: true });
            } else {
                mostrarError(`No se puede modificar el interno ${values.InternoSintia} porque otro usuario está modificandolo`);
                updateEstado({ tipo: 'setGenerandoInterno', valor: false });
            }
        } catch (error) {
            if (!api.isCancel(true)) {
                console.error('Error al generar interno Sintia', error);
                mostrarError('Error al generar interno Sintia');
            }
            if (!api.isUnmounted()) {
                updateEstado({ tipo: 'setGenerandoInterno', valor: false });
            }
        }
    }
    async function generarInternoAutonumerico(values: { TipoOperacion?: string, CodigoSubregimen: string }) {
        updateEstado({ tipo: 'setMostrarModalCrearInternoSintiaAutonumerico', mostrar: false });
        updateEstado({ tipo: 'setGenerandoInterno', valor: true });
        try {
            let { exito, error, internoSintia } = await api.transferirCaratulaProcesoExcelASintiaAutonumerico(interno,
                values.CodigoSubregimen, values.TipoOperacion);
            if (exito) {
                history.push('/caratulas/' + encodeURIComponent(internoSintia), { mostrarTabCarpeta: true });
            } else {
                mostrarError(error as string);
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al generar interno Sintia', error);
                mostrarError('Error al generar interno Sintia');
            }
            if (!api.isUnmounted()) {
                updateEstado({ tipo: 'setGenerandoInterno', valor: false });
            }
        }
    }
    function getTipoOperacionPorDefecto(tipos: Array<{ Codigo: string, PorDefecto?: boolean }>) {
        if (tipos.length === 1) {
            return tipos[0].Codigo;
        } else {
            return tipos.find(t => t.PorDefecto)?.Codigo ?? '';
        }
    }
    function getOptionsTiposOperacion(tipos: Array<{ Codigo: string, Descripcion: string }>) {
        return tipos.map(t => ({ value: t.Codigo, label: t.Descripcion }));
    }
    const sumaFob = estado.articulos?.reduce((acc: Decimal, cur: any) => acc.plus(cur.FobTotal), new Decimal(0));
    const sumaKgNeto = estado.articulos?.reduce((acc: Decimal, cur: any) => acc.plus(cur.KgNeto), new Decimal(0));
    return notFound ? <NotFoundComponent></NotFoundComponent> : <>
        <Container>
            <h2>Proceso Excel</h2>
            <h4>Caratula {interno}</h4>
            <BlockUi blocking={estado.cargando}>
                <dl style={{ fontSize: '1.2em' }}>
                    <div className="row">
                        <dt className="col">Articulos completos</dt>
                        <dd className="col">{estado.articulos?.filter((art: any) => art.EstadoArticulo === EstadoArticuloProcesoExcel.OK)?.length}</dd>
                    </div>
                    <div className="row">
                        <dt className="col">Articulos incompletos</dt>
                        <dd className="col">{estado.articulos?.filter((art: any) => art.EstadoArticulo !== EstadoArticuloProcesoExcel.OK)?.length}</dd>
                    </div>
                    <div className="row">
                        <dt className="col">Cantidad de lineas</dt>
                        <dd className="col">{estado.articulos?.length}</dd>
                    </div>
                    <div className="row">
                        <dt className="col">Monto total de articulos</dt>
                        <dd className="col">{toFixedDecimal(sumaFob, 2)}</dd>
                    </div>
                    <div className="row">
                        <dt className="col">Kg neto total de articulos</dt>
                        <dd className="col">{toFixedDecimal(sumaKgNeto, 2)}</dd>
                    </div>
                </dl>
                <Button className="w-100 my-2" onClick={() => history.push('/procesoExcel/caratulas/' + encodeURIComponent(interno) + '/articulos?mostrar=error')}>Articulos incompletos</Button>
                <Button className="w-100 my-2" onClick={() => history.push('/procesoExcel/caratulas/' + encodeURIComponent(interno) + '/articulos?mostrar=ok')}>Articulos completos</Button>
                <Button className="w-100 my-2" onClick={eventoBotonGenerarInterno} disabled={estado.articulos?.filter((art: any) => art.EstadoArticulo !== EstadoArticuloProcesoExcel.OK)?.length > 0}>Generar interno Sintia</Button>
                <Button variant="danger" className="my-2" onClick={() => history.push('/procesoExcel')}>Atrás</Button>
                <Button className="m-2" onClick={() => history.push('/procesoExcel/cargar/' + encodeURIComponent(interno))}>Volver a cargar Excel</Button>
            </BlockUi>
        </Container>
        <MyModal show={estado.mostrarModalCrearInternoSintia} onHide={() => updateEstado({ tipo: 'setMostrarModalCrearInternoSintia', mostrar: false })}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    Generar Interno
                </Modal.Header>
                <Modal.Body>
                    <Formik innerRef={formikRef} onSubmit={generarInterno} validationSchema={Yup.object({
                        'InternoSintia': Yup.string().nullable().required('Debe ingresar el interno'),
                        'CodigoSubregimen': Yup.string().nullable().required('Debe seleccionar el subregimen'),
                        'TipoOperacion': Yup.string().nullable().test('tipo', 'Debe seleccionar el tipo de operación',
                            (valor: any) => estado.tiposOperacion.length === 0 ? true : !isNullOrWhiteSpace(valor))
                    })} initialValues={{
                        InternoSintia: estado.caratula?.Carpeta,
                        TipoOperacion: getTipoOperacionPorDefecto(estado.tiposOperacion),
                        CodigoSubregimen: ''
                    }}>
                        <MyForm blockWhenSubmitting={false}>
                            <Form.Group controlId="txtInternoExcel">
                                <Form.Label>Carpeta Excel</Form.Label>
                                <Form.Control plaintext disabled value={estado.caratula?.Carpeta}></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <MyFormControl name="InternoSintia" label="Interno" type="text" autoFocus></MyFormControl>
                            </Form.Group>
                            {estado.tiposOperacion.length === 0 ? <Field type="hidden" name="TipoOperacion"></Field> :
                                <Form.Group>
                                    <MySelect name="TipoOperacion" label="Tipo Operación"
                                        options={getOptionsTiposOperacion(estado.tiposOperacion)}></MySelect>
                                </Form.Group>}
                            <Form.Group>
                                <MySelect name="CodigoSubregimen" label="Subregimen" getOptionLabel={optionLabelConCodigo}
                                    options={() => api.getSubregimenes().then(convertirDatosGenericosAOption)}></MySelect>
                            </Form.Group>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstado({ tipo: 'setMostrarModalCrearInternoSintia', mostrar: false })}>
                        Cancelar
                    </Button>
                    <Button onClick={() => formikRef.current?.submitForm()}>
                        Ingresar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estado.mostrarModalCrearInternoSintiaAutonumerico} onHide={() => updateEstado({ tipo: 'setMostrarModalCrearInternoSintiaAutonumerico', mostrar: false })}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    Generar Interno
                </Modal.Header>
                <Modal.Body>
                    <Formik innerRef={formikAutonumericoRef} onSubmit={generarInternoAutonumerico} validationSchema={Yup.object({
                        'CodigoSubregimen': Yup.string().nullable().required('Debe seleccionar el subregimen'),
                        'TipoOperacion': Yup.string().nullable().test('tipo', 'Debe seleccionar el tipo de operación',
                            (valor: any) => estado.tiposOperacion.length === 0 ? true : !isNullOrWhiteSpace(valor))
                    })} initialValues={{
                        TipoOperacion: getTipoOperacionPorDefecto(estado.tiposOperacion),
                        CodigoSubregimen: ''
                    }}>
                        <MyForm blockWhenSubmitting={false}>
                            <Form.Group controlId="txtInternoExcel">
                                <Form.Label>Carpeta Excel</Form.Label>
                                <Form.Control plaintext disabled value={estado.caratula?.Carpeta}></Form.Control>
                            </Form.Group>
                            {estado.tiposOperacion.length === 0 ? <Field type="hidden" name="TipoOperacion"></Field> :
                                <Form.Group>
                                    <MySelect name="TipoOperacion" label="Tipo Operación"
                                        options={getOptionsTiposOperacion(estado.tiposOperacion)}></MySelect>
                                </Form.Group>}
                            <Form.Group>
                                <MySelect name="CodigoSubregimen" label="Subregimen" getOptionLabel={optionLabelConCodigo}
                                    options={() => api.getSubregimenes().then(convertirDatosGenericosAOption)}></MySelect>
                            </Form.Group>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateEstado({ tipo: 'setMostrarModalCrearInternoSintiaAutonumerico', mostrar: false })}>
                        Cancelar
                    </Button>
                    <Button onClick={() => formikAutonumericoRef.current?.submitForm()}>
                        Ingresar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estado.generandoInterno}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead">Generando Interno Sintia...</p>
                    <div className="loader-container">
                        <Loader type="ball-spin-fade-loader" active></Loader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        api.cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <DialogoSobreescribirInterno ref={dialogoSobreescribirInternoRef} mensaje={estado.mensajeModalSobreescribir}></DialogoSobreescribirInterno>
    </>;
}